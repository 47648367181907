.ag-theme-balham {
    --ag-font-size: 11px;
}

.ag-header-row-column .ag-header-cell-label .ag-header-cell-text {
    white-space: pre-wrap !important;
    text-align: center;
}

.mi-clase {
    text-align: center;
}

.primera_columna {
    color: #185DAA;
    font-weight: bold;
}

.rectangle {
    height: 22px;
    width: 22px;
    color: white;
    background-color: rgb(77, 77, 77);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
}

.ag-theme-balham .ant-tag {
    margin-inline-end: 0px !important;
    padding-inline: 0px !important;
}