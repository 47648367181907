.riesgos {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    overflow-y: scroll;
    height: 42vh;
}

.scrollable-column {
    width: 335px;
    overflow-y: hidden;
}

.riesgo {
    line-height: 0px;
    cursor: pointer;
    position: relative;
    height: 31px;
    padding: 10px;
    margin: 2px;
}

.riesgo>.selected {
    border: 1px solid black;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: black;
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.overlay {
    position: absolute;
    top: 0;
    margin-bottom: 15px;
    left: 0;
    width: 82%;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding: 20px;
    box-shadow: inset 0em -2em 3em rgba(0, 0, 0, 0.1),  0em 0.5em 0.5em rgba(0, 0, 0, 0.3);
}

.riesgo:hover .overlay {
    opacity: .5;
}

.grafica {
    width: 65%;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-top: 20px;
    margin-bottom: 30px;
}

.selected {
    opacity: 1;
}

.oculta {
    display: none;
}

.progress {
    height: 19px;
    width: 95%;
    border-radius: 5px;
    background-color: #e6f3fa;
    margin-top: 10px;
    font-size: 11px;
}

.progress-bar {
    height: 100%;
    display: flex;
    align-items: center;
    transition: width 0.25s;
    border-radius: 5px;
}

.red {
    background: linear-gradient(rgba(243, 105, 105, 0.92),  #c80000eb );
    color: white;
}
.orange {
    background: linear-gradient(#ff7d0bc3, #e36c04dc );
    color: white;
}
.yellow {
    background: linear-gradient(rgb(247, 243, 11), rgb(239, 239, 4));
    color: rgb(129, 125, 125);
}
.green {
    background: linear-gradient(rgb(9, 218, 9), rgb(2, 115, 2));
    color: rgb(239, 232, 232);
}
.soft-green {
    background: linear-gradient(rgba(5, 255, 5, 0.75), rgba(10, 211, 7, 0.819));
    color: rgb(144, 138, 138);
}

.progress-bar-text {
    margin-left: 10px;
    font-weight: bold;
    position: absolute;
}

.restablecerRiesgos{
    cursor: pointer;
    font-size: small;
    color: rgba(255, 0, 0, 0.696);
}
.restablecerRiesgos:hover {
    color: rgb(255, 0, 0);
    transition: 0.4s;
}

.div-padre-boton :hover{
    color: red;
    transition: 0.4s;
}