@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.todoLogin {
  background-image: url('PortadaVeltis.png');
  /*opacity: 0.92;*/
  height: 100vh; 
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center; 
  /*background-position: 0vh -10vh;*/
  background-size: cover;
  background-size: 100vw 100vh;
}

/* Estilos para el contenedor principal*/
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  background: rgba(14, 13, 13, 0.596);
  border-radius: 16px;
  backdrop-filter: blur(6.8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.2);
  width: 570px;
  height: 480px;
  margin: 0px auto;
  margin-right: 5%;
  padding-bottom: 34px;
  font-family: 'Roboto', sans-serif;
  position: relative;
  z-index: 1234;
  animation: aparecerDerechaIzquierda 0.5s forwards;
}


.login-header {
  font-size: 22px;
  color: rgb(255, 255, 255);
  max-width: 79%;
  font-weight: 600;
  margin-left: 38px;
}

/* Estilos para el formulario de inicio de sesi贸n */
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

/* Estilos para los campos de entrada */
.login-input {
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 35px;
  width: 165%;
  border: none;
  border-bottom: 1.5px solid #fffffff5;
  font-size: 22px;
  color: #ffffff;
  outline: none;
  background-color: transparent;
  position: relative;
}

.login-input:focus {
  border-bottom: 2px solid #ffffff;
  transition: 0.3s;
}

/* Estilos para el bot贸n de inicio de sesi贸n */
.login-button {
  padding: 10px 20px;
  background-color: #0a0a0a3a;
  color: #fffffff0;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-image: linear-gradient(#777676ab, #1b1a1a57);
  margin-top: 16px;
  margin-bottom: 50px;
  margin-left: 1px;
  margin-right: 22px;
  z-index: 104;
  transition: 1s;
  backdrop-filter: blur(9.8px);
  border: 0.2px solid rgba(255, 255, 255, 0.733);
}


.login-button:hover {
  transition: 0.5s;
  background-color: #0b5a878c;
  color: white;
}

@keyframes aparecerDerechaIzquierda {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.registro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  background-image: linear-gradient(#3f3e3e, #07070757);
  background: rgba(14, 13, 13, 0.596);
  border-radius: 16px;
  backdrop-filter: blur(6.8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.2);
  width: 600px;
  height: 550px;
  margin: 0px auto;
  margin-right: 5%;
  padding-bottom: 54px;
  font-family: 'Roboto', sans-serif;
  position: relative;
  z-index: 1234;
  animation: aparecerDerechaIzquierda 0.5s forwards;
}
.registro .ant-form-item-label > * {
  color: white !important;
}

.registro-button {
  font-size: 18px;
  margin-top: 2px;
  margin-left: 10px;
  cursor: pointer;
  background-image: none;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.596);
  border-top: none;
  border-right: none;
  border-left: none;
  padding: 1px;
  border-width: 0.3px;
  transition: 1s;
}

.registro-button:hover {
  color: white;
  transition: 0.5s;
}

/* Estilos para descripci贸n de abajo */
.descripcionTerminal {
  text-align: center;
  margin: 20px;
  font-size: small;
  line-height: 14px;
  font-family: 'Roboto', sans-serif;
}

.descripcionTerminal h2 {
  color: #005690ad;
  margin-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.des1 {
  color: rgb(51, 48, 48);
  font-weight: 500;
}

.des2 {
  color: rgba(51, 48, 48, 0.603);
}

.selloVeltis {
  position: absolute;
  top: 60%;
  height: 220px;
  width: auto;
  margin: 18px;
  margin-left: 40px;

}

.oculta {
  display: none;
}

@media screen and (max-width: 600px) {

  .todoLogin{
    height: 640px;
  }
  .logoVeltis {
    display: block;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    transition: 1s;
  }

  .login-container {
    width: 340px;
    height: 400px;
    justify-content: center;
    margin: auto;
    
  }

  .login-header {
    max-width: 76%;
    font-size: large;
    margin-left: 0;
  }

  .login-input {
    width: 110%;
    border: 1px solid #ffffffa2;
    border-radius: 10px;
  }

  .login-input:focus {
    border: 1px solid #ffffff;
    transition: 0.3s;
  }

  .registro {
    width: 240px;
    height: 560px;
    transition: 1s;
    justify-content: center;
    margin: auto;
    padding: 60px;

  }

  .registrar {
    display: block;
    width: 90%;
    font-weight: 800;
    margin: auto;
  }

  .botonesRegistro{
    display: flex;
  }
}