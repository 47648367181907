.div-botonera{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 1%;
    margin-left: 3%;
    margin-right: 17%;
    margin-bottom: 5%;
}

.div-padre-boton{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 5px;
    padding: 0.5%;
    padding-left: 1%;
    padding-right: 1%;
    border: 1px solid lightgray;
    border-radius: 10px;
    background-color: rgb(250, 250, 250);
    cursor: pointer;
}