.contenedor {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1%;
  padding-bottom: 5%;
  margin: 1%;
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: rgb(240, 240, 240);
  min-width: 50%;
  max-width: 95%;
}

.displayBarras {
  margin-top: 5%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin: 0.2%;
}

.pointer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: small;
  font-weight: 600;
}

.boxes {
  width: 12px;
  padding: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
}

.leyenda {
  margin-top: -5%;
  margin-bottom: 4.5%;
  font-size: 14px;
  color: #666;
  text-align: center;
  font-weight: 600;
}