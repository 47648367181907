.navbar-desktop {
    border-bottom: 1px solid lightgrey;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar {
    display: flex;
    align-items: center;
}

.navbar-item {
    margin-top: 10px;
}

.navbar-item :hover {
    background-color: white;
}

.logout {
    text-align: right;
    width: 140px;
}
.ant-dropdown-link{
    font-size: 17px;
    font-family: Arial, sans-serif;
}