.tabla_climaticos {
    margin-top: 5px;
    margin-bottom: 5px;
}

.titulos_tabla_climaticos th{
    padding-bottom: 1%;
    font-weight: 650;
    font-size: small;
}

.velocimetroo{
    /*width: 600px;*/
    /*height: 200px;*/
    display: flex;
    justify-content: center;
}
.contenttt {
    position: relative;
    top: -157px;
}

.contenido_tabla_climaticos td{
    padding-top: 1%;
    padding-bottom: 1%;
    font-weight: 550;    
    text-align: center;
    vertical-align: middle;
}

.fila-impacto td{
    background-color: rgb(245, 245, 245);
}

.fila-riesgo td{
    background-color: rgb(230, 230, 230);
}