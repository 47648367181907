
* {
    margin: 0;
    padding: 0;
    text-indent: 0;
}


.s1 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 9pt;
}

.s2 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
}

.s3 {
    color: #FFF;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 4.5pt;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #00963F;
    width: 50%;
}

.sb {
    color: #FFF;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 4.5pt;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #21B24B;
    width: 55%;
}
.sc {
    color: #FFF;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 4.5pt;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #99ca3d;
    width: 60%;
}
.sd {
    color: #FFF;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 4.5pt;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #ece824;
    width: 65%;
}
.se {
    color: #FFF;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 4.5pt;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #F0B418;
    width: 70%;
}
.sf {
    color: #FFF;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 4.5pt;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #E07627;
    width: 75%;
}
.sg {
    color: #FFF;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 4.5pt;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #E52E29;
    width: 80%;
}


.s4 {
    color: #FFF;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 7.5pt;
    vertical-align: -1pt;
}

.s5 {
    color: white;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 6.5pt;
    background-color: black;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 40%;
}

.none {
    display: none;
}

.s6 {
    color: #FFF;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 7.5pt;
}


.s7 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.s8 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: italic;
    font-weight: bold;
    text-decoration: none;
    font-size: 10pt;
}

.s9 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: italic;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.s10 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.s11 {
    color: #FFF;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 5.5pt;
}

.s12 {
    color: #FFF;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 9.5pt;
    vertical-align: -1pt;
}

.s13 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 8.5pt;
}

.s14 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: italic;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.s15 {
    color: #FFF;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 9.5pt;
}

.s16 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7pt;
}


.s17 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 8pt;
}

.s18 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 6pt;
}

.s19 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7pt;
}

.s20 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 10pt;
}

li {
    display: block;
}

#l1 {
    padding-left: 0pt;
}

#l1>li>*:first-child:before {
    content: "○ ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
}

li {
    display: block;
}

#l2 {
    padding-left: 0pt;
}

#l2>li>*:first-child:before {
    content: "● ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
}

li {
    display: block;
}

#l3 {
    padding-left: 0pt;
}

#l3>li>*:first-child:before {
    content: "● ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
}

#l4 {
    padding-left: 0pt;
}

#l4>li>*:first-child:before {
    content: "● ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
}

li {
    display: block;
}

#l5 {
    padding-left: 0pt;
}

#l5>li>*:first-child:before {
    content: "○ ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
}

#l6 {
    padding-left: 0pt;
}

#l6>li>*:first-child:before {
    content: "○ ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
}

li {
    display: block;
}

#l7 {
    padding-left: 0pt;
}

#l7>li>*:first-child:before {
    content: "○ ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
}

#l8 {
    padding-left: 0pt;
}

#l8>li>*:first-child:before {
    content: "○ ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
}

li {
    display: block;
}

#l9 {
    padding-left: 0pt;
    counter-reset: h1 1;
}

#l9>li>*:first-child:before {
    counter-increment: h1;
    content: counter(h1, decimal)". ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 10pt;
}

#l9>li:first-child>*:first-child:before {
    counter-increment: h1 0;
}

li {
    display: block;
}

#l10 {
    padding-left: 0pt;
    counter-reset: i1 1;
}

#l10>li>*:first-child:before {
    counter-increment: i1;
    content: counter(i1, decimal)". ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 10pt;
}

#l10>li:first-child>*:first-child:before {
    counter-increment: i1 0;
}

li {
    display: block;
}

#l11 {
    padding-left: 0pt;
}

#l11>li>*:first-child:before {
    content: "- ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
}

.moster table, .moster tbody {
    vertical-align: top;
    overflow: visible;
    border: solid black 1px;
}

/* estilos de los anexos */

.div-una-fila {
    display: flex;
    margin-top: 2%;
    margin-bottom: 2%;
    empty-cells: show;
    & div {
        border: 1px solid black;
        width: 100%;
        padding-left: 1%;
    }
    & :nth-child(odd) {
        background-color: #FDE8D8;
        font-weight: 700;
    }
}

.tabla-img {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    margin-top: 2%;
    margin-bottom: 2%;
    empty-cells: show;
    & thead{
        background-color: #FDE8D8;
        font-size: medium;
        font-weight: 700;
    }
    & tr{
        border: 1px solid black;
    }
    & td{
        text-align: center;
    }
    & img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
    }
}

.tabla-envolvente-termica {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    margin-top: 2%;
    margin-bottom: 2%;
    empty-cells: show;
    & thead{
        background-color: #FDE8D8;
        font-size: medium;
        font-weight: 700;
    }
    & tr{
        border: 1px solid black;
    }
    & td{
        text-align: center;
        vertical-align: middle;
        border: 1px solid black;
    }
    & tbody :first-child {
        padding-left: 1%;
        text-align: left;
    }
}

.tabla-instalaciones-termicas-iluminacion {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    margin-top: 2%;
    margin-bottom: 2%;
    empty-cells: show;
    & thead{
        background-color: #FDE8D8;
        font-size: medium;
        font-weight: 700;
    }
    & tr{
        border: 1px solid black;
    }
    & td{
        text-align: center;
        vertical-align: middle;
        border: 1px solid black;
    }
    & tbody :first-child {
        padding-left: 1%;
        text-align: left;
    }
    & tbody tr:last-child {
        background-color: #ffcaa1;
    }
    & tbody tr:last-child td:first-child {
        text-align: center;
        font-weight: 800;
    }
}

.tabla-calificacion-energetica {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    empty-cells: show;
    & thead{
        background-color: #FDE8D8;
        font-size: medium;
        font-weight: 700;
    }
    & tr{
        border: 1px solid black;
    }
    & td{
        text-align: center;
        vertical-align: middle;
        border: 1px solid black;
    }
    & tbody tr:nth-child(even) {
        background-color: white;
    }
    & tbody tr:nth-child(1) {
        background-color: #FDE8D8;
        font-weight: 700;
    }
    & tbody tr:nth-child(4) {
        background-color: #FDE8D8;
        font-weight: 700;
    }
}

.tabla-emisiones-consumo {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    margin-top: 2%;
    margin-bottom: 2%;
    empty-cells: show;
    & thead{
        background-color: #FDE8D8;
        font-size: medium;
        font-weight: 700;
    }
    & tr{
        border: 1px solid black;
    }
    & td{
        text-align: center;
        vertical-align: middle;
        border: 1px solid black;
    }
    & thead td:first-child{
        background-color: white;
        border-top: hidden;
        border-left: 1px solid white;
    }
}

.tabla-analisis-tecnico {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    margin-top: 2%;
    margin-bottom: 2%;
    empty-cells: show;
    & thead{
        background-color: #FDE8D8;
        font-size: medium;
        font-weight: 700;
    }
    & thead tr:last-child {
        background-color: #FDE8D8;
        font-size: small;
        font-weight: 500;
    }
    & tbody tr:first-child td:nth-child(odd){
        background-color: #ffcaa1;
    }
    & tbody tr:nth-child(1n+2) td:nth-child(3n + 1){
        background-color: #ffcaa1;
    }
    & tbody td:first-child{
        background-color: #FDE8D8 !important;
    }
    & tr{
        border: 1px solid black;
    }
    & td{
        text-align: center;
        vertical-align: middle;
        border: 1px solid black;
    }
}

.tabla-descripcion-medida {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    margin-top: 2%;
    margin-bottom: 2%;
    empty-cells: show;
    & thead{
        background-color: #FDE8D8;
        font-size: medium;
        font-weight: 700;
    }
    & tr{
        border: 1px solid black;
    }
    & td{
        padding: 1%;
        text-align: left;
        border: 1px solid black;
    }
}