ul {
    list-style-type: none;
}

.grid {
    column-count: 3;
    column-gap: 20px;
    line-height: 2.5;
    margin-left: 10%;
}

li {
    break-inside: avoid-column;
}

h2 {
    font-weight: 700;
    margin-left: 0px;
    margin-top: 20px;

}

.ValoracionTop {
    white-space: none;
    overflow: hidden;
    background-color: white;
    padding: 0;
    display: flex;
    gap: 20px;
    margin-bottom: 50px;

    display: flex;
    justify-content: space-evenly;

}

.infoActivos {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10%;
}

.foto {
    border: 1px solid lightgray;
    border-radius: 5px;
    width: fit-content;
    height: fit-content;
    margin-top: 30px;
    margin-left: 5%;
}

.foto img {
    height: auto;
    max-width: 250px;
}

.datosCalle,
.datosCat {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: right;
    margin-top: 2%;
}

.datosActivo h2 {
    text-align: center;
    font-weight: 700;
}

.valorActivo {
    font-size: 120%;
    text-align: center;
    margin-top: 10px;
    color: #258bffdc;
    font-weight: 600;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
}

.numValorActivo {
    border: none;
    padding-bottom: 4px;
    padding-top: 4px;
    font-size: 110%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imgcat {
    border-radius: 11px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.numValorActivo h3 {
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgb(207, 207, 207);
    background-color: #f2f2f2;
    border: 2px solid #ccc;


}

.precio h3 {
    color: rgba(0, 0, 0, 0.434);
}
.precio {
    font-size: 105%;
    font-weight: bold;
}

.valoracionVenta {
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgb(207, 207, 207);
    background-color: white;
    border: 2px solid #ccc;
    height: 37px;
}

.valoracionVenta p {
    font-size: 12px;
    color: black;
}

.valoracionVenta h4 {
    color: grey;
}


.icon {
    margin-top: 4px;
    margin-right: 3px;
}

.dataTabla {
    display: flex;
}

.accesoDenegado {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: -40%;
    background-color: rgba(255, 255, 255, 0.9);
}

.accesoPermitido {
    position: absolute;
    z-index: -1;
}

.tabla-historico-tasacion {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 4%;
    & th {
        background-color: #f5f5f5;
        text-align: center;
        font-weight: bold;
        border: 1px solid #e8e8e8;
        padding: 8px;
    }
    & td {
        border: 1px solid #e8e8e8;
        text-align: center;
        padding: 8px;
      }
      & tr:nth-child(even) {
        background-color: #f7f7f7;
      }
      & tr:hover {
        background-color: #e6f7ff;
        cursor: pointer;
      }
}

@media screen and (max-width: 1200px) {
    .grid {
        column-count: 2;
    }

    .numValorActivo {
        margin-left: 30%;
        margin-right: 30%;

    }
}

@media screen and (max-width: 800px) {
    .grid {
        column-count: 1;
    }

    .ValoracionTop {
        flex-direction: column;
        margin: 20;
    }

    .datosActivo {
        text-align: center;
    }
}