.xxx {
  display: flex;
  align-items: center;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  /* text-align: center; */
  border-block-start: 0 rgba(5, 5, 5, 0.06);
}

.xxx .ant-space {
  position: absolute;
  right: 10px;
}

/* Estilos de la tabla */
.table {
  width: 100%;
  border-collapse: collapse;
  min-width: min-content;
  white-space: normal;
}

.table th,
.table td {
  padding: 6px 5px;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
}

.table th {
  background-color: #f5f5f5;
  height: 10%;
  font-size: small;
  padding-left: 5%;
}

.table td {
  font-size: smaller;
  text-align: center;
  vertical-align: middle;
}

.table tbody {
  white-space: nowrap;
}

.table2 tbody {
  white-space: nowrap;
}

.table tbody tr{
  overflow: auto;
}

.table2 tbody tr{
  overflow: auto;
}

.table tbody tr:hover {
  background-color: #fafafa;
}

.table-header th {
  background-color: #c7c7c7;
  font-weight: 600;
  color: #000000;
}

.table-data td {
  color: #595959;
  white-space: break-spaces;
}

.table-data-alt td {
  background-color: #fafafa;
}

.table-data td:nth-child(even) {
  border-left: 1px solid lightgrey;
  border-right: 1px solid black;
}

tr:nth-child(even) {
  background-color: #e6e4e4;
}

/* Estilos de la tabla 2*/
.table2 {
  width: 100%;
  border-collapse: collapse;
  min-width: fit-content;
}

.table2 th,
.table2 td {
  /*padding: 12px 16px;*/
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
}

.table2 th {
  background-color: #f5f5f5;
  height: 10%;
  font-size: small;
  text-align: center;
  vertical-align: middle;
}

.table2 td {
  font-size: smaller;
  text-align: center;
  vertical-align: middle;
}

.table2 tbody tr:hover {
  background-color: #fafafa;
}

.table-header2 th {
  background-color: #c7c7c7;
  font-weight: 600;
  color: #000000;
}

.table-data2 td {
  border-left: 1px solid black;
  color: #595959;
}

.table-data-alt2 td {
  background-color: #fafafa;
}

.indic_riesg {
  display: flex;
  flex-wrap: wrap;
}

.graficos_indic_riesg {
  max-width: 90%;
  min-width: 50%;
}
.ceeactivo {
  font-weight: bold;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
.ceeactivo.a {
  color: #00963F;
}
.ceeactivo.b {
  color: #21B24B;
}
.ceeactivo.c {
  color: #99ca3d;
}
.ceeactivo.d {
  color: #ece824;
}
.ceeactivo.e {
  color: #F0B418;
}
.ceeactivo.f {
  color: #E07627;
}
.ceeactivo.g {
  color: #E52E29;
}