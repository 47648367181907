:root {
    --color-primario: #82c9f9bc; 
    --color-secundario: #1779baa7; 
  }

th {
    font-size: 1.2em;
}

.title{
    font-size: 30px;
    margin: auto;
    text-align:center;
    margin-bottom: 10px;
}
.tabla1{
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 12px;
    margin-bottom: 20px;
}
.simulacion{
    background-color: var(--color-secundario);
    color: white;
}
.subtitulos1{
    font-weight: 600;
    width: auto;
}
.subtitulos1 td{
    padding: 5px;
}
.tabla2{
    margin-top: 10px;
    margin-left: 20px;
    height:fit-content;
}

.espacio{
    background-color: var(--color-primario);
}
.liquidez{
    background-color: rgb(249, 217, 206);
    font-weight: 500;
}

.estilo1{
    background-color: var(--color-secundario);
    color: white;
}

.tabla3{
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 13px;
    margin-bottom: 0px;
    margin-top: -5px;  
}

.graficasHorizontales{
    margin-top: 50px;
    background: white;
}
.aportacion{
    background: var(--color-secundario);
    color: white;
    border-color: black;
}


