/* estilos del componente */
.bQleFN {
    background-color: rgb(250, 251, 252);
    /*width: 100%;*/
    padding: 16px;
}

::selection {
    background: rgb(250, 229, 160);
}

::-webkit-scrollbar-track {
    background-color: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb {
    background-color: rgb(186, 186, 192);
    border-radius: 16px;
    border: 2px solid rgb(255, 255, 255);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0px;
    padding: 0px;
    border: 0px;
    font: inherit;
    vertical-align: baseline;
}

.Wrobs {
    font-weight: 500;
    color: rgb(54, 60, 75);
    font-size: 24px;
    top: -12px;
    line-height: 28px;
}

html {
    font-family: Roboto, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: rgb(33, 38, 50);
    line-height: 1.5;
    height: -webkit-fill-available;
}

.bYxYhS {
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 4px;
    color: rgb(116, 125, 147);
}

.bQleFN header {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-start;
}

.jLmhIK {
    color: rgb(54, 60, 75);
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
}

h4 {
    font-family: Roboto, Arial, sans-serif;
    font-weight: 500;
}

.iihNdL {
    color: rgb(116, 125, 147);
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 16px;
}

.ggPjZk {
    color: rgb(182, 187, 198);
}

.eWBwvG {
    font-family: Roboto, Arial, sans-serif;
    font-weight: 500;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    margin: 0px;
    white-space: nowrap;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: auto;
    height: auto;
    color: rgb(116, 125, 147);
    padding: 3px 4px;
    font-size: 12px;
}

button {
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0px;
    padding: 0px;
}

.bQleFN .cards__container {
    background: rgb(250, 251, 252);
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

.iLnozd {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.ceXVlo {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.biRCTt {
    height: inherit;
    width: 1px;
    margin-right: 12px;
    /*background-color: rgb(220, 223, 230);*/
}

.byaMit ul {
    display: grid;
    margin-right: 24px;
    margin-top: 12px;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0.5rem;
}

.ldDgjY {
    padding: 2px 8px;
    background-color: rgb(239, 242, 247);
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    color: rgb(116, 125, 147);
}

/* estilos de las tablas */
table {
    border-collapse: collapse;
    border: none;
    background-color: transparent;
}

th,
td {
    width: 100px;
    /*padding: 1%;*/
    text-align: center;
    vertical-align: middle;
    font-size: .9em;
}

thead {
    font-size: 10px;
}

tbody {
    font-size: 14px;
    font-weight: 550;
}

/* td:nth-child(2) {
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
}

th:nth-child(2) {
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
} */

.irs--flat .irs-shadow {
    height: 1px;
    bottom: 16px;
    background-color: red;
}

.irs--flat .irs-bar {
    top: 25px;
    height: 12px;
    background-color: rgb(237, 85, 101, 0);
}

.irs-disabled {
    opacity: 1;
}

.irs--flat .irs-grid-text {
    color: #000;
}

.irs--flat .irs-min,
.irs--flat .irs-max {
    top: 0;
    padding: 1px 3px;
    color: #000;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    background-color: #e1e4e9;
    border-radius: 4px;
}

.size_15 {
    font-size: 15px;
    text-decoration: underline;
    padding-left: 10px;
    padding-top: 10px;
}

.dot {
    height: 10px;
    width: 10px;
    /*border-radius: 50%;*/
    display: inline-block;
}

.red {
    background-color: #c80000;
}

.green {
    background-color: #34641c;
}

.green_2 {
    background-color: #84a720;
}

.orange {
    background-color: #fa7300;
}

.white {
    background-color: #ffdf00;
}

.yellow {
    background-color: yellow;
}

.midd {
    font-size: 14px;
    color: black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    height: 30px;
}

.table_coyun tbody tr:nth-child(even):not(:first-child):not(.midd) td:not(:first-child) {
    background-color: rgba(255, 255, 255, .1);
}

.table_coyun tbody tr:nth-child(odd):not(:first-child):not(.midd) td:not(:first-child) {
    background: #b5e0ff
}

.table_coyun tbody tr:not(.midd) td:not(:first-child) {
    border-right: 1px solid black;
    border-left: 1px solid black;
}

.table_coyun tr:last-child {
    border-bottom: 1px solid black;
}

.table_coyun {
    font-size: 12px;
    color: #3077ba;
    border-spacing: 1px;
    border-collapse: collapse;
    font-weight: 600;
    font-size: 10px;
}

.table_coyun td {
    text-align: center;
    height: 22px;
    font-size: 10px;
    vertical-align: middle;
}

.table_coyun .mid {
    text-align: center;
    height: 22px;
    font-size: 15px;
}

.table_coyun th {
    font-size: 15px;
}

.table_act tbody tr:nth-child(even):not(.midd) td:not(:first-child) {
    background-color: rgba(255, 255, 255, .1);
}

.table_act tbody tr:nth-child(odd):not(.midd) td:not(:first-child) {
    background: #b5e0ff
}

.table_act tbody tr:not(.midd) td:not(:first-child) {
    border-right: 1px solid black;
    border-left: 1px solid black;
}

.table_act {
    font-size: 12px;
    color: #3077ba;
    border-spacing: 1px;
    border-collapse: collapse;
    font-weight: 600;
}

.table_act td {
    text-align: center;
}

.table_act th {
    font-size: 15px;
}

.back_fotlio {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 30.7cm;
    opacity: 1;
}

.water_mark_top {
    top: 9px;
    opacity: 1;
}