.filtros{
    display: flex; 
    flex-direction: column;
    gap: 8px;
    margin: 5%;
    width: 85%;

}
.boton-addlayer{
    background-color: rgba(128, 128, 128, 0.244);
    color: #ffffff;
    margin-bottom: 15px;
    margin-left: 10px;
}

/* Estilos generales de los select */
.select-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.select-wrapper .select-trigger {
    display: inline-block;
    padding: 8px 16px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    background-color: #fff;
    transition: border-color 0.3s;
}

.select-wrapper .select-trigger:hover {
    border-color: #40a9ff;
}

.select-wrapper .select-trigger:focus {
    border-color: #1890ff;
    outline: none;
}
  
/* Estilos del menú desplegable */
.select-wrapper .select-menu {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    z-index: 999;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: #fff;
}
  
.select-wrapper .select-menu-item {
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}
  
.select-wrapper .select-menu-item:hover {
    background-color: #f5f5f5;
}
  
.select-wrapper .select-menu-item.active {
    background-color: #e6f7ff;
}
  
.select-wrapper .select-menu-item.disabled {
    cursor: not-allowed;
    color: #ccc;
}
  
/* Estilos del ícono desplegable */
.select-wrapper .select-icon {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    pointer-events: none;
}
  
.select-wrapper .select-icon svg {
    width: 12px;
    height: 12px;
    fill: #aaa;
}
  
  /* Estilos para el texto seleccionado */
.select-wrapper .selected-text {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
  