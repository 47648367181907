.leaflet-container {
  width: '100%';
  height: '100%';
}

/* Estilos de la leyenda */
.legend {
  background-color: white;
  background: rgba(255, 255, 255, 0.457);
  border-radius: 5px;
  backdrop-filter: blur(2.8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.2);
  padding: 15px;
  font-size: 12px;
}

.legend-item {
  display: flex;
  align-items: center;
}

.legend-color {
  width: 20px;
  height: 10px;
  margin-right: 5px;
  margin-top: 4px;
}

#filtros-mapa{
  margin-left: -5%;
  margin-bottom: 10%;
  padding: 4%;
  padding-left: 7%;
  border: 1px solid lightgrey;
  border-radius: 7px;
}