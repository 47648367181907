.boton{
    position: absolute;
    z-index: 10000;
    top: 50%;
    bottom: 50%;
    transform: rotate(90deg);
    border-radius: 10;
    left: 540px;
    background: linear-gradient(white, rgb(243, 239, 239));
    color: grey;
}

.boton2{
    position: absolute;
    z-index: 10000;
    top: 50%;
    bottom: 50%;
    transform: rotate(90deg);
    border-radius: 10;
    background: linear-gradient(white, rgb(243, 239, 239));
    color: grey;
}

.afecciones{
    display: flex;
    justify-content: space-between;

}

.boton-editar{
    background-color: transparent;
    color: grey;
    box-shadow: none;
    margin-top: 14px;
}

.boton-guardar{
    background-color: transparent;
    color: grey;
    height: 30px;
    border-color: rgba(128, 128, 128, 0.294);
}

.oculta{
    display: none;
}
  
