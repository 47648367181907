.pointer-row:hover {
    cursor: pointer;
}
.graficos {
    margin: 20px;
    width: 90%;
}
.moco {
    background-color: #4096FF !important;
    font-weight: bold;
}