.main-section .ant-col {
    background: rgba(128, 128, 128, 0.03);
    margin-right: 10px;
}

.ant-tabs-content-holder {
    overflow-y: auto;
}

.ant-tabs-nav-list {
    margin-left: 10px;
}

.ant-tabs {
    height: 100%;
}

.container {
    font-family: arial;
    font-size: 24px;
    width: 100%;
    height: 100%;
    position: relative;
}

.child {
    background: rgba(0, 0, 0, 0.100);
    color: rgb(103, 241, 11);
    border-radius: 1em;
    padding: 2.5em;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.imgcat {
    border-radius: 11px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.wrapper2 {
    display: table-cell;
    /*vertical-align: middle;*/
    text-align: center;
    width: 150px;
    height: 300px;
    padding: 10px;
}

.container2 {
    display: inline-block;
    text-align: center;
    padding: 20px;
    font-size: 30px;
    /*top: 10px;*/
    /*position: relative;*/
}

.sc-gTRrQi .ant-tabs-nav-wrap {
    background-color: #80808047;
}

