@import url('https://fonts.googleapis.com/css2?family=Montserra&display=swap');

.minmaxSelectTop {
    line-height: 10px;
    display: flex;
    align-items: center;
}

.minmaxSelect {
    background-color: transparent;
    background: white;
    border: 1px solid #ccc;
    border-top: none;
    border-right: none;
    border-left: none;
    border-width: 1px;
    cursor: pointer;
    margin-right: -5px;
    border-bottom-color: #ccc;
    border-radius: 2px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333333e1;
}

.minmaxSelect select {
    outline: none;
    border: none;
}

.minmaxSelect select option:first-child {
    color: #999;
}

.minmaxSelect::after {
    content: "\25BC";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 12px;
    color: #333;
    pointer-events: none;
}

.amore {
    color: red;
}

.minmaxSelect:hover {
    background-color: #f5f5f5;
}

.minmaxSelect:after {
    content: "\25BC";
    font-size: 12px;
    margin-left: 5px;
}

.minmaxSelectName {
    font-weight: 540;
    color: #005790;
    font-size: 13px;
    max-width: 60px;
    margin-right: 25px;
    font-family: 'Montserra', sans-serif;
    line-height: 12px;
}

.space {
    line-height: 100%;
}

.bold {
    font-weight: 500;
}


.minmaxSelectTop {
    display: flex;
    justify-content: space-between;
    margin-right: 50px;
    margin-left: 3px;
}



.limpiarFiltros1 {
    padding-top: 10px;
    font-size: small;
    color: rgba(255, 0, 0, 0.678);
    cursor: pointer;
    position: static;
    margin-bottom: 15px;
}

.limpiarFiltros1, .margenizq {
    margin-left: 10px;
}
.limpiarFiltros1, .margenizqdere {
    margin-left: 10px;
    margin-right: 10px;
}

.limpiarFiltros1:hover {
    color: rgb(243, 12, 12);
    transition: 0.5s;
}

.limpiarFiltros2 {
    padding: 10px;
    font-size: small;
    color: rgba(255, 0, 0, 0.678);
    margin-top: -40px;
    margin-left: 130px;
    position: relative;
    cursor: pointer;

}

.ant-tabs-tabpane,
.ant-tabs-content {
    height: 100%;
}

.gridFiltros {
    height: 100%;
    background-color: var(--color-box-bg);
    border-radius: 8px;
}

.ant-select-item, .ant-select-selection-item {
    font-size: 12px !important;
}

.filter-card {
    width: 300px;
}

.limpiarFiltros2:hover {
    color: rgb(243, 12, 12);
    transition: 0.5s;
}

.filtrosYlimpiar {
    display: grid;
    z-index: 100;
    width: 100%;
}

.Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
    cursor: not-allowed;
}

.Resizer.disabled:hover {
    border-color: transparent;
}

#inputBuscador {
    padding-right: -130%;
    z-index: 98;
}

.resetText {
    cursor: pointer;
}

.recomendaciones {
    background-color: #ffff;
    position: absolute;
    z-index: 101;
    padding-left: 3%;
    padding-right: 50%;
    line-height: 25px;
    border: 1px solid rgba(4, 57, 92, 0.479);
    margin-top: 15%;
}

.results2 {
    margin-left: 130%;
    color: var(--color-primary-color);
} 

.resultsPadre {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.noResults {
    background-color: #ffff;
    position: absolute;
    z-index: 100;
    padding-left: 3%;
    padding-right: 30%;
    line-height: 30px;
    border: 1px solid rgba(4, 57, 92, 0.479);
    margin-top: 15%;
}

.buscadorGeneral {
    display: flex;
    z-index: 98;
    width: 100%;
}

#buscadorPrincipal {
    border-right: none;
    background-color: var(--color-input-bg);
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
    z-index: 98;
    width: 160%;
    height: 60%;
}

.imagenesBusqueda {
    border: 1px solid rgba(4, 57, 92, 0.479);
    border-radius: 5px;
    border-bottom-left-radius: 0%;
    border-top-left-radius: 0%;
    border-left: none;
    background-color: #0000000a;
    width: 50%;
    background-color: color
}

.imagenCatastro {
    width: 22px;
    height: 22px;

}

.imagenMaps {
    width: 22px;
    height: 22px;

}


.section[data-v-78d81933] {
    margin: 20px;
    max-width: 800px;
}

.section>[data-v-78d81933] {
    margin-top: 8px;
}

.result[data-v-78d81933] {
    min-height: 200px;
}

.company-brand[data-v-78d81933] {
    position: absolute;
    font-weight: 300;
    right: 20px;
    bottom: 20px;
    font-size: 12px;
}

.link-section a[data-v-ae6d21fa] {
    color: var(--color-primary-color);
}

.search-bar-section[data-v-69d2778f] {
    padding: 0 8px;
    position: relative;
}

.input-box[data-v-69d2778f] {
    border: 1px solid var(--color-8);
    box-sizing: border-box;
    border-radius: 4px;
    background: var(--color-input-bg);
}

.input-box[data-v-69d2778f]:focus {
    outline: none !important;
    border: 1px solid #409eff
}

.input[data-v-69d2778f] {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background: var(--color-input-bg);
    border: none;
    outline: none;
    border-radius: 4px;
    color: var(--color-10)
}

.search-bar-clear[data-v-69d2778f] {
    position: absolute;
    font-size: 12px;
    top: 8px;
    right: 13px
}

.search-bar-drop-down[data-v-69d2778f] {
    position: absolute;
    padding: 4px;
    z-index: 9999;
    top: 100%;
    min-width: 246px;
    left: 8px;
    background: var(--color-tooltip-bg);
    border: 1px solid var(--color-border);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-height: calc(100vh - 200px);
    min-height: 200px;
    max-width: calc(100vw - 8px);
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0 2px 18px 4px rgba(0, 0, 0, .2)
}

.search-bar-drop-down[data-v-69d2778f]::-webkit-scrollbar {
    width: 0 !important
}

.search-bar-drop-down[data-v-69d2778f] {
    -ms-overflow-style: none;
    scrollbar-width: none
}

.search-result[data-v-69d2778f] {
    cursor: pointer;
    padding: 4px;
    border-bottom: 1px solid var(--color-border)
}

.popular-search-item[data-v-69d2778f] {
    width: 600px;
    max-width: 100%;
    box-sizing: border-box
}

.search-item[data-v-69d2778f] {
    width: 400px;
    max-width: 100%
}

b[data-v-0a86b88c] {
    font-size: 120%
}

.error-status[data-v-0a86b88c] {
    font-size: 80px;
    font-weight: 300;
    margin: 0 10%;
    line-height: 150%
}

.error-type[data-v-0a86b88c] {
    font-size: 40px;
    font-weight: 300;
    margin: 16px 10%;
    line-height: 100%
}

.error-message[data-v-0a86b88c] {
    font-size: 14px;
    font-weight: 350;
    margin: 20px 10%;
    color: #606266
}

.error-message-link[data-v-0a86b88c] {
    font-size: 14px;
    font-weight: 350;
    color: #606266;
    text-decoration: underline
}

.company-brand[data-v-0a86b88c] {
    position: absolute;
    font-weight: 300;
    left: 20px;
    bottom: 20px;
    font-size: 12px
}

.related-link[data-v-0a86b88c] {
    margin: 0 10%
}

.related-link a[data-v-0a86b88c] {
    font-size: 13px;
    color: #005790;
    margin-right: 10px;
    text-decoration: none
}

.el-button--error[data-v-0a86b88c],
.el-button--error[data-v-0a86b88c]:focus,
.el-button--error[data-v-0a86b88c]:hover {
    color: #0c61f7;
    background: rgba(12, 97, 247, .05);
    border: none
}

.el-button--link[data-v-0a86b88c]:focus,
.el-button--link[data-v-0a86b88c]:hover {
    color: #0c61f7;
    background: rgba(12, 97, 247, .05);
    border: none;
    border-radius: 8px
}

.el-button--link[data-v-0a86b88c] {
    color: #0c61f7;
    background: #fff;
    border: none;
    border-radius: 8px
}

.show-arrow[data-v-0a86b88c] {
    visibility: hidden
}

.link-tag:hover .show-arrow[data-v-0a86b88c] {
    visibility: visible
}

.nuxt-error {
    position: relative;
    height: calc(100% - 20px)
}

.error-status {
    font-size: 80px;
    line-height: 150%
}

.error-status,
.error-type {
    font-weight: 300;
    margin: 0 10%
}

.error-type {
    font-size: 40px;
    line-height: 100%
}

.error-message {
    font-size: 14px;
    font-weight: 350;
    margin: 20px 10%;
    color: #606266
}

.company-brand {
    position: absolute;
    font-weight: 300;
    right: 20px;
    bottom: 20px;
    font-size: 12px
}

.related-link {
    margin: 0 10%
}

.related-link a {
    font-size: 13px;
    color: #005790;
    margin-right: 10px;
    text-decoration: none
}

.nuxt-progress {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    width: 0;
    opacity: 1;
    transition: width .1s, opacity .4s;
    background-color: #3aaf77;
    z-index: 999999
}

.nuxt-progress.nuxt-progress-notransition {
    transition: none
}

.nuxt-progress-failed {
    background-color: red
}

.aio-tabs {
    border-bottom: 1px solid var(--color-border);
    clear: both
}

.aio-tabs-img {
    width: 48px;
    height: 36px;
    vertical-align: top
}

.aio-tabs-divider {
    width: 1px;
    margin: 8px 0;
    background: var(--color-border)
}

.aio-tabs-button,
.aio-tabs-divider {
    height: 20px;
    display: inline-block;
    vertical-align: top
}

.aio-tabs-button {
    color: #409eff;
    padding: 8px 4px
}

.aio-tabs-button,
.aio-tabs-title {
    cursor: pointer;
    font-size: 12px;
    line-height: 20px
}

.aio-tabs-title {
    display: inline-block;
    color: var(--color-9);
    height: 20px;
    margin: 8px 4px;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    font-weight: 700;
    width: 100px;
    border-right: 1px solid var(--color-border)
}

.aio-tabs-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px
}

.aio-tabs-item {
    display: inline-block;
    cursor: pointer;
    color: var(--color-8);
    font-size: 12px;
    line-height: 20px;
    height: 20px;
    padding: 8px 4px;
    vertical-align: top;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    transition: background-color .3s
}

.aio-tabs-item .el-icon-loading {
    font-size: 14px;
    font-weight: 700
}

.aio-tabs-item .aio-tabs-hint {
    color: var(--color-primary-color);
    font-size: 11px;
    font-weight: 700;
    padding: 0 2px
}

.aio-tabs-button:hover,
.aio-tabs-item:hover {
    background: var(--color-hover-bg)
}

.aio-tabs-selected {
    background: var(--color-primary-color) !important;
    color: #fff !important;
    padding: 0 4px;
    position: relative;
    margin: 8px;
    border-radius: var(--size-radius-sm)
}

.aio-tabs-selected .aio-tabs-hint {
    background: #fff;
    font-size: 11px;
    font-weight: 700;
    padding: 0 4px
}

.aio-tabs-selected .bottom-divider {
    transition: background-color .3s;
    position: absolute;
    bottom: -11px;
    left: 0;
    right: 0;
    height: 2px;
    border-radius: 1px;
    background: rgba(103, 194, 58, .5)
}

.aio-popover {
    margin-top: 12px !important;
    max-height: 50vh;
    overflow-y: auto;
    background: var(--color-tooltip-bg);
    border: 1px solid var(--color-border) !important;
    box-sizing: border-box;
    box-shadow: 0 36px 72px rgba(4, 9, 33, .06) !important;
    border-radius: 6px !important
}

.aio-popover .item {
    white-space: nowrap;
    cursor: pointer;
    line-height: 20px;
    font-size: 12px;
    padding: 0 8px;
    min-width: 24px
}

.aio-popover .item:hover {
    background: var(--color-hover-bg)
}

.aio-popover .item.selected {
    background: var(--color-active-bg)
}

.aio-popover .title {
    font-size: 12px;
    line-height: 20px;
    padding: 4px 8px;
    color: var(--color-10);
    cursor: pointer
}

.aio-popover .title:not(.no-hover):hover {
    background: var(--color-primary-surface)
}

.aio-popover-loading {
    text-align: center;
    color: #409eff;
    padding: 4px 8px;
    font-size: 12px;
    line-height: 20px;
    cursor: pointer
}

.aio-item-link:hover * {
    color: #195daa;
    text-decoration: underline
}

.aio-item-more {
    display: inline-block;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #303133;
    vertical-align: top;
    text-align: right;
    cursor: pointer;
    white-space: normal
}

.aio-item-input,
.aio-item-more {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 4px
}

.aio-item-input {
    width: 100%;
    line-height: 16px;
    border: 1px solid var(--color-border);
    font-size: 11px !important;
    background-color: var(--color-bg)
}

.aio-item-input:focus {
    outline: none !important;
    border: 1px solid #409eff
}

.aio-item-input.selected {
    color: var(--color-primary-color) !important;
    font-weight: 700
}

.aio-drop-down {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    background: var(--color-tooltip-bg);
    z-index: 9999;
    border: 1px solid var(--color-border);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-height: 400px;
    overflow-y: auto
}

.aio-drop-down .item {
    white-space: nowrap;
    cursor: pointer;
    line-height: 20px;
    font-size: 12px;
    padding: 0 8px;
    min-width: 24px
}

.aio-drop-down .item:hover {
    background: var(--color-hover-bg)
}

.aio-drop-down .item.selected {
    background: var(--color-active-bg)
}

.aio-item-checkbox {
    margin: 1px !important
}

.aio-item-checkbox .el-checkbox__label {
    font-size: 11px;
    padding-left: 4px;
    color: var(--color-9);
    font-weight: 400
}

.screener-info-popover-background {
    background-color: #fbfbfb !important
}

.el-button--filter-default {
    color: #c0c4cc
}

.referral-link {
    display: inline-block;
    line-height: 30px;
    font-size: 12px;
    height: 30px;
    padding: 0 8px;
    margin: 0 8px;
    background: #ffb401;
    border-radius: 4px;
    font-weight: 700;
    color: var(--color-10)
}

.referral-link * {
    color: #fff !important
}

.referral-link-bg {
    background: #ffb401
}

.table-check-list-section {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(var(--color-bg-rgb), .625);
    z-index: 4
}

:root {
    --aio-filter-xs-title-min-width: 100px;
    --aio-filter-xs-signal-content-max-width: calc(100% - 120px);
    --aio-filter-xs-range-content-max-width: calc(50% - 70px);
    --aio-filter-xs-range-to-max-width: 20px
}

html {
    background-color: #fff
}

html.dark-mode .el-loading-mask {
    filter: invert(1)
}

html.dark-mode .referral-link,
html.dark-mode .referral-link-bg {
    background: var(--color-warning-surface);
    border-color: var(--color-warning-border)
}

html.dark-mode .referral-link {
    font-weight: 500
}

html.dark-mode #components-root>:not(.enable-darkmode),
html.dark-mode .invert {
    filter: invert(1)
}

html.dark-mode #components-root>:not(.enable-darkmode) .indicator-progress-bar,
html.dark-mode #components-root>:not(.enable-darkmode) img,
html.dark-mode .invert .indicator-progress-bar,
html.dark-mode .invert img {
    filter: invert(100%)
}

html.dark-mode #components-root>:not(.enable-darkmode) .tags-view-stock-tag,
html.dark-mode .invert .tags-view-stock-tag {
    filter: invert(100%);
    background-color: #141414;
    color: #fff;
    border: 1px solid #767676
}

html.dark-mode #components-root>:not(.enable-darkmode) .good-sign,
html.dark-mode .invert .good-sign {
    filter: invert(100%);
    background: #000 !important
}

html.dark-mode #components-root>:not(.enable-darkmode) .fs-regular,
html.dark-mode #components-root>:not(.enable-darkmode) .referral-link,
html.dark-mode .invert .fs-regular,
html.dark-mode .invert .referral-link {
    filter: invert(100%)
}

html.dark-mode #components-root>:not(.enable-darkmode) .el-loading-mask,
html.dark-mode .invert .el-loading-mask {
    filter: none
}

html.dark-mode #components-root>:not(.enable-darkmode) .segment-bar,
html.dark-mode #components-root>:not(.enable-darkmode) .table-action-info,
html.dark-mode .invert .segment-bar,
html.dark-mode .invert .table-action-info {
    filter: invert(100%)
}

html.dark-mode #components-root>:not(.enable-darkmode) .number-field,
html.dark-mode .invert .number-field {
    filter: invert(100%);
    color: #fff
}

html.dark-mode #components-root>:not(.enable-darkmode) .el-alert__content,
html.dark-mode .invert .el-alert__content {
    filter: invert(1)
}

html.dark-mode #components-root>:not(.enable-darkmode) .el-rate__item,
html.dark-mode #components-root>:not(.enable-darkmode) .gf-icon-useful,
html.dark-mode .invert .el-rate__item,
html.dark-mode .invert .gf-icon-useful {
    filter: invert(100%)
}

html.dark-mode #components-root>:not(.enable-darkmode) .color-green,
html.dark-mode #components-root>:not(.enable-darkmode) .color-red,
html.dark-mode .invert .color-green,
html.dark-mode .invert .color-red {
    filter: invert(1)
}

html.dark-mode #components-root>:not(.enable-darkmode) .color-black,
html.dark-mode .invert .color-black {
    color: #fff
}

html.dark-mode #components-root>:not(.enable-darkmode) iframe,
html.dark-mode .invert iframe {
    filter: invert(100%)
}

html.dark-mode .medium-warning-sign,
html.dark-mode .serve-warning-sign {
    filter: invert(100%);
    background: #000 !important
}

html.dark-mode .competitor-tag,
html.dark-mode .sign-subscribe {
    filter: invert(100%)
}

html.dark-mode #valueline {
    filter: invert(100%);
    color: #fff
}

html.dark-mode #valueline .highcharts-background {
    fill: #000 !important
}

html.dark-mode .sign {
    filter: invert(100%);
    background: #000 !important
}

html.dark-mode .download-svg {
    filter: invert(100%)
}

html.dark-mode #footer,
html.dark-mode .bg-primary,
html.dark-mode .footergrid {
    background: #fff
}

html.dark-mode .fc-white,
html.dark-mode .footerli a,
html.dark-mode .navbar-item>a {
    color: #000
}

html.dark-mode .el-alert__icon {
    filter: invert(1)
}

html.dark-mode .dark-mode-no-invert {
    filter: invert(0)
}

html.dark-mode .dark-mode-invert {
    filter: invert(1);
    color: #fff
}

html.dark-mode .dark-mode-invert-filter,
html.dark-mode :not(iframe):not(:root):fullscreen {
    filter: invert(1)
}

.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0
}

.d-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-neutral-90);
    -webkit-transition: .4s;
    transition: .4s
}

.d-slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: .6s;
    transition: .6s
}

input:checked+.d-slider {
    background-color: var(--color-neutral-90)
}

input:focus+.d-slider {
    box-shadow: 0 0 1px var(--color-neutral-90)
}

input:checked+.d-slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px)
}

.d-slider.round {
    border-radius: 24px
}

.d-slider.round:before {
    border-radius: 50%
}

.compare-table-tag-item-no-hover {
    font-size: .8rem
}

.compare-table-tag-item,
.compare-table-tag-item-no-hover {
    color: var(--color-9);
    cursor: pointer;
    min-height: 32px;
    display: flex;
    align-items: center;
    border-left: 1px solid var(--color-border)
}

.compare-table-tag-item {
    font-size: 12px;
    padding: 3px 10px
}

.compare-table-tag-item i {
    margin-right: 5px
}

.compare-table-tag-item:hover {
    background-color: var(--color-hover-bg)
}

.compare-table-with-side-border-right {
    border-right: 1px solid var(--color-border)
}

.compare-table-with-side-border-left {
    border-left: 1px solid var(--color-border)
}

.compare-table-tag-item-no-border {
    border: 0
}

.table-locked-field {
    height: 20px;
    line-height: 20px;
    font-size: 10px;
    margin: 0;
    padding: 0 4px
}

.text-h1 {
    font-size: 1.6em;
    margin: .67em 0
}

.text-h1,
.text-h2 {
    display: block;
    font-weight: 700
}

.text-h2 {
    font-size: 1.33em;
    margin: .83em 0
}

.text-h3 {
    font-size: 1em;
    margin: 1em 0
}

.text-h3,
.text-h4 {
    display: block;
    font-weight: 700
}

.text-h4 {
    font-size: .83em;
    margin: 1.33em 0
}

.text-h5 {
    font-size: .7em;
    margin: 1.67em 0
}

.text-h5,
.text-h6 {
    display: block;
    font-weight: 700
}

.text-h6 {
    font-size: .67em;
    margin: 2.33em 0
}

.child-padding-desktop {
    padding-top: 80px;
    padding-bottom: 80px
}

.child-padding-tablet {
    padding-top: 64px;
    padding-bottom: 64px
}

.child-padding-mobile {
    padding-top: 32px;
    padding-bottom: 32px
}

.p-b-desktop {
    padding-bottom: 80px
}

.p-b-tablet {
    padding-bottom: 48px
}

.p-b-mobile {
    padding-bottom: 32px
}

.padding-mobile {
    padding-left: 12px;
    padding-right: 12px;
    width: 100vw
}

.padding-tablet {
    padding-left: 32px;
    padding-right: 32px
}

.padding-desktop {
    padding-left: 120px;
    padding-right: 120px
}

.fs-x-large {
    font-size: 16px;
    line-height: 28px
}

.fs-x-large-s {
    font-size: 16px;
    line-height: 24px
}

.fs-x-large-s-i {
    font-size: 16px !important;
    line-height: 24px !important
}

.fs-large {
    font-size: 14px;
    line-height: 24px
}

.fs-regular {
    font-size: 12px;
    line-height: 20px
}

.fs-regular-small {
    font-size: 12px;
    line-height: 16px
}

.fs-small {
    font-size: 11px;
    line-height: 16px
}

.fs-mini {
    font-size: 11px;
    line-height: 14px
}

.fs-18 {
    font-size: 18px;
    line-height: 24px
}

.fs-20 {
    font-size: 20px;
    line-height: 32px
}

.fs-30 {
    font-size: 30px;
    line-height: 40px
}

.fs-48 {
    font-size: 48px;
    line-height: 56px
}

.fs-24 {
    font-size: 24px;
    line-height: 36px
}

.fs-28 {
    font-size: 28px;
    line-height: 40px
}

.fs-32 {
    font-size: 32px;
    line-height: 44px
}

.fs-x-large-i {
    font-size: 16px !important;
    line-height: 28px !important
}

.fs-large-i {
    font-size: 14px !important;
    line-height: 24px !important
}

.fs-regular-i {
    font-size: 12px !important;
    line-height: 20px !important
}

.fs-small-i {
    font-size: 10px !important;
    line-height: 16px !important
}

.fs-xs {
    font-size: 10px;
    line-height: 14px
}

.body-small,
.fs-body-small {
    font-size: 14px;
    line-height: 20px
}

.body-small {
    font-style: normal
}

.italic {
    font-style: italic
}

.fw-light {
    font-weight: 300
}

.fw-bold {
    font-weight: 500
}

.fw-bolder {
    font-weight: 600
}

.fw-bolder-i {
    font-weight: 600 !important
}

.fc-white {
    color: #fff
}

.fc-white-i {
    color: #fff !important
}

.fc-extra-light {
    color: #b0b7c3
}

.fc-extra-light-i {
    color: #b0b7c3 !important
}

.fc-light {
    color: #8d98af
}

.fc-light-i {
    color: #8d98af !important
}

.fc-light-grey {
    color: var(--color-neutral-90)
}

.fc-light-grey-i {
    color: var(--color-neutral-90) !important
}

.fc-primary {
    color: var(--color-10)
}

.fc-regular {
    color: var(--color-8)
}

.fc-secondary {
    color: var(--color-7)
}

.fc-placeholder {
    color: #c0c4cc
}

.fc-black {
    color: #000
}

.fc-normal {
    color: var(--color-10)
}

.fc-normal-i {
    color: var(--color-10) !important
}

.fc-facebook {
    color: #1877f2
}

.fc-facebook-i {
    color: #1877f2 !important
}

.fc-twitter {
    color: #55acee
}

.bg-main-black {
    background: #081131
}

.bg-main-black-i {
    background: #081131 !important
}

.bg-main-blue {
    background: var(--color-primary-color)
}

.bg-main-blue-i {
    background: var(--color-primary-color) !important
}

.bg-light-grey {
    background: var(--color-box-bg)
}

.bg-light-grey-i {
    background: var(--color-box-bg) !important
}

.bg-primary {
    background: #005790
}

.bg-accent {
    background: #fc3
}

.bg-white {
    background: var(--color-bg)
}

.bg-white-1 {
    background: var(--color-box-bg)
}

.bg-white-2 {
    background: var(--color-4)
}

.bg-dark {
    background: #6a7985
}

.bg-dark-1 {
    background: rgba(106, 121, 133, .5333333333333333)
}

.bg-dark-2 {
    background: rgba(106, 121, 133, .26666666666666666)
}

.bg-green {
    background: #67c23a
}

.bg-deep-green {
    background: #42b983
}

.bg-light-green {
    background: #fcfff7
}

.bg-light-red {
    background: #fff7f9
}

.bg-deep-blue {
    background: #283b56
}

.bg-light-blue {
    background: #cef
}

.bg-red-i {
    background: #f56c6c !important
}

.bg-neutral-30 {
    background: #eaeff3
}

.bg-facebook {
    background: #1877f2
}

.bg-twitter {
    background: #55acee
}

.bg-twitter-i {
    background: #55acee !important
}

.bg-youtube {
    background: #ff0302
}

.bg-youtube-i {
    background: #ff0302 !important
}

.bg-spotify-dark {
    background: #171313 !important
}

.bg-spotify-green {
    background: #1ed760 !important
}

.bg-main-yellow {
    background: var(--color-warning-color) !important
}

.hover-white-1:hover {
    background: var(--color-hover-bg)
}

.hover-white-2:hover {
    background: #dcdfe6
}

.color-primary-blue {
    color: #195daa
}

.color-primary-blue-i {
    color: #195daa !important
}

.color-primary {
    color: var(--color-primary-text)
}

.color-primary-i {
    color: var(--color-primary-text) !important
}

.color-accent {
    color: #ffb401
}

.color-blue {
    color: #409eff
}

.color-blue-i {
    color: #409eff !important
}

.color-main-blue {
    color: var(--color-primary-color)
}

.color-main-blue-i {
    color: var(--color-primary-color) !important
}

.color-green {
    color: #67c23a
}

.color-green-i {
    color: #67c23a !important
}

.color-main-green {
    color: #009f5e
}

.color-main-green-i {
    color: #009f5e !important
}

.color-yellow {
    color: #e6a23c
}

.color-main-yellow {
    color: var(--color-warning-color);
    color: var(--color-warning-color) !important
}

.color-brown {
    color: #791001
}

.color-orange {
    color: #f53
}

.color-red {
    color: #f56c6c
}

.color-red-i {
    color: #f56c6c !important
}

.color-main-red {
    color: #d53128
}

.color-main-red-i {
    color: #d53128 !important
}

.color-gray {
    color: #909399
}

.color-gray-darker {
    color: #787878
}

.color-black {
    color: var(--color-10)
}

.color-black-lighter {
    color: var(--color-9)
}

.color-black-lighter-i {
    color: var(--color-9) !important
}

.color-white {
    color: #fff
}

.color-white-i {
    color: #fff !important
}

.color-spotify-dark {
    color: #171313
}

.color-spotify-green {
    color: #1ed760
}

.highlight-red {
    -webkit-box-shadow: 0 0 4px 4px rgba(245, 108, 108, .5);
    -moz-box-shadow: 0 0 4px 4px rgba(245, 108, 108, .5);
    box-shadow: 0 0 4px 4px rgba(245, 108, 108, .5)
}

.highlight-orange {
    -webkit-box-shadow: 0 0 4px 4px rgba(255, 85, 51, .5);
    -moz-box-shadow: 0 0 4px 4px rgba(255, 85, 51, .5);
    box-shadow: 0 0 4px 4px rgba(255, 85, 51, .5)
}

.highlight-blue {
    -webkit-box-shadow: 0 0 4px 4px rgba(64, 158, 255, .5);
    -moz-box-shadow: 0 0 4px 4px rgba(64, 158, 255, .5);
    box-shadow: 0 0 4px 4px rgba(64, 158, 255, .5)
}

.highlight-green {
    -webkit-box-shadow: 0 0 4px 4px rgba(103, 194, 58, .5);
    -moz-box-shadow: 0 0 4px 4px rgba(103, 194, 58, .5);
    box-shadow: 0 0 4px 4px rgba(103, 194, 58, .5)
}

.divider-0 {
    height: 1px;
    width: 100%;
    background: #fff
}

.divider-1 {
    height: 1px;
    width: 100%;
    background: var(--color-border)
}

.divider-2 {
    height: 1px;
    width: 100%;
    background: #e4e7ed
}

.divider-3 {
    height: 1px;
    width: 100%;
    background: #ebeef5
}

.divider-4 {
    height: 1px;
    width: 100%;
    background: #f2f6fc
}

.bounce {
    animation: bounce 1.5s infinite alternate;
    -webkit-animation: bounce 1.5s infinite alternate
}

@keyframes bounce {
    0% {
        transform: scale(1)
    }

    10% {
        transform: scale(.9, 1.1)
    }

    20% {
        transform: scale(1)
    }

    to {
        transform: scale(1)
    }
}

::-webkit-scrollbar {
    width: 10px !important
}

.gf-padding-h-5 {
    padding: 0 5px
}

.gf-padding-h-10 {
    padding: 0 10px
}

.gf-padding-h-20 {
    padding: 0 20px
}

.gf-padding-v-5 {
    padding: 5px 0
}

.gf-padding-v-10 {
    padding: 10px 0
}

.gf-padding-v-20 {
    padding: 20px 0
}

.gf-padding-5 {
    padding: 5px
}

.gf-padding-10 {
    padding: 10px
}

.gf-padding-20 {
    padding: 20px
}

.gf-margin-h-5 {
    margin: 0 5px
}

.gf-margin-h-10 {
    margin: 0 10px
}

.gf-margin-h-20 {
    margin: 0 20px
}

.gf-margin-v-5 {
    margin: 5px 0
}

.gf-margin-v-10 {
    margin: 10px 0
}

.gf-margin-v-20 {
    margin: 20px 0
}

.gf-margin-5 {
    margin: 5px
}

.gf-margin-10 {
    margin: 10px
}

.gf-margin-20 {
    margin: 20px
}

.dashboard-flex-container {
    display: flex;
    align-items: center
}

.dashboard-flex-container-start {
    display: flex;
    align-items: flex-start
}

.dashboard-flex-container-end {
    display: flex;
    align-items: flex-end
}

.global-flex-container {
    display: flex;
    align-items: center
}

.global-flex-container-start {
    display: flex;
    align-items: flex-start
}

.flex-justify-center {
    display: flex;
    justify-content: center
}

.flex-start {
    display: flex;
    justify-content: flex-start
}

.flex-align-start {
    display: flex;
    align-items: start
}

.flex-center {
    display: flex
}

.flex-baseline {
    display: flex;
    align-items: baseline
}

.flex-between {
    display: flex;
    justify-content: space-between
}

.align-child-center {
    align-items: center
}

.align-child-center,
.align-h-center {
    display: flex;
    justify-content: center
}

.flex-stretch {
    display: flex;
    align-items: stretch
}

.relative-box {
    position: relative
}

.text-center {
    text-align: center
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.float-right {
    float: right
}

.overflow-hidden {
    overflow: hidden
}

.ellipsis {
    white-space: nowrap
}

.ellipsis,
.one-line-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis
}

.one-line-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical
}

.two-line-ellipsis {
    -webkit-line-clamp: 2;
    line-clamp: 2
}

.three-line-ellipsis,
.two-line-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical
}

.three-line-ellipsis {
    -webkit-line-clamp: 3;
    line-clamp: 3
}

.five-line-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical
}

.keep-all {
    word-break: keep-all
}

.bread-word {
    word-break: break-word
}

.button-dark-border {
    color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;
    line-height: 30px;
    background: 0 0
}

.border-thin {
    border: 1px solid #eaeff3
}

.border-normal {
    border: 2px solid #eaeff3
}

.border-thick {
    border: 4px solid #eaeff3
}

.no-border {
    border: none !important
}

.round-border {
    border-radius: 50%
}

.link-hover:hover {
    color: var(--color-primary-color) !important
}

.link-underline,
.underline-on-hover:hover {
    text-decoration: underline
}

.line-through {
    text-decoration: line-through
}

.fit-cover {
    object-fit: cover
}

.max-content {
    width: max-content
}

.tutorial-butotn {
    display: inline-block;
    color: #409eff;
    vertical-align: top;
    cursor: pointer;
    font-size: 12px;
    line-height: 20px;
    height: 20px
}

.dashboard-widget-scroll-section {
    max-height: 400px;
    overflow-y: scroll
}

.full-width {
    width: 100%
}

.full-height {
    height: 100%
}

.gf-chart-header {
    font-size: 14px !important;
    font-family: sans-serif !important
}

.mobile-dialog-cover {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    border: none
}

.mobile-dialog-cover .top-right-close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10
}

.fixed-button {
    position: fixed;
    z-index: 99999;
    cursor: pointer
}

.fixed-icon-wraper {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%
}

.fixed-icon-item {
    font-size: 20px;
    color: #fff;
    padding: 10px;
    display: inline-block
}

:root {
    font-family: Inter, arial, helvetica, sans-serif;
    --color-1: #fff;
    --color-1-rgb: 255, 255, 255;
    --color-2: #fafafa;
    --color-2-rgb: 250, 250, 250;
    --color-3: #eaeff3;
    --color-3-rgb: 234, 239, 243;
    --color-4: #d4dbe1;
    --color-4-rgb: 212, 219, 225;
    --color-5: #c1c7d0;
    --color-5-rgb: 193, 199, 208;
    --color-6: #b0b7c3;
    --color-6-rgb: 176, 183, 195;
    --color-7: #8d98af;
    --color-7-rgb: 141, 152, 175;
    --color-8: #6d7794;
    --color-8-rgb: 109, 119, 148;
    --color-9: #3e4763;
    --color-9-rgb: 62, 71, 99;
    --color-10: #081131;
    --color-10-rgb: 8, 17, 49;
    --color-bg: #fff;
    --color-bg-rgb: 255, 255, 255;
    --color-box-bg: #fafafa;
    --color-box-bg-rgb: 250, 250, 250;
    --color-border: #eaeff3;
    --color-border-rgb: 234, 239, 243;
    --color-disabled: #b0b7c3;
    --color-disabled-rgb: 176, 183, 195;
    --color-tooltip-bg: #fff;
    --color-tooltip-bg-rgb: 255, 255, 255;
    --color-tooltip-darkbg: rgba(8, 17, 49, 0.8);
    --color-tooltip-darkbg-rgb: 8, 17, 49;
    --color-input-bg: #fafafa;
    --color-input-bg-rgb: 250, 250, 250;
    --color-input-border: #d4dbe1;
    --color-input-border-rgb: 212, 219, 225;
    --color-hover-bg: rgba(66, 133, 244, 0.14);
    --color-hover-bg-rgb: 66, 133, 244;
    --color-active-bg: rgba(66, 133, 244, 0.14);
    --color-active-bg-rgb: 66, 133, 244;
    --color-disabled-bg: #fafafa;
    --color-disabled-bg-rgb: 250, 250, 250;
    --color-shadow: #000;
    --color-shadow-rgb: 0, 0, 0;
    --color-primary-color: #4285f4;
    --color-primary-color-rgb: 66, 133, 244;
    --color-light-primary-color: #4285f4;
    --color-primary-surface: rgba(66, 133, 244, 0.14);
    --color-primary-surface-rgb: 66, 133, 244;
    --color-light-primary-surface: rgba(66, 133, 244, 0.14);
    --color-primary-border: #c0d6fb;
    --color-primary-border-rgb: 192, 214, 251;
    --color-light-primary-border: #c0d6fb;
    --color-primary-hover: #376fcb;
    --color-primary-hover-rgb: 55, 111, 203;
    --color-light-primary-hover: #376fcb;
    --color-primary-pressed: #21427a;
    --color-primary-pressed-rgb: 33, 66, 122;
    --color-light-primary-pressed: #21427a;
    --color-primary-focus: rgba(66, 133, 244, 0.2);
    --color-primary-focus-rgb: 66, 133, 244;
    --color-light-primary-focus: rgba(66, 133, 244, 0.2);
    --color-primary-chart: #4285f4;
    --color-primary-chart-rgb: 66, 133, 244;
    --color-light-primary-chart: #4285f4;
    --color-primary-text: #4285f4;
    --color-primary-text-rgb: 66, 133, 244;
    --color-light-primary-text: #4285f4;
    --color-primary-number: #4285f4;
    --color-primary-number-rgb: 66, 133, 244;
    --color-light-primary-number: #4285f4;
    --color-success-color: #34a853;
    --color-success-color-rgb: 52, 168, 83;
    --color-light-success-color: #34a853;
    --color-success-surface: #e2f3e7;
    --color-success-surface-rgb: 226, 243, 231;
    --color-light-success-surface: #e2f3e7;
    --color-success-border: #bbe2c6;
    --color-success-border-rgb: 187, 226, 198;
    --color-light-success-border: #bbe2c6;
    --color-success-hover: #2b8c45;
    --color-success-hover-rgb: 43, 140, 69;
    --color-light-success-hover: #2b8c45;
    --color-success-pressed: #1a5429;
    --color-success-pressed-rgb: 26, 84, 41;
    --color-light-success-pressed: #1a5429;
    --color-success-focus: rgba(52, 168, 83, 0.2);
    --color-success-focus-rgb: 52, 168, 83;
    --color-light-success-focus: rgba(52, 168, 83, 0.2);
    --color-success-chart: #34a853;
    --color-success-chart-rgb: 52, 168, 83;
    --color-light-success-chart: #34a853;
    --color-success-text: #34a853;
    --color-success-text-rgb: 52, 168, 83;
    --color-light-success-text: #34a853;
    --color-success-number: #2b8c45;
    --color-success-number-rgb: 43, 140, 69;
    --color-light-success-number: #2b8c45;
    --color-warning-color: #fbbc05;
    --color-warning-color-rgb: 251, 188, 5;
    --color-light-warning-color: #fbbc05;
    --color-warning-surface: #fef6dc;
    --color-warning-surface-rgb: 254, 246, 220;
    --color-light-warning-surface: #fef6dc;
    --color-warning-border: #fee9ac;
    --color-warning-border-rgb: 254, 233, 172;
    --color-light-warning-border: #fee9ac;
    --color-warning-hover: #d19d04;
    --color-warning-hover-rgb: 209, 157, 4;
    --color-light-warning-hover: #d19d04;
    --color-warning-pressed: #7e5e02;
    --color-warning-pressed-rgb: 126, 94, 2;
    --color-light-warning-pressed: #7e5e02;
    --color-warning-focus: rgba(251, 188, 5, 0.2);
    --color-warning-focus-rgb: 251, 188, 5;
    --color-light-warning-focus: rgba(251, 188, 5, 0.2);
    --color-warning-chart: #fbbc05;
    --color-warning-chart-rgb: 251, 188, 5;
    --color-light-warning-chart: #fbbc05;
    --color-warning-text: #fbbc05;
    --color-warning-text-rgb: 251, 188, 5;
    --color-light-warning-text: #fbbc05;
    --color-warning-number: #d19d04;
    --color-warning-number-rgb: 209, 157, 4;
    --color-light-warning-number: #d19d04;
    --color-danger-color: #ea4335;
    --color-danger-color-rgb: 234, 67, 53;
    --color-light-danger-color: #ea4335;
    --color-danger-surface: #fce5e3;
    --color-danger-surface-rgb: 252, 229, 227;
    --color-light-danger-surface: #fce5e3;
    --color-danger-border: #f8c0bc;
    --color-danger-border-rgb: 248, 192, 188;
    --color-light-danger-border: #f8c0bc;
    --color-danger-hover: #c3382c;
    --color-danger-hover-rgb: 195, 56, 44;
    --color-light-danger-hover: #c3382c;
    --color-danger-pressed: #801d18;
    --color-danger-pressed-rgb: 128, 29, 24;
    --color-light-danger-pressed: #801d18;
    --color-danger-focus: rgba(234, 67, 53, 0.2);
    --color-danger-focus-rgb: 234, 67, 53;
    --color-light-danger-focus: rgba(234, 67, 53, 0.2);
    --color-danger-chart: #ea4335;
    --color-danger-chart-rgb: 234, 67, 53;
    --color-light-danger-chart: #ea4335;
    --color-danger-text: #ea4335;
    --color-danger-text-rgb: 234, 67, 53;
    --color-light-danger-text: #ea4335;
    --color-danger-number: #c3382c;
    --color-danger-number-rgb: 195, 56, 44;
    --color-light-danger-number: #c3382c;
    --color-theme-bg: #185daa;
    --color-theme-bg-rgb: 24, 93, 170;
    --color-light-theme-bg: #185daa;
    --color-theme-active-bg: #0e4c92;
    --color-theme-active-bg-rgb: 14, 76, 146;
    --color-light-theme-active-bg: #0e4c92;
    --color-neutral-10: #fff;
    --color-neutral-10-rgb: 255, 255, 255;
    --color-light-neutral-10: #fff;
    --color-neutral-20: #fafafa;
    --color-neutral-20-rgb: 250, 250, 250;
    --color-light-neutral-20: #fafafa;
    --color-neutral-30: #eaeff3;
    --color-neutral-30-rgb: 234, 239, 243;
    --color-light-neutral-30: #eaeff3;
    --color-neutral-40: #d4dbe1;
    --color-neutral-40-rgb: 212, 219, 225;
    --color-light-neutral-40: #d4dbe1;
    --color-neutral-50: #c1c7d0;
    --color-neutral-50-rgb: 193, 199, 208;
    --color-light-neutral-50: #c1c7d0;
    --color-neutral-60: #b0b7c3;
    --color-neutral-60-rgb: 176, 183, 195;
    --color-light-neutral-60: #b0b7c3;
    --color-neutral-70: #8d98af;
    --color-neutral-70-rgb: 141, 152, 175;
    --color-light-neutral-70: #8d98af;
    --color-neutral-80: #6d7794;
    --color-neutral-80-rgb: 109, 119, 148;
    --color-light-neutral-80: #6d7794;
    --color-neutral-90: #3e4763;
    --color-neutral-90-rgb: 62, 71, 99;
    --color-light-neutral-90: #3e4763;
    --color-neutral-100: #081131;
    --color-neutral-100-rgb: 8, 17, 49;
    --color-light-neutral-100: #081131;
    --color-neutral-border: #eaeff3;
    --color-neutral-border-rgb: 234, 239, 243;
    --color-light-neutral-border: #eaeff3;
    --color-chart-good-color: #34a853;
    --color-chart-good-color-rgb: 52, 168, 83;
    --color-light-chart-good-color: #34a853;
    --color-chart-medium-color: #fbbc05;
    --color-chart-medium-color-rgb: 251, 188, 5;
    --color-light-chart-medium-color: #fbbc05;
    --color-chart-severe-color: #ea4335;
    --color-chart-severe-color-rgb: 234, 67, 53;
    --color-light-chart-severe-color: #ea4335;
    --color-chart-range-1: #ea4335;
    --color-chart-range-1-rgb: 234, 67, 53;
    --color-light-chart-range-1: #ea4335;
    --color-chart-range-2: #fbbc05;
    --color-chart-range-2-rgb: 251, 188, 5;
    --color-light-chart-range-2: #fbbc05;
    --color-chart-range-3: #f7d70f;
    --color-chart-range-3-rgb: 247, 215, 15;
    --color-light-chart-range-3: #f7d70f;
    --color-chart-range-4: #99ce95;
    --color-chart-range-4-rgb: 153, 206, 149;
    --color-light-chart-range-4: #99ce95;
    --color-chart-range-5: #34a853;
    --color-chart-range-5-rgb: 52, 168, 83;
    --color-light-chart-range-5: #34a853;
    --size-none: 0;
    --size-auto: auto;
    --size-xs: 4px;
    --size-sm2: 6px;
    --size-sm: 8px;
    --size-md: 12px;
    --size-lg: 16px;
    --size-xl: 20px;
    --size-xxl: 24px;
    --size-32: 32px;
    --size-radius-1: 1px;
    --size-radius-sm: 4px;
    --size-radius-md: 6px;
    --size-radius-lg: 10px;
    --size-radius-xl: 12px;
    --color-dark-primary-color: #4285f4;
    --color-dark-primary-surface: rgba(66, 133, 244, 0.14);
    --color-dark-primary-border: #2c4168;
    --color-dark-primary-hover: #376fcb;
    --color-dark-primary-text: #4285f4;
    --color-dark-success-surface: rgba(52, 168, 83, 0.14);
    --color-dark-warning-surface: rgba(251, 188, 5, 0.14);
    --color-dark-danger-surface: rgba(234, 67, 53, 0.14);
    --color-dark-theme-bg: #081131;
    --color-dark-theme-active-bg: #3e4763;
    --color-dark-neutral-10: #23242c;
    --color-dark-neutral-20: #2c2d34;
    --color-dark-neutral-30: #303139;
    --color-dark-neutral-40: #35363d;
    --color-dark-neutral-50: #393a41;
    --color-dark-neutral-60: #8d98af;
    --color-dark-neutral-70: #b0b7c3;
    --color-dark-neutral-80: #c1c7d0;
    --color-dark-neutral-90: #d4dbe1;
    --color-dark-neutral-100: #fff;
    --color-dark-neutral-border: #35363d;
    --size-form-h-sm: 24px;
    --color-youtube: #ff0302
}

:root .color-light-primary-color {
    color: #4285f4
}

:root .color-primary-color {
    color: var(--color-primary-color)
}

:root .color-primary-color-i {
    color: var(--color-primary-color) !important
}

:root .bg-primary-color {
    background: var(--color-primary-color)
}

:root .bg-primary-color-i {
    background: var(--color-primary-color) !important
}

:root .bc-primary-color {
    border-color: var(--color-primary-color)
}

:root .color-light-primary-surface {
    color: rgba(66, 133, 244, .14)
}

:root .color-primary-surface {
    color: var(--color-primary-surface)
}

:root .color-primary-surface-i {
    color: var(--color-primary-surface) !important
}

:root .bg-primary-surface {
    background: var(--color-primary-surface)
}

:root .bg-primary-surface-i {
    background: var(--color-primary-surface) !important
}

:root .bc-primary-surface {
    border-color: var(--color-primary-surface)
}

:root .color-light-primary-border {
    color: #c0d6fb
}

:root .color-primary-border {
    color: var(--color-primary-border)
}

:root .color-primary-border-i {
    color: var(--color-primary-border) !important
}

:root .bg-primary-border {
    background: var(--color-primary-border)
}

:root .bg-primary-border-i {
    background: var(--color-primary-border) !important
}

:root .bc-primary-border {
    border-color: var(--color-primary-border)
}

:root .color-light-primary-hover {
    color: #376fcb
}

:root .color-primary-hover {
    color: var(--color-primary-hover)
}

:root .color-primary-hover-i {
    color: var(--color-primary-hover) !important
}

:root .bg-primary-hover {
    background: var(--color-primary-hover)
}

:root .bg-primary-hover-i {
    background: var(--color-primary-hover) !important
}

:root .bc-primary-hover {
    border-color: var(--color-primary-hover)
}

:root .color-light-primary-pressed {
    color: #21427a
}

:root .color-primary-pressed {
    color: var(--color-primary-pressed)
}

:root .color-primary-pressed-i {
    color: var(--color-primary-pressed) !important
}

:root .bg-primary-pressed {
    background: var(--color-primary-pressed)
}

:root .bg-primary-pressed-i {
    background: var(--color-primary-pressed) !important
}

:root .bc-primary-pressed {
    border-color: var(--color-primary-pressed)
}

:root .color-light-primary-focus {
    color: rgba(66, 133, 244, .2)
}

:root .color-primary-focus {
    color: var(--color-primary-focus)
}

:root .color-primary-focus-i {
    color: var(--color-primary-focus) !important
}

:root .bg-primary-focus {
    background: var(--color-primary-focus)
}

:root .bg-primary-focus-i {
    background: var(--color-primary-focus) !important
}

:root .bc-primary-focus {
    border-color: var(--color-primary-focus)
}

:root .color-light-primary-chart {
    color: #4285f4
}

:root .color-primary-chart {
    color: var(--color-primary-chart)
}

:root .color-primary-chart-i {
    color: var(--color-primary-chart) !important
}

:root .bg-primary-chart {
    background: var(--color-primary-chart)
}

:root .bg-primary-chart-i {
    background: var(--color-primary-chart) !important
}

:root .bc-primary-chart {
    border-color: var(--color-primary-chart)
}

:root .color-light-primary-text {
    color: #4285f4
}

:root .color-primary-text {
    color: var(--color-primary-text)
}

:root .color-primary-text-i {
    color: var(--color-primary-text) !important
}

:root .bg-primary-text {
    background: var(--color-primary-text)
}

:root .bg-primary-text-i {
    background: var(--color-primary-text) !important
}

:root .bc-primary-text {
    border-color: var(--color-primary-text)
}

:root .color-light-primary-number {
    color: #4285f4
}

:root .color-primary-number {
    color: var(--color-primary-number)
}

:root .color-primary-number-i {
    color: var(--color-primary-number) !important
}

:root .bg-primary-number {
    background: var(--color-primary-number)
}

:root .bg-primary-number-i {
    background: var(--color-primary-number) !important
}

:root .bc-primary-number {
    border-color: var(--color-primary-number)
}

:root .color-light-success-color {
    color: #34a853
}

:root .color-success-color {
    color: var(--color-success-color)
}

:root .color-success-color-i {
    color: var(--color-success-color) !important
}

:root .bg-success-color {
    background: var(--color-success-color)
}

:root .bg-success-color-i {
    background: var(--color-success-color) !important
}

:root .bc-success-color {
    border-color: var(--color-success-color)
}

:root .color-light-success-surface {
    color: #e2f3e7
}

:root .color-success-surface {
    color: var(--color-success-surface)
}

:root .color-success-surface-i {
    color: var(--color-success-surface) !important
}

:root .bg-success-surface {
    background: var(--color-success-surface)
}

:root .bg-success-surface-i {
    background: var(--color-success-surface) !important
}

:root .bc-success-surface {
    border-color: var(--color-success-surface)
}

:root .color-light-success-border {
    color: #bbe2c6
}

:root .color-success-border {
    color: var(--color-success-border)
}

:root .color-success-border-i {
    color: var(--color-success-border) !important
}

:root .bg-success-border {
    background: var(--color-success-border)
}

:root .bg-success-border-i {
    background: var(--color-success-border) !important
}

:root .bc-success-border {
    border-color: var(--color-success-border)
}

:root .color-light-success-hover {
    color: #2b8c45
}

:root .color-success-hover {
    color: var(--color-success-hover)
}

:root .color-success-hover-i {
    color: var(--color-success-hover) !important
}

:root .bg-success-hover {
    background: var(--color-success-hover)
}

:root .bg-success-hover-i {
    background: var(--color-success-hover) !important
}

:root .bc-success-hover {
    border-color: var(--color-success-hover)
}

:root .color-light-success-pressed {
    color: #1a5429
}

:root .color-success-pressed {
    color: var(--color-success-pressed)
}

:root .color-success-pressed-i {
    color: var(--color-success-pressed) !important
}

:root .bg-success-pressed {
    background: var(--color-success-pressed)
}

:root .bg-success-pressed-i {
    background: var(--color-success-pressed) !important
}

:root .bc-success-pressed {
    border-color: var(--color-success-pressed)
}

:root .color-light-success-focus {
    color: rgba(52, 168, 83, .2)
}

:root .color-success-focus {
    color: var(--color-success-focus)
}

:root .color-success-focus-i {
    color: var(--color-success-focus) !important
}

:root .bg-success-focus {
    background: var(--color-success-focus)
}

:root .bg-success-focus-i {
    background: var(--color-success-focus) !important
}

:root .bc-success-focus {
    border-color: var(--color-success-focus)
}

:root .color-light-success-chart {
    color: #34a853
}

:root .color-success-chart {
    color: var(--color-success-chart)
}

:root .color-success-chart-i {
    color: var(--color-success-chart) !important
}

:root .bg-success-chart {
    background: var(--color-success-chart)
}

:root .bg-success-chart-i {
    background: var(--color-success-chart) !important
}

:root .bc-success-chart {
    border-color: var(--color-success-chart)
}

:root .color-light-success-text {
    color: #34a853
}

:root .color-success-text {
    color: var(--color-success-text)
}

:root .color-success-text-i {
    color: var(--color-success-text) !important
}

:root .bg-success-text {
    background: var(--color-success-text)
}

:root .bg-success-text-i {
    background: var(--color-success-text) !important
}

:root .bc-success-text {
    border-color: var(--color-success-text)
}

:root .color-light-success-number {
    color: #2b8c45
}

:root .color-success-number {
    color: var(--color-success-number)
}

:root .color-success-number-i {
    color: var(--color-success-number) !important
}

:root .bg-success-number {
    background: var(--color-success-number)
}

:root .bg-success-number-i {
    background: var(--color-success-number) !important
}

:root .bc-success-number {
    border-color: var(--color-success-number)
}

:root .color-light-warning-color {
    color: #fbbc05
}

:root .color-warning-color {
    color: var(--color-warning-color)
}

:root .color-warning-color-i {
    color: var(--color-warning-color) !important
}

:root .bg-warning-color {
    background: var(--color-warning-color)
}

:root .bg-warning-color-i {
    background: var(--color-warning-color) !important
}

:root .bc-warning-color {
    border-color: var(--color-warning-color)
}

:root .color-light-warning-surface {
    color: #fef6dc
}

:root .color-warning-surface {
    color: var(--color-warning-surface)
}

:root .color-warning-surface-i {
    color: var(--color-warning-surface) !important
}

:root .bg-warning-surface {
    background: var(--color-warning-surface)
}

:root .bg-warning-surface-i {
    background: var(--color-warning-surface) !important
}

:root .bc-warning-surface {
    border-color: var(--color-warning-surface)
}

:root .color-light-warning-border {
    color: #fee9ac
}

:root .color-warning-border {
    color: var(--color-warning-border)
}

:root .color-warning-border-i {
    color: var(--color-warning-border) !important
}

:root .bg-warning-border {
    background: var(--color-warning-border)
}

:root .bg-warning-border-i {
    background: var(--color-warning-border) !important
}

:root .bc-warning-border {
    border-color: var(--color-warning-border)
}

:root .color-light-warning-hover {
    color: #d19d04
}

:root .color-warning-hover {
    color: var(--color-warning-hover)
}

:root .color-warning-hover-i {
    color: var(--color-warning-hover) !important
}

:root .bg-warning-hover {
    background: var(--color-warning-hover)
}

:root .bg-warning-hover-i {
    background: var(--color-warning-hover) !important
}

:root .bc-warning-hover {
    border-color: var(--color-warning-hover)
}

:root .color-light-warning-pressed {
    color: #7e5e02
}

:root .color-warning-pressed {
    color: var(--color-warning-pressed)
}

:root .color-warning-pressed-i {
    color: var(--color-warning-pressed) !important
}

:root .bg-warning-pressed {
    background: var(--color-warning-pressed)
}

:root .bg-warning-pressed-i {
    background: var(--color-warning-pressed) !important
}

:root .bc-warning-pressed {
    border-color: var(--color-warning-pressed)
}

:root .color-light-warning-focus {
    color: rgba(251, 188, 5, .2)
}

:root .color-warning-focus {
    color: var(--color-warning-focus)
}

:root .color-warning-focus-i {
    color: var(--color-warning-focus) !important
}

:root .bg-warning-focus {
    background: var(--color-warning-focus)
}

:root .bg-warning-focus-i {
    background: var(--color-warning-focus) !important
}

:root .bc-warning-focus {
    border-color: var(--color-warning-focus)
}

:root .color-light-warning-chart {
    color: #fbbc05
}

:root .color-warning-chart {
    color: var(--color-warning-chart)
}

:root .color-warning-chart-i {
    color: var(--color-warning-chart) !important
}

:root .bg-warning-chart {
    background: var(--color-warning-chart)
}

:root .bg-warning-chart-i {
    background: var(--color-warning-chart) !important
}

:root .bc-warning-chart {
    border-color: var(--color-warning-chart)
}

:root .color-light-warning-text {
    color: #fbbc05
}

:root .color-warning-text {
    color: var(--color-warning-text)
}

:root .color-warning-text-i {
    color: var(--color-warning-text) !important
}

:root .bg-warning-text {
    background: var(--color-warning-text)
}

:root .bg-warning-text-i {
    background: var(--color-warning-text) !important
}

:root .bc-warning-text {
    border-color: var(--color-warning-text)
}

:root .color-light-warning-number {
    color: #d19d04
}

:root .color-warning-number {
    color: var(--color-warning-number)
}

:root .color-warning-number-i {
    color: var(--color-warning-number) !important
}

:root .bg-warning-number {
    background: var(--color-warning-number)
}

:root .bg-warning-number-i {
    background: var(--color-warning-number) !important
}

:root .bc-warning-number {
    border-color: var(--color-warning-number)
}

:root .color-light-danger-color {
    color: #ea4335
}

:root .color-danger-color {
    color: var(--color-danger-color)
}

:root .color-danger-color-i {
    color: var(--color-danger-color) !important
}

:root .bg-danger-color {
    background: var(--color-danger-color)
}

:root .bg-danger-color-i {
    background: var(--color-danger-color) !important
}

:root .bc-danger-color {
    border-color: var(--color-danger-color)
}

:root .color-light-danger-surface {
    color: #fce5e3
}

:root .color-danger-surface {
    color: var(--color-danger-surface)
}

:root .color-danger-surface-i {
    color: var(--color-danger-surface) !important
}

:root .bg-danger-surface {
    background: var(--color-danger-surface)
}

:root .bg-danger-surface-i {
    background: var(--color-danger-surface) !important
}

:root .bc-danger-surface {
    border-color: var(--color-danger-surface)
}

:root .color-light-danger-border {
    color: #f8c0bc
}

:root .color-danger-border {
    color: var(--color-danger-border)
}

:root .color-danger-border-i {
    color: var(--color-danger-border) !important
}

:root .bg-danger-border {
    background: var(--color-danger-border)
}

:root .bg-danger-border-i {
    background: var(--color-danger-border) !important
}

:root .bc-danger-border {
    border-color: var(--color-danger-border)
}

:root .color-light-danger-hover {
    color: #c3382c
}

:root .color-danger-hover {
    color: var(--color-danger-hover)
}

:root .color-danger-hover-i {
    color: var(--color-danger-hover) !important
}

:root .bg-danger-hover {
    background: var(--color-danger-hover)
}

:root .bg-danger-hover-i {
    background: var(--color-danger-hover) !important
}

:root .bc-danger-hover {
    border-color: var(--color-danger-hover)
}

:root .color-light-danger-pressed {
    color: #801d18
}

:root .color-danger-pressed {
    color: var(--color-danger-pressed)
}

:root .color-danger-pressed-i {
    color: var(--color-danger-pressed) !important
}

:root .bg-danger-pressed {
    background: var(--color-danger-pressed)
}

:root .bg-danger-pressed-i {
    background: var(--color-danger-pressed) !important
}

:root .bc-danger-pressed {
    border-color: var(--color-danger-pressed)
}

:root .color-light-danger-focus {
    color: rgba(234, 67, 53, .2)
}

:root .color-danger-focus {
    color: var(--color-danger-focus)
}

:root .color-danger-focus-i {
    color: var(--color-danger-focus) !important
}

:root .bg-danger-focus {
    background: var(--color-danger-focus)
}

:root .bg-danger-focus-i {
    background: var(--color-danger-focus) !important
}

:root .bc-danger-focus {
    border-color: var(--color-danger-focus)
}

:root .color-light-danger-chart {
    color: #ea4335
}

:root .color-danger-chart {
    color: var(--color-danger-chart)
}

:root .color-danger-chart-i {
    color: var(--color-danger-chart) !important
}

:root .bg-danger-chart {
    background: var(--color-danger-chart)
}

:root .bg-danger-chart-i {
    background: var(--color-danger-chart) !important
}

:root .bc-danger-chart {
    border-color: var(--color-danger-chart)
}

:root .color-light-danger-text {
    color: #ea4335
}

:root .color-danger-text {
    color: var(--color-danger-text)
}

:root .color-danger-text-i {
    color: var(--color-danger-text) !important
}

:root .bg-danger-text {
    background: var(--color-danger-text)
}

:root .bg-danger-text-i {
    background: var(--color-danger-text) !important
}

:root .bc-danger-text {
    border-color: var(--color-danger-text)
}

:root .color-light-danger-number {
    color: #c3382c
}

:root .color-danger-number {
    color: var(--color-danger-number)
}

:root .color-danger-number-i {
    color: var(--color-danger-number) !important
}

:root .bg-danger-number {
    background: var(--color-danger-number)
}

:root .bg-danger-number-i {
    background: var(--color-danger-number) !important
}

:root .bc-danger-number {
    border-color: var(--color-danger-number)
}

:root .color-light-theme-bg {
    color: #185daa
}

:root .color-theme-bg {
    color: var(--color-theme-bg)
}

:root .color-theme-bg-i {
    color: var(--color-theme-bg) !important
}

:root .bg-theme-bg {
    background: var(--color-theme-bg)
}

:root .bg-theme-bg-i {
    background: var(--color-theme-bg) !important
}

:root .bc-theme-bg {
    border-color: var(--color-theme-bg)
}

:root .color-light-theme-active-bg {
    color: #0e4c92
}

:root .color-theme-active-bg {
    color: var(--color-theme-active-bg)
}

:root .color-theme-active-bg-i {
    color: var(--color-theme-active-bg) !important
}

:root .bg-theme-active-bg {
    background: var(--color-theme-active-bg)
}

:root .bg-theme-active-bg-i {
    background: var(--color-theme-active-bg) !important
}

:root .bc-theme-active-bg {
    border-color: var(--color-theme-active-bg)
}

:root .color-light-neutral-10 {
    color: #fff
}

:root .color-neutral-10 {
    color: var(--color-neutral-10)
}

:root .color-neutral-10-i {
    color: var(--color-neutral-10) !important
}

:root .bg-neutral-10 {
    background: var(--color-neutral-10)
}

:root .bg-neutral-10-i {
    background: var(--color-neutral-10) !important
}

:root .bc-neutral-10 {
    border-color: var(--color-neutral-10)
}

:root .color-light-neutral-20 {
    color: #fafafa
}

:root .color-neutral-20 {
    color: var(--color-neutral-20)
}

:root .color-neutral-20-i {
    color: var(--color-neutral-20) !important
}

:root .bg-neutral-20 {
    background: var(--color-neutral-20)
}

:root .bg-neutral-20-i {
    background: var(--color-neutral-20) !important
}

:root .bc-neutral-20 {
    border-color: var(--color-neutral-20)
}

:root .color-light-neutral-30 {
    color: #eaeff3
}

:root .color-neutral-30 {
    color: var(--color-neutral-30)
}

:root .color-neutral-30-i {
    color: var(--color-neutral-30) !important
}

:root .bg-neutral-30 {
    background: var(--color-neutral-30)
}

:root .bg-neutral-30-i {
    background: var(--color-neutral-30) !important
}

:root .bc-neutral-30 {
    border-color: var(--color-neutral-30)
}

:root .color-light-neutral-40 {
    color: #d4dbe1
}

:root .color-neutral-40 {
    color: var(--color-neutral-40)
}

:root .color-neutral-40-i {
    color: var(--color-neutral-40) !important
}

:root .bg-neutral-40 {
    background: var(--color-neutral-40)
}

:root .bg-neutral-40-i {
    background: var(--color-neutral-40) !important
}

:root .bc-neutral-40 {
    border-color: var(--color-neutral-40)
}

:root .color-light-neutral-50 {
    color: #c1c7d0
}

:root .color-neutral-50 {
    color: var(--color-neutral-50)
}

:root .color-neutral-50-i {
    color: var(--color-neutral-50) !important
}

:root .bg-neutral-50 {
    background: var(--color-neutral-50)
}

:root .bg-neutral-50-i {
    background: var(--color-neutral-50) !important
}

:root .bc-neutral-50 {
    border-color: var(--color-neutral-50)
}

:root .color-light-neutral-60 {
    color: #b0b7c3
}

:root .color-neutral-60 {
    color: var(--color-neutral-60)
}

:root .color-neutral-60-i {
    color: var(--color-neutral-60) !important
}

:root .bg-neutral-60 {
    background: var(--color-neutral-60)
}

:root .bg-neutral-60-i {
    background: var(--color-neutral-60) !important
}

:root .bc-neutral-60 {
    border-color: var(--color-neutral-60)
}

:root .color-light-neutral-70 {
    color: #8d98af
}

:root .color-neutral-70 {
    color: var(--color-neutral-70)
}

:root .color-neutral-70-i {
    color: var(--color-neutral-70) !important
}

:root .bg-neutral-70 {
    background: var(--color-neutral-70)
}

:root .bg-neutral-70-i {
    background: var(--color-neutral-70) !important
}

:root .bc-neutral-70 {
    border-color: var(--color-neutral-70)
}

:root .color-light-neutral-80 {
    color: #6d7794
}

:root .color-neutral-80 {
    color: var(--color-neutral-80)
}

:root .color-neutral-80-i {
    color: var(--color-neutral-80) !important
}

:root .bg-neutral-80 {
    background: var(--color-neutral-80)
}

:root .bg-neutral-80-i {
    background: var(--color-neutral-80) !important
}

:root .bc-neutral-80 {
    border-color: var(--color-neutral-80)
}

:root .color-light-neutral-90 {
    color: #3e4763
}

:root .color-neutral-90 {
    color: var(--color-neutral-90)
}

:root .color-neutral-90-i {
    color: var(--color-neutral-90) !important
}

:root .bg-neutral-90 {
    background: var(--color-neutral-90)
}

:root .bg-neutral-90-i {
    background: var(--color-neutral-90) !important
}

:root .bc-neutral-90 {
    border-color: var(--color-neutral-90)
}

:root .color-light-neutral-100 {
    color: #081131
}

:root .color-neutral-100 {
    color: var(--color-neutral-100)
}

:root .color-neutral-100-i {
    color: var(--color-neutral-100) !important
}

:root .bg-neutral-100 {
    background: var(--color-neutral-100)
}

:root .bg-neutral-100-i {
    background: var(--color-neutral-100) !important
}

:root .bc-neutral-100 {
    border-color: var(--color-neutral-100)
}

:root .color-light-neutral-border {
    color: #eaeff3
}

:root .color-neutral-border {
    color: var(--color-neutral-border)
}

:root .color-neutral-border-i {
    color: var(--color-neutral-border) !important
}

:root .bg-neutral-border {
    background: var(--color-neutral-border)
}

:root .bg-neutral-border-i {
    background: var(--color-neutral-border) !important
}

:root .bc-neutral-border {
    border-color: var(--color-neutral-border)
}

:root .color-light-chart-good-color {
    color: #34a853
}

:root .color-chart-good-color {
    color: var(--color-chart-good-color)
}

:root .color-chart-good-color-i {
    color: var(--color-chart-good-color) !important
}

:root .bg-chart-good-color {
    background: var(--color-chart-good-color)
}

:root .bg-chart-good-color-i {
    background: var(--color-chart-good-color) !important
}

:root .bc-chart-good-color {
    border-color: var(--color-chart-good-color)
}

:root .color-light-chart-medium-color {
    color: #fbbc05
}

:root .color-chart-medium-color {
    color: var(--color-chart-medium-color)
}

:root .color-chart-medium-color-i {
    color: var(--color-chart-medium-color) !important
}

:root .bg-chart-medium-color {
    background: var(--color-chart-medium-color)
}

:root .bg-chart-medium-color-i {
    background: var(--color-chart-medium-color) !important
}

:root .bc-chart-medium-color {
    border-color: var(--color-chart-medium-color)
}

:root .color-light-chart-severe-color {
    color: #ea4335
}

:root .color-chart-severe-color {
    color: var(--color-chart-severe-color)
}

:root .color-chart-severe-color-i {
    color: var(--color-chart-severe-color) !important
}

:root .bg-chart-severe-color {
    background: var(--color-chart-severe-color)
}

:root .bg-chart-severe-color-i {
    background: var(--color-chart-severe-color) !important
}

:root .bc-chart-severe-color {
    border-color: var(--color-chart-severe-color)
}

:root .color-light-chart-range-1 {
    color: #ea4335
}

:root .color-chart-range-1 {
    color: var(--color-chart-range-1)
}

:root .color-chart-range-1-i {
    color: var(--color-chart-range-1) !important
}

:root .bg-chart-range-1 {
    background: var(--color-chart-range-1)
}

:root .bg-chart-range-1-i {
    background: var(--color-chart-range-1) !important
}

:root .bc-chart-range-1 {
    border-color: var(--color-chart-range-1)
}

:root .color-light-chart-range-2 {
    color: #fbbc05
}

:root .color-chart-range-2 {
    color: var(--color-chart-range-2)
}

:root .color-chart-range-2-i {
    color: var(--color-chart-range-2) !important
}

:root .bg-chart-range-2 {
    background: var(--color-chart-range-2)
}

:root .bg-chart-range-2-i {
    background: var(--color-chart-range-2) !important
}

:root .bc-chart-range-2 {
    border-color: var(--color-chart-range-2)
}

:root .color-light-chart-range-3 {
    color: #f7d70f
}

:root .color-chart-range-3 {
    color: var(--color-chart-range-3)
}

:root .color-chart-range-3-i {
    color: var(--color-chart-range-3) !important
}

:root .bg-chart-range-3 {
    background: var(--color-chart-range-3)
}

:root .bg-chart-range-3-i {
    background: var(--color-chart-range-3) !important
}

:root .bc-chart-range-3 {
    border-color: var(--color-chart-range-3)
}

:root .color-light-chart-range-4 {
    color: #99ce95
}

:root .color-chart-range-4 {
    color: var(--color-chart-range-4)
}

:root .color-chart-range-4-i {
    color: var(--color-chart-range-4) !important
}

:root .bg-chart-range-4 {
    background: var(--color-chart-range-4)
}

:root .bg-chart-range-4-i {
    background: var(--color-chart-range-4) !important
}

:root .bc-chart-range-4 {
    border-color: var(--color-chart-range-4)
}

:root .color-light-chart-range-5 {
    color: #34a853
}

:root .color-chart-range-5 {
    color: var(--color-chart-range-5)
}

:root .color-chart-range-5-i {
    color: var(--color-chart-range-5) !important
}

:root .bg-chart-range-5 {
    background: var(--color-chart-range-5)
}

:root .bg-chart-range-5-i {
    background: var(--color-chart-range-5) !important
}

:root .bc-chart-range-5 {
    border-color: var(--color-chart-range-5)
}

#components-root>:not(.enable-darkmode),
:root .disable-darkmode,
:root .invert {
    --color-1: #fff;
    --color-1-rgb: 255, 255, 255;
    --color-2: #fafafa;
    --color-2-rgb: 250, 250, 250;
    --color-3: #eaeff3;
    --color-3-rgb: 234, 239, 243;
    --color-4: #d4dbe1;
    --color-4-rgb: 212, 219, 225;
    --color-5: #c1c7d0;
    --color-5-rgb: 193, 199, 208;
    --color-6: #b0b7c3;
    --color-6-rgb: 176, 183, 195;
    --color-7: #8d98af;
    --color-7-rgb: 141, 152, 175;
    --color-8: #6d7794;
    --color-8-rgb: 109, 119, 148;
    --color-9: #3e4763;
    --color-9-rgb: 62, 71, 99;
    --color-10: #081131;
    --color-10-rgb: 8, 17, 49;
    --color-bg: #fff;
    --color-bg-rgb: 255, 255, 255;
    --color-box-bg: #fafafa;
    --color-box-bg-rgb: 250, 250, 250;
    --color-border: #eaeff3;
    --color-border-rgb: 234, 239, 243;
    --color-disabled: #b0b7c3;
    --color-disabled-rgb: 176, 183, 195;
    --color-tooltip-bg: #fff;
    --color-tooltip-bg-rgb: 255, 255, 255;
    --color-tooltip-darkbg: rgba(8, 17, 49, 0.8);
    --color-tooltip-darkbg-rgb: 8, 17, 49;
    --color-input-bg: #fafafa;
    --color-input-bg-rgb: 250, 250, 250;
    --color-input-border: #d4dbe1;
    --color-input-border-rgb: 212, 219, 225;
    --color-hover-bg: rgba(66, 133, 244, 0.14);
    --color-hover-bg-rgb: 66, 133, 244;
    --color-active-bg: rgba(66, 133, 244, 0.14);
    --color-active-bg-rgb: 66, 133, 244;
    --color-disabled-bg: #fafafa;
    --color-disabled-bg-rgb: 250, 250, 250;
    --color-shadow: #000;
    --color-shadow-rgb: 0, 0, 0;
    --color-primary-color: #4285f4;
    --color-primary-color-i: #4285f4 !important;
    --color-primary-color-rgb: 66, 133, 244;
    --color-light-primary-color: #4285f4;
    --color-primary-surface: rgba(66, 133, 244, 0.14);
    --color-primary-surface-i: rgba(66, 133, 244, 0.14) !important;
    --color-primary-surface-rgb: 66, 133, 244;
    --color-light-primary-surface: rgba(66, 133, 244, 0.14);
    --color-primary-border: #c0d6fb;
    --color-primary-border-i: #c0d6fb !important;
    --color-primary-border-rgb: 192, 214, 251;
    --color-light-primary-border: #c0d6fb;
    --color-primary-hover: #376fcb;
    --color-primary-hover-i: #376fcb !important;
    --color-primary-hover-rgb: 55, 111, 203;
    --color-light-primary-hover: #376fcb;
    --color-primary-pressed: #21427a;
    --color-primary-pressed-i: #21427a !important;
    --color-primary-pressed-rgb: 33, 66, 122;
    --color-light-primary-pressed: #21427a;
    --color-primary-focus: rgba(66, 133, 244, 0.2);
    --color-primary-focus-i: rgba(66, 133, 244, 0.2) !important;
    --color-primary-focus-rgb: 66, 133, 244;
    --color-light-primary-focus: rgba(66, 133, 244, 0.2);
    --color-primary-chart: #4285f4;
    --color-primary-chart-i: #4285f4 !important;
    --color-primary-chart-rgb: 66, 133, 244;
    --color-light-primary-chart: #4285f4;
    --color-primary-text: #4285f4;
    --color-primary-text-i: #4285f4 !important;
    --color-primary-text-rgb: 66, 133, 244;
    --color-light-primary-text: #4285f4;
    --color-primary-number: #4285f4;
    --color-primary-number-i: #4285f4 !important;
    --color-primary-number-rgb: 66, 133, 244;
    --color-light-primary-number: #4285f4;
    --color-success-color: #34a853;
    --color-success-color-i: #34a853 !important;
    --color-success-color-rgb: 52, 168, 83;
    --color-light-success-color: #34a853;
    --color-success-surface: #e2f3e7;
    --color-success-surface-i: #e2f3e7 !important;
    --color-success-surface-rgb: 226, 243, 231;
    --color-light-success-surface: #e2f3e7;
    --color-success-border: #bbe2c6;
    --color-success-border-i: #bbe2c6 !important;
    --color-success-border-rgb: 187, 226, 198;
    --color-light-success-border: #bbe2c6;
    --color-success-hover: #2b8c45;
    --color-success-hover-i: #2b8c45 !important;
    --color-success-hover-rgb: 43, 140, 69;
    --color-light-success-hover: #2b8c45;
    --color-success-pressed: #1a5429;
    --color-success-pressed-i: #1a5429 !important;
    --color-success-pressed-rgb: 26, 84, 41;
    --color-light-success-pressed: #1a5429;
    --color-success-focus: rgba(52, 168, 83, 0.2);
    --color-success-focus-i: rgba(52, 168, 83, 0.2) !important;
    --color-success-focus-rgb: 52, 168, 83;
    --color-light-success-focus: rgba(52, 168, 83, 0.2);
    --color-success-chart: #34a853;
    --color-success-chart-i: #34a853 !important;
    --color-success-chart-rgb: 52, 168, 83;
    --color-light-success-chart: #34a853;
    --color-success-text: #34a853;
    --color-success-text-i: #34a853 !important;
    --color-success-text-rgb: 52, 168, 83;
    --color-light-success-text: #34a853;
    --color-success-number: #2b8c45;
    --color-success-number-i: #2b8c45 !important;
    --color-success-number-rgb: 43, 140, 69;
    --color-light-success-number: #2b8c45;
    --color-warning-color: #fbbc05;
    --color-warning-color-i: #fbbc05 !important;
    --color-warning-color-rgb: 251, 188, 5;
    --color-light-warning-color: #fbbc05;
    --color-warning-surface: #fef6dc;
    --color-warning-surface-i: #fef6dc !important;
    --color-warning-surface-rgb: 254, 246, 220;
    --color-light-warning-surface: #fef6dc;
    --color-warning-border: #fee9ac;
    --color-warning-border-i: #fee9ac !important;
    --color-warning-border-rgb: 254, 233, 172;
    --color-light-warning-border: #fee9ac;
    --color-warning-hover: #d19d04;
    --color-warning-hover-i: #d19d04 !important;
    --color-warning-hover-rgb: 209, 157, 4;
    --color-light-warning-hover: #d19d04;
    --color-warning-pressed: #7e5e02;
    --color-warning-pressed-i: #7e5e02 !important;
    --color-warning-pressed-rgb: 126, 94, 2;
    --color-light-warning-pressed: #7e5e02;
    --color-warning-focus: rgba(251, 188, 5, 0.2);
    --color-warning-focus-i: rgba(251, 188, 5, 0.2) !important;
    --color-warning-focus-rgb: 251, 188, 5;
    --color-light-warning-focus: rgba(251, 188, 5, 0.2);
    --color-warning-chart: #fbbc05;
    --color-warning-chart-i: #fbbc05 !important;
    --color-warning-chart-rgb: 251, 188, 5;
    --color-light-warning-chart: #fbbc05;
    --color-warning-text: #fbbc05;
    --color-warning-text-i: #fbbc05 !important;
    --color-warning-text-rgb: 251, 188, 5;
    --color-light-warning-text: #fbbc05;
    --color-warning-number: #d19d04;
    --color-warning-number-i: #d19d04 !important;
    --color-warning-number-rgb: 209, 157, 4;
    --color-light-warning-number: #d19d04;
    --color-danger-color: #ea4335;
    --color-danger-color-i: #ea4335 !important;
    --color-danger-color-rgb: 234, 67, 53;
    --color-light-danger-color: #ea4335;
    --color-danger-surface: #fce5e3;
    --color-danger-surface-i: #fce5e3 !important;
    --color-danger-surface-rgb: 252, 229, 227;
    --color-light-danger-surface: #fce5e3;
    --color-danger-border: #f8c0bc;
    --color-danger-border-i: #f8c0bc !important;
    --color-danger-border-rgb: 248, 192, 188;
    --color-light-danger-border: #f8c0bc;
    --color-danger-hover: #c3382c;
    --color-danger-hover-i: #c3382c !important;
    --color-danger-hover-rgb: 195, 56, 44;
    --color-light-danger-hover: #c3382c;
    --color-danger-pressed: #801d18;
    --color-danger-pressed-i: #801d18 !important;
    --color-danger-pressed-rgb: 128, 29, 24;
    --color-light-danger-pressed: #801d18;
    --color-danger-focus: rgba(234, 67, 53, 0.2);
    --color-danger-focus-i: rgba(234, 67, 53, 0.2) !important;
    --color-danger-focus-rgb: 234, 67, 53;
    --color-light-danger-focus: rgba(234, 67, 53, 0.2);
    --color-danger-chart: #ea4335;
    --color-danger-chart-i: #ea4335 !important;
    --color-danger-chart-rgb: 234, 67, 53;
    --color-light-danger-chart: #ea4335;
    --color-danger-text: #ea4335;
    --color-danger-text-i: #ea4335 !important;
    --color-danger-text-rgb: 234, 67, 53;
    --color-light-danger-text: #ea4335;
    --color-danger-number: #c3382c;
    --color-danger-number-i: #c3382c !important;
    --color-danger-number-rgb: 195, 56, 44;
    --color-light-danger-number: #c3382c;
    --color-theme-bg: #185daa;
    --color-theme-bg-i: #185daa !important;
    --color-theme-bg-rgb: 24, 93, 170;
    --color-light-theme-bg: #185daa;
    --color-theme-active-bg: #0e4c92;
    --color-theme-active-bg-i: #0e4c92 !important;
    --color-theme-active-bg-rgb: 14, 76, 146;
    --color-light-theme-active-bg: #0e4c92;
    --color-neutral-10: #fff;
    --color-neutral-10-i: #fff !important;
    --color-neutral-10-rgb: 255, 255, 255;
    --color-light-neutral-10: #fff;
    --color-neutral-20: #fafafa;
    --color-neutral-20-i: #fafafa !important;
    --color-neutral-20-rgb: 250, 250, 250;
    --color-light-neutral-20: #fafafa;
    --color-neutral-30: #eaeff3;
    --color-neutral-30-i: #eaeff3 !important;
    --color-neutral-30-rgb: 234, 239, 243;
    --color-light-neutral-30: #eaeff3;
    --color-neutral-40: #d4dbe1;
    --color-neutral-40-i: #d4dbe1 !important;
    --color-neutral-40-rgb: 212, 219, 225;
    --color-light-neutral-40: #d4dbe1;
    --color-neutral-50: #c1c7d0;
    --color-neutral-50-i: #c1c7d0 !important;
    --color-neutral-50-rgb: 193, 199, 208;
    --color-light-neutral-50: #c1c7d0;
    --color-neutral-60: #b0b7c3;
    --color-neutral-60-i: #b0b7c3 !important;
    --color-neutral-60-rgb: 176, 183, 195;
    --color-light-neutral-60: #b0b7c3;
    --color-neutral-70: #8d98af;
    --color-neutral-70-i: #8d98af !important;
    --color-neutral-70-rgb: 141, 152, 175;
    --color-light-neutral-70: #8d98af;
    --color-neutral-80: #6d7794;
    --color-neutral-80-i: #6d7794 !important;
    --color-neutral-80-rgb: 109, 119, 148;
    --color-light-neutral-80: #6d7794;
    --color-neutral-90: #3e4763;
    --color-neutral-90-i: #3e4763 !important;
    --color-neutral-90-rgb: 62, 71, 99;
    --color-light-neutral-90: #3e4763;
    --color-neutral-100: #081131;
    --color-neutral-100-i: #081131 !important;
    --color-neutral-100-rgb: 8, 17, 49;
    --color-light-neutral-100: #081131;
    --color-neutral-border: #eaeff3;
    --color-neutral-border-i: #eaeff3 !important;
    --color-neutral-border-rgb: 234, 239, 243;
    --color-light-neutral-border: #eaeff3;
    --color-chart-good-color: #34a853;
    --color-chart-good-color-i: #34a853 !important;
    --color-chart-good-color-rgb: 52, 168, 83;
    --color-light-chart-good-color: #34a853;
    --color-chart-medium-color: #fbbc05;
    --color-chart-medium-color-i: #fbbc05 !important;
    --color-chart-medium-color-rgb: 251, 188, 5;
    --color-light-chart-medium-color: #fbbc05;
    --color-chart-severe-color: #ea4335;
    --color-chart-severe-color-i: #ea4335 !important;
    --color-chart-severe-color-rgb: 234, 67, 53;
    --color-light-chart-severe-color: #ea4335;
    --color-chart-range-1: #ea4335;
    --color-chart-range-1-i: #ea4335 !important;
    --color-chart-range-1-rgb: 234, 67, 53;
    --color-light-chart-range-1: #ea4335;
    --color-chart-range-2: #fbbc05;
    --color-chart-range-2-i: #fbbc05 !important;
    --color-chart-range-2-rgb: 251, 188, 5;
    --color-light-chart-range-2: #fbbc05;
    --color-chart-range-3: #f7d70f;
    --color-chart-range-3-i: #f7d70f !important;
    --color-chart-range-3-rgb: 247, 215, 15;
    --color-light-chart-range-3: #f7d70f;
    --color-chart-range-4: #99ce95;
    --color-chart-range-4-i: #99ce95 !important;
    --color-chart-range-4-rgb: 153, 206, 149;
    --color-light-chart-range-4: #99ce95;
    --color-chart-range-5: #34a853;
    --color-chart-range-5-i: #34a853 !important;
    --color-chart-range-5-rgb: 52, 168, 83;
    --color-light-chart-range-5: #34a853
}

.dark-mode #components-root .use-darkmode,
:root.dark-mode,
:root .dark-mode {
    --color-1: #23242c;
    --color-1-rgb: 35, 36, 44;
    --color-2: #2c2d34;
    --color-2-rgb: 44, 45, 52;
    --color-3: #303139;
    --color-3-rgb: 48, 49, 57;
    --color-4: #35363d;
    --color-4-rgb: 53, 54, 61;
    --color-5: #393a41;
    --color-5-rgb: 57, 58, 65;
    --color-6: #8d98af;
    --color-6-rgb: 141, 152, 175;
    --color-7: #b0b7c3;
    --color-7-rgb: 176, 183, 195;
    --color-8: #c1c7d0;
    --color-8-rgb: 193, 199, 208;
    --color-9: #d4dbe1;
    --color-9-rgb: 212, 219, 225;
    --color-10: #fff;
    --color-10-rgb: 255, 255, 255;
    --color-bg: #23242c;
    --color-bg-rgb: 35, 36, 44;
    --color-box-bg: #2c2d34;
    --color-box-bg-rgb: 44, 45, 52;
    --color-border: #35363d;
    --color-border-rgb: 53, 54, 61;
    --color-disabled: #b0b7c3;
    --color-disabled-rgb: 176, 183, 195;
    --color-tooltip-bg: #303139;
    --color-tooltip-bg-rgb: 48, 49, 57;
    --color-input-bg: #303139;
    --color-input-bg-rgb: 48, 49, 57;
    --color-input-border: #35363d;
    --color-input-border-rgb: 53, 54, 61;
    --color-hover-bg: rgba(66, 133, 244, 0.14);
    --color-hover-bg-rgb: 66, 133, 244;
    --color-active-bg: rgba(66, 133, 244, 0.14);
    --color-active-bg-rgb: 66, 133, 244;
    --color-disabled-bg: #35363d;
    --color-disabled-bg-rgb: 53, 54, 61;
    --color-shadow: #fff;
    --color-shadow-rgb: 255, 255, 255;
    --color-primary-color: #4285f4;
    --color-primary-color-i: #4285f4 !important;
    --color-primary-color-rgb: 66, 133, 244;
    --color-primary-surface: rgba(66, 133, 244, 0.14);
    --color-primary-surface-i: rgba(66, 133, 244, 0.14) !important;
    --color-primary-surface-rgb: 66, 133, 244;
    --color-primary-border: #2c4168;
    --color-primary-border-i: #2c4168 !important;
    --color-primary-border-rgb: 44, 65, 104;
    --color-primary-hover: #376fcb;
    --color-primary-hover-i: #376fcb !important;
    --color-primary-hover-rgb: 55, 111, 203;
    --color-primary-text: #4285f4;
    --color-primary-text-i: #4285f4 !important;
    --color-primary-text-rgb: 66, 133, 244;
    --color-success-surface: rgba(52, 168, 83, 0.14);
    --color-success-surface-i: rgba(52, 168, 83, 0.14) !important;
    --color-success-surface-rgb: 52, 168, 83;
    --color-warning-surface: rgba(251, 188, 5, 0.14);
    --color-warning-surface-i: rgba(251, 188, 5, 0.14) !important;
    --color-warning-surface-rgb: 251, 188, 5;
    --color-danger-surface: rgba(234, 67, 53, 0.14);
    --color-danger-surface-i: rgba(234, 67, 53, 0.14) !important;
    --color-danger-surface-rgb: 234, 67, 53;
    --color-theme-bg: #081131;
    --color-theme-bg-i: #081131 !important;
    --color-theme-bg-rgb: 8, 17, 49;
    --color-theme-active-bg: #3e4763;
    --color-theme-active-bg-i: #3e4763 !important;
    --color-theme-active-bg-rgb: 62, 71, 99;
    --color-neutral-10: #23242c;
    --color-neutral-10-i: #23242c !important;
    --color-neutral-10-rgb: 35, 36, 44;
    --color-neutral-20: #2c2d34;
    --color-neutral-20-i: #2c2d34 !important;
    --color-neutral-20-rgb: 44, 45, 52;
    --color-neutral-30: #303139;
    --color-neutral-30-i: #303139 !important;
    --color-neutral-30-rgb: 48, 49, 57;
    --color-neutral-40: #35363d;
    --color-neutral-40-i: #35363d !important;
    --color-neutral-40-rgb: 53, 54, 61;
    --color-neutral-50: #393a41;
    --color-neutral-50-i: #393a41 !important;
    --color-neutral-50-rgb: 57, 58, 65;
    --color-neutral-60: #8d98af;
    --color-neutral-60-i: #8d98af !important;
    --color-neutral-60-rgb: 141, 152, 175;
    --color-neutral-70: #b0b7c3;
    --color-neutral-70-i: #b0b7c3 !important;
    --color-neutral-70-rgb: 176, 183, 195;
    --color-neutral-80: #c1c7d0;
    --color-neutral-80-i: #c1c7d0 !important;
    --color-neutral-80-rgb: 193, 199, 208;
    --color-neutral-90: #d4dbe1;
    --color-neutral-90-i: #d4dbe1 !important;
    --color-neutral-90-rgb: 212, 219, 225;
    --color-neutral-100: #fff;
    --color-neutral-100-i: #fff !important;
    --color-neutral-100-rgb: 255, 255, 255;
    --color-neutral-border: #35363d;
    --color-neutral-border-i: #35363d !important;
    --color-neutral-border-rgb: 53, 54, 61;
    --color-youtube: #ff0302
}

.color-1 {
    color: var(--color-1) !important
}

.color-2 {
    color: var(--color-2) !important
}

.color-3 {
    color: var(--color-3) !important
}

.color-4 {
    color: var(--color-4) !important
}

.color-5 {
    color: var(--color-5) !important
}

.color-6 {
    color: var(--color-6) !important
}

.color-7 {
    color: var(--color-7) !important
}

.color-8 {
    color: var(--color-8) !important
}

.color-9 {
    color: var(--color-9) !important
}

.color-10 {
    color: var(--color-10) !important
}

.color-bg {
    color: var(--color-bg) !important
}

.color-box-bg {
    color: var(--color-box-bg) !important
}

.color-border {
    color: var(--color-border) !important
}

.color-disabled {
    color: var(--color-disabled) !important
}

.color-tooltip-bg {
    color: var(--color-tooltip-bg) !important
}

.color-tooltip-darkbg {
    color: var(--color-tooltip-darkbg) !important
}

.color-input-bg {
    color: var(--color-input-bg) !important
}

.color-input-border {
    color: var(--color-input-border) !important
}

.color-hover-bg {
    color: var(--color-hover-bg) !important
}

.color-active-bg {
    color: var(--color-active-bg) !important
}

.color-disabled-bg {
    color: var(--color-disabled-bg) !important
}

.color-shadow {
    color: var(--color-shadow) !important
}

.dark-mode #components-root>:not(.enable-darkmode) .use-darkmode {
    filter: invert(1)
}

.gf-page,
.gf .page {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    color: var(--color-10)
}

.gf {
    font-family: Inter, arial, helvetica, sans-serif
}

.gf,
.gf #components-root>:not(.enable-darkmode),
.gfbody {
    color: var(--color-10)
}

.t-h1 {
    font-size: 2.625rem;
    line-height: 3.5rem;
    font-size: clamp(1.5rem, 1.785vw + 1rem, 2.625rem);
    line-height: clamp(2.25rem, 2.36vw + 1.375rem, 3.5rem)
}

.t-h1,
.t-h2 {
    font-weight: 800;
    font-family: Inter, arial, helvetica, sans-serif
}

.t-h2 {
    font-size: 1.875rem;
    line-height: 2.5rem;
    font-size: clamp(1.25rem, 1.488vw + .5357rem, 1.875rem);
    line-height: clamp(2rem, 1.19vw + 1.4288rem, 2.5rem)
}

.t-h3 {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-size: clamp(1rem, 2vw + .54375rem, 1.5rem);
    line-height: clamp(1.5rem, 2vw + 1.04375rem, 2.25rem);
    font-weight: 800
}

.t-h3,
.t-h4 {
    font-family: Inter, arial, helvetica, sans-serif
}

.t-h4 {
    font-size: 1.25rem;
    line-height: 2rem;
    font-size: clamp(1rem, 1vw + .77rem, 1.25rem);
    line-height: clamp(1.5rem, 1vw + 1.27rem, 2.25rem);
    font-weight: 500
}

.t-h5 {
    font-size: 1.125rem;
    font-weight: 800
}

.t-h5,
.t-h6,
.t-title-sm {
    line-height: 1.5rem;
    font-family: Inter, arial, helvetica, sans-serif
}

.t-h6,
.t-title-sm {
    font-size: 1rem;
    font-weight: 700
}

.t-h7 {
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 700;
    font-family: Inter, arial, helvetica, sans-serif
}

.t-body-lg {
    font-size: 1.25rem;
    line-height: 2rem
}

.t-body-md {
    font-size: 1rem;
    line-height: 1.75rem
}

.t-body-md.bold,
.t-body-sm {
    line-height: 1.5rem
}

.t-body-sm {
    font-size: .875rem
}

.t-body-sm.bold {
    line-height: 1.25rem
}

.extra-bold {
    font-weight: 800
}

.bold {
    font-weight: 700
}

.semi-bold {
    font-weight: 500
}

.t-caption,
.t-description {
    font-size: .75rem;
    line-height: 1rem
}

.t-caption-sm {
    font-size: .6875rem;
    line-height: .875rem
}

.t-right {
    text-align: right !important
}

.t-left {
    text-align: left !important
}

.t-center {
    text-align: center !important
}

.t-default {
    color: var(--color-10)
}

.t-label,
.t-tertiary {
    color: var(--color-9) !important
}

.t-icon {
    color: var(--color-8)
}

.t-nowrap {
    white-space: nowrap
}

.t-break-word {
    word-break: break-word
}

.t-number {
    font-family: arial, helvetica, sans-serif !important
}

@media screen and (min-width: 441px) and (max-width:768px) {
    .t-body1 {
        font-size: 1rem;
        line-height: 1.75rem
    }
}

@media screen and (max-width: 440px) {
    .t-body1 {
        font-size: .875rem;
        line-height: 1.5rem
    }
}

.gf a {
    /*color: var(--color-10)*/
}

.gf a:hover {
    color: #4285f4
}

.gf .border {
    border: 1px solid var(--color-border);
    border-radius: 8px;
    box-sizing: border-box
}

.gf .border-md {
    border: 1px solid var(--color-border);
    border-radius: 6px;
    box-sizing: border-box
}

.gf .gf-border {
    border: 1px solid var(--color-border);
    border-radius: 8px;
    box-sizing: border-box
}

.gf .gf-border-i {
    border: 1px solid var(--color-border) !important;
    border-radius: 8px !important;
    box-sizing: border-box !important
}

.gf .border-radius {
    border-radius: 8px
}

.bg-box {
    background-color: var(--color-2) !important
}

.bg-emphasize {
    background-color: #eaeff3
}

.borderless {
    border: none
}

.border-box {
    box-sizing: border-box
}

.flex {
    display: flex;
    flex-wrap: wrap
}

.flex-auto {
    flex: auto
}

.flex-none {
    flex: none
}

.flex-center {
    align-items: center
}

.flex-end {
    align-items: flex-end
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse
}

.flex-wrap {
    flex-wrap: wrap
}

.flex-nowrap {
    flex-wrap: nowrap
}

.flex-noshrink {
    flex-shrink: 0
}

.justify-around {
    justify-content: space-around
}

.justify-between {
    justify-content: space-between
}

.justify-center {
    justify-content: center
}

.justify-start {
    justify-content: flex-start
}

.justify-end {
    justify-content: flex-end
}

.align-items-center {
    align-items: center
}

.m-none {
    margin: 0
}

.m-auto {
    margin: auto
}

.m-xs {
    margin: 4px
}

.m-sm2 {
    margin: 6px
}

.m-sm {
    margin: 8px
}

.m-md {
    margin: 12px
}

.m-lg {
    margin: 16px
}

.m-xl {
    margin: 20px
}

.m-xxl {
    margin: 24px
}

.m-32 {
    margin: 32px
}

.m-v-none {
    margin-top: 0;
    margin-bottom: 0
}

.m-v-auto {
    margin-top: auto;
    margin-bottom: auto
}

.m-v-xs {
    margin-top: 4px;
    margin-bottom: 4px
}

.m-v-sm2 {
    margin-top: 6px;
    margin-bottom: 6px
}

.m-v-sm {
    margin-top: 8px;
    margin-bottom: 8px
}

.m-v-md {
    margin-top: 12px;
    margin-bottom: 12px
}

.m-v-lg {
    margin-top: 16px;
    margin-bottom: 16px
}

.m-v-xl {
    margin-top: 20px;
    margin-bottom: 20px
}

.m-v-xxl {
    margin-top: 24px;
    margin-bottom: 24px
}

.m-v-32 {
    margin-top: 32px;
    margin-bottom: 32px
}

.m-h-none {
    margin-left: 0;
    margin-right: 0
}

.m-h-auto {
    margin-left: auto;
    margin-right: auto
}

.m-h-xs {
    margin-left: 4px;
    margin-right: 4px
}

.m-h-sm2 {
    margin-left: 6px;
    margin-right: 6px
}

.m-h-sm {
    margin-left: 8px;
    margin-right: 8px
}

.m-h-md {
    margin-left: 12px;
    margin-right: 12px
}

.m-h-lg {
    margin-left: 16px;
    margin-right: 16px
}

.m-h-xl {
    margin-left: 20px;
    margin-right: 20px
}

.m-h-xxl {
    margin-left: 24px;
    margin-right: 24px
}

.m-h-32 {
    margin-left: 32px;
    margin-right: 32px
}

.m-t-none {
    margin-top: 0
}

.m-t-auto {
    margin-top: auto
}

.m-t-xs {
    margin-top: 4px
}

.m-t-sm2 {
    margin-top: 6px
}

.m-t-sm {
    margin-top: 8px
}

.m-t-md {
    margin-top: 12px
}

.m-t-lg {
    margin-top: 16px
}

.m-t-xl {
    margin-top: 20px
}

.m-t-xxl {
    margin-top: 24px
}

.m-t-32 {
    margin-top: 32px
}

.m-b-none {
    margin-bottom: 0
}

.m-b-auto {
    margin-bottom: auto
}

.m-b-xs {
    margin-bottom: 4px
}

.m-b-sm2 {
    margin-bottom: 6px
}

.m-b-sm {
    margin-bottom: 8px
}

.m-b-md {
    margin-bottom: 12px
}

.m-b-lg {
    margin-bottom: 16px
}

.m-b-xl {
    margin-bottom: 20px
}

.m-b-xxl {
    margin-bottom: 24px
}

.m-b-32 {
    margin-bottom: 32px
}

.m-l-none {
    margin-left: 0
}

.m-l-auto {
    margin-left: auto
}

.m-l-xs {
    margin-left: 4px
}

.m-l-sm2 {
    margin-left: 6px
}

.m-l-sm {
    margin-left: 8px
}

.m-l-md {
    margin-left: 12px
}

.m-l-lg {
    margin-left: 16px
}

.m-l-xl {
    margin-left: 20px
}

.m-l-xxl {
    margin-left: 24px
}

.m-l-32 {
    margin-left: 32px
}

.m-r-none {
    margin-right: 0
}

.m-r-auto {
    margin-right: auto
}

.m-r-xs {
    margin-right: 4px
}

.m-r-sm2 {
    margin-right: 6px
}

.m-r-sm {
    margin-right: 8px
}

.m-r-md {
    margin-right: 12px
}

.m-r-lg {
    margin-right: 16px
}

.m-r-xl {
    margin-right: 20px
}

.m-r-xxl {
    margin-right: 24px
}

.m-r-32 {
    margin-right: 32px
}

.p-none {
    padding: 0
}

.p-auto {
    padding: auto
}

.p-xs {
    padding: 4px
}

.p-sm2 {
    padding: 6px
}

.p-sm {
    padding: 8px
}

.p-md {
    padding: 12px
}

.p-lg {
    padding: 16px
}

.p-xl {
    padding: 20px
}

.p-xxl {
    padding: 24px
}

.p-32 {
    padding: 32px
}

.p-v-none {
    padding-top: 0;
    padding-bottom: 0
}

.p-v-auto {
    padding-top: auto;
    padding-bottom: auto
}

.p-v-xs {
    padding-top: 4px;
    padding-bottom: 4px
}

.p-v-sm2 {
    padding-top: 6px;
    padding-bottom: 6px
}

.p-v-sm {
    padding-top: 8px;
    padding-bottom: 8px
}

.p-v-md {
    padding-top: 12px;
    padding-bottom: 12px
}

.p-v-lg {
    padding-top: 16px;
    padding-bottom: 16px
}

.p-v-xl {
    padding-top: 20px;
    padding-bottom: 20px
}

.p-v-xxl {
    padding-top: 24px;
    padding-bottom: 24px
}

.p-v-32 {
    padding-top: 32px;
    padding-bottom: 32px
}

.p-h-none {
    padding-left: 0;
    padding-right: 0
}

.p-h-auto {
    padding-left: auto;
    padding-right: auto
}

.p-h-xs {
    padding-left: 4px;
    padding-right: 4px
}

.p-h-sm2 {
    padding-left: 6px;
    padding-right: 6px
}

.p-h-sm {
    padding-left: 8px;
    padding-right: 8px
}

.p-h-md {
    padding-left: 12px;
    padding-right: 12px
}

.p-h-lg {
    padding-left: 16px;
    padding-right: 16px
}

.p-h-xl {
    padding-left: 20px;
    padding-right: 20px
}

.p-h-xxl {
    padding-left: 24px;
    padding-right: 24px
}

.p-h-32 {
    padding-left: 32px;
    padding-right: 32px
}

.p-t-none {
    padding-top: 0
}

.p-t-auto {
    padding-top: auto
}

.p-t-xs {
    padding-top: 4px
}

.p-t-sm2 {
    padding-top: 6px
}

.p-t-sm {
    padding-top: 8px
}

.p-t-md {
    padding-top: 12px
}

.p-t-lg {
    padding-top: 16px
}

.p-t-xl {
    padding-top: 20px
}

.p-t-xxl {
    padding-top: 24px
}

.p-t-32 {
    padding-top: 32px
}

.p-b-none {
    padding-bottom: 0
}

.p-b-auto {
    padding-bottom: auto
}

.p-b-xs {
    padding-bottom: 4px
}

.p-b-sm2 {
    padding-bottom: 6px
}

.p-b-sm {
    padding-bottom: 8px
}

.p-b-md {
    padding-bottom: 12px
}

.p-b-lg {
    padding-bottom: 16px
}

.p-b-xl {
    padding-bottom: 20px
}

.p-b-xxl {
    padding-bottom: 24px
}

.p-b-32 {
    padding-bottom: 32px
}

.p-l-none {
    padding-left: 0
}

.p-l-auto {
    padding-left: auto
}

.p-l-xs {
    padding-left: 4px
}

.p-l-sm2 {
    padding-left: 6px
}

.p-l-sm {
    padding-left: 8px
}

.p-l-md {
    padding-left: 12px
}

.p-l-lg {
    padding-left: 16px
}

.p-l-xl {
    padding-left: 20px
}

.p-l-xxl {
    padding-left: 24px
}

.p-l-32 {
    padding-left: 32px
}

.p-r-none {
    padding-right: 0
}

.p-r-auto {
    padding-right: auto
}

.p-r-xs {
    padding-right: 4px
}

.p-r-sm2 {
    padding-right: 6px
}

.p-r-sm {
    padding-right: 8px
}

.p-r-md {
    padding-right: 12px
}

.p-r-lg {
    padding-right: 16px
}

.p-r-xl {
    padding-right: 20px
}

.p-r-xxl {
    padding-right: 24px
}

.p-r-32 {
    padding-right: 32px
}

.border-1 {
    border-radius: 1px !important
}

.border-sm {
    border-radius: 4px !important
}

.border-md {
    border-radius: 6px !important
}

.border-lg {
    border-radius: 10px !important
}

.border-xl {
    border-radius: 12px !important
}

.border-primary {
    border-color: #c0d6fb
}

.bg-primary {
    background-color: var(--color-primary-surface)
}

.t-primary {
    color: var(--color-primary-text) !important
}

.t-primary-number {
    color: var(--color-primary-number) !important
}

.border-success {
    border-color: #bbe2c6
}

.bg-success {
    background-color: var(--color-success-surface)
}

.t-success {
    color: var(--color-success-text) !important
}

.t-success-number {
    color: var(--color-success-number) !important
}

.border-warning {
    border-color: #fee9ac
}

.bg-warning {
    background-color: var(--color-warning-surface)
}

.t-warning {
    color: var(--color-warning-text) !important
}

.t-warning-number {
    color: var(--color-warning-number) !important
}

.border-danger {
    border-color: #f8c0bc
}

.bg-danger {
    background-color: var(--color-danger-surface)
}

.t-danger {
    color: var(--color-danger-text) !important
}

.t-danger-number {
    color: var(--color-danger-number) !important
}

.border-neutral {
    border-color: #eaeff3
}

.t-chart-good {
    color: var(--color-chart-good-color) !important
}

.t-chart-medium {
    color: var(--color-chart-medium-color) !important
}

.t-chart-severe {
    color: var(--color-chart-severe-color) !important
}

@media screen and (max-width: 1007px) {
    .ms-none {
        margin: 0
    }

    .ms-auto {
        margin: auto
    }

    .ms-xs {
        margin: 4px
    }

    .ms-sm2 {
        margin: 6px
    }

    .ms-sm {
        margin: 8px
    }

    .ms-md {
        margin: 12px
    }

    .ms-lg {
        margin: 16px
    }

    .ms-xl {
        margin: 20px
    }

    .ms-xxl {
        margin: 24px
    }

    .ms-32 {
        margin: 32px
    }

    .ms-v-none {
        margin-top: 0;
        margin-bottom: 0
    }

    .ms-v-auto {
        margin-top: auto;
        margin-bottom: auto
    }

    .ms-v-xs {
        margin-top: 4px;
        margin-bottom: 4px
    }

    .ms-v-sm2 {
        margin-top: 6px;
        margin-bottom: 6px
    }

    .ms-v-sm {
        margin-top: 8px;
        margin-bottom: 8px
    }

    .ms-v-md {
        margin-top: 12px;
        margin-bottom: 12px
    }

    .ms-v-lg {
        margin-top: 16px;
        margin-bottom: 16px
    }

    .ms-v-xl {
        margin-top: 20px;
        margin-bottom: 20px
    }

    .ms-v-xxl {
        margin-top: 24px;
        margin-bottom: 24px
    }

    .ms-v-32 {
        margin-top: 32px;
        margin-bottom: 32px
    }

    .ms-h-none {
        margin-left: 0;
        margin-right: 0
    }

    .ms-h-auto {
        margin-left: auto;
        margin-right: auto
    }

    .ms-h-xs {
        margin-left: 4px;
        margin-right: 4px
    }

    .ms-h-sm2 {
        margin-left: 6px;
        margin-right: 6px
    }

    .ms-h-sm {
        margin-left: 8px;
        margin-right: 8px
    }

    .ms-h-md {
        margin-left: 12px;
        margin-right: 12px
    }

    .ms-h-lg {
        margin-left: 16px;
        margin-right: 16px
    }

    .ms-h-xl {
        margin-left: 20px;
        margin-right: 20px
    }

    .ms-h-xxl {
        margin-left: 24px;
        margin-right: 24px
    }

    .ms-h-32 {
        margin-left: 32px;
        margin-right: 32px
    }

    .ms-t-none {
        margin-top: 0
    }

    .ms-t-auto {
        margin-top: auto
    }

    .ms-t-xs {
        margin-top: 4px
    }

    .ms-t-sm2 {
        margin-top: 6px
    }

    .ms-t-sm {
        margin-top: 8px
    }

    .ms-t-md {
        margin-top: 12px
    }

    .ms-t-lg {
        margin-top: 16px
    }

    .ms-t-xl {
        margin-top: 20px
    }

    .ms-t-xxl {
        margin-top: 24px
    }

    .ms-t-32 {
        margin-top: 32px
    }

    .ms-b-none {
        margin-bottom: 0
    }

    .ms-b-auto {
        margin-bottom: auto
    }

    .ms-b-xs {
        margin-bottom: 4px
    }

    .ms-b-sm2 {
        margin-bottom: 6px
    }

    .ms-b-sm {
        margin-bottom: 8px
    }

    .ms-b-md {
        margin-bottom: 12px
    }

    .ms-b-lg {
        margin-bottom: 16px
    }

    .ms-b-xl {
        margin-bottom: 20px
    }

    .ms-b-xxl {
        margin-bottom: 24px
    }

    .ms-b-32 {
        margin-bottom: 32px
    }

    .ms-l-none {
        margin-left: 0
    }

    .ms-l-auto {
        margin-left: auto
    }

    .ms-l-xs {
        margin-left: 4px
    }

    .ms-l-sm2 {
        margin-left: 6px
    }

    .ms-l-sm {
        margin-left: 8px
    }

    .ms-l-md {
        margin-left: 12px
    }

    .ms-l-lg {
        margin-left: 16px
    }

    .ms-l-xl {
        margin-left: 20px
    }

    .ms-l-xxl {
        margin-left: 24px
    }

    .ms-l-32 {
        margin-left: 32px
    }

    .ms-r-none {
        margin-right: 0
    }

    .ms-r-auto {
        margin-right: auto
    }

    .ms-r-xs {
        margin-right: 4px
    }

    .ms-r-sm2 {
        margin-right: 6px
    }

    .ms-r-sm {
        margin-right: 8px
    }

    .ms-r-md {
        margin-right: 12px
    }

    .ms-r-lg {
        margin-right: 16px
    }

    .ms-r-xl {
        margin-right: 20px
    }

    .ms-r-xxl {
        margin-right: 24px
    }

    .ms-r-32 {
        margin-right: 32px
    }

    .ps-none {
        padding: 0
    }

    .ps-auto {
        padding: auto
    }

    .ps-xs {
        padding: 4px
    }

    .ps-sm2 {
        padding: 6px
    }

    .ps-sm {
        padding: 8px
    }

    .ps-md {
        padding: 12px
    }

    .ps-lg {
        padding: 16px
    }

    .ps-xl {
        padding: 20px
    }

    .ps-xxl {
        padding: 24px
    }

    .ps-32 {
        padding: 32px
    }

    .ps-v-none {
        padding-top: 0;
        padding-bottom: 0
    }

    .ps-v-auto {
        padding-top: auto;
        padding-bottom: auto
    }

    .ps-v-xs {
        padding-top: 4px;
        padding-bottom: 4px
    }

    .ps-v-sm2 {
        padding-top: 6px;
        padding-bottom: 6px
    }

    .ps-v-sm {
        padding-top: 8px;
        padding-bottom: 8px
    }

    .ps-v-md {
        padding-top: 12px;
        padding-bottom: 12px
    }

    .ps-v-lg {
        padding-top: 16px;
        padding-bottom: 16px
    }

    .ps-v-xl {
        padding-top: 20px;
        padding-bottom: 20px
    }

    .ps-v-xxl {
        padding-top: 24px;
        padding-bottom: 24px
    }

    .ps-v-32 {
        padding-top: 32px;
        padding-bottom: 32px
    }

    .ps-h-none {
        padding-left: 0;
        padding-right: 0
    }

    .ps-h-auto {
        padding-left: auto;
        padding-right: auto
    }

    .ps-h-xs {
        padding-left: 4px;
        padding-right: 4px
    }

    .ps-h-sm2 {
        padding-left: 6px;
        padding-right: 6px
    }

    .ps-h-sm {
        padding-left: 8px;
        padding-right: 8px
    }

    .ps-h-md {
        padding-left: 12px;
        padding-right: 12px
    }

    .ps-h-lg {
        padding-left: 16px;
        padding-right: 16px
    }

    .ps-h-xl {
        padding-left: 20px;
        padding-right: 20px
    }

    .ps-h-xxl {
        padding-left: 24px;
        padding-right: 24px
    }

    .ps-h-32 {
        padding-left: 32px;
        padding-right: 32px
    }

    .ps-t-none {
        padding-top: 0
    }

    .ps-t-auto {
        padding-top: auto
    }

    .ps-t-xs {
        padding-top: 4px
    }

    .ps-t-sm2 {
        padding-top: 6px
    }

    .ps-t-sm {
        padding-top: 8px
    }

    .ps-t-md {
        padding-top: 12px
    }

    .ps-t-lg {
        padding-top: 16px
    }

    .ps-t-xl {
        padding-top: 20px
    }

    .ps-t-xxl {
        padding-top: 24px
    }

    .ps-t-32 {
        padding-top: 32px
    }

    .ps-b-none {
        padding-bottom: 0
    }

    .ps-b-auto {
        padding-bottom: auto
    }

    .ps-b-xs {
        padding-bottom: 4px
    }

    .ps-b-sm2 {
        padding-bottom: 6px
    }

    .ps-b-sm {
        padding-bottom: 8px
    }

    .ps-b-md {
        padding-bottom: 12px
    }

    .ps-b-lg {
        padding-bottom: 16px
    }

    .ps-b-xl {
        padding-bottom: 20px
    }

    .ps-b-xxl {
        padding-bottom: 24px
    }

    .ps-b-32 {
        padding-bottom: 32px
    }

    .ps-l-none {
        padding-left: 0
    }

    .ps-l-auto {
        padding-left: auto
    }

    .ps-l-xs {
        padding-left: 4px
    }

    .ps-l-sm2 {
        padding-left: 6px
    }

    .ps-l-sm {
        padding-left: 8px
    }

    .ps-l-md {
        padding-left: 12px
    }

    .ps-l-lg {
        padding-left: 16px
    }

    .ps-l-xl {
        padding-left: 20px
    }

    .ps-l-xxl {
        padding-left: 24px
    }

    .ps-l-32 {
        padding-left: 32px
    }

    .ps-r-none {
        padding-right: 0
    }

    .ps-r-auto {
        padding-right: auto
    }

    .ps-r-xs {
        padding-right: 4px
    }

    .ps-r-sm2 {
        padding-right: 6px
    }

    .ps-r-sm {
        padding-right: 8px
    }

    .ps-r-md {
        padding-right: 12px
    }

    .ps-r-lg {
        padding-right: 16px
    }

    .ps-r-xl {
        padding-right: 20px
    }

    .ps-r-xxl {
        padding-right: 24px
    }

    .ps-r-32 {
        padding-right: 32px
    }
}

@media screen and (min-width: 641px) {
    .hidden-tablet-and-up {
        display: none !important
    }
}

@media screen and (max-width: 640px) {
    .hidden-phone {
        display: none !important
    }

    .mp-none {
        margin: 0
    }

    .mp-auto {
        margin: auto
    }

    .mp-v-none {
        margin-top: 0;
        margin-bottom: 0
    }

    .mp-v-auto {
        margin-top: auto;
        margin-bottom: auto
    }

    .mp-h-none {
        margin-left: 0;
        margin-right: 0
    }

    .mp-h-auto {
        margin-left: auto;
        margin-right: auto
    }

    .mp-t-none {
        margin-top: 0
    }

    .mp-t-auto {
        margin-top: auto
    }

    .mp-b-none {
        margin-bottom: 0
    }

    .mp-b-auto {
        margin-bottom: auto
    }

    .mp-l-none {
        margin-left: 0
    }

    .mp-l-auto {
        margin-left: auto
    }

    .mp-r-none {
        margin-right: 0
    }

    .mp-r-auto {
        margin-right: auto
    }

    .pp-none {
        padding: 0
    }

    .pp-auto {
        padding: auto
    }

    .pp-v-none {
        padding-top: 0;
        padding-bottom: 0
    }

    .pp-v-auto {
        padding-top: auto;
        padding-bottom: auto
    }

    .pp-h-none {
        padding-left: 0;
        padding-right: 0
    }

    .pp-h-auto {
        padding-left: auto;
        padding-right: auto
    }

    .pp-t-none {
        padding-top: 0
    }

    .pp-t-auto {
        padding-top: auto
    }

    .pp-b-none {
        padding-bottom: 0
    }

    .pp-b-auto {
        padding-bottom: auto
    }

    .pp-l-none {
        padding-left: 0
    }

    .pp-l-auto {
        padding-left: auto
    }

    .pp-r-none {
        padding-right: 0
    }

    .pp-r-auto {
        padding-right: auto
    }
}

.gf-button-md {
    border-radius: 8px;
    padding: 6px 12px
}

.gf-button-sm {
    border-radius: 8px;
    padding: 6px
}

.gf.el-button {
    background: var(--color-1);
    color: var(--color-9)
}

.gf.el-button.borderless {
    border: none !important;
    background: 0 0 !important
}

.gf.el-button.el-button--mini {
    border-radius: 4px
}

.gf.el-button.el-button--md {
    border-radius: 6px
}

.gf.el-button.selected {
    background: var(--color-primary-surface);
    border-color: var(--color-primary-color)
}

.gf.el-button.selected span {
    color: var(--color-primary-color);
    font-weight: 700
}

.gf.el-button.selected-success {
    background: var(--color-success-surface);
    border-color: var(--color-success-color)
}

.gf.el-button.selected-success span {
    color: var(--color-success-color);
    font-weight: 700
}

.gf.el-button.selected-danger {
    background: var(--color-danger-surface);
    border-color: var(--color-danger-color)
}

.gf.el-button.selected-danger span {
    color: var(--color-danger-color);
    font-weight: 700
}

.gf.el-button--xl {
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 8px
}

.gf.el-button--xl span {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500
}

.gf.el-button--large,
.gf.el-button--lg {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px
}

.gf.el-button--large span,
.gf.el-button--lg span {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500
}

.gf.el-button--md,
.gf.el-button--medium {
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 8px
}

.gf.el-button--md span,
.gf.el-button--medium span {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500
}

.gf.el-button--sm,
.gf.el-button--small {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 6px
}

.gf.el-button--small span,
.gf.el-button--sm span {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500
}

.gf.el-button--mini {
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;
    height: 26px
}

.gf.el-button--mini span {
    font-size: 12px;
    line-height: 16px
}

.gf.el-button--mini.icon {
    padding: 4px;
    font-size: 14px
}

.gf.el-button--xs {
    padding: 3px 6px
}

.gf.el-button--xs span {
    font-size: 11px;
    line-height: 14px;
    font-weight: 400
}

.gf.el-button--xxs {
    padding: 2px 4px
}

.gf.el-button--xxs span {
    font-size: 11px;
    line-height: 14px;
    font-weight: 400
}

.gf.el-button--mini.is-circle,
.gf.el-button--xs.is-circle {
    padding: 5px;
    border-radius: 50%
}

.gf.el-button--primary.is-plain.border-normal {
    border-color: var(--color-border) !important;
    background: 0 0 !important
}

.gf.el-button--primary.is-plain.border-normal:hover {
    border-color: var(--color-primary-border) !important;
    color: var(--color-primary-hover) !important
}

.gf.el-button.border-primary {
    border-color: var(--color-primary-color);
    background: 0 0 !important
}

.gf.el-button.border-primary:hover {
    border-color: var(--color-primary-hover) !important;
    color: var(--color-primary-hover) !important
}

.gf.el-button--primary {
    background-color: var(--color-primary-color);
    color: #fff;
    border-color: var(--color-primary-color)
}

.gf.el-button--primary.is-plain {
    background-color: var(--color-primary-surface);
    color: var(--color-primary-color);
    border: 1px solid var(--color-primary-color) !important
}

.gf.el-button--primary.is-plain:focus,
.gf.el-button--primary.is-plain:hover {
    background-color: var(--color-primary-color);
    color: #fff
}

.gf.el-button--success {
    background-color: var(--color-success-color);
    color: #fff;
    border-color: var(--color-success-color)
}

.gf.el-button--success.is-plain {
    background-color: var(--color-success-surface);
    color: var(--color-success-color);
    border: 1px solid var(--color-success-color) !important
}

.gf.el-button--success.is-plain:focus,
.gf.el-button--success.is-plain:hover {
    background-color: var(--color-success-color);
    color: #fff
}

.gf.el-button--warning {
    background-color: var(--color-warning-color);
    color: #fff;
    border-color: var(--color-warning-color)
}

.gf.el-button--warning.is-plain {
    background-color: var(--color-warning-surface);
    color: var(--color-warning-color);
    border: 1px solid var(--color-warning-color) !important
}

.gf.el-button--warning.is-plain:focus,
.gf.el-button--warning.is-plain:hover {
    background-color: var(--color-warning-color);
    color: #fff
}

.gf.el-button--danger {
    background-color: var(--color-danger-color);
    color: #fff;
    border-color: var(--color-danger-color)
}

.gf.el-button--danger.is-plain {
    background-color: var(--color-danger-surface);
    color: var(--color-danger-color);
    border: 1px solid var(--color-danger-color) !important
}

.gf.el-button--danger.is-plain:focus,
.gf.el-button--danger.is-plain:hover {
    background-color: var(--color-danger-color);
    color: #fff
}

.gf.el-button--theme {
    background-color: var(--color-theme-color);
    color: #fff;
    border-color: var(--color-theme-color)
}

.gf.el-button--theme.is-plain {
    background-color: var(--color-theme-surface);
    color: var(--color-theme-color);
    border: 1px solid var(--color-theme-color) !important
}

.gf.el-button--theme.is-plain:focus,
.gf.el-button--theme.is-plain:hover {
    background-color: var(--color-theme-color);
    color: #fff
}

.gf.el-button--neutral {
    background-color: var(--color-neutral-color);
    color: #fff;
    border-color: var(--color-neutral-color)
}

.gf.el-button--neutral.is-plain {
    background-color: var(--color-neutral-surface);
    color: var(--color-neutral-color);
    border: 1px solid var(--color-neutral-color) !important
}

.gf.el-button--neutral.is-plain:focus,
.gf.el-button--neutral.is-plain:hover {
    background-color: var(--color-neutral-color);
    color: #fff
}

.gf.el-button--chart-good {
    background-color: var(--color-chart-good-color);
    color: #fff;
    border-color: var(--color-chart-good-color)
}

.gf.el-button--chart-good.is-plain {
    background-color: var(--color-chart-good-surface);
    color: var(--color-chart-good-color);
    border: 1px solid var(--color-chart-good-color) !important
}

.gf.el-button--chart-good.is-plain:focus,
.gf.el-button--chart-good.is-plain:hover {
    background-color: var(--color-chart-good-color);
    color: #fff
}

.gf.el-button--chart-medium {
    background-color: var(--color-chart-medium-color);
    color: #fff;
    border-color: var(--color-chart-medium-color)
}

.gf.el-button--chart-medium.is-plain {
    background-color: var(--color-chart-medium-surface);
    color: var(--color-chart-medium-color);
    border: 1px solid var(--color-chart-medium-color) !important
}

.gf.el-button--chart-medium.is-plain:focus,
.gf.el-button--chart-medium.is-plain:hover {
    background-color: var(--color-chart-medium-color);
    color: #fff
}

.gf.el-button--chart-severe {
    background-color: var(--color-chart-severe-color);
    color: #fff;
    border-color: var(--color-chart-severe-color)
}

.gf.el-button--chart-severe.is-plain {
    background-color: var(--color-chart-severe-surface);
    color: var(--color-chart-severe-color);
    border: 1px solid var(--color-chart-severe-color) !important
}

.gf.el-button--chart-severe.is-plain:focus,
.gf.el-button--chart-severe.is-plain:hover {
    background-color: var(--color-chart-severe-color);
    color: #fff
}

.gf.el-button--chart-range {
    background-color: var(--color-chart-range-color);
    color: #fff;
    border-color: var(--color-chart-range-color)
}

.gf.el-button--chart-range.is-plain {
    background-color: var(--color-chart-range-surface);
    color: var(--color-chart-range-color);
    border: 1px solid var(--color-chart-range-color) !important
}

.gf.el-button--chart-range.is-plain:focus,
.gf.el-button--chart-range.is-plain:hover {
    background-color: var(--color-chart-range-color);
    color: #fff
}

.premium-button {
    background: linear-gradient(180deg, rgba(255, 174, 0, .56), var(--color-warning-color)) !important;
    color: #fff !important
}

.premium_plus-button {
    background: linear-gradient(180deg, rgba(12, 97, 247, .56), var(--color-primary-color)) !important;
    color: #fff !important
}

.premium_plus-button-dark {
    background: linear-gradient(180deg, var(--color-primary-color), #185daa) !important;
    color: #fff !important
}

.professional-button {
    background: linear-gradient(180deg, rgba(8, 17, 49, .64), #081131) !important;
    color: var(--color-warning-color) !important
}

.gf.el-tabs .el-tabs__item {
    font-size: 12px;
    line-height: 16px;
    color: var(--color-9);
    font-weight: 500;
    padding: 0 8px
}

.gf.el-tabs .el-tabs__active-bar {
    background-color: #4285f4
}

.gf.el-tabs .el-tabs__item.is-active {
    color: #4285f4
}

.gf.sm.el-tabs .el-tabs__item {
    font-size: 11px;
    line-height: 14px;
    padding: 0 12px 6px
}

.gf .el-tabs__nav-next,
.gf .el-tabs__nav-prev {
    line-height: 30px !important
}

.gf.el-radio-group {
    border: 1px solid var(--color-border);
    border-radius: 100px;
    background: var(--color-2);
    height: 24px;
    padding: 0 3px
}

.gf.el-radio-group .el-radio-button {
    top: 3px
}

.gf.el-radio-group .el-radio-button__inner {
    padding-top: 3px;
    padding-bottom: 3px;
    background: 0 0;
    border: none;
    color: var(--color-8)
}

.gf.el-radio-group .el-radio-button:first-child .el-radio-button__inner {
    border: none
}

.gf.el-radio-group .el-radio-button__inner:hover {
    color: var(--color-primary-hover)
}

.gf.el-radio-group .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    border-radius: 100px;
    background: var(--color-1);
    color: var(--color-primary-color);
    box-shadow: 0 1px 4px rgba(8, 17, 49, .04) !important
}

.gf.el-checkbox,
.gf.el-tree .el-checkbox {
    color: var(--color-10)
}

.gf.el-checkbox .el-checkbox__inner,
.gf.el-tree .el-checkbox .el-checkbox__inner {
    width: 16px;
    height: 16px;
    border-radius: 4px
}

.gf.el-checkbox .el-checkbox__inner:after,
.gf.el-tree .el-checkbox .el-checkbox__inner:after {
    left: 5px;
    top: 2px
}

.gf.el-checkbox .el-checkbox__label,
.gf.el-tree .el-checkbox .el-checkbox__label {
    font-size: 12px;
    padding-left: 4px
}

.gf.el-checkbox .el-checkbox__input.is-indeterminate .el-checkbox__inner:before,
.gf.el-tree .el-checkbox .el-checkbox__input.is-indeterminate .el-checkbox__inner:before {
    top: 6px
}

.gf.el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner,
.gf.el-checkbox .el-checkbox__input.is-indeterminate .el-checkbox__inner,
.gf.el-tree .el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner,
.gf.el-tree .el-checkbox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background: #326af6;
    border-color: #326af6
}

.gf.el-checkbox .el-checkbox__inner:hover,
.gf.el-tree .el-checkbox .el-checkbox__inner:hover {
    border-color: #376fcb
}

.gf.el-checkbox .el-checkbox__input.is-checked+.el-checkbox__label,
.gf.el-tree .el-checkbox .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #4285f4
}

.gf.el-checkbox el-checkbox__input.is-disabled .el-checkbox__inner,
.gf.el-tree .el-checkbox el-checkbox__input.is-disabled .el-checkbox__inner {
    border-color: #c1c7d0 !important;
    cursor: not-allowed !important
}

.gf.el-checkbox .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner,
.gf.el-tree .el-checkbox .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background: #eaeff3 !important;
    border-color: #c1c7d0 !important
}

.gf.el-checkbox.mini .el-checkbox__inner {
    width: 12px;
    height: 12px
}

.gf.el-checkbox.mini .el-checkbox__label {
    font-size: 11px;
    font-weight: 400
}

.gf.el-checkbox.mini .el-checkbox__inner:after {
    left: 3px;
    top: 0
}

.gf.el-checkbox.mini .el-checkbox__input.is-indeterminate .el-checkbox__inner:before {
    top: 4px
}

.gf.el-radio .el-radio__inner {
    width: 16px;
    height: 16px
}

.gf.el-radio .el-radio__input.is-checked .el-radio__inner {
    background: #326af6;
    border-color: #326af6
}

.gf.el-radio .el-radio__input.is-checked+.el-radio__label {
    color: #4285f4
}

.gf.el-radio .el-radio__inner:hover {
    border-color: #376fcb
}

.gf.el-radio .el-radio__inner:after {
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 5px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg) scaleY(0);
    width: 3px;
    transition: transform .15s ease-in .05s;
    transform-origin: center;
    background: 0 0;
    border-radius: 0
}

.gf.el-radio .el-radio__input.is-checked .el-radio__inner:after {
    transform: rotate(45deg) scaleY(1)
}

.gf.el-switch .el-switch__core {
    width: 28px !important;
    height: 16px !important
}

.gf.el-switch .el-switch__core:after {
    width: 12px;
    height: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .15), 0 2px 1px rgba(0, 0, 0, .06)
}

.gf.el-switch.is-checked .el-switch__core {
    background-color: #4285f4;
    border-color: #4285f4
}

.gf.el-switch.is-checked .el-switch__core:hover {
    background-color: #376fcb;
    border-color: #376fcb
}

.gf.el-switch.is-checked .el-switch__core:after {
    margin-left: -13px
}

.gf.el-input .el-input__inner,
.gf.el-select .el-input__inner {
    background-color: var(--color-1);
    border-color: var(--color-border);
    color: var(--color-9)
}

.gf.el-input--small .el-input__inner {
    height: 28px;
    line-height: 28px;
    border-radius: 6px
}

.gf.el-input--small .el-input-group__append {
    padding: 0 20px
}

.gf.el-input--medium .el-input__inner {
    height: 40px;
    line-height: 40px;
    border-radius: 8px
}

.gf.el-input--large .el-input__inner {
    height: 48px;
    line-height: 48px;
    border-radius: 8px
}

.gf.el-input--mini .el-input__inner {
    height: 26px;
    line-height: 26px
}

.gf.el-input--mini .el-input--mini .el-input__icon {
    line-height: 26px;
    font-size: 16px
}

.gf.el-input--prefix .el-input__inner {
    padding-left: 24px
}

.gf.el-textarea__inner:focus {
    outline: 0;
    border-color: #4285f4
}

.gf .el-form-item.is-error .el-input__inner {
    border-color: var(--color-danger-border)
}

.el-form-item.is-success .el-textarea__inner,
.gf .el-form-item.is-success .el-input__inner {
    border-color: var(--color-border)
}

.gf.el-form .el-form-item__label {
    padding-right: var(--size-sm)
}

.gf.el-select .el-input__suffix-inner i:before {
    content: "\e609";
    color: var(--color-8)
}

.gf.el-select--mini .el-input--mini .el-input__inner {
    height: 26px;
    line-height: 26px;
    padding-left: 8px;
    font-size: 11px;
    border-radius: var(--size-radius-md);
    border-color: var(--color-border)
}

.gf.el-select--mini .el-input__suffix-inner i:before {
    font-size: 10px
}

.gf.el-select--small .el-input--small .el-select__caret {
    margin-right: 4px
}

.gf.el-select-group__wrap .el-select-group__title {
    line-height: 24px;
    padding-left: var(--size-md)
}

.gf.el-select-dropdown .el-select-dropdown__item,
.gf.el-select-group__wrap .el-select-dropdown__item {
    font-size: 12px;
    line-height: 20px;
    height: 20px
}

.gf.el-select-group__wrap:not(:last-of-type) {
    padding-bottom: var(--size-md)
}

.gf.el-select-group__wrap:not(:last-of-type):after {
    bottom: 6px;
    background: var(--color-border);
    left: var(--size-md);
    right: var(--size-md)
}

.gf.el-popover {
    background: 0 0;
    background-color: var(--color-1);
    border-color: var(--color-border);
    border-radius: var(--size-radius-md);
    color: var(--color-10)
}

.gf.el-popover .popper__arrow,
.gf.el-popover .popper__arrow:after {
    border-width: 9px
}

.gf.el-popper[x-placement^=bottom] .popper__arrow {
    top: -9px;
    border-bottom-color: var(--color-border)
}

.gf.el-popper[x-placement^=bottom] .popper__arrow:after {
    margin-left: -9px;
    border-bottom-color: var(--color-1)
}

.gf.el-popper[x-placement^=top] .popper__arrow {
    bottom: -9px;
    border-top-color: var(--color-border)
}

.gf.el-popper[x-placement^=top] .popper__arrow:after {
    margin-left: -9px;
    border-top-color: var(--color-1)
}

.gf.el-popper[x-placement^=right] .popper__arrow {
    left: -9px;
    border-right-color: var(--color-border)
}

.gf.el-popper[x-placement^=right] .popper__arrow:after {
    left: 1px;
    bottom: -9px;
    border-right-color: var(--color-1)
}

.gf.el-popper[x-placement^=left] .popper__arrow {
    right: -9px;
    border-left-color: var(--color-border)
}

.gf.el-popper[x-placement^=left] .popper__arrow:after {
    right: 1px;
    bottom: -9px;
    border-left-color: var(--color-1)
}

.dialog-no-header>.el-dialog .el-dialog__header {
    display: none
}

.gf>.el-dialog {
    border-radius: 8px !important;
    overflow: hidden
}

.gf>.el-dialog .el-dialog__body {
    padding: 0 8px !important
}

.gf.el-dialog {
    background: var(--color-bg) !important;
    border-radius: var(--size-radius-lg)
}

.gf.el-dialog .el-dialog__header {
    background-color: var(--color-bg);
    text-align: left;
    margin: 0 var(--size-xxl) var(--size-lg);
    color: var(--color-10);
    border-bottom: 1px solid var(--color-border);
    padding: var(--size-xl) 0 var(--size-lg)
}

.gf.el-dialog .el-dialog__title {
    font-size: .875rem;
    font-weight: 700;
    color: var(--color-10)
}

.gf.el-dialog .el-dialog__headerbtn {
    top: var(--size-xxl);
    right: var(--size-xxl)
}

.gf.el-dialog .el-dialog__body {
    padding: 0 var(--size-xxl) var(--size-xl)
}

.gf.el-dialog.sm {
    border-radius: var(--size-radius-md)
}

.gf.el-dialog.sm .el-dialog__header {
    margin: 0;
    padding: var(--size-md)
}

.gf.el-dialog.sm .el-dialog__headerbtn {
    top: var(--size-md);
    right: var(--size-md)
}

.gf.el-dialog.sm .el-dialog__body {
    padding: 0 var(--size-md) var(--size-md)
}

.gf.el-breadcrumb .el-breadcrumb__inner.is-link,
.gf.el-breadcrumb .el-breadcrumb__inner a {
    color: var(--color-primary-color);
    font-weight: 400;
    font-size: 11px
}

.gf.el-breadcrumb .el-breadcrumb__separator {
    font-size: 11px;
    font-weight: 400
}

.gf.el-breadcrumb .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    font-size: 11px;
    color: var(--color-8)
}

.gf.el-breadcrumb .el-breadcrumb__separator {
    margin: 0 6px
}

.gf.el-breadcrumb .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    font-weight: 700
}

.gf-border {
    border: 1px solid var(--color-border)
}

.gf-border-b {
    border-bottom: 1px solid var(--color-border)
}

.gf-border-t {
    border-top: 1px solid var(--color-border)
}

.gf-border-l {
    border-left: 1px solid var(--color-border)
}

.gf-border-r {
    border-right: 1px solid var(--color-border)
}

input::-webkit-input-placeholder {
    color: var(--color-7)
}

input:-moz-placeholder,
input::-moz-placeholder {
    color: var(--color-7)
}

input:-ms-input-placeholder {
    color: var(--color-7)
}

hr {
    border: 0;
    border-top: 1px solid var(--color-border)
}

.border-radius-md {
    border-radius: var(--size-radius-md)
}

.border-radius-md-i {
    border-radius: var(--size-radius-md) !important
}

.bg-box-bg {
    background: var(--color-box-bg)
}

@media screen and (max-width: 768px) {
    [gf-resize-xs="0"] {
        width: 0
    }

    [gf-resize-xs="20"] {
        width: 20%
    }

    [gf-resize-xs="25"] {
        width: 25%
    }

    [gf-resize-xs="33"] {
        width: 33%
    }

    [gf-resize-xs="40"] {
        width: 40%
    }

    [gf-resize-xs="42"] {
        width: 42%
    }

    [gf-resize-xs="50"] {
        width: 50%
    }

    [gf-resize-xs="58"] {
        width: 58%
    }

    [gf-resize-xs="60"] {
        width: 60%
    }

    [gf-resize-xs="66"] {
        width: 66%
    }

    [gf-resize-xs="67"] {
        width: 67%
    }

    [gf-resize-xs="75"] {
        width: 75%
    }

    [gf-resize-xs="90"] {
        width: 90%
    }

    [gf-resize-xs="100"] {
        width: 100%
    }

    [gf-resize-xs="0"] {
        display: none
    }

    [gf-resize-xs-class=block] {
        display: block !important
    }

    [gf-resize-xs-class=inline] {
        display: inline-block !important
    }
}

@media screen and (min-width: 768px) {
    [gf-resize-sm="0"] {
        width: 0
    }

    [gf-resize-sm="20"] {
        width: 20%
    }

    [gf-resize-sm="25"] {
        width: 25%
    }

    [gf-resize-sm="33"] {
        width: 33%
    }

    [gf-resize-sm="40"] {
        width: 40%
    }

    [gf-resize-sm="42"] {
        width: 42%
    }

    [gf-resize-sm="50"] {
        width: 50%
    }

    [gf-resize-sm="58"] {
        width: 58%
    }

    [gf-resize-sm="60"] {
        width: 60%
    }

    [gf-resize-sm="66"] {
        width: 66%
    }

    [gf-resize-sm="67"] {
        width: 67%
    }

    [gf-resize-sm="75"] {
        width: 75%
    }

    [gf-resize-sm="90"] {
        width: 90%
    }

    [gf-resize-sm="100"] {
        width: 100%
    }

    [gf-resize-sm="0"] {
        display: none
    }

    [gf-resize-sm-class=block] {
        display: block !important
    }

    [gf-resize-sm-class=inline] {
        display: inline-block !important
    }
}

@media screen and (min-width: 992px) {
    [gf-resize-md="0"] {
        width: 0
    }

    [gf-resize-md="20"] {
        width: 20%
    }

    [gf-resize-md="25"] {
        width: 25%
    }

    [gf-resize-md="33"] {
        width: 33%
    }

    [gf-resize-md="40"] {
        width: 40%
    }

    [gf-resize-md="42"] {
        width: 42%
    }

    [gf-resize-md="50"] {
        width: 50%
    }

    [gf-resize-md="58"] {
        width: 58%
    }

    [gf-resize-md="60"] {
        width: 60%
    }

    [gf-resize-md="66"] {
        width: 66%
    }

    [gf-resize-md="67"] {
        width: 67%
    }

    [gf-resize-md="75"] {
        width: 75%
    }

    [gf-resize-md="90"] {
        width: 90%
    }

    [gf-resize-md="100"] {
        width: 100%
    }

    [gf-resize-md="0"] {
        display: none
    }

    [gf-resize-md-class=block] {
        display: block !important
    }

    [gf-resize-md-class=inline] {
        display: inline-block !important
    }
}

@media screen and (min-width: 1200px) {
    [gf-resize-lg="0"] {
        width: 0
    }

    [gf-resize-lg="20"] {
        width: 20%
    }

    [gf-resize-lg="25"] {
        width: 25%
    }

    [gf-resize-lg="33"] {
        width: 33%
    }

    [gf-resize-lg="40"] {
        width: 40%
    }

    [gf-resize-lg="42"] {
        width: 42%
    }

    [gf-resize-lg="50"] {
        width: 50%
    }

    [gf-resize-lg="58"] {
        width: 58%
    }

    [gf-resize-lg="60"] {
        width: 60%
    }

    [gf-resize-lg="66"] {
        width: 66%
    }

    [gf-resize-lg="67"] {
        width: 67%
    }

    [gf-resize-lg="75"] {
        width: 75%
    }

    [gf-resize-lg="90"] {
        width: 90%
    }

    [gf-resize-lg="100"] {
        width: 100%
    }

    [gf-resize-lg="0"] {
        display: none
    }

    [gf-resize-lg-class=block] {
        display: block !important
    }

    [gf-resize-lg-class=inline] {
        display: inline-block !important
    }
}

@media screen and (min-width: 1920px) {
    [gf-resize-xl="0"] {
        width: 0
    }

    [gf-resize-xl="20"] {
        width: 20%
    }

    [gf-resize-xl="25"] {
        width: 25%
    }

    [gf-resize-xl="33"] {
        width: 33%
    }

    [gf-resize-xl="40"] {
        width: 40%
    }

    [gf-resize-xl="42"] {
        width: 42%
    }

    [gf-resize-xl="50"] {
        width: 50%
    }

    [gf-resize-xl="58"] {
        width: 58%
    }

    [gf-resize-xl="60"] {
        width: 60%
    }

    [gf-resize-xl="66"] {
        width: 66%
    }

    [gf-resize-xl="67"] {
        width: 67%
    }

    [gf-resize-xl="75"] {
        width: 75%
    }

    [gf-resize-xl="90"] {
        width: 90%
    }

    [gf-resize-xl="100"] {
        width: 100%
    }

    [gf-resize-xl="0"] {
        display: none
    }

    [gf-resize-xl-class=block] {
        display: block !important
    }

    [gf-resize-xl-class=inline] {
        display: inline-block !important
    }
}

.guru-avatar {
    border-radius: 50%;
    object-fit: cover
}

.guru-avatar-border {
    border: 2px solid #e4e7ed
}

.guru-avatar-abbr {
    color: #e4e7ed;
    font-weight: 700;
    text-align: center
}

.guru-avatar-abbr:hover {
    color: var(--color-neutral-100)
}

@font-face {
    font-family: gf-icons;
    src: url(../../../assets/71a5a85a942a7fe8a04a4eaf20aad615.woff) format("woff"), url(../../../assets/9fce837df6bdb88e552ed80efd228340.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

.gf-icon {
    width: 1em;
    height: 1em;
    vertical-align: -.15em;
    fill: currentColor;
    overflow: hidden
}

[class*=" gf-icon-"],
[class^=gf-icon-] {
    font-family: gf-icons !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.gf-icon-eye-blind:before {
    content: "\e6a4"
}

.gf-icon-source:before {
    content: "\e6a3"
}

.gf-icon-unuseful:before {
    content: "\e6a1"
}

.gf-icon-useful:before {
    content: "\e6a2"
}

.gf-icon-ranking-1:before {
    content: "\e69e"
}

.gf-icon-ranking-2:before {
    content: "\e69f"
}

.gf-icon-ranking-3:before {
    content: "\e6a0"
}

.gf-icon-ranking:before {
    content: "\e69d"
}

.gf-icon-close:before {
    content: "\e670"
}

.gf-icon-link:before {
    content: "\e61f"
}

.gf-icon-camera:before {
    content: "\e644"
}

.gf-icon-podcast:before {
    content: "\e698"
}

.gf-icon-em:before {
    content: "\e694"
}

.gf-icon-at:before {
    content: "\e699"
}

.gf-icon-blockquote:before {
    content: "\e69a"
}

.gf-icon-mute:before {
    content: "\e69b"
}

.gf-icon-unmute:before {
    content: "\e69c"
}

.gf-icon-strong:before {
    content: "\e695"
}

.gf-icon-redo:before {
    content: "\e628"
}

.gf-icon-undo:before {
    content: "\e637"
}

.gf-icon-emoji:before {
    content: "\e696"
}

.gf-icon-del:before {
    content: "\e697"
}

.gf-icon-customer-service:before {
    content: "\e693"
}

.gf-icon-arrow-right:before {
    content: "\e65a"
}

.gf-icon-arrow-left:before {
    content: "\e660"
}

.gf-icon-opportunity:before {
    content: "\e68b"
}

.gf-icon-caret-left:before {
    content: "\e68c"
}

.gf-icon-caret-right:before {
    content: "\e68d"
}

.gf-icon-reading:before {
    content: "\e68e"
}

.gf-icon-chart-pie:before {
    content: "\e68f"
}

.gf-icon-notebook:before {
    content: "\e690"
}

.gf-icon-close-notification:before {
    content: "\e691"
}

.gf-icon-crop:before {
    content: "\e692"
}

.gf-icon-chart-bar:before {
    content: "\e606"
}

.gf-icon-chart-area:before {
    content: "\e607"
}

.gf-icon-align-justify:before {
    content: "\e600"
}

.gf-icon-apple:before {
    content: "\e601"
}

.gf-icon-bars:before {
    content: "\e602"
}

.gf-icon-caret-down:before {
    content: "\e603"
}

.gf-icon-caret-up:before {
    content: "\e604"
}

.gf-icon-cart:before {
    content: "\e605"
}

.gf-icon-square-check:before {
    content: "\e608"
}

.gf-icon-chevron-down:before {
    content: "\e609"
}

.gf-icon-chevron-up:before {
    content: "\e60a"
}

.gf-icon-circle:before {
    content: "\e60b"
}

.gf-icon-clock:before {
    content: "\e60c"
}

.gf-icon-cog:before {
    content: "\e60d"
}

.gf-icon-columns:before {
    content: "\e60e"
}

.gf-icon-comment-alt-dots:before {
    content: "\e60f"
}

.gf-icon-comment-alt:before {
    content: "\e610"
}

.gf-icon-compress:before {
    content: "\e611"
}

.gf-icon-copy:before {
    content: "\e612"
}

.gf-icon-cube:before {
    content: "\e613"
}

.gf-icon-ellipsis-v:before {
    content: "\e614"
}

.gf-icon-envelope:before {
    content: "\e615"
}

.gf-icon-excel:before {
    content: "\e616"
}

.gf-icon-exchange-alt:before {
    content: "\e617"
}

.gf-icon-expand-arrows-alt:before {
    content: "\e618"
}

.gf-icon-file-alt:before {
    content: "\e619"
}

.gf-icon-filter:before {
    content: "\e61a"
}

.gf-icon-folder:before {
    content: "\e61b"
}

.gf-icon-gift:before {
    content: "\e61c"
}

.gf-icon-globe:before {
    content: "\e61d"
}

.gf-icon-image:before {
    content: "\e61e"
}

.gf-icon-linkedin:before {
    content: "\e620"
}

.gf-icon-lock:before {
    content: "\e621"
}

.gf-icon-map:before {
    content: "\e622"
}

.gf-icon-square-minus:before {
    content: "\e623"
}

.gf-icon-pdf:before {
    content: "\e624"
}

.gf-icon-pencil-alt:before {
    content: "\e625"
}

.gf-icon-poll-h:before {
    content: "\e626"
}

.gf-icon-reddit:before {
    content: "\e627"
}

.gf-icon-rss-square:before {
    content: "\e629"
}

.gf-icon-save:before {
    content: "\e62a"
}

.gf-icon-sliders-h:before {
    content: "\e62b"
}

.gf-icon-sort-alpha-up:before {
    content: "\e62c"
}

.gf-icon-sort-amount-up:before {
    content: "\e62d"
}

.gf-icon-sort-numeric-up:before {
    content: "\e62e"
}

.gf-icon-spotify:before {
    content: "\e62f"
}

.gf-icon-square:before {
    content: "\e630"
}

.gf-icon-sticky-note:before {
    content: "\e631"
}

.gf-icon-sync:before {
    content: "\e632"
}

.gf-icon-table:before {
    content: "\e633"
}

.gf-icon-thumbtack:before {
    content: "\e634"
}

.gf-icon-trophy:before {
    content: "\e635"
}

.gf-icon-twitter:before {
    content: "\e636"
}

.gf-icon-unlock:before {
    content: "\e638"
}

.gf-icon-user-friends:before {
    content: "\e639"
}

.gf-icon-vials:before {
    content: "\e63a"
}

.gf-icon-facebook:before {
    content: "\e63b"
}

.gf-icon-wrench:before {
    content: "\e63c"
}

.gf-icon-youtube:before {
    content: "\e63d"
}

.gf-icon-attach:before {
    content: "\e63e"
}

.gf-icon-attract:before {
    content: "\e63f"
}

.gf-icon-bell:before {
    content: "\e640"
}

.gf-icon-arrow-down:before {
    content: "\e641"
}

.gf-icon-bottom-left:before {
    content: "\e642"
}

.gf-icon-bottom-right:before {
    content: "\e643"
}

.gf-icon-caret:before {
    content: "\e645"
}

.gf-icon-check:before {
    content: "\e646"
}

.gf-icon-chevron-left:before {
    content: "\e647"
}

.gf-icon-chevron-right:before {
    content: "\e648"
}

.gf-icon-chevron-double-left:before {
    content: "\e649"
}

.gf-icon-chevron-double-right:before {
    content: "\e64a"
}

.gf-icon-collection-tag:before {
    content: "\e64b"
}

.gf-icon-date:before {
    content: "\e64c"
}

.gf-icon-document-check:before {
    content: "\e64d"
}

.gf-icon-document-add:before {
    content: "\e64e"
}

.gf-icon-document-delete:before {
    content: "\e64f"
}

.gf-icon-document-remove:before {
    content: "\e650"
}

.gf-icon-document:before {
    content: "\e651"
}

.gf-icon-download:before {
    content: "\e652"
}

.gf-icon-edit-outline:before {
    content: "\e653"
}

.gf-icon-ellipsis:before {
    content: "\e654"
}

.gf-icon-error:before {
    content: "\e655"
}

.gf-icon-finished:before {
    content: "\e656"
}

.gf-icon-flag:before {
    content: "\e657"
}

.gf-icon-fold:before {
    content: "\e658"
}

.gf-icon-folder-open:before {
    content: "\e659"
}

.gf-icon-menu:before {
    content: "\e65b"
}

.gf-icon-grid:before {
    content: "\e65c"
}

.gf-icon-home:before {
    content: "\e65d"
}

.gf-icon-top-left:before {
    content: "\e65e"
}

.gf-icon-printer:before {
    content: "\e65f"
}

.gf-icon-loading:before {
    content: "\e661"
}

.gf-icon-upload:before {
    content: "\e662"
}

.gf-icon-minus:before {
    content: "\e663"
}

.gf-icon-mobile:before {
    content: "\e664"
}

.gf-icon-monitor:before {
    content: "\e665"
}

.gf-icon-operation:before {
    content: "\e666"
}

.gf-icon-pause:before {
    content: "\e667"
}

.gf-icon-play:before {
    content: "\e668"
}

.gf-icon-plus:before {
    content: "\e669"
}

.gf-icon-square-plus:before {
    content: "\e66a"
}

.gf-icon-circle-plus:before {
    content: "\e66b"
}

.gf-icon-question:before {
    content: "\e66c"
}

.gf-icon-circle-minus:before {
    content: "\e66d"
}

.gf-icon-scissors:before {
    content: "\e66e"
}

.gf-icon-search:before {
    content: "\e66f"
}

.gf-icon-send:before {
    content: "\e671"
}

.gf-icon-setting:before {
    content: "\e672"
}

.gf-icon-setting-outline:before {
    content: "\e673"
}

.gf-icon-share:before {
    content: "\e674"
}

.gf-icon-sort-down:before {
    content: "\e675"
}

.gf-icon-sort-up:before {
    content: "\e676"
}

.gf-icon-sort:before {
    content: "\e677"
}

.gf-icon-star:before {
    content: "\e678"
}

.gf-icon-star-outline:before {
    content: "\e679"
}

.gf-icon-circle-check:before {
    content: "\e67a"
}

.gf-icon-delete-outline:before {
    content: "\e67b"
}

.gf-icon-delete:before {
    content: "\e67c"
}

.gf-icon-takeaway:before {
    content: "\e67d"
}

.gf-icon-tickets:before {
    content: "\e67e"
}

.gf-icon-arrow-up:before {
    content: "\e67f"
}

.gf-icon-top-right:before {
    content: "\e680"
}

.gf-icon-unfold:before {
    content: "\e681"
}

.gf-icon-user:before {
    content: "\e682"
}

.gf-icon-video:before {
    content: "\e683"
}

.gf-icon-view:before {
    content: "\e684"
}

.gf-icon-warning:before {
    content: "\e685"
}

.gf-icon-zoom-in:before {
    content: "\e686"
}

.gf-icon-zoom-out:before {
    content: "\e687"
}

.gf-icon-phone:before {
    content: "\e688"
}

.gf-icon-info:before {
    content: "\e689"
}

.gf-icon-rank:before {
    content: "\e68a"
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    margin: 0;
    scrollbar-track-color: #aaa;
    scrollbar-3dlight-color: #aaa;
    scrollbar-darkshadow-color: #aaa;
    scrollbar-arrow-color: #aaa
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 500;
    padding-right: 4px
}

a {
    text-decoration: none;
    color: var(--color-primary-color)
}

img {
    font-size: 1px !important
}

input {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

p {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0
}

.square,
.square * {
    border-radius: 0 !important
}

.inline {
    display: inline-block !important
}

.block {
    display: block !important
}

.no-padding {
    padding: 0 !important
}

.no-margin {
    margin: 0 !important
}

.no-overflow {
    overflow: hidden !important
}

.right-float {
    float: right
}

.left-float {
    float: left
}

.right-align {
    text-align: right;
    padding-right: 8px !important
}

.left-align {
    text-align: left;
    padding-left: 8px !important
}

.left-align-i {
    text-align: left !important
}

.middle-align {
    text-align: center
}

.cursor-pointer {
    cursor: pointer !important
}

.cursor-text {
    cursor: text !important
}

.cursor-grab {
    cursor: grab !important
}

.cursor-move {
    cursor: move !important
}

.clear-both {
    clear: both
}

.no-left-border,
.no-left-border>* {
    border-left: none !important
}

.no-right-border,
.no-right-border>* {
    border-right: none !important
}

.no-top-border,
.no-top-border>* {
    border-top: none !important
}

.no-bottom-border,
.no-bottom-border>* {
    border-bottom: none !important
}

.blur {
    filter: blur(3px);
    pointer-events: none;
    -webkit-touch-callout: none;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none
}

.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap
}

.strike>span {
    position: relative;
    display: inline-block
}

.strike>span:after,
.strike>span:before {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #dcdfe6
}

.strike>span:before {
    right: 100%;
    margin-right: 15px
}

.strike>span:after {
    left: 100%;
    margin-left: 15px
}

.full-width {
    width: 100% !important
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}

.components-container {
    height: calc(100vh - 76px);
    width: 100%
}

.container-header {
    box-sizing: border-box;
    padding: 4px !important;
    font-size: 12px;
    line-height: 20px;
    height: 28px !important;
    color: var(--color-9);
    background: var(--color-box-bg);
    border: 1px solid var(--color-border)
}

.normal-table,
.normal-table-mobile {
    font-size: 12px;
    line-height: 20px;
    overflow: scroll;
    border-collapse: collapse;
    width: 100%
}

.normal-table-mobile thead>tr>th:first-child,
.normal-table thead>tr>th:first-child {
    border-left: 0
}

.normal-table-mobile thead>tr>th:last-child,
.normal-table thead>tr>th:last-child {
    border-right: 0
}

.normal-table-mobile thead>tr>th,
.normal-table thead>tr>th {
    text-align: center;
    background: var(--color-box-bg);
    border: none;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: .2px;
    color: var(--color-neutral-90);
    padding: 4px
}

.normal-table-mobile tbody>tr>td,
.normal-table tbody>tr>td {
    word-break: keep-all;
    white-space: nowrap;
    color: var(--color-9);
    border-bottom: 1px solid var(--color-2);
    padding: 4px
}

.normal-table-mobile tbody>tr:hover>td,
.normal-table tbody>tr:hover>td {
    background: var(--color-primary-surface)
}

.normal-table-border tbody>tr>td,
.normal-table-border thead>tr>th {
    border: 1px solid #ddd
}

#hot-table {
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: 12px
}

#hot-table>.ht_master>.wtHolder>.wtHider>.wtSpreader>.htCore>tbody tr:first-child>td,
#hot-table>.ht_master>.wtHolder>.wtHider>.wtSpreader>.htCore>tbody tr:nth-child(2)>td {
    background: #757575;
    color: #fff
}

#hot-table>.ht_master>.wtHolder>.wtHider>.wtSpreader>.htCore>tbody tr:nth-child(2n+3)>td {
    background: #cef
}

.insider-page {
    width: 1300px;
    max-width: 100%;
    margin: 0 auto
}

.responsive-section {
    width: 1300px;
    max-width: 98%;
    margin: 4px auto
}

.responsive-section-wider {
    width: 1600px !important
}

expression-error {
    background-color: rgba(245, 107, 107, .1);
    border: 1px solid rgba(245, 107, 107, .2);
    color: #f56c6c;
    border-radius: 4px
}

expression-error,
expression-field {
    padding: 0 5px;
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 3px;
    font-size: 12px;
    line-height: 25px;
    height: 25px
}

expression-field {
    background-color: var(--color-success-surface);
    border: 1px solid var(--color-success-border);
    color: var(--color-success-color);
    border-radius: 4px;
    border-radius: var(--size-radius-md)
}

expression-sign {
    background-color: rgba(144, 146, 152, .1);
    border: 1px solid rgba(144, 146, 152, .2);
    color: #909399;
    padding: 0 5px;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 3px;
    font-size: 12px;
    line-height: 25px;
    height: 25px
}

.thin-expression expression-error,
.thin-expression expression-field,
.thin-expression expression-sign {
    font-size: 11px;
    height: 18px;
    line-height: 18px;
    margin: 0
}

.mini-expression expression-error,
.mini-expression expression-field,
.mini-expression expression-sign {
    font-size: 11px;
    height: 16px;
    line-height: 16px;
    margin-bottom: 1px
}

.summary-tooltip {
    position: relative;
    display: inline-block
}

.summary-tooltip .summary-tooltiptext {
    visibility: hidden;
    min-width: 300px;
    background: #f6f6f6;
    border: 1px solid #000;
    color: #303133;
    text-align: center;
    border-radius: 6px;
    padding: 4px;
    position: absolute;
    z-index: 16777271;
    bottom: 105%;
    margin-left: -160px;
    height: auto;
    font-size: 12px
}

.summary-tooltip:hover .summary-tooltiptext {
    visibility: visible
}

@media print {
    .hide-on-print {
        display: none !important
    }

    .auto-height-on-print {
        height: auto !important
    }

    .full-width-on-print {
        width: calc(100vw - 50px) !important
    }

    .half-width-on-print {
        width: calc(50vw - 50px) !important
    }

    .components-container {
        height: auto !important;
        width: 100%
    }

    #chart canvas,
    #chart div {
        width: 100% !important
    }

    #chart canvas {
        height: 100% !important
    }

    .normal-table,
    .normal-table-mobile {
        table-layout: fixed !important;
        border-collapse: separate !important;
        border-spacing: 1px !important
    }

    .normal-table-mobile tbody,
    .normal-table-mobile th,
    .normal-table-mobile thead,
    .normal-table-mobile tr,
    .normal-table tbody,
    .normal-table th,
    .normal-table thead,
    .normal-table tr {
        display: block
    }

    .normal-table-mobile thead tr,
    .normal-table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px
    }

    .normal-table-mobile tr,
    .normal-table tr {
        border: 1px solid #ccc
    }

    .normal-table-mobile td,
    .normal-table td {
        display: inline-block;
        vertical-align: top;
        border: none !important;
        border-left: 1px solid #eee !important;
        position: relative;
        padding-left: 24% !important;
        width: 25% !important;
        min-height: 20px
    }

    .normal-table-mobile td.top-10-td,
    .normal-table td.top-10-td {
        border-top: 1px solid #eee !important;
        width: 75% !important
    }

    .normal-table-mobile td:before,
    .normal-table td:before {
        position: absolute;
        left: 3px;
        width: 25%;
        white-space: nowrap;
        text-align: left !important;
        content: attr(data-column);
        color: #000;
        font-weight: 700
    }

    .normal-table-mobile tbody,
    .normal-table-mobile th,
    .normal-table-mobile thead,
    .normal-table-mobile tr {
        display: inline;
        width: 100%
    }
}

@media only screen and (max-width: 800px) {
    .hide-on-800-and-down {
        display: none
    }
}

::-webkit-scrollbar {
    width: 6px;
    height: 8px
}

::-webkit-scrollbar-track {
    width: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
    background: hsla(0, 0%, 80%, .2)
}

::-webkit-scrollbar-thumb {
    box-shadow: none;
    -webkit-box-shadow: none;
    background: hsla(0, 0%, 80%, .8)
}

.img-hidden-on-error[lazy=error] {
    display: none
}

.img-avatar-on-error[lazy=error] {
    content: url(https://avatar.gurufocus.com/no_avatar.png)
}

.tooltip {
    position: relative
}

.tooltip .tooltiptext {
    visibility: hidden;
    opacity: 0;
    width: 120px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
    transition: visibility .3s linear, opacity .3s linear
}

.tooltip .tooltiptext:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid
}

.tooltip .light {
    background-color: #f2f6fc;
    color: #000
}

.tooltip .light:after {
    border-color: #f2f6fc transparent transparent
}

.tooltip .dark {
    background-color: #424242;
    color: #fff
}

.tooltip .dark:after {
    border-color: #424242 transparent transparent
}

.tooltip .orange {
    background-color: #f63;
    color: #fff
}

.tooltip .orange:after {
    border-color: #f63 transparent transparent
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1
}

.description {
    font-size: 13px;
    color: var(--color-8)
}

.subscribe-card-small {
    text-align: center;
    line-height: 16px;
    font-size: 14px;
    margin: 5px 16px 5px 1px;
    border: 2px solid #ffb401;
    border-radius: 4px
}

.date-btn-unselected {
    color: var(--color-8);
    background: var(--color-bg)
}

.date-btn-selected,
.date-btn-unselected {
    border: 1px solid var(--color-border);
    border-radius: 4px !important;
    padding: 5px
}

.date-btn-selected {
    color: #fff;
    background: var(--color-primary-color)
}

.portfolio-table {
    border: none;
    table-layout: auto;
    background-color: var(--color-bg);
    width: 100%;
    border-collapse: collapse;
    padding: 2px;
    font-size: 12px;
    line-height: 16px;
    font-family: arial, helvetica, clean, sans-serif
}

.portfolio-table th {
    font-size: 11px;
    color: var(--color-neutral-90);
    padding-left: 5px;
    text-align: left;
    background-color: var(--color-box-bg)
}

.portfolio-table td {
    color: var(--color-10);
    padding: 8px 8px 8px 0
}

.portfolio-table tr {
    border-bottom: 1px solid var(--color-border)
}

.portfolio-table tr:hover {
    background: var(--color-parimary-surface)
}

.add-to-dashboard-btn-p:hover .add-to-dashboard-btn {
    visibility: visible
}

.add-to-dashboard-btn {
    border: 2px solid var(--color-primary-color);
    color: var(--color-primary-color);
    border-radius: 10px;
    font-size: 14px;
    padding: 1px;
    visibility: hidden;
    cursor: pointer
}

.widget-in-db .add-to-dashboard-btn {
    display: none !important
}

@font-face {
    font-family: gf-plus-icons;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../../../assets/1304cf408a38186d59eff99d54729903.woff2) format("woff2")
}

.gf-icon:before {
    display: inline-block !important;
    font-weight: 400
}

[class*=" gfp-"],
[class^=gfp-] {
    font-family: gf-plus-icons !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.gfp-About-Us:before {
    content: "\ea01"
}

.gfp-API:before {
    content: "\ea02"
}

.gfp-arrow-circle-right-fill:before {
    content: "\ea03"
}

.gfp-arrow-up-right:before {
    content: "\ea04"
}

.gfp-Article-1:before {
    content: "\ea05"
}

.gfp-Article:before {
    content: "\ea06"
}

.gfp-Book-Search:before {
    content: "\ea07"
}

.gfp-bookmark:before {
    content: "\ea08"
}

.gfp-button-play-fill:before {
    content: "\ea09"
}

.gfp-Calculator:before {
    content: "\ea0a"
}

.gfp-calendar:before {
    content: "\ea0b"
}

.gfp-cards:before {
    content: "\ea0c"
}

.gfp-chart-pie:before {
    content: "\ea0d"
}

.gfp-chart-scatter-plot:before {
    content: "\ea0e"
}

.gfp-check-2:before {
    content: "\ea0f"
}

.gfp-check-circle:before {
    content: "\ea10"
}

.gfp-chevron-down:before {
    content: "\ea11"
}

.gfp-chevron-left:before {
    content: "\ea12"
}

.gfp-chevron-right:before {
    content: "\ea13"
}

.gfp-chevron-up:before {
    content: "\ea14"
}

.gfp-clock:before {
    content: "\ea15"
}

.gfp-Close:before {
    content: "\ea16"
}

.gfp-Cloud:before {
    content: "\ea17"
}

.gfp-cog:before {
    content: "\ea18"
}

.gfp-copy:before {
    content: "\ea19"
}

.gfp-credit-card:before {
    content: "\ea1a"
}

.gfp-csv-fill:before {
    content: "\ea1b"
}

.gfp-cursor-click:before {
    content: "\ea1c"
}

.gfp-CustomerService:before {
    content: "\ea1d"
}

.gfp-danger-fill:before {
    content: "\ea1e"
}

.gfp-Discussion-1:before {
    content: "\ea1f"
}

.gfp-Discussion:before {
    content: "\ea20"
}

.gfp-document-search:before {
    content: "\ea21"
}

.gfp-document-text:before {
    content: "\ea22"
}

.gfp-down:before {
    content: "\ea23"
}

.gfp-download-cloud:before {
    content: "\ea24"
}

.gfp-download-fill:before {
    content: "\ea25"
}

.gfp-embed-chart:before {
    content: "\ea26"
}

.gfp-Excel:before {
    content: "\ea27"
}

.gfp-Exclamation-circle:before {
    content: "\ea28"
}

.gfp-exclamation-fill:before {
    content: "\ea29"
}

.gfp-external-link:before {
    content: "\ea2a"
}

.gfp-Eye:before {
    content: "\ea2b"
}

.gfp-facebook-dark:before {
    content: "\ea2c"
}

.gfp-facebook:before {
    content: "\ea2d"
}

.gfp-Feedback:before {
    content: "\ea2e"
}

.gfp-fluent-fill:before {
    content: "\ea2f"
}

.gfp-gf-plus-icons:before {
    content: "\ea30"
}

.gfp-Gift:before {
    content: "\ea31"
}

.gfp-globe:before {
    content: "\ea32"
}

.gfp-google:before {
    content: "\ea33"
}

.gfp-guru-portfolio-fill:before {
    content: "\ea34"
}

.gfp-IconLeft:before {
    content: "\ea35"
}

.gfp-IconRight:before {
    content: "\ea36"
}

.gfp-identification:before {
    content: "\ea37"
}

.gfp-instagram:before {
    content: "\ea38"
}

.gfp-like-fill:before {
    content: "\ea39"
}

.gfp-link-fill:before {
    content: "\ea3a"
}

.gfp-link:before {
    content: "\ea3b"
}

.gfp-linkedIn-dark:before {
    content: "\ea3c"
}

.gfp-linkedIn:before {
    content: "\ea3d"
}

.gfp-live-chat-vector:before {
    content: "\ea3e"
}

.gfp-live-chat:before {
    content: "\ea3f"
}

.gfp-location:before {
    content: "\ea40"
}

.gfp-log-out:before {
    content: "\ea41"
}

.gfp-logo:before {
    content: "\ea42"
}

.gfp-mail-fill:before {
    content: "\ea43"
}

.gfp-manual-fill:before {
    content: "\ea44"
}

.gfp-maximize:before {
    content: "\ea45"
}

.gfp-menu:before {
    content: "\ea46"
}

.gfp-moon-fill:before {
    content: "\ea47"
}

.gfp-more-horizontal:before {
    content: "\ea48"
}

.gfp-more-vertical:before {
    content: "\ea49"
}

.gfp-newspaper:before {
    content: "\ea4a"
}

.gfp-notifications-fill:before {
    content: "\ea4b"
}

.gfp-pause-fill:before {
    content: "\ea4c"
}

.gfp-pdf-fill:before {
    content: "\ea4d"
}

.gfp-pencil:before {
    content: "\ea4e"
}

.gfp-photograph:before {
    content: "\ea4f"
}

.gfp-play:before {
    content: "\ea50"
}

.gfp-plus:before {
    content: "\ea51"
}

.gfp-Podcast:before {
    content: "\ea52"
}

.gfp-premium-plus:before {
    content: "\ea53"
}

.gfp-premium:before {
    content: "\ea54"
}

.gfp-presentation-chart-line:before {
    content: "\ea55"
}

.gfp-Preview-dark:before {
    content: "\ea56"
}

.gfp-Preview:before {
    content: "\ea57"
}

.gfp-printer:before {
    content: "\ea58"
}

.gfp-professional:before {
    content: "\ea59"
}

.gfp-Questions:before {
    content: "\ea5a"
}

.gfp-scale:before {
    content: "\ea5b"
}

.gfp-screener:before {
    content: "\ea5c"
}

.gfp-search-circle:before {
    content: "\ea5d"
}

.gfp-search:before {
    content: "\ea5e"
}

.gfp-series-area:before {
    content: "\ea5f"
}

.gfp-series-bar:before {
    content: "\ea60"
}

.gfp-series-candlestick:before {
    content: "\ea61"
}

.gfp-series-line:before {
    content: "\ea62"
}

.gfp-series-ohlc:before {
    content: "\ea63"
}

.gfp-Share:before {
    content: "\ea64"
}

.gfp-Summary:before {
    content: "\ea65"
}

.gfp-switch-horizontal:before {
    content: "\ea66"
}

.gfp-table-2:before {
    content: "\ea67"
}

.gfp-table:before {
    content: "\ea68"
}

.gfp-text-document-fill:before {
    content: "\ea69"
}

.gfp-Thumb-up:before {
    content: "\ea6a"
}

.gfp-ticket:before {
    content: "\ea6b"
}

.gfp-Toggle:before {
    content: "\ea6c"
}

.gfp-trash:before {
    content: "\ea6d"
}

.gfp-twitter-dark:before {
    content: "\ea6e"
}

.gfp-twitter:before {
    content: "\ea6f"
}

.gfp-uncheck:before {
    content: "\ea70"
}

.gfp-up:before {
    content: "\ea71"
}

.gfp-user-group-fill:before {
    content: "\ea72"
}

.gfp-user:before {
    content: "\ea73"
}

.gfp-video-camera:before {
    content: "\ea74"
}

.gfp-VIP:before {
    content: "\ea75"
}

.gfp-volume-max:before {
    content: "\ea76"
}

.gfp-Whatsapp:before {
    content: "\ea77"
}

.gfp-x:before {
    content: "\ea78"
}

.gfp-youtube-dark:before {
    content: "\ea79"
}

.gfp-YouTube:before {
    content: "\ea7a"
}

.el-alert {
    -webkit-box-sizing: border-box;
    width: 100%;
    padding: 8px 16px;
    margin: 0;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    opacity: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: opacity .2s;
    transition: opacity .2s
}

.el-alert.is-light .el-alert__closebtn {
    color: #c0c4cc
}

.el-alert.is-dark .el-alert__closebtn,
.el-alert.is-dark .el-alert__description {
    color: #fff
}

.el-alert.is-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.el-alert--success.is-light {
    background-color: #f0f9eb;
    color: #67c23a
}

.el-alert--success.is-light .el-alert__description {
    color: #67c23a
}

.el-alert--success.is-dark {
    background-color: #67c23a;
    color: #fff
}

.el-alert--info.is-light {
    background-color: var(--color-box-bg);
    color: var(--color-7)
}

.el-alert--info.is-dark {
    background-color: #909399;
    color: #fff
}

.el-alert--info .el-alert__description {
    color: var(--color-7)
}

.el-alert--warning.is-light {
    background-color: var(--color-warning-surface);
    color: var(--color-warning-color)
}

.el-alert--warning.is-light .el-alert__description {
    color: #e6a23c
}

.el-alert--warning.is-dark {
    background-color: #e6a23c;
    color: #fff
}

.el-alert--error.is-light {
    background-color: var(--color-danger-surface);
    color: var(--color-danger-text)
}

.el-alert--error.is-light .el-alert__description {
    color: #f56c6c
}

.el-alert--error.is-dark {
    background-color: #f56c6c;
    color: #fff
}

.el-alert__content {
    display: table-cell;
    padding: 0 8px
}

.el-alert__icon {
    font-size: 16px;
    width: 16px
}

.el-alert__icon.is-big {
    font-size: 28px;
    width: 28px
}

.el-alert__title {
    font-size: 13px;
    line-height: 18px
}

.el-alert__title.is-bold {
    font-weight: 700
}

.el-alert .el-alert__description {
    font-size: 12px;
    margin: 5px 0 0
}

.el-alert__closebtn {
    font-size: 12px;
    opacity: 1;
    position: absolute;
    top: 12px;
    right: 15px;
    cursor: pointer
}

.el-alert-fade-enter,
.el-alert-fade-leave-active,
.el-loading-fade-enter,
.el-loading-fade-leave-active,
.el-notification-fade-leave-active {
    opacity: 0
}

.el-alert__closebtn.is-customed {
    font-style: normal;
    font-size: 13px;
    top: 9px
}

.v-modal-enter {
    -webkit-animation: v-modal-in .2s ease;
    animation: v-modal-in .2s ease
}

.v-modal-leave {
    -webkit-animation: v-modal-out .2s ease forwards;
    animation: v-modal-out .2s ease forwards
}

.v-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: #000
}

.slideInLeft-transition,
.slideInRight-transition {
    display: inline-block
}

.slideInRight-enter {
    -webkit-animation: slideInRight-enter .3s;
    animation: slideInRight-enter .3s
}

.slideInRight-leave {
    position: absolute;
    left: 0;
    right: 0;
    -webkit-animation: slideInRight-leave .3s;
    animation: slideInRight-leave .3s
}

.slideInLeft-enter {
    -webkit-animation: slideInLeft-enter .3s;
    animation: slideInLeft-enter .3s
}

.slideInLeft-leave {
    position: absolute;
    left: 0;
    right: 0;
    -webkit-animation: slideInLeft-leave .3s;
    animation: slideInLeft-leave .3s
}

.el-scrollbar {
    overflow: hidden;
    position: relative
}

.el-scrollbar:active>.el-scrollbar__bar,
.el-scrollbar:focus>.el-scrollbar__bar,
.el-scrollbar:hover>.el-scrollbar__bar {
    opacity: 1;
    -webkit-transition: opacity .34s ease-out;
    transition: opacity .34s ease-out
}

.el-scrollbar__wrap {
    overflow: scroll;
    height: 100%
}

.el-scrollbar__wrap--hidden-default::-webkit-scrollbar {
    width: 0;
    height: 0
}

.el-scrollbar__thumb {
    position: relative;
    display: block;
    width: 0;
    height: 0;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(144, 147, 153, .3);
    -webkit-transition: background-color .3s;
    transition: background-color .3s
}

.el-scrollbar__thumb:hover {
    background-color: rgba(144, 147, 153, .5)
}

.el-scrollbar__bar {
    position: absolute;
    right: 2px;
    bottom: 2px;
    z-index: 1;
    border-radius: 4px;
    opacity: 0;
    -webkit-transition: opacity .12s ease-out;
    transition: opacity .12s ease-out
}

.el-scrollbar__bar.is-vertical {
    width: 6px;
    top: 2px
}

.el-scrollbar__bar.is-vertical>div {
    width: 100%
}

.el-scrollbar__bar.is-horizontal {
    height: 6px;
    left: 2px
}

.el-scrollbar__bar.is-horizontal>div {
    height: 100%
}

.el-fade-in-enter,
.el-fade-in-leave-active,
.el-fade-in-linear-enter,
.el-fade-in-linear-leave,
.el-fade-in-linear-leave-active,
.fade-in-linear-enter,
.fade-in-linear-leave,
.fade-in-linear-leave-active {
    opacity: 0
}

.el-fade-in-linear-enter-active,
.el-fade-in-linear-leave-active,
.fade-in-linear-enter-active,
.fade-in-linear-leave-active {
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear
}

.el-fade-in-enter-active,
.el-fade-in-leave-active,
.el-zoom-in-center-enter-active,
.el-zoom-in-center-leave-active {
    -webkit-transition: all .3s cubic-bezier(.55, 0, .1, 1);
    transition: all .3s cubic-bezier(.55, 0, .1, 1)
}

.el-zoom-in-center-enter,
.el-zoom-in-center-leave-active {
    opacity: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0)
}

.el-zoom-in-top-enter-active,
.el-zoom-in-top-leave-active {
    opacity: 1;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition: opacity .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    transition: opacity .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1);
    transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    -webkit-transform-origin: center top;
    transform-origin: center top
}

.el-zoom-in-top-enter,
.el-zoom-in-top-leave-active {
    opacity: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0)
}

.el-zoom-in-bottom-enter-active,
.el-zoom-in-bottom-leave-active {
    opacity: 1;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition: opacity .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    transition: opacity .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1);
    transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom
}

.el-zoom-in-bottom-enter,
.el-zoom-in-bottom-leave-active {
    opacity: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0)
}

.el-zoom-in-left-enter-active,
.el-zoom-in-left-leave-active {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    transition: opacity .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1);
    transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    -webkit-transform-origin: top left;
    transform-origin: top left
}

.el-zoom-in-left-enter,
.el-zoom-in-left-leave-active {
    opacity: 0;
    -webkit-transform: scale(.45);
    transform: scale(.45)
}

.collapse-transition {
    -webkit-transition: height .3s ease-in-out, padding-top .3s ease-in-out, padding-bottom .3s ease-in-out;
    transition: height .3s ease-in-out, padding-top .3s ease-in-out, padding-bottom .3s ease-in-out
}

.horizontal-collapse-transition {
    -webkit-transition: width .3s ease-in-out, padding-left .3s ease-in-out, padding-right .3s ease-in-out;
    transition: width .3s ease-in-out, padding-left .3s ease-in-out, padding-right .3s ease-in-out
}

.el-list-enter-active,
.el-list-leave-active {
    -webkit-transition: all 1s;
    transition: all 1s
}

.el-list-enter,
.el-list-leave-active {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px)
}

.el-opacity-transition {
    -webkit-transition: opacity .3s cubic-bezier(.55, 0, .1, 1);
    transition: opacity .3s cubic-bezier(.55, 0, .1, 1)
}

.el-loading-parent--relative {
    position: relative !important
}

.el-loading-parent--hidden {
    overflow: hidden !important
}

.el-loading-mask {
    position: absolute;
    z-index: 2000;
    background-color: hsla(0, 0%, 100%, .9);
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.el-loading-mask.is-fullscreen {
    position: fixed
}

.el-loading-mask.is-fullscreen .el-loading-spinner {
    margin-top: -25px
}

.el-loading-mask.is-fullscreen .el-loading-spinner .circular {
    height: 50px;
    width: 50px
}

.el-loading-spinner {
    top: 50%;
    margin-top: -21px;
    width: 100%;
    text-align: center;
    position: absolute
}

.el-loading-spinner .el-loading-text {
    color: #409eff;
    margin: 3px 0;
    font-size: 14px
}

.el-loading-spinner .circular {
    height: 42px;
    width: 42px;
    -webkit-animation: loading-rotate 2s linear infinite;
    animation: loading-rotate 2s linear infinite
}

.el-loading-spinner .path {
    -webkit-animation: loading-dash 1.5s ease-in-out infinite;
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke: #409eff;
    stroke-linecap: round
}

.el-loading-spinner i {
    color: #409eff
}

.el-time-spinner {
    width: 100%;
    white-space: nowrap
}

.el-time-spinner.has-seconds .el-time-spinner__wrapper {
    width: 33.3%
}

.el-time-spinner__wrapper {
    max-height: 190px;
    overflow: auto;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    position: relative
}

.el-time-spinner__wrapper .el-scrollbar__wrap:not(.el-scrollbar__wrap--hidden-default) {
    padding-bottom: 15px
}

.el-time-spinner__input.el-input .el-input__inner,
.el-time-spinner__list {
    padding: 0;
    text-align: center
}

.el-time-spinner__wrapper.is-arrow {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    overflow: hidden
}

.el-time-spinner__wrapper.is-arrow .el-time-spinner__list {
    -webkit-transform: translateY(-32px);
    transform: translateY(-32px)
}

.el-time-spinner__wrapper.is-arrow .el-time-spinner__item:hover:not(.disabled):not(.active) {
    background: #fff;
    cursor: default
}

.el-time-spinner__arrow {
    font-size: 12px;
    color: #909399;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    cursor: pointer
}

.el-time-spinner__arrow:hover {
    color: #409eff
}

.el-time-spinner__arrow.el-icon-arrow-up {
    top: 10px
}

.el-time-spinner__arrow.el-icon-arrow-down {
    bottom: 10px
}

.el-time-spinner__input.el-input {
    width: 70%
}

.el-time-spinner__list {
    margin: 0;
    list-style: none
}

.el-time-spinner__list:after,
.el-time-spinner__list:before {
    content: "";
    display: block;
    width: 100%;
    height: 80px
}

.el-time-spinner__item {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    color: #606266
}

.el-time-spinner__item:hover:not(.disabled):not(.active) {
    background: #f5f7fa;
    cursor: pointer
}

.el-time-spinner__item.active:not(.disabled) {
    color: #303133;
    font-weight: 700
}

.el-time-spinner__item.disabled {
    color: #c0c4cc;
    cursor: not-allowed
}

.el-spinner-inner {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    width: 50px;
    height: 50px
}

.el-spinner-inner .path {
    stroke: #ececec;
    stroke-linecap: round;
    -webkit-animation: dash 1.5s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite
}

.el-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    box-sizing: border-box;
    min-width: 0
}

.el-aside,
.el-container,
.el-header {
    -webkit-box-sizing: border-box
}

.el-container.is-vertical {
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column
}

.el-aside,
.el-header {
    box-sizing: border-box;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.el-aside {
    overflow: auto
}

.el-footer,
.el-main {
    -webkit-box-sizing: border-box
}

.el-main {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0
}

.el-footer,
.el-main {
    box-sizing: border-box
}

.el-footer {
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.el-container,
.el-container.is-vertical {
    -webkit-box-direction: normal
}

.el-slider:after,
.el-slider:before {
    content: "";
    display: table
}

.el-slider:after {
    clear: both
}

.el-slider__button {
    -webkit-user-select: none;
    -moz-user-select: none
}

.el-slider__button:after,
.el-upload-cover:after {
    content: ""
}

.el-slider__button-wrapper {
    height: 10px;
    width: 10px;
    top: -9px;
    z-index: 1001;
    transform: translateX(-50%);
    background-color: transparent;
    text-align: center;
    -ms-user-select: none;
    user-select: none;
    line-height: normal;
    -webkit-transform: translateX(-50%);
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none
}

.el-slider__button-wrapper:after {
    height: 100%
}

.el-slider__button-wrapper.hover,
.el-slider__button-wrapper:hover {
    cursor: -webkit-grab;
    cursor: grab
}

.el-slider__button-wrapper.dragging {
    cursor: -webkit-grabbing;
    cursor: grabbing
}

.el-slider__button-wrapper .el-tooltip,
.el-slider__button-wrapper:after {
    vertical-align: middle;
    display: inline-block
}

.el-slider__runway {
    width: 100%;
    height: 3px;
    margin: 5px 0;
    background-color: #e4e7ed;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    vertical-align: middle
}

.el-slider__runway.show-input {
    margin-right: 160px;
    width: auto
}

.el-slider__runway.disabled {
    cursor: default
}

.el-slider__runway.disabled .el-slider__bar {
    background-color: #c0c4cc
}

.el-slider__runway.disabled .el-slider__button {
    border-color: #c0c4cc
}

.el-slider__runway.disabled .el-slider__button-wrapper.dragging,
.el-slider__runway.disabled .el-slider__button-wrapper.hover,
.el-slider__runway.disabled .el-slider__button-wrapper:hover {
    cursor: not-allowed
}

.el-slider__runway.disabled .el-slider__button.dragging,
.el-slider__runway.disabled .el-slider__button.hover,
.el-slider__runway.disabled .el-slider__button:hover {
    -webkit-transform: scale(1);
    transform: scale(1);
    cursor: not-allowed
}

.el-slider__input {
    float: right;
    margin-top: 3px;
    width: 130px
}

.el-slider__input.el-input-number--mini {
    margin-top: 5px
}

.el-slider__input.el-input-number--medium {
    margin-top: 0
}

.el-slider__input.el-input-number--large {
    margin-top: -2px
}

.el-slider__bar {
    height: 3px;
    background-color: #409eff;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    position: absolute
}

.el-slider__button {
    width: 6px;
    height: 6px;
    border: 2px solid #409eff;
    background-color: #fff;
    border-radius: 50%;
    -webkit-transition: .2s;
    transition: .2s;
    -ms-user-select: none;
    user-select: none
}

.el-slider__button.dragging,
.el-slider__button.hover,
.el-slider__button:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
}

.el-slider__button.hover,
.el-slider__button:hover {
    cursor: -webkit-grab;
    cursor: grab
}

.el-slider__button.dragging {
    cursor: -webkit-grabbing;
    cursor: grabbing
}

.el-slider__stop {
    -webkit-transform: translateX(-50%);
    position: absolute;
    height: 3px;
    width: 3px;
    border-radius: 100%;
    background-color: #fff;
    transform: translateX(-50%)
}

.el-slider__marks {
    top: 0;
    left: 12px;
    width: 18px;
    height: 100%
}

.el-slider__marks-text {
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 14px;
    color: #909399;
    margin-top: 15px
}

.el-slider.is-vertical {
    position: relative
}

.el-slider.is-vertical .el-slider__runway {
    width: 6px;
    height: 100%;
    margin: 0 16px
}

.el-slider.is-vertical .el-slider__bar {
    width: 6px;
    height: auto;
    border-radius: 0 0 3px 3px
}

.el-slider.is-vertical .el-slider__button-wrapper {
    top: auto;
    left: -15px
}

.el-slider.is-vertical .el-slider__button-wrapper,
.el-slider.is-vertical .el-slider__stop {
    -webkit-transform: translateY(50%);
    transform: translateY(50%)
}

.el-slider.is-vertical.el-slider--with-input {
    padding-bottom: 58px
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input {
    overflow: visible;
    float: none;
    position: absolute;
    bottom: 22px;
    width: 36px;
    margin-top: 15px
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input .el-input__inner {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input .el-input-number__decrease,
.el-slider.is-vertical.el-slider--with-input .el-slider__input .el-input-number__increase {
    top: 32px;
    margin-top: -1px;
    border: 1px solid #dcdfe6;
    line-height: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1)
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input .el-input-number__decrease {
    width: 18px;
    right: 18px;
    border-bottom-left-radius: 4px
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input .el-input-number__increase {
    width: 19px;
    border-bottom-right-radius: 4px
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input .el-input-number__increase~.el-input .el-input__inner {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input:hover .el-input-number__decrease,
.el-slider.is-vertical.el-slider--with-input .el-slider__input:hover .el-input-number__increase {
    border-color: #c0c4cc
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input:active .el-input-number__decrease,
.el-slider.is-vertical.el-slider--with-input .el-slider__input:active .el-input-number__increase {
    border-color: #409eff
}

.el-slider.is-vertical .el-slider__marks-text {
    margin-top: 0;
    left: 15px;
    -webkit-transform: translateY(50%);
    transform: translateY(50%)
}

.el-color-hue-slider {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 280px;
    height: 12px;
    background-color: red;
    padding: 0 2px
}

.el-color-hue-slider__bar {
    position: relative;
    background: -webkit-gradient(linear, left top, right top, from(red), color-stop(17%, #ff0), color-stop(33%, #0f0), color-stop(50%, #0ff), color-stop(67%, #00f), color-stop(83%, #f0f), to(red));
    background: linear-gradient(90deg, red 0, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red);
    height: 100%
}

.el-color-hue-slider__thumb {
    position: absolute;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    border-radius: 1px;
    background: #fff;
    border: 1px solid #f0f0f0;
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, .6);
    box-shadow: 0 0 2px rgba(0, 0, 0, .6);
    z-index: 1
}

.el-color-hue-slider.is-vertical {
    width: 12px;
    height: 180px;
    padding: 2px 0
}

.el-color-hue-slider.is-vertical .el-color-hue-slider__bar {
    background: -webkit-gradient(linear, left top, left bottom, from(red), color-stop(17%, #ff0), color-stop(33%, #0f0), color-stop(50%, #0ff), color-stop(67%, #00f), color-stop(83%, #f0f), to(red));
    background: linear-gradient(180deg, red 0, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red)
}

.el-color-hue-slider.is-vertical .el-color-hue-slider__thumb {
    left: 0;
    top: 0;
    width: 100%;
    height: 4px
}

.el-color-alpha-slider {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 280px;
    height: 12px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==)
}

.el-color-alpha-slider__bar {
    position: relative;
    background: -webkit-gradient(linear, left top, right top, from(hsla(0, 0%, 100%, 0)), to(#fff));
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 0, #fff);
    height: 100%
}

.el-color-alpha-slider__thumb {
    position: absolute;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    border-radius: 1px;
    background: #fff;
    border: 1px solid #f0f0f0;
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, .6);
    box-shadow: 0 0 2px rgba(0, 0, 0, .6);
    z-index: 1
}

.el-color-alpha-slider.is-vertical {
    width: 20px;
    height: 180px
}

.el-color-alpha-slider.is-vertical .el-color-alpha-slider__bar {
    background: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), to(#fff));
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff)
}

.el-color-alpha-slider.is-vertical .el-color-alpha-slider__thumb {
    left: 0;
    top: 0;
    width: 100%;
    height: 4px
}

.el-badge {
    position: relative;
    vertical-align: middle;
    display: inline-block
}

.el-badge__content {
    background-color: #f56c6c;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #fff
}

.el-badge__content.is-fixed {
    position: absolute;
    top: 5px;
    right: 15px;
    -webkit-transform: translateY(-50%) translateX(100%);
    transform: translateY(-50%) translateX(100%)
}

.el-badge__content.is-fixed.is-dot {
    right: 5px
}

.el-badge__content.is-dot {
    height: 8px;
    width: 8px;
    padding: 0;
    right: 0;
    border-radius: 50%
}

.el-badge__content--primary {
    background-color: #409eff
}

.el-badge__content--success {
    background-color: #67c23a
}

.el-badge__content--warning {
    background-color: #e6a23c
}

.el-badge__content--info {
    background-color: #909399
}

.el-badge__content--danger {
    background-color: #f56c6c
}

.el-dialog {
    background: var(--color-bg);
    position: relative;
    margin: 0 auto 50px;
    border-radius: 2px;
    -webkit-box-sizing: border-box;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    box-sizing: border-box;
    width: 50%
}

.el-dialog.is-fullscreen {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    overflow: auto;
    border-radius: 0 !important
}

.el-dialog__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0
}

.el-dialog__header {
    padding: 8px 10px
}

.el-dialog__headerbtn {
    position: absolute;
    top: 4px;
    right: 10px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px
}

.el-dialog__headerbtn .el-dialog__close {
    color: #909399
}

.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
    color: #409eff
}

.el-dialog__title {
    line-height: 24px;
    font-size: 18px;
    color: var(--color-9)
}

.el-dialog__body {
    padding: 4px 10px;
    color: var(--color-9);
    font-size: 14px;
    word-break: break-all
}

.el-dialog__footer {
    padding: 10px 20px 20px;
    text-align: right;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-dialog--center {
    text-align: center
}

.el-dialog--center .el-dialog__body {
    text-align: initial;
    padding: 25px 25px 30px
}

.el-dialog--center .el-dialog__footer {
    text-align: inherit
}

.dialog-fade-enter-active {
    -webkit-animation: dialog-fade-in .3s;
    animation: dialog-fade-in .3s
}

.dialog-fade-leave-active {
    -webkit-animation: dialog-fade-out .3s;
    animation: dialog-fade-out .3s
}

.el-message {
    border-radius: 4px;
    overflow: hidden;
    min-width: 380px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #ebeef5;
    position: fixed;
    left: 50%;
    top: 20px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #edf2fc;
    -webkit-transition: opacity .3s, -webkit-transform .4s;
    transition: opacity .3s, -webkit-transform .4s;
    transition: opacity .3s, transform .4s;
    transition: opacity .3s, transform .4s, -webkit-transform .4s;
    padding: 15px 15px 15px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.el-message.is-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.el-message.is-closable .el-message__content {
    padding-right: 16px
}

.el-message p {
    margin: 0
}

.el-message--info .el-message__content {
    color: #909399
}

.el-message--success {
    background-color: #f0f9eb;
    border-color: #e1f3d8
}

.el-message--success .el-message__content {
    color: #67c23a
}

.el-message--warning {
    background-color: #fdf6ec;
    border-color: #faecd8
}

.el-message--warning .el-message__content {
    color: #e6a23c
}

.el-message--error {
    background-color: #fef0f0;
    border-color: #fde2e2
}

.el-message--error .el-message__content {
    color: #f56c6c
}

.el-message__icon {
    margin-right: 10px
}

.el-message__content {
    padding: 0;
    font-size: 14px;
    line-height: 1
}

.el-message__closeBtn {
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    color: #c0c4cc;
    font-size: 16px
}

.el-message__closeBtn:hover {
    color: #909399
}

.el-message .el-icon-success {
    color: #67c23a
}

.el-message .el-icon-error {
    color: #f56c6c
}

.el-message .el-icon-info {
    color: #909399
}

.el-message .el-icon-warning {
    color: #e6a23c
}

.el-message-fade-enter,
.el-message-fade-leave-active {
    opacity: 0;
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%)
}

.el-message__closeBtn:focus,
.el-message__content:focus {
    outline-width: 0
}

.el-message-box {
    display: inline-block;
    width: 420px;
    padding-bottom: 10px;
    vertical-align: middle;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ebeef5;
    font-size: 18px;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    text-align: left;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.el-message-box__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center
}

.el-message-box__wrapper:after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 0;
    vertical-align: middle
}

.el-message-box__header {
    position: relative;
    padding: 15px 15px 10px
}

.el-message-box__title {
    padding-left: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1;
    color: #303133
}

.el-message-box__headerbtn {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 0;
    border: none;
    outline: 0;
    background: 0 0;
    font-size: 16px;
    cursor: pointer
}

.el-message-box__input input.invalid,
.el-message-box__input input.invalid:focus {
    border-color: #f56c6c
}

.el-message-box__headerbtn .el-message-box__close {
    color: #909399
}

.el-message-box__headerbtn:focus .el-message-box__close,
.el-message-box__headerbtn:hover .el-message-box__close {
    color: #409eff
}

.el-message-box__content {
    position: relative;
    padding: 10px 15px;
    color: #606266;
    font-size: 14px
}

.el-message-box__input {
    padding-top: 15px
}

.el-message-box__status {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 24px !important
}

.el-message-box__status:before {
    padding-left: 1px
}

.el-message-box__status+.el-message-box__message {
    padding-left: 36px;
    padding-right: 12px
}

.el-message-box__status.el-icon-success {
    color: #67c23a
}

.el-message-box__status.el-icon-info {
    color: #909399
}

.el-message-box__status.el-icon-warning {
    color: #e6a23c
}

.el-message-box__status.el-icon-error {
    color: #f56c6c
}

.el-message-box__message {
    margin: 0
}

.el-message-box__message p {
    margin: 0;
    line-height: 24px
}

.el-message-box__errormsg {
    color: #f56c6c;
    font-size: 12px;
    min-height: 18px;
    margin-top: 2px
}

.el-message-box__btns {
    padding: 5px 15px 0;
    text-align: right
}

.el-message-box__btns button:nth-child(2) {
    margin-left: 10px
}

.el-message-box__btns-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.el-message-box--center {
    padding-bottom: 30px
}

.el-message-box--center .el-message-box__header {
    padding-top: 30px
}

.el-message-box--center .el-message-box__title {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.el-message-box--center .el-message-box__status {
    position: relative;
    top: auto;
    padding-right: 5px;
    text-align: center;
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px)
}

.el-message-box--center .el-message-box__message {
    margin-left: 0
}

.el-message-box--center .el-message-box__btns {
    text-align: center
}

.el-message-box--center .el-message-box__content {
    text-align: center;
    padding-left: 27px;
    padding-right: 27px
}

.msgbox-fade-enter-active {
    -webkit-animation: msgbox-fade-in .3s;
    animation: msgbox-fade-in .3s
}

.msgbox-fade-leave-active {
    -webkit-animation: msgbox-fade-out .3s;
    animation: msgbox-fade-out .3s
}

.el-breadcrumb {
    font-size: 14px;
    line-height: 1
}

.el-breadcrumb:after,
.el-breadcrumb:before {
    display: table;
    content: ""
}

.el-breadcrumb:after {
    clear: both
}

.el-breadcrumb__separator {
    margin: 0 9px;
    font-weight: 700;
    color: #c0c4cc
}

.el-breadcrumb__separator[class*=icon] {
    margin: 0 6px;
    font-weight: 400
}

.el-breadcrumb__item {
    float: left
}

.el-breadcrumb__inner {
    color: #606266
}

.el-breadcrumb__inner.is-link,
.el-breadcrumb__inner a {
    font-weight: 700;
    text-decoration: none;
    -webkit-transition: color .2s cubic-bezier(.645, .045, .355, 1);
    transition: color .2s cubic-bezier(.645, .045, .355, 1);
    color: #303133
}

.el-breadcrumb__inner.is-link:hover,
.el-breadcrumb__inner a:hover {
    color: #409eff;
    cursor: pointer
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover {
    font-weight: 400;
    color: #606266;
    cursor: text
}

.el-breadcrumb__item:last-child .el-breadcrumb__separator {
    display: none
}

.el-divider__text {
    font-weight: 500;
    font-size: 14px
}

.el-divider {
    background-color: var(--color-4);
    position: relative
}

.el-divider--horizontal {
    display: block;
    height: 1px;
    width: 100%;
    margin: 24px 0
}

.el-divider--vertical {
    display: inline-block;
    width: 1px;
    height: 1em;
    margin: 0 8px;
    vertical-align: middle;
    position: relative
}

.el-divider__text {
    position: absolute;
    background-color: var(--color-bg);
    padding: 0 20px;
    color: var(--color-9)
}

.el-divider__text.is-left {
    left: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.el-divider__text.is-center {
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%)
}

.el-divider__text.is-right {
    right: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.el-notification {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 330px;
    padding: 14px 26px 14px 13px;
    border-radius: 8px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #ebeef5;
    position: fixed;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    -webkit-transition: opacity .3s, left .3s, right .3s, top .4s, bottom .3s, -webkit-transform .3s;
    transition: opacity .3s, left .3s, right .3s, top .4s, bottom .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s, left .3s, right .3s, top .4s, bottom .3s;
    transition: opacity .3s, transform .3s, left .3s, right .3s, top .4s, bottom .3s, -webkit-transform .3s;
    overflow: hidden
}

.el-notification.right {
    right: 16px
}

.el-notification.left {
    left: 16px
}

.el-notification__group {
    margin-left: 13px;
    margin-right: 8px
}

.el-notification__title {
    font-weight: 700;
    font-size: 16px;
    color: #303133;
    margin: 0
}

.el-notification__content {
    font-size: 14px;
    line-height: 21px;
    margin: 6px 0 0;
    color: #606266;
    text-align: justify
}

.el-notification__content p {
    margin: 0
}

.el-notification__icon {
    height: 24px;
    width: 24px;
    font-size: 24px
}

.el-notification__closeBtn {
    position: absolute;
    top: 18px;
    right: 15px;
    cursor: pointer;
    color: #909399;
    font-size: 16px
}

.el-notification__closeBtn:hover {
    color: #606266
}

.el-notification .el-icon-success {
    color: #67c23a
}

.el-notification .el-icon-error {
    color: #f56c6c
}

.el-notification .el-icon-info {
    color: #909399
}

.el-notification .el-icon-warning {
    color: #e6a23c
}

.el-notification-fade-enter.right {
    right: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.el-notification-fade-enter.left {
    left: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.el-tabs {
    min-height: 26px;
    line-height: 26px
}

.el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0 0 15px
}

.el-tabs__active-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #409eff;
    z-index: 1;
    -webkit-transition: -webkit-transform .3s cubic-bezier(.645, .045, .355, 1);
    transition: -webkit-transform .3s cubic-bezier(.645, .045, .355, 1);
    transition: transform .3s cubic-bezier(.645, .045, .355, 1);
    transition: transform .3s cubic-bezier(.645, .045, .355, 1), -webkit-transform .3s cubic-bezier(.645, .045, .355, 1);
    list-style: none
}

.el-tabs__new-tab {
    float: right;
    border: 1px solid #d3dce6;
    height: 18px;
    width: 18px;
    line-height: 18px;
    margin: 12px 0 9px 10px;
    border-radius: 3px;
    text-align: center;
    font-size: 12px;
    color: #d3dce6;
    cursor: pointer;
    -webkit-transition: all .15s;
    transition: all .15s
}

.el-tabs__new-tab .el-icon-plus {
    -webkit-transform: scale(.8);
    transform: scale(.8)
}

.el-tabs__new-tab:hover {
    color: #409eff
}

.el-tabs__nav-wrap {
    overflow: hidden;
    margin-bottom: -1px;
    position: relative
}

.el-tabs__nav-wrap:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: var(--color-border);
    z-index: 1
}

.el-tabs--border-card>.el-tabs__header .el-tabs__nav-wrap:after,
.el-tabs--card>.el-tabs__header .el-tabs__nav-wrap:after {
    content: none
}

.el-tabs__nav-wrap.is-scrollable {
    padding: 0 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-tabs__nav-scroll {
    overflow: hidden
}

.el-tabs__nav-next,
.el-tabs__nav-prev {
    position: absolute;
    cursor: pointer;
    line-height: 44px;
    font-size: 12px;
    color: #909399
}

.el-tabs__nav-next {
    right: 0
}

.el-tabs__nav-prev {
    left: 0
}

.el-tabs__nav {
    white-space: nowrap;
    position: relative;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    float: left;
    z-index: 2
}

.el-tabs__nav.is-stretch {
    min-width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.el-tabs__nav.is-stretch>* {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center
}

.el-tabs__item {
    padding: 0 8px;
    min-height: 26px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 30px;
    display: inline-block;
    list-style: none;
    font-size: 16px;
    font-weight: 500;
    color: var(--color-neutral-90);
    position: relative
}

.el-tabs__item:focus,
.el-tabs__item:focus:active {
    outline: 0
}

.el-tabs__item:focus.is-active.is-focus:not(:active) {
    -webkit-box-shadow: 0 0 2px 2px #409eff inset;
    box-shadow: inset 0 0 2px 2px #409eff;
    border-radius: 3px
}

.el-tabs__item .el-icon-close {
    border-radius: 50%;
    text-align: center;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    margin-left: 5px
}

.el-tabs__item .el-icon-close:before {
    -webkit-transform: scale(.9);
    transform: scale(.9);
    display: inline-block
}

.el-tabs__item .el-icon-close:hover {
    background-color: #c0c4cc;
    color: #fff
}

.el-tabs__item.is-active {
    color: var(--color-primary-color)
}

.el-tabs__item:hover {
    color: #409eff;
    cursor: pointer
}

.el-tabs__item.is-disabled {
    color: #c0c4cc;
    cursor: default
}

.el-tabs__content {
    overflow: hidden;
    position: relative
}

.el-tabs--card>.el-tabs__header {
    border-bottom: 1px solid var(--color-border)
}

.el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: 1px solid var(--color-border);
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-tabs--card>.el-tabs__header .el-tabs__active-bar {
    display: none
}

.el-tabs--card>.el-tabs__header .el-tabs__item {
    border-bottom: 1px solid transparent;
    border-left: 1px solid var(--color-border);
    -webkit-transition: color .3s cubic-bezier(.645, .045, .355, 1), padding .3s cubic-bezier(.645, .045, .355, 1);
    transition: color .3s cubic-bezier(.645, .045, .355, 1), padding .3s cubic-bezier(.645, .045, .355, 1)
}

.el-tabs--card>.el-tabs__header .el-tabs__item .el-icon-close {
    position: relative;
    font-size: 12px;
    width: 0;
    height: 14px;
    vertical-align: middle;
    line-height: 15px;
    overflow: hidden;
    top: -1px;
    right: -2px;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-active.is-closable .el-icon-close,
.el-tabs--card>.el-tabs__header .el-tabs__item.is-closable:hover .el-icon-close {
    width: 14px
}

.el-tabs--card>.el-tabs__header .el-tabs__item:first-child {
    border-left: none
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-closable:hover {
    padding-left: 13px;
    padding-right: 13px
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    border-bottom-color: var(--color-bg)
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-active.is-closable {
    padding-left: 20px;
    padding-right: 20px
}

.el-tabs--border-card {
    background: var(--color-bg);
    border: 1px solid var(--color-border);
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04)
}

.el-tabs--border-card>.el-tabs__content {
    padding: 0
}

.el-tabs--border-card>.el-tabs__header {
    background-color: var(--color-box-bg);
    border-bottom: 1px solid var(--color-border);
    margin: 0
}

.el-tabs--border-card>.el-tabs__header .el-tabs__item {
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    border: 1px solid transparent;
    margin-top: -1px;
    color: var(--color-8)
}

.el-tabs--border-card>.el-tabs__header .el-tabs__item+.el-tabs__item,
.el-tabs--border-card>.el-tabs__header .el-tabs__item:first-child {
    margin-left: -1px
}

.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    color: #409eff;
    background-color: var(--color-bg);
    border-right-color: var(--color-border);
    border-left-color: var(--color-border)
}

.el-tabs--border-card>.el-tabs__header .el-tabs__item:not(.is-disabled):hover {
    color: #409eff
}

.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-disabled {
    color: #c0c4cc
}

.el-tabs--border-card>.el-tabs__header .is-scrollable .el-tabs__item:first-child {
    margin-left: 0
}

.el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
.el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
.el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 0
}

.el-tabs--bottom .el-tabs__item.is-bottom:last-child,
.el-tabs--bottom .el-tabs__item.is-top:last-child,
.el-tabs--top .el-tabs__item.is-bottom:last-child,
.el-tabs--top .el-tabs__item.is-top:last-child {
    padding-right: 0
}

.el-tabs--bottom.el-tabs--border-card .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--card .el-tabs__item:nth-child(2),
.el-tabs--bottom .el-tabs--left .el-tabs__item:nth-child(2),
.el-tabs--bottom .el-tabs--right .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--border-card .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--card .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--left .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--right .el-tabs__item:nth-child(2) {
    padding-left: 20px
}

.el-tabs--bottom.el-tabs--border-card .el-tabs__item:last-child,
.el-tabs--bottom.el-tabs--card .el-tabs__item:last-child,
.el-tabs--bottom .el-tabs--left .el-tabs__item:last-child,
.el-tabs--bottom .el-tabs--right .el-tabs__item:last-child,
.el-tabs--top.el-tabs--border-card .el-tabs__item:last-child,
.el-tabs--top.el-tabs--card .el-tabs__item:last-child,
.el-tabs--top .el-tabs--left .el-tabs__item:last-child,
.el-tabs--top .el-tabs--right .el-tabs__item:last-child {
    padding-right: 20px
}

.el-tabs--bottom .el-tabs__header.is-bottom {
    margin-bottom: 0;
    margin-top: 0
}

.el-tabs--bottom.el-tabs--border-card .el-tabs__header.is-bottom {
    border-bottom: 0;
    border-top: 1px solid #dcdfe6
}

.el-tabs--bottom.el-tabs--border-card .el-tabs__nav-wrap.is-bottom {
    margin-top: -1px;
    margin-bottom: 0
}

.el-tabs--bottom.el-tabs--border-card .el-tabs__item.is-bottom {
    margin: 0 -1px -1px
}

.el-tabs--bottom.el-tabs--border-card .el-tabs__item.is-bottom:not(.is-active) {
    border: 1px solid transparent
}

.el-tabs--left,
.el-tabs--right {
    overflow: hidden
}

.el-tabs--left .el-tabs__header.is-left,
.el-tabs--left .el-tabs__header.is-right,
.el-tabs--left .el-tabs__nav-scroll,
.el-tabs--left .el-tabs__nav-wrap.is-left,
.el-tabs--left .el-tabs__nav-wrap.is-right,
.el-tabs--right .el-tabs__header.is-left,
.el-tabs--right .el-tabs__header.is-right,
.el-tabs--right .el-tabs__nav-scroll,
.el-tabs--right .el-tabs__nav-wrap.is-left,
.el-tabs--right .el-tabs__nav-wrap.is-right {
    height: 100%
}

.el-tabs--left .el-tabs__active-bar.is-left,
.el-tabs--left .el-tabs__active-bar.is-right,
.el-tabs--right .el-tabs__active-bar.is-left,
.el-tabs--right .el-tabs__active-bar.is-right {
    top: 0;
    bottom: auto;
    width: 2px;
    height: auto
}

.el-tabs--left .el-tabs__nav-wrap.is-left,
.el-tabs--left .el-tabs__nav-wrap.is-right,
.el-tabs--right .el-tabs__nav-wrap.is-left,
.el-tabs--right .el-tabs__nav-wrap.is-right {
    margin-bottom: 0
}

.el-tabs--left .el-tabs__nav-wrap.is-left>.el-tabs__nav-next,
.el-tabs--left .el-tabs__nav-wrap.is-left>.el-tabs__nav-prev,
.el-tabs--left .el-tabs__nav-wrap.is-right>.el-tabs__nav-next,
.el-tabs--left .el-tabs__nav-wrap.is-right>.el-tabs__nav-prev,
.el-tabs--right .el-tabs__nav-wrap.is-left>.el-tabs__nav-next,
.el-tabs--right .el-tabs__nav-wrap.is-left>.el-tabs__nav-prev,
.el-tabs--right .el-tabs__nav-wrap.is-right>.el-tabs__nav-next,
.el-tabs--right .el-tabs__nav-wrap.is-right>.el-tabs__nav-prev {
    height: 26px;
    line-height: 26px;
    width: 100%;
    text-align: center;
    cursor: pointer
}

.el-tabs--left .el-tabs__nav-wrap.is-left>.el-tabs__nav-next i,
.el-tabs--left .el-tabs__nav-wrap.is-left>.el-tabs__nav-prev i,
.el-tabs--left .el-tabs__nav-wrap.is-right>.el-tabs__nav-next i,
.el-tabs--left .el-tabs__nav-wrap.is-right>.el-tabs__nav-prev i,
.el-tabs--right .el-tabs__nav-wrap.is-left>.el-tabs__nav-next i,
.el-tabs--right .el-tabs__nav-wrap.is-left>.el-tabs__nav-prev i,
.el-tabs--right .el-tabs__nav-wrap.is-right>.el-tabs__nav-next i,
.el-tabs--right .el-tabs__nav-wrap.is-right>.el-tabs__nav-prev i {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.el-tabs--left .el-tabs__nav-wrap.is-left>.el-tabs__nav-prev,
.el-tabs--left .el-tabs__nav-wrap.is-right>.el-tabs__nav-prev,
.el-tabs--right .el-tabs__nav-wrap.is-left>.el-tabs__nav-prev,
.el-tabs--right .el-tabs__nav-wrap.is-right>.el-tabs__nav-prev {
    left: auto;
    top: 0
}

.el-tabs--left .el-tabs__nav-wrap.is-left>.el-tabs__nav-next,
.el-tabs--left .el-tabs__nav-wrap.is-right>.el-tabs__nav-next,
.el-tabs--right .el-tabs__nav-wrap.is-left>.el-tabs__nav-next,
.el-tabs--right .el-tabs__nav-wrap.is-right>.el-tabs__nav-next {
    right: auto;
    bottom: 0
}

.el-tabs--left .el-tabs__active-bar.is-left,
.el-tabs--left .el-tabs__nav-wrap.is-left:after {
    right: 0;
    left: auto
}

.el-tabs--left .el-tabs__nav-wrap.is-left.is-scrollable,
.el-tabs--left .el-tabs__nav-wrap.is-right.is-scrollable,
.el-tabs--right .el-tabs__nav-wrap.is-left.is-scrollable,
.el-tabs--right .el-tabs__nav-wrap.is-right.is-scrollable {
    padding: 30px 0
}

.el-tabs--left .el-tabs__nav-wrap.is-left:after,
.el-tabs--left .el-tabs__nav-wrap.is-right:after,
.el-tabs--right .el-tabs__nav-wrap.is-left:after,
.el-tabs--right .el-tabs__nav-wrap.is-right:after {
    height: 100%;
    width: 2px;
    bottom: auto;
    top: 0
}

.el-tabs--left .el-tabs__nav.is-left,
.el-tabs--left .el-tabs__nav.is-right,
.el-tabs--right .el-tabs__nav.is-left,
.el-tabs--right .el-tabs__nav.is-right {
    float: none
}

.el-tabs--left .el-tabs__item.is-left,
.el-tabs--left .el-tabs__item.is-right,
.el-tabs--right .el-tabs__item.is-left,
.el-tabs--right .el-tabs__item.is-right {
    display: block
}

.el-tabs--left.el-tabs--card .el-tabs__active-bar.is-left,
.el-tabs--right.el-tabs--card .el-tabs__active-bar.is-right {
    display: none
}

.el-tabs--left .el-tabs__header.is-left {
    float: left;
    margin-bottom: 0;
    margin-right: 0
}

.el-tabs--left .el-tabs__nav-wrap.is-left {
    margin-right: -1px
}

.el-tabs--left .el-tabs__item.is-left {
    text-align: right
}

.el-tabs--left.el-tabs--card .el-tabs__item.is-left {
    border: 1px solid #e4e7ed;
    border-bottom: none;
    border-left: none
}

.el-tabs--left.el-tabs--card .el-tabs__item.is-left:first-child {
    border-right: 1px solid #e4e7ed;
    border-top: none
}

.el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
    border: none;
    border-top: 1px solid #e4e7ed;
    border-right: 1px solid #fff
}

.el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active:first-child {
    border-top: none
}

.el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active:last-child {
    border-bottom: none
}

.el-tabs--left.el-tabs--card .el-tabs__nav {
    border-radius: 4px 0 0 4px;
    border-bottom: 1px solid #e4e7ed;
    border-right: none
}

.el-tabs--left.el-tabs--card .el-tabs__new-tab {
    float: none
}

.el-tabs--left.el-tabs--border-card .el-tabs__header.is-left {
    border-right: 1px solid #dfe4ed
}

.el-tabs--left.el-tabs--border-card .el-tabs__item.is-left {
    border: 1px solid transparent;
    margin: -1px 0 -1px -1px
}

.el-tabs--left.el-tabs--border-card .el-tabs__item.is-left.is-active {
    border-color: #d1dbe5 transparent
}

.el-tabs--right .el-tabs__header.is-right {
    float: right;
    margin-bottom: 0;
    margin-left: 0
}

.el-tabs--right .el-tabs__nav-wrap.is-right {
    margin-left: -1px
}

.el-tabs--right .el-tabs__nav-wrap.is-right:after {
    left: 0;
    right: auto
}

.el-tabs--right .el-tabs__active-bar.is-right {
    left: 0
}

.el-tabs--right.el-tabs--card .el-tabs__item.is-right {
    border-bottom: none;
    border-top: 1px solid #e4e7ed
}

.el-tabs--right.el-tabs--card .el-tabs__item.is-right:first-child {
    border-left: 1px solid #e4e7ed;
    border-top: none
}

.el-tabs--right.el-tabs--card .el-tabs__item.is-right.is-active {
    border: none;
    border-top: 1px solid #e4e7ed;
    border-left: 1px solid #fff
}

.el-tabs--right.el-tabs--card .el-tabs__item.is-right.is-active:first-child {
    border-top: none
}

.el-tabs--right.el-tabs--card .el-tabs__item.is-right.is-active:last-child {
    border-bottom: none
}

.el-tabs--right.el-tabs--card .el-tabs__nav {
    border-radius: 0 4px 4px 0;
    border-bottom: 1px solid #e4e7ed;
    border-left: none
}

.el-tabs--right.el-tabs--border-card .el-tabs__header.is-right {
    border-left: 1px solid #dfe4ed
}

.el-tabs--right.el-tabs--border-card .el-tabs__item.is-right {
    border: 1px solid transparent;
    margin: -1px -1px -1px 0
}

.el-tabs--right.el-tabs--border-card .el-tabs__item.is-right.is-active {
    border-color: #d1dbe5 transparent
}

.el-button {
    -moz-user-select: none;
    -webkit-user-select: none;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: var(--color-bg);
    border: 1px solid var(--color-border);
    color: var(--color-8);
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    -webkit-transition: .1s;
    transition: .1s;
    font-weight: 500;
    -ms-user-select: none;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px
}

.el-button+.el-button {
    margin-left: 10px
}

.el-button:focus,
.el-button:hover {
    color: #409eff;
    border-color: #c6e2ff;
    background-color: var(--color-box-bg)
}

.el-button:active {
    color: #3a8ee6;
    border-color: #3a8ee6;
    outline: 0
}

.el-button::-moz-focus-inner {
    border: 0
}

.el-button [class*=el-icon-]+span,
.el-button [class*=gfp-]+span {
    margin-left: 5px
}

.el-button.is-plain:focus,
.el-button.is-plain:hover {
    background: var(--color-bg);
    border-color: #409eff;
    color: #409eff
}

.el-button.is-active,
.el-button.is-plain:active {
    color: #3a8ee6;
    border-color: #3a8ee6
}

.el-button.is-plain:active {
    background: #fff;
    outline: 0
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
    background-image: none;
    background-color: #fff;
    border-color: #ebeef5
}

.el-button.is-disabled.el-button--text {
    background-color: transparent
}

.el-button.is-disabled.is-plain,
.el-button.is-disabled.is-plain:focus,
.el-button.is-disabled.is-plain:hover {
    background-color: #fff;
    border-color: #ebeef5;
    color: #c0c4cc
}

.el-button.is-loading {
    position: relative;
    pointer-events: none
}

.el-button.is-loading:before {
    pointer-events: none;
    content: "";
    position: absolute;
    left: -1px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: inherit;
    background-color: hsla(0, 0%, 100%, .35)
}

.el-button.is-round {
    border-radius: 20px;
    padding: 12px 23px
}

.el-button.is-circle {
    border-radius: 50%;
    padding: 12px
}

.el-button--primary {
    color: #fff;
    background-color: #195daa;
    border-color: #195daa
}

.el-button--primary:focus,
.el-button--primary:hover {
    background: #66b1ff;
    border-color: #66b1ff;
    color: #fff
}

.el-button--primary.is-active,
.el-button--primary:active {
    background: #3a8ee6;
    border-color: #3a8ee6;
    color: #fff
}

.el-button--primary:active {
    outline: 0
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
    color: #fff;
    background-color: #a0cfff;
    border-color: #a0cfff
}

.el-button--primary.is-plain {
    color: #409eff;
    background: var(--color-primary-surface);
    border-color: var(--color-primary-border)
}

.el-button--primary.is-plain:focus,
.el-button--primary.is-plain:hover {
    background: #409eff;
    border-color: #409eff;
    color: #fff
}

.el-button--primary.is-plain:active {
    background: #3a8ee6;
    border-color: #3a8ee6;
    color: #fff;
    outline: 0
}

.el-button--primary.is-plain.is-disabled,
.el-button--primary.is-plain.is-disabled:active,
.el-button--primary.is-plain.is-disabled:focus,
.el-button--primary.is-plain.is-disabled:hover {
    color: #8cc5ff;
    background-color: #ecf5ff;
    border-color: #d9ecff
}

.el-button--success {
    color: #fff;
    background-color: #67c23a;
    border-color: #67c23a
}

.el-button--success:focus,
.el-button--success:hover {
    background: #85ce61;
    border-color: #85ce61;
    color: #fff
}

.el-button--success.is-active,
.el-button--success:active {
    background: #5daf34;
    border-color: #5daf34;
    color: #fff
}

.el-button--success:active {
    outline: 0
}

.el-button--success.is-disabled,
.el-button--success.is-disabled:active,
.el-button--success.is-disabled:focus,
.el-button--success.is-disabled:hover {
    color: #fff;
    background-color: #b3e19d;
    border-color: #b3e19d
}

.el-button--success.is-plain {
    color: #67c23a;
    background: #f0f9eb;
    border-color: #c2e7b0
}

.el-button--success.is-plain:focus,
.el-button--success.is-plain:hover {
    background: #67c23a;
    border-color: #67c23a;
    color: #fff
}

.el-button--success.is-plain:active {
    background: #5daf34;
    border-color: #5daf34;
    color: #fff;
    outline: 0
}

.el-button--success.is-plain.is-disabled,
.el-button--success.is-plain.is-disabled:active,
.el-button--success.is-plain.is-disabled:focus,
.el-button--success.is-plain.is-disabled:hover {
    color: #a4da89;
    background-color: #f0f9eb;
    border-color: #e1f3d8
}

.el-button--warning {
    color: #fff;
    background-color: #e6a23c;
    border-color: #e6a23c
}

.el-button--warning:focus,
.el-button--warning:hover {
    background: #ebb563;
    border-color: #ebb563;
    color: #fff
}

.el-button--warning.is-active,
.el-button--warning:active {
    background: #cf9236;
    border-color: #cf9236;
    color: #fff
}

.el-button--warning:active {
    outline: 0
}

.el-button--warning.is-disabled,
.el-button--warning.is-disabled:active,
.el-button--warning.is-disabled:focus,
.el-button--warning.is-disabled:hover {
    color: #fff;
    background-color: #f3d19e;
    border-color: #f3d19e
}

.el-button--warning.is-plain {
    color: #e6a23c;
    background: #fdf6ec;
    border-color: #f5dab1
}

.el-button--warning.is-plain:focus,
.el-button--warning.is-plain:hover {
    background: #e6a23c;
    border-color: #e6a23c;
    color: #fff
}

.el-button--warning.is-plain:active {
    background: #cf9236;
    border-color: #cf9236;
    color: #fff;
    outline: 0
}

.el-button--warning.is-plain.is-disabled,
.el-button--warning.is-plain.is-disabled:active,
.el-button--warning.is-plain.is-disabled:focus,
.el-button--warning.is-plain.is-disabled:hover {
    color: #f0c78a;
    background-color: #fdf6ec;
    border-color: #faecd8
}

.el-button--danger {
    color: #fff;
    background-color: #f56c6c;
    border-color: #f56c6c
}

.el-button--danger:focus,
.el-button--danger:hover {
    background: #f78989;
    border-color: #f78989;
    color: #fff
}

.el-button--danger.is-active,
.el-button--danger:active {
    background: #dd6161;
    border-color: #dd6161;
    color: #fff
}

.el-button--danger:active {
    outline: 0
}

.el-button--danger.is-disabled,
.el-button--danger.is-disabled:active,
.el-button--danger.is-disabled:focus,
.el-button--danger.is-disabled:hover {
    color: #fff;
    background-color: #fab6b6;
    border-color: #fab6b6
}

.el-button--danger.is-plain {
    color: #f56c6c;
    background: #fef0f0;
    border-color: #fbc4c4
}

.el-button--danger.is-plain:focus,
.el-button--danger.is-plain:hover {
    background: #f56c6c;
    border-color: #f56c6c;
    color: #fff
}

.el-button--danger.is-plain:active {
    background: #dd6161;
    border-color: #dd6161;
    color: #fff;
    outline: 0
}

.el-button--danger.is-plain.is-disabled,
.el-button--danger.is-plain.is-disabled:active,
.el-button--danger.is-plain.is-disabled:focus,
.el-button--danger.is-plain.is-disabled:hover {
    color: #f9a7a7;
    background-color: #fef0f0;
    border-color: #fde2e2
}

.el-button--info {
    color: #fff;
    background-color: #909399;
    border-color: #909399
}

.el-button--info:focus,
.el-button--info:hover {
    background: #a6a9ad;
    border-color: #a6a9ad;
    color: #fff
}

.el-button--info.is-active,
.el-button--info:active {
    background: #82848a;
    border-color: #82848a;
    color: #fff
}

.el-button--info:active {
    outline: 0
}

.el-button--info.is-disabled,
.el-button--info.is-disabled:active,
.el-button--info.is-disabled:focus,
.el-button--info.is-disabled:hover {
    color: #fff;
    background-color: #c8c9cc;
    border-color: #c8c9cc
}

.el-button--info.is-plain {
    color: #909399;
    background: #f4f4f5;
    border-color: #d3d4d6
}

.el-button--info.is-plain:focus,
.el-button--info.is-plain:hover {
    background: #909399;
    border-color: #909399;
    color: #fff
}

.el-button--info.is-plain:active {
    background: #82848a;
    border-color: #82848a;
    color: #fff;
    outline: 0
}

.el-button--info.is-plain.is-disabled,
.el-button--info.is-plain.is-disabled:active,
.el-button--info.is-plain.is-disabled:focus,
.el-button--info.is-plain.is-disabled:hover {
    color: #bcbec2;
    background-color: #f4f4f5;
    border-color: #e9e9eb
}

.el-button--text,
.el-button--text.is-disabled,
.el-button--text.is-disabled:focus,
.el-button--text.is-disabled:hover,
.el-button--text:active {
    border-color: transparent
}

.el-button--medium {
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 4px
}

.el-button--mini,
.el-button--small {
    font-size: 12px;
    border-radius: 3px
}

.el-button--medium.is-round {
    padding: 10px 20px
}

.el-button--medium.is-circle {
    padding: 10px
}

.el-button--small,
.el-button--small.is-round {
    padding: 9px 15px
}

.el-button--small.is-circle {
    padding: 9px
}

.el-button--mini,
.el-button--mini.is-round {
    padding: 7px 15px
}

.el-button--mini.is-circle {
    padding: 7px
}

.el-button--text {
    color: #409eff;
    background: 0 0;
    padding-left: 0;
    padding-right: 0
}

.el-button--text:focus,
.el-button--text:hover {
    color: #66b1ff;
    border-color: transparent;
    background-color: transparent
}

.el-button--text:active {
    color: #3a8ee6;
    background-color: transparent
}

.el-button-group {
    display: inline-block;
    vertical-align: middle
}

.el-button-group:after,
.el-button-group:before {
    display: table;
    content: ""
}

.el-button-group:after {
    clear: both
}

.el-button-group>.el-button {
    float: left;
    position: relative
}

.el-button-group>.el-button+.el-button {
    margin-left: 0
}

.el-button-group>.el-button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.el-button-group>.el-button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.el-button-group>.el-button:first-child:last-child {
    border-radius: 4px
}

.el-button-group>.el-button:first-child:last-child.is-round {
    border-radius: 20px
}

.el-button-group>.el-button:first-child:last-child.is-circle {
    border-radius: 50%
}

.el-button-group>.el-button:not(:first-child):not(:last-child) {
    border-radius: 0
}

.el-button-group>.el-button:not(:last-child) {
    margin-right: -1px
}

.el-button-group>.el-dropdown>.el-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-color: hsla(0, 0%, 100%, .5)
}

.el-button-group .el-button--danger:first-child,
.el-button-group .el-button--info:first-child,
.el-button-group .el-button--primary:first-child,
.el-button-group .el-button--success:first-child,
.el-button-group .el-button--warning:first-child {
    border-right-color: hsla(0, 0%, 100%, .5)
}

.el-button-group .el-button--danger:last-child,
.el-button-group .el-button--info:last-child,
.el-button-group .el-button--primary:last-child,
.el-button-group .el-button--success:last-child,
.el-button-group .el-button--warning:last-child {
    border-left-color: hsla(0, 0%, 100%, .5)
}

.el-button-group .el-button--danger:not(:first-child):not(:last-child),
.el-button-group .el-button--info:not(:first-child):not(:last-child),
.el-button-group .el-button--primary:not(:first-child):not(:last-child),
.el-button-group .el-button--success:not(:first-child):not(:last-child),
.el-button-group .el-button--warning:not(:first-child):not(:last-child) {
    border-left-color: hsla(0, 0%, 100%, .5);
    border-right-color: hsla(0, 0%, 100%, .5)
}

.el-dropdown {
    display: inline-block;
    position: relative;
    color: var(--color-8);
    font-size: 14px
}

.el-dropdown .el-button-group {
    display: block
}

.el-dropdown .el-button-group .el-button {
    float: none
}

.el-dropdown:active,
.el-dropdown:not(.focusing) {
    outline-width: 0
}

.el-dropdown .el-dropdown__caret-button {
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    border-left: none
}

.el-dropdown .el-dropdown__caret-button:before {
    content: "";
    position: absolute;
    display: block;
    width: 1px;
    top: 5px;
    bottom: 5px;
    left: 0;
    background: hsla(0, 0%, 100%, .5)
}

.el-dropdown .el-dropdown__caret-button:hover:before {
    top: 0;
    bottom: 0
}

.el-dropdown .el-dropdown__caret-button .el-dropdown__icon {
    padding-left: 0
}

.el-dropdown__icon {
    font-size: 12px;
    margin: 0 3px
}

.el-dropdown-selfdefine:focus:active,
.el-dropdown-selfdefine:focus:not(.focusing) {
    outline-width: 0
}

.el-dropdown-menu {
    max-height: 50%;
    overflow-y: scroll;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    margin: 5px 0;
    background-color: var(--color-tooltip-bg);
    border: 1px solid var(--color-border);
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1)
}

.el-dropdown-menu__item {
    line-height: 30px;
    font-size: 12px;
    list-style: none;
    padding: 0 20px;
    margin: 0;
    color: var(--color-8);
    cursor: pointer;
    outline: 0
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #ecf5ff;
    color: #66b1ff
}

.el-dropdown-menu__item i {
    margin-right: 5px
}

.el-dropdown-menu__item--divided {
    position: relative;
    margin-top: 6px;
    border-top: 1px solid #ebeef5
}

.el-dropdown-menu__item--divided:before {
    content: "";
    height: 6px;
    display: block;
    margin: 0 -20px;
    background-color: #fff
}

.el-dropdown-menu__item.is-disabled {
    cursor: default;
    color: #bbb;
    pointer-events: none
}

.el-dropdown-menu--medium {
    padding: 6px 0
}

.el-dropdown-menu--medium .el-dropdown-menu__item {
    line-height: 30px;
    padding: 0 17px;
    font-size: 14px
}

.el-dropdown-menu--medium .el-dropdown-menu__item.el-dropdown-menu__item--divided {
    margin-top: 6px
}

.el-dropdown-menu--medium .el-dropdown-menu__item.el-dropdown-menu__item--divided:before {
    height: 6px;
    margin: 0 -17px
}

.el-dropdown-menu--small {
    padding: 6px 0
}

.el-dropdown-menu--small .el-dropdown-menu__item {
    line-height: 27px;
    padding: 0 15px;
    font-size: 13px
}

.el-dropdown-menu--small .el-dropdown-menu__item.el-dropdown-menu__item--divided {
    margin-top: 4px
}

.el-dropdown-menu--small .el-dropdown-menu__item.el-dropdown-menu__item--divided:before {
    height: 4px;
    margin: 0 -15px
}

.el-dropdown-menu--mini {
    padding: 3px 0
}

.el-dropdown-menu--mini .el-dropdown-menu__item {
    line-height: 24px;
    padding: 0 10px;
    font-size: 12px
}

.el-dropdown-menu--mini .el-dropdown-menu__item.el-dropdown-menu__item--divided {
    margin-top: 3px
}

.el-dropdown-menu--mini .el-dropdown-menu__item.el-dropdown-menu__item--divided:before {
    height: 3px;
    margin: 0 -10px
}

.el-pager .more,
.el-pager .number {
    background: 0 0
}

.el-pagination {
    white-space: nowrap;
    padding: 2px 5px;
    color: var(--color-9);
    font-weight: 700
}

.el-pagination:after,
.el-pagination:before {
    display: table;
    content: ""
}

.el-pagination:after {
    clear: both
}

.el-pagination button,
.el-pagination span:not([class*=suffix]) {
    display: inline-block;
    font-size: 13px;
    min-width: 35.5px;
    height: 28px;
    line-height: 28px;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-pagination .el-input__inner {
    text-align: center;
    -moz-appearance: textfield;
    line-height: normal
}

.el-pagination .el-input__suffix {
    right: 0;
    -webkit-transform: scale(.8);
    transform: scale(.8)
}

.el-pagination .el-select .el-input {
    width: 100px;
    margin: 0 5px
}

.el-pagination .el-select .el-input .el-input__inner {
    padding-right: 25px;
    border-radius: 3px
}

.el-pagination button {
    border: none;
    padding: 0 6px;
    background: 0 0
}

.el-pagination button:focus {
    outline: 0
}

.el-pagination button:hover {
    color: #409eff
}

.el-pagination button:disabled {
    color: #c0c4cc;
    cursor: not-allowed
}

.el-pagination .btn-next,
.el-pagination .btn-prev {
    background-size: 16px;
    cursor: pointer;
    margin: 0;
    color: var(--color-9)
}

.el-pagination .btn-next .el-icon {
    display: block;
    font-size: 12px;
    font-weight: 700
}

.el-pagination .btn-prev {
    padding-right: 12px
}

.el-pagination .btn-prev .el-icon {
    display: block;
    font-size: 12px;
    font-weight: 700
}

.el-pagination .btn-next {
    padding-left: 12px
}

.el-pagination .el-pager li.disabled {
    color: #c0c4cc;
    cursor: not-allowed
}

.el-pager li,
.el-pager li.btn-quicknext:hover,
.el-pager li.btn-quickprev:hover {
    cursor: pointer
}

.el-pagination--small .btn-next,
.el-pagination--small .btn-prev,
.el-pagination--small .el-pager li,
.el-pagination--small .el-pager li.btn-quicknext,
.el-pagination--small .el-pager li.btn-quickprev,
.el-pagination--small .el-pager li:last-child {
    border-color: transparent;
    font-size: 12px;
    line-height: 22px;
    height: 22px;
    min-width: 22px
}

.el-pagination--small .more:before,
.el-pagination--small li.more:before {
    line-height: 24px
}

.el-pagination--small button,
.el-pagination--small span:not([class*=suffix]) {
    height: 22px;
    line-height: 22px
}

.el-pagination--small .el-pagination__editor,
.el-pagination--small .el-pagination__editor.el-input .el-input__inner {
    height: 22px
}

.el-pagination--small .arrow.disabled {
    visibility: hidden
}

.el-pagination__sizes {
    margin: 0 10px 0 0;
    font-weight: 400;
    color: #606266
}

.el-pagination__sizes .el-input .el-input__inner {
    font-size: 13px;
    padding-left: 8px
}

.el-pagination__sizes .el-input .el-input__inner:hover {
    border-color: #409eff
}

.el-pagination__total {
    margin-right: 10px;
    font-weight: 400;
    color: #606266
}

.el-pagination__jump {
    margin-left: 24px;
    font-weight: 400;
    color: #606266
}

.el-pagination__jump .el-input__inner {
    padding: 0 3px
}

.el-pagination__rightwrapper {
    float: right
}

.el-pagination__editor {
    line-height: 18px;
    padding: 0 2px;
    height: 28px;
    text-align: center;
    margin: 0 2px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px
}

.el-pager,
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev {
    padding: 0
}

.el-pagination__editor.el-input {
    width: 50px
}

.el-pagination__editor.el-input .el-input__inner {
    height: 28px
}

.el-pagination__editor .el-input__inner::-webkit-inner-spin-button,
.el-pagination__editor .el-input__inner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
    margin: 0 5px;
    color: #606266;
    min-width: 30px;
    border-radius: 2px
}

.el-pagination.is-background .btn-next.disabled,
.el-pagination.is-background .btn-next:disabled,
.el-pagination.is-background .btn-prev.disabled,
.el-pagination.is-background .btn-prev:disabled,
.el-pagination.is-background .el-pager li.disabled {
    color: #c0c4cc
}

.el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #409eff
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #409eff;
    color: #fff
}

.el-pager li {
    background: #fff;
    -webkit-box-sizing: border-box
}

.el-pagination.is-background.el-pagination--small .btn-next,
.el-pagination.is-background.el-pagination--small .btn-prev,
.el-pagination.is-background.el-pagination--small .el-pager li {
    margin: 0 3px;
    min-width: 22px
}

.el-pager {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    list-style: none;
    font-size: 0
}

.el-pager,
.el-pager li {
    vertical-align: top;
    margin: 0;
    display: inline-block
}

.el-pager .more:before {
    line-height: 30px
}

.el-pager li {
    padding: 0 4px;
    font-size: 13px;
    min-width: 35.5px;
    height: 28px;
    line-height: 28px;
    box-sizing: border-box;
    text-align: center
}

.el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
    line-height: 28px;
    color: var(--color-9)
}

.el-pager li.btn-quicknext.disabled,
.el-pager li.btn-quickprev.disabled {
    color: #c0c4cc
}

.el-pager li.active+li {
    border-left: 0
}

.el-pager li:hover {
    color: #409eff
}

.el-pager li.active {
    color: #409eff;
    cursor: default
}

.el-tag {
    background-color: rgba(64, 158, 255, .1);
    padding: 0 10px;
    height: 32px;
    line-height: 30px;
    font-size: 12px;
    color: #409eff;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid rgba(64, 158, 255, .2);
    white-space: nowrap;
    display: inline-block;
    -webkit-box-sizing: border-box
}

.el-tag .el-icon-close {
    border-radius: 50%;
    text-align: center;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 16px;
    top: -1px;
    right: -5px;
    color: #409eff
}

.el-tag .el-icon-close:before {
    display: block
}

.el-tag .el-icon-close:hover {
    background-color: #409eff;
    color: #fff
}

.el-tag--info {
    color: #909399;
    background-color: rgba(144, 147, 153, .1);
    border-color: rgba(144, 147, 153, .2)
}

.el-tag--info .el-tag__close {
    color: #909399
}

.el-tag--info.is-hit {
    border-color: #909399
}

.el-tag--info .el-tag__close:hover {
    background-color: #909399;
    color: #fff
}

.el-tag--success {
    background-color: rgba(103, 194, 58, .1);
    border-color: rgba(103, 194, 58, .2);
    color: #67c23a
}

.el-tag--success.is-hit {
    border-color: #67c23a
}

.el-tag--success .el-tag__close {
    color: #67c23a
}

.el-tag--success .el-tag__close:hover {
    background-color: #67c23a;
    color: #fff
}

.el-tag--warning {
    background-color: rgba(230, 162, 60, .1);
    border-color: rgba(230, 162, 60, .2);
    color: #e6a23c
}

.el-tag--warning.is-hit {
    border-color: #e6a23c
}

.el-tag--warning .el-tag__close {
    color: #e6a23c
}

.el-tag--warning .el-tag__close:hover {
    background-color: #e6a23c;
    color: #fff
}

.el-tag--danger {
    background-color: rgba(245, 108, 108, .1);
    border-color: rgba(245, 108, 108, .2);
    color: #f56c6c
}

.el-tag--danger.is-hit {
    border-color: #f56c6c
}

.el-tag--danger .el-tag__close {
    color: #f56c6c
}

.el-tag--danger .el-tag__close:hover {
    background-color: #f56c6c;
    color: #fff
}

.el-tag--medium {
    height: 28px;
    line-height: 26px
}

.el-tag--medium .el-icon-close {
    -webkit-transform: scale(.8);
    transform: scale(.8)
}

.el-tag--small {
    height: 24px;
    padding: 0 8px;
    line-height: 22px
}

.el-tag--small .el-icon-close {
    -webkit-transform: scale(.8);
    transform: scale(.8)
}

.el-tag--mini {
    font-size: 11px;
    height: 18px;
    padding: 0 4px;
    line-height: 17px
}

.el-tag--mini .el-icon-close {
    margin-left: -3px;
    -webkit-transform: scale(.7);
    transform: scale(.7)
}

.el-form-item {
    margin-bottom: 8px
}

.el-form-item:after,
.el-form-item:before {
    display: table;
    content: ""
}

.el-form-item:after {
    clear: both
}

.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px
}

.el-form-item.is-error .el-input-group__append .el-input__inner,
.el-form-item.is-error .el-input-group__prepend .el-input__inner {
    border-color: transparent
}

.el-form-item.is-error .el-input__validateIcon {
    color: #f56c6c
}

.el-form-item.is-success .el-input__inner,
.el-form-item.is-success .el-input__inner:focus,
.el-form-item.is-success .el-textarea__inner,
.el-form-item.is-success .el-textarea__inner:focus {
    border-color: #67c23a
}

.el-form-item.is-success .el-input-group__append .el-input__inner,
.el-form-item.is-success .el-input-group__prepend .el-input__inner {
    border-color: transparent
}

.el-form-item.is-success .el-input__validateIcon {
    color: #67c23a
}

.el-form-item .el-form-item {
    margin-bottom: 0
}

.el-form-item .is-error .el-input__inner,
.el-form-item .is-error .el-input__inner:focus,
.el-form-item .is-error .el-textarea__inner,
.el-form-item .is-error .el-textarea__inner:focus {
    border-color: #f56c6c
}

.el-form-item__label {
    padding: 0;
    line-height: 24px;
    text-align: right;
    float: left;
    font-size: 14px;
    color: var(--color-9);
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-form-item__label .el-tag .el-icon-close {
    vertical-align: middle
}

.el-form-item__content {
    line-height: 40px;
    position: relative;
    font-size: 14px
}

.el-form-item__content:after,
.el-form-item__content:before {
    display: table;
    content: ""
}

.el-form-item__content:after {
    clear: both
}

.el-form--label-top {
    padding: 0 !important;
    line-height: 24px !important
}

.el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 10px
}

.el-form--label-left .el-form-item__label {
    text-align: left
}

.el-form--inline .el-form-item {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top
}

.el-form--inline .el-form-item__label {
    float: none;
    display: inline-block
}

.el-form--inline .el-form-item__content {
    display: inline-block;
    vertical-align: top
}

.el-form-item__content .el-input-group {
    vertical-align: middle
}

.el-form--inline.el-form--label-top .el-form-item__content {
    display: block
}

.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 18px
}

.el-form-item .el-input__validateIcon {
    display: none
}

.el-form-item--medium .el-form-item__content,
.el-form-item--medium .el-form-item__label {
    line-height: 36px
}

.el-form-item--small .el-form-item__content,
.el-form-item--small .el-form-item__label {
    line-height: 32px
}

.el-form-item--small .el-form-item__error {
    padding-top: 2px
}

.el-form-item--mini .el-form-item__content,
.el-form-item--mini .el-form-item__label {
    line-height: 28px
}

.el-form-item--mini .el-form-item__error {
    padding-top: 1px
}

.el-form-item__label-wrap {
    float: left
}

.el-form-item__label-wrap .el-form-item__label {
    display: inline-block;
    float: none
}

.el-form-item__error {
    color: #f56c6c;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0
}

.el-form-item__error--inline {
    position: relative;
    top: auto;
    left: auto;
    display: inline-block;
    margin-left: 10px
}

.el-form-item--feedback .el-input__validateIcon {
    display: inline-block
}

.el-time-panel {
    -webkit-user-select: none;
    -moz-user-select: none
}

.el-picker-panel {
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1)
}

.el-date-range-picker {
    width: 646px
}

.el-date-range-picker.has-sidebar {
    width: 756px
}

.el-date-range-picker table {
    table-layout: fixed;
    width: 100%
}

.el-date-range-picker .el-picker-panel__body {
    min-width: 513px
}

.el-date-range-picker .el-picker-panel__content {
    margin: 0
}

.el-date-range-picker__header {
    position: relative;
    text-align: center;
    height: 28px
}

.el-date-range-picker__header [class*=arrow-left] {
    float: left
}

.el-date-range-picker__header [class*=arrow-right] {
    float: right
}

.el-date-range-picker__header div {
    font-size: 16px;
    font-weight: 500;
    margin-right: 50px
}

.el-date-range-picker__content {
    float: left;
    width: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 16px
}

.el-date-range-picker__content.is-left {
    border-right: 1px solid #e4e4e4
}

.el-date-range-picker__content .el-date-range-picker__header div {
    margin-left: 50px;
    margin-right: 50px
}

.el-date-range-picker__editors-wrap {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: table-cell
}

.el-date-range-picker__editors-wrap.is-right {
    text-align: right
}

.el-date-range-picker__time-header {
    position: relative;
    border-bottom: 1px solid #e4e4e4;
    font-size: 12px;
    padding: 8px 5px 5px;
    display: table;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-date-range-picker__time-header>.el-icon-arrow-right {
    font-size: 20px;
    vertical-align: middle;
    display: table-cell;
    color: #303133
}

.el-date-range-picker__time-picker-wrap {
    position: relative;
    display: table-cell;
    padding: 0 5px
}

.el-date-range-picker__time-picker-wrap .el-picker-panel {
    position: absolute;
    top: 13px;
    right: 0;
    z-index: 1;
    background: #fff
}

.el-date-picker {
    width: 322px
}

.el-date-picker.has-sidebar {
    width: 438px
}

.el-date-picker.has-sidebar.has-time {
    width: 434px
}

.el-date-picker.has-time .el-picker-panel__body-wrapper {
    position: relative
}

.el-date-picker .el-picker-panel__content {
    width: 292px
}

.el-date-picker table {
    table-layout: fixed;
    width: 100%
}

.el-date-picker__editor-wrap {
    position: relative;
    display: table-cell;
    padding: 0 5px
}

.el-date-picker__time-header {
    position: relative;
    border-bottom: 1px solid #e4e4e4;
    font-size: 12px;
    padding: 8px 5px 5px;
    display: table;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-date-picker__header {
    margin: 12px;
    text-align: center
}

.el-date-picker__header--bordered {
    margin-bottom: 0;
    padding-bottom: 12px;
    border-bottom: 1px solid #ebeef5
}

.el-date-picker__header--bordered+.el-picker-panel__content {
    margin-top: 0
}

.el-date-picker__header-label {
    font-size: 16px;
    font-weight: 500;
    padding: 0 5px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
    color: var(--color-9)
}

.el-date-picker__header-label.active,
.el-date-picker__header-label:hover {
    color: #409eff
}

.el-date-picker__prev-btn {
    float: left
}

.el-date-picker__next-btn {
    float: right
}

.el-date-picker__time-wrap {
    padding: 10px;
    text-align: center
}

.el-date-picker__time-label {
    float: left;
    cursor: pointer;
    line-height: 30px;
    margin-left: 10px
}

.time-select {
    margin: 5px 0;
    min-width: 0
}

.time-select .el-picker-panel__content {
    max-height: 200px;
    margin: 0
}

.time-select-item {
    padding: 8px 10px;
    font-size: 14px;
    line-height: 20px
}

.time-select-item.selected:not(.disabled) {
    color: #409eff;
    font-weight: 700
}

.time-select-item.disabled {
    color: #e4e7ed;
    cursor: not-allowed
}

.time-select-item:hover {
    background-color: #f5f7fa;
    font-weight: 700;
    cursor: pointer
}

.el-date-editor {
    position: relative;
    display: inline-block;
    text-align: left
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 220px
}

.el-date-editor--monthrange.el-input,
.el-date-editor--monthrange.el-input__inner {
    width: 300px
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
    width: 350px
}

.el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
    width: 400px
}

.el-date-editor--dates {
    text-overflow: ellipsis;
    white-space: nowrap
}

.el-date-editor .el-icon-circle-close {
    cursor: pointer
}

.el-date-editor .el-range__icon {
    font-size: 14px;
    margin-left: -5px;
    color: #c0c4cc;
    float: left;
    line-height: 32px
}

.el-date-editor .el-range-input,
.el-date-editor .el-range-separator {
    height: 100%;
    margin: 0;
    text-align: center;
    display: inline-block;
    font-size: 14px
}

.el-date-editor .el-range-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: 0;
    padding: 0;
    width: 39%;
    color: #606266
}

.el-date-editor .el-range-input:-ms-input-placeholder,
.el-date-editor .el-range-input::-moz-placeholder,
.el-date-editor .el-range-input::-ms-input-placeholder,
.el-date-editor .el-range-input::-webkit-input-placeholder,
.el-date-editor .el-range-input::placeholder {
    color: #c0c4cc
}

.el-date-editor .el-range-separator {
    padding: 0 5px;
    line-height: 32px;
    width: 5%;
    color: #303133
}

.el-date-editor .el-range__close-icon {
    font-size: 14px;
    color: #c0c4cc;
    width: 25px;
    display: inline-block;
    float: right;
    line-height: 32px
}

.el-range-editor.el-input__inner {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 3px 10px
}

.el-range-editor .el-range-input {
    line-height: 1
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover {
    border-color: #409eff
}

.el-range-editor--medium.el-input__inner {
    height: 36px
}

.el-range-editor--medium .el-range-separator {
    line-height: 28px;
    font-size: 14px
}

.el-range-editor--medium .el-range-input {
    font-size: 14px
}

.el-range-editor--medium .el-range__close-icon,
.el-range-editor--medium .el-range__icon {
    line-height: 28px
}

.el-range-editor--small.el-input__inner {
    height: 32px
}

.el-range-editor--small .el-range-separator {
    line-height: 24px;
    font-size: 13px
}

.el-range-editor--small .el-range-input {
    font-size: 13px
}

.el-range-editor--small .el-range__close-icon,
.el-range-editor--small .el-range__icon {
    line-height: 24px
}

.el-range-editor--mini.el-input__inner {
    height: 28px
}

.el-range-editor--mini .el-range-separator {
    line-height: 20px;
    font-size: 12px
}

.el-range-editor--mini .el-range-input {
    font-size: 12px
}

.el-range-editor--mini .el-range__close-icon,
.el-range-editor--mini .el-range__icon {
    line-height: 20px
}

.el-range-editor.is-disabled {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed
}

.el-range-editor.is-disabled:focus,
.el-range-editor.is-disabled:hover {
    border-color: #e4e7ed
}

.el-range-editor.is-disabled input {
    background-color: #f5f7fa;
    color: #c0c4cc;
    cursor: not-allowed
}

.el-range-editor.is-disabled input:-ms-input-placeholder,
.el-range-editor.is-disabled input::-moz-placeholder,
.el-range-editor.is-disabled input::-ms-input-placeholder,
.el-range-editor.is-disabled input::-webkit-input-placeholder,
.el-range-editor.is-disabled input::placeholder {
    color: #c0c4cc
}

.el-range-editor.is-disabled .el-range-separator {
    color: #c0c4cc
}

.el-picker-panel {
    color: var(--color-8);
    border: 1px solid #e4e7ed;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    background: var(--color-tooltip-bg);
    border-radius: 4px;
    line-height: 30px;
    margin: 5px 0
}

.el-time-panel {
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1)
}

.el-picker-panel__body-wrapper:after,
.el-picker-panel__body:after {
    content: "";
    display: table;
    clear: both
}

.el-picker-panel__content {
    position: relative;
    margin: 15px
}

.el-picker-panel__footer {
    border-top: 1px solid #e4e4e4;
    padding: 4px;
    text-align: right;
    background-color: #fff;
    position: relative;
    font-size: 0
}

.el-picker-panel__shortcut {
    display: block;
    width: 100%;
    border: 0;
    background-color: transparent;
    line-height: 28px;
    font-size: 14px;
    color: #606266;
    padding-left: 12px;
    text-align: left;
    outline: 0;
    cursor: pointer
}

.el-picker-panel__shortcut:hover {
    color: #409eff
}

.el-picker-panel__shortcut.active {
    background-color: #e6f1fe;
    color: #409eff
}

.el-picker-panel__btn {
    border: 1px solid #dcdcdc;
    color: #333;
    line-height: 24px;
    border-radius: 2px;
    padding: 0 20px;
    cursor: pointer;
    background-color: transparent;
    outline: 0;
    font-size: 12px
}

.el-picker-panel__btn[disabled] {
    color: #ccc;
    cursor: not-allowed
}

.el-picker-panel__icon-btn {
    font-size: 12px;
    color: var(--color-9);
    border: 0;
    background: 0 0;
    cursor: pointer;
    outline: 0;
    margin-top: 8px
}

.el-picker-panel__icon-btn:hover {
    color: #409eff
}

.el-picker-panel__icon-btn.is-disabled {
    color: #bbb
}

.el-picker-panel__icon-btn.is-disabled:hover {
    cursor: not-allowed
}

.el-picker-panel__link-btn {
    vertical-align: middle
}

.el-picker-panel [slot=sidebar],
.el-picker-panel__sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 110px;
    border-right: 1px solid #e4e4e4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 6px;
    background-color: #fff;
    overflow: auto
}

.el-picker-panel [slot=sidebar]+.el-picker-panel__body,
.el-picker-panel__sidebar+.el-picker-panel__body {
    margin-left: 110px
}

.el-time-panel {
    margin: 5px 0;
    border: 1px solid #e4e7ed;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    border-radius: 2px;
    position: absolute;
    width: 180px;
    left: 0;
    z-index: 1000;
    -ms-user-select: none;
    user-select: none;
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

.el-time-panel__content {
    font-size: 0;
    position: relative;
    overflow: hidden
}

.el-time-panel__content:after,
.el-time-panel__content:before {
    content: "";
    top: 50%;
    position: absolute;
    margin-top: -15px;
    height: 32px;
    z-index: -1;
    left: 0;
    right: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 6px;
    text-align: left;
    border-top: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed
}

.el-time-panel__content:after {
    left: 50%;
    margin-left: 12%;
    margin-right: 12%
}

.el-time-panel__content:before {
    padding-left: 50%;
    margin-right: 12%;
    margin-left: 12%
}

.el-time-panel__content.has-seconds:after {
    left: 66.66667%
}

.el-time-panel__content.has-seconds:before {
    padding-left: 33.33333%
}

.el-time-panel__footer {
    border-top: 1px solid #e4e4e4;
    padding: 4px;
    height: 36px;
    line-height: 25px;
    text-align: right;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-time-panel__btn {
    border: none;
    line-height: 28px;
    padding: 0 5px;
    margin: 0 5px;
    cursor: pointer;
    background-color: transparent;
    outline: 0;
    font-size: 12px;
    color: #303133
}

.el-time-panel__btn.confirm {
    font-weight: 800;
    color: #409eff
}

.el-time-range-picker {
    width: 354px;
    overflow: visible
}

.el-time-range-picker__content {
    position: relative;
    text-align: center;
    padding: 10px
}

.el-time-range-picker__cell {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 7px 7px;
    width: 50%;
    display: inline-block
}

.el-time-range-picker__header {
    margin-bottom: 5px;
    text-align: center;
    font-size: 14px
}

.el-time-range-picker__body {
    border-radius: 2px;
    border: 1px solid #e4e7ed
}

.el-color-predefine {
    font-size: 12px;
    margin-top: 8px;
    width: 280px
}

.el-color-predefine,
.el-color-predefine__colors {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.el-color-predefine__colors {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.el-color-predefine__color-selector {
    margin: 0 0 8px 8px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    cursor: pointer
}

.el-color-predefine__color-selector:nth-child(10n+1) {
    margin-left: 0
}

.el-color-predefine__color-selector.selected {
    -webkit-box-shadow: 0 0 3px 2px #409eff;
    box-shadow: 0 0 3px 2px #409eff
}

.el-color-predefine__color-selector>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    border-radius: 3px
}

.el-color-predefine__color-selector.is-alpha {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==)
}

.el-color-svpanel {
    position: relative;
    width: 280px;
    height: 180px
}

.el-color-svpanel__black,
.el-color-svpanel__white {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.el-color-svpanel__white {
    background: -webkit-gradient(linear, left top, right top, from(#fff), to(hsla(0, 0%, 100%, 0)));
    background: linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0))
}

.el-color-svpanel__black {
    background: -webkit-gradient(linear, left bottom, left top, from(#000), to(transparent));
    background: linear-gradient(0deg, #000, transparent)
}

.el-color-svpanel__cursor {
    position: absolute
}

.el-color-svpanel__cursor>div {
    cursor: head;
    width: 4px;
    height: 4px;
    -webkit-box-shadow: 0 0 0 1.5px #fff, inset 0 0 1px 1px rgba(0, 0, 0, .3), 0 0 1px 2px rgba(0, 0, 0, .4);
    box-shadow: 0 0 0 1.5px #fff, inset 0 0 1px 1px rgba(0, 0, 0, .3), 0 0 1px 2px rgba(0, 0, 0, .4);
    border-radius: 50%;
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px)
}

.el-color-dropdown {
    width: 300px
}

.el-color-dropdown__main-wrapper {
    margin-bottom: 6px
}

.el-color-dropdown__main-wrapper:after {
    content: "";
    display: table;
    clear: both
}

.el-color-dropdown__btns {
    margin-top: 6px;
    text-align: right
}

.el-color-dropdown__value {
    float: left;
    line-height: 26px;
    font-size: 12px;
    color: #000;
    width: 160px
}

.el-color-dropdown__btn {
    border: 1px solid #dcdcdc;
    color: #333;
    line-height: 24px;
    border-radius: 2px;
    padding: 0 20px;
    cursor: pointer;
    background-color: transparent;
    outline: 0;
    font-size: 12px
}

.el-color-dropdown__btn[disabled] {
    color: #ccc;
    cursor: not-allowed
}

.el-color-dropdown__btn:hover {
    color: #409eff;
    border-color: #409eff
}

.el-color-dropdown__link-btn {
    cursor: pointer;
    color: #409eff;
    text-decoration: none;
    padding: 15px;
    font-size: 12px
}

.el-color-dropdown__link-btn:hover {
    color: tint(#409eff, 20%)
}

.el-color-picker {
    display: inline-block;
    position: relative;
    line-height: normal;
    height: 40px
}

.el-color-picker.is-disabled .el-color-picker__trigger {
    cursor: not-allowed
}

.el-color-picker--medium {
    height: 36px
}

.el-color-picker--medium .el-color-picker__trigger {
    height: 36px;
    width: 36px
}

.el-color-picker--medium .el-color-picker__mask {
    height: 34px;
    width: 34px
}

.el-color-picker--small {
    height: 32px
}

.el-color-picker--small .el-color-picker__trigger {
    height: 32px;
    width: 32px
}

.el-color-picker--small .el-color-picker__mask {
    height: 30px;
    width: 30px
}

.el-color-picker--small .el-color-picker__empty,
.el-color-picker--small .el-color-picker__icon {
    -webkit-transform: translate3d(-50%, -50%, 0) scale(.8);
    transform: translate3d(-50%, -50%, 0) scale(.8)
}

.el-color-picker--mini {
    height: 28px
}

.el-color-picker--mini .el-color-picker__trigger {
    height: 28px;
    width: 28px
}

.el-color-picker--mini .el-color-picker__mask {
    height: 26px;
    width: 26px
}

.el-color-picker--mini .el-color-picker__empty,
.el-color-picker--mini .el-color-picker__icon {
    -webkit-transform: translate3d(-50%, -50%, 0) scale(.8);
    transform: translate3d(-50%, -50%, 0) scale(.8)
}

.el-color-picker__mask {
    height: 38px;
    width: 38px;
    border-radius: 4px;
    position: absolute;
    top: 1px;
    left: 1px;
    z-index: 1;
    cursor: not-allowed;
    background-color: hsla(0, 0%, 100%, .7)
}

.el-color-picker__trigger {
    display: inline-block;
    height: 40px;
    width: 40px;
    padding: 4px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    font-size: 0;
    cursor: pointer
}

.el-color-picker__color,
.el-color-picker__trigger {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative
}

.el-color-picker__color {
    display: block;
    border: 1px solid #999;
    border-radius: 2px;
    width: 100%;
    height: 100%;
    text-align: center
}

.el-color-picker__color.is-alpha {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==)
}

.el-color-picker__color-inner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0
}

.el-color-picker__empty,
.el-color-picker__icon {
    top: 50%;
    left: 50%;
    font-size: 12px;
    position: absolute
}

.el-color-picker__empty {
    color: #999
}

.el-color-picker__empty,
.el-color-picker__icon {
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0)
}

.el-color-picker__icon {
    display: inline-block;
    width: 100%;
    color: #fff;
    text-align: center
}

.el-color-picker__panel {
    position: absolute;
    z-index: 10;
    padding: 6px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    background-color: #fff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1)
}

.el-card {
    overflow: hidden;
    border-radius: 0 !important;
    border: 1px solid #ebeef5;
    background-color: #fff;
    color: #303133;
    -webkit-transition: .3s;
    transition: .3s
}

.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1)
}

.el-card__header {
    padding: 18px 20px;
    border-bottom: 1px solid #ebeef5;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-card__body {
    padding: 20px
}

[class*=" el-icon-"],
[class^=el-icon-] {
    font-family: gf-icons !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.el-icon-refresh-left:before {
    content: "\e637"
}

.el-icon-refresh-right:before {
    content: "\e628"
}

.el-icon-warning-outline:before {
    content: "\e685"
}

.el-icon-setting:before {
    content: "\e672"
}

.el-icon-phone-outline:before {
    content: "\e688"
}

.el-icon-more-outline:before {
    content: "\e614"
}

.el-icon-finished:before {
    content: "\e656"
}

.el-icon-view:before {
    content: "\e684"
}

.el-icon-loading:before {
    content: "\e661"
}

.el-icon-refresh:before {
    content: "\e632"
}

.el-icon-rank:before {
    content: "\e68a"
}

.el-icon-sort:before {
    content: "\e677"
}

.el-icon-mobile-phone:before {
    content: "\e664"
}

.el-icon-delete:before {
    content: "\e67c"
}

.el-icon-minus:before {
    content: "\e663"
}

.el-icon-plus:before {
    content: "\e669"
}

.el-icon-check:before {
    content: "\e646"
}

.el-icon-close:before {
    content: "\e670"
}

.el-icon-d-arrow-right:before {
    content: "\e64a"
}

.el-icon-d-arrow-left:before {
    content: "\e649"
}

.el-icon-arrow-left:before {
    content: "\e660"
}

.el-icon-arrow-up:before {
    content: "\e67f"
}

.el-icon-arrow-right:before {
    content: "\e65a"
}

.el-icon-arrow-down:before {
    content: "\e641"
}

.el-icon-user:before {
    content: "\e682"
}

.el-icon-unlock:before {
    content: "\e638"
}

.el-icon-lock:before {
    content: "\e621"
}

.el-icon-top:before {
    content: "\e67f"
}

.el-icon-top-right:before {
    content: "\e680"
}

.el-icon-top-left:before {
    content: "\e65e"
}

.el-icon-right:before {
    content: "\e65a"
}

.el-icon-back:before {
    content: "\e660"
}

.el-icon-bottom:before {
    content: "\e641"
}

.el-icon-bottom-right:before {
    content: "\e643"
}

.el-icon-bottom-left:before {
    content: "\e642"
}

.el-icon-trophy-1:before,
.el-icon-trophy:before {
    content: "\e635"
}

.el-icon-star-off:before {
    content: "\e679"
}

.el-icon-copy-document:before {
    content: "\e612"
}

.el-icon-full-screen:before {
    content: "\e68a"
}

.el-icon-crop:before {
    content: "\e692"
}

.el-icon-time:before {
    content: "\e60c"
}

.el-icon-circle-check:before {
    content: "\e67a"
}

.el-icon-remove-outline:before {
    content: "\e655"
}

.el-icon-circle-plus-outline:before {
    content: "\e66b"
}

.el-icon-bell:before {
    content: "\e640"
}

.el-icon-close-notification:before {
    content: "\e691"
}

.el-icon-message:before {
    content: "\e615"
}

.el-icon-chat-line-square:before {
    content: "\e610"
}

.el-icon-chat-dot-round:before,
.el-icon-chat-dot-square:before {
    content: "\e60f"
}

.el-icon-chat-line-round:before,
.el-icon-chat-round:before,
.el-icon-chat-square:before {
    content: "\e610"
}

.el-icon-connection:before,
.el-icon-link:before {
    content: "\e61f"
}

.el-icon-box:before {
    content: "\e67d"
}

.el-icon-present:before {
    content: "\e61c"
}

.el-icon-house:before {
    content: "\e65d"
}

.el-icon-notebook-1:before,
.el-icon-notebook-2:before {
    content: "\e690"
}

.el-icon-files:before {
    content: "\e651"
}

.el-icon-collection:before {
    content: "\e64b"
}

.el-icon-picture-outline-round:before,
.el-icon-picture-outline:before {
    content: "\e61e"
}

.el-icon-film:before {
    content: "\e683"
}

.el-icon-edit-outline:before {
    content: "\e653"
}

.el-icon-collection-tag:before {
    content: "\e64b"
}

.el-icon-pie-chart:before {
    content: "\e68f"
}

.el-icon-reading:before {
    content: "\e68e"
}

.el-icon-scissors:before {
    content: "\e66e"
}

.el-icon-video-camera:before {
    content: "\e683"
}

.el-icon-mobile:before {
    content: "\e664"
}

.el-icon-attract:before {
    content: "\e63f"
}

.el-icon-monitor:before {
    content: "\e665"
}

.el-icon-zoom-out:before {
    content: "\e687"
}

.el-icon-zoom-in:before {
    content: "\e686"
}

.el-icon-search:before {
    content: "\e66f"
}

.el-icon-camera:before {
    content: "\e644"
}

.el-icon-takeaway-box:before {
    content: "\e67d"
}

.el-icon-upload2:before {
    content: "\e662"
}

.el-icon-download:before {
    content: "\e652"
}

.el-icon-paperclip:before {
    content: "\e63e"
}

.el-icon-printer:before {
    content: "\e65f"
}

.el-icon-document-add:before {
    content: "\e64e"
}

.el-icon-document:before {
    content: "\e651"
}

.el-icon-document-checked:before {
    content: "\e64d"
}

.el-icon-document-copy:before {
    content: "\e612"
}

.el-icon-document-delete:before {
    content: "\e64f"
}

.el-icon-document-remove:before {
    content: "\e650"
}

.el-icon-tickets:before {
    content: "\e67e"
}

.el-icon-folder-opened:before {
    content: "\e659"
}

.el-icon-folder:before {
    content: "\e61b"
}

.el-icon-edit:before {
    content: "\e625"
}

.el-icon-circle-close:before {
    content: "\e655"
}

.el-icon-date:before {
    content: "\e64c"
}

.el-icon-caret-top:before {
    content: "\e604"
}

.el-icon-caret-bottom:before {
    content: "\e603"
}

.el-icon-caret-right:before {
    content: "\e68d"
}

.el-icon-caret-left:before {
    content: "\e68c"
}

.el-icon-share:before {
    content: "\e674"
}

.el-icon-more:before {
    content: "\e654"
}

.el-icon-phone:before {
    content: "\e664"
}

.el-icon-star-on:before {
    content: "\e678"
}

.el-icon-menu:before {
    content: "\e65b"
}

.el-icon-message-solid:before {
    content: "\e610"
}

.el-icon-d-caret:before {
    content: "\e645"
}

.el-icon-camera-solid:before {
    content: "\e644"
}

.el-icon-success:before {
    content: "\e67a"
}

.el-icon-error:before {
    content: "\e655"
}

.el-icon-picture:before {
    content: "\e61e"
}

.el-icon-circle-plus:before {
    content: "\e66b"
}

.el-icon-info:before {
    content: "\e689"
}

.el-icon-remove:before {
    content: "\e66d"
}

.el-icon-warning:before {
    content: "\e685"
}

.el-icon-question:before {
    content: "\e66c"
}

.el-icon-user-solid:before {
    content: "\e682"
}

.el-icon-s-grid:before {
    content: "\e65c"
}

.el-icon-s-check:before {
    content: "\e67a"
}

.el-icon-s-fold:before {
    content: "\e658"
}

.el-icon-s-opportunity:before {
    content: "\e68b"
}

.el-icon-s-custom:before {
    content: "\e682"
}

.el-icon-s-tools:before {
    content: "\e63c"
}

.el-icon-s-comment:before {
    content: "\e610"
}

.el-icon-s-flag:before {
    content: "\e657"
}

.el-icon-s-ticket:before {
    content: "\e67e"
}

.el-icon-s-home:before {
    content: "\e65d"
}

.el-icon-s-promotion:before {
    content: "\e671"
}

.el-icon-s-unfold:before {
    content: "\e681"
}

.el-icon-s-platform:before {
    content: "\e665"
}

.el-icon-s-order:before {
    content: "\e651"
}

.el-icon-video-play:before {
    content: "\e668"
}

.el-icon-video-pause:before {
    content: "\e667"
}

.el-icon-upload:before {
    content: "\e662"
}

.el-icon-sort-down:before {
    content: "\e675"
}

.el-icon-sort-up:before {
    content: "\e676"
}

.el-icon-delete-solid:before {
    content: "\e67c"
}

.el-icon-loading {
    -webkit-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite
}

.el-icon--right {
    margin-left: 5px
}

.el-icon--left {
    margin-right: 5px
}

.el-popover {
    background: var(--color-bg);
    border: 1px solid var(--color-border);
    border-radius: 4px;
    max-width: 50%;
    position: absolute;
    min-width: 150px;
    padding: 12px;
    z-index: 2000;
    color: var(--color-8);
    line-height: 1.4;
    text-align: justify;
    font-size: 14px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    word-break: break-all;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1)
}

.el-popover:focus,
.el-popover:focus:active,
.el-popover__reference:focus:hover,
.el-popover__reference:focus:not(.focusing) {
    outline-width: 0
}

.el-popover--plain {
    padding: 18px 20px;
    word-break: break-word
}

.el-popover__title {
    color: var(--color-9);
    font-size: 16px;
    line-height: 1;
    margin-bottom: 12px
}

.el-popper .popper__arrow {
    -webkit-filter: drop-shadow(0 2px 12px rgba(0, 0, 0, .03));
    filter: drop-shadow(0 2px 12px rgba(0, 0, 0, .03))
}

.el-popper .popper__arrow,
.el-popper .popper__arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border: 6px solid transparent
}

.el-popper .popper__arrow:after {
    content: " "
}

.el-popper[x-placement^=top] {
    margin-bottom: 12px
}

.el-popper[x-placement^=top] .popper__arrow {
    bottom: -6px;
    left: 50%;
    margin-right: 3px;
    border-top-color: var(--color-border);
    border-bottom-width: 0
}

.el-popper[x-placement^=top] .popper__arrow:after {
    bottom: 1px;
    margin-left: -6px;
    border-top-color: var(--color-bg);
    border-bottom-width: 0
}

.el-popper[x-placement^=bottom] {
    margin-top: 12px
}

.el-popper[x-placement^=bottom] .popper__arrow {
    top: -6px;
    left: 50%;
    margin-right: 3px;
    border-top-width: 0;
    border-bottom-color: var(--color-border)
}

.el-popper[x-placement^=bottom] .popper__arrow:after {
    top: 1px;
    margin-left: -6px;
    border-top-width: 0;
    border-bottom-color: var(--color-bg)
}

.el-popper[x-placement^=right] {
    margin-left: 12px
}

.el-popper[x-placement^=right] .popper__arrow {
    top: 50%;
    left: -6px;
    margin-bottom: 3px;
    border-right-color: var(--color-border);
    border-left-width: 0
}

.el-popper[x-placement^=right] .popper__arrow:after {
    bottom: -6px;
    left: 1px;
    border-right-color: var(--color-bg);
    border-left-width: 0
}

.el-popper[x-placement^=left] {
    margin-right: 12px
}

.el-popper[x-placement^=left] .popper__arrow {
    top: 50%;
    right: -6px;
    margin-bottom: 3px;
    border-right-width: 0;
    border-left-color: var(--color-border)
}

.el-popper[x-placement^=left] .popper__arrow:after {
    right: 1px;
    bottom: -6px;
    margin-left: -6px;
    border-right-width: 0;
    border-left-color: var(--color-bg)
}

.el-popup-parent--hidden {
    overflow: hidden
}

.el-tooltip:focus:hover,
.el-tooltip:focus:not(.focusing) {
    outline-width: 0
}

.el-tooltip__popper {
    max-width: 50%;
    background: #f6f6f6;
    border: 1px solid #000;
    color: #303133;
    position: absolute;
    border-radius: 4px;
    padding: 10px;
    z-index: 2000;
    font-size: 12px;
    line-height: 1.2;
    min-width: 10px;
    word-wrap: break-word
}

.el-tooltip__popper .popper__arrow {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border: 6px solid transparent
}

.el-tooltip__popper .popper__arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    content: " ";
    border: 5px solid transparent
}

.el-tooltip__popper[x-placement^=top] {
    margin-bottom: 12px
}

.el-tooltip__popper[x-placement^=top] .popper__arrow {
    bottom: -6px;
    border-top-color: #303133;
    border-bottom-width: 0
}

.el-tooltip__popper[x-placement^=top] .popper__arrow:after {
    bottom: 1px;
    margin-left: -5px;
    border-top-color: #303133;
    border-bottom-width: 0
}

.el-tooltip__popper[x-placement^=bottom] {
    margin-top: 12px
}

.el-tooltip__popper[x-placement^=bottom] .popper__arrow {
    top: -6px;
    border-top-width: 0;
    border-bottom-color: #303133
}

.el-tooltip__popper[x-placement^=bottom] .popper__arrow:after {
    top: 1px;
    margin-left: -5px;
    border-top-width: 0;
    border-bottom-color: #303133
}

.el-tooltip__popper[x-placement^=right] {
    margin-left: 12px
}

.el-tooltip__popper[x-placement^=right] .popper__arrow {
    left: -6px;
    border-right-color: #303133;
    border-left-width: 0
}

.el-tooltip__popper[x-placement^=right] .popper__arrow:after {
    bottom: -5px;
    left: 1px;
    border-right-color: #303133;
    border-left-width: 0
}

.el-tooltip__popper[x-placement^=left] {
    margin-right: 12px
}

.el-tooltip__popper[x-placement^=left] .popper__arrow {
    right: -6px;
    border-right-width: 0;
    border-left-color: #303133
}

.el-tooltip__popper[x-placement^=left] .popper__arrow:after {
    right: 1px;
    bottom: -5px;
    margin-left: -5px;
    border-right-width: 0;
    border-left-color: #303133
}

.el-tooltip__popper.is-dark {
    background: #303133;
    color: #fff
}

.el-tooltip__popper.is-light {
    background: #fff;
    border: 1px solid #303133
}

.el-tooltip__popper.is-light[x-placement^=top] .popper__arrow {
    border-top-color: #303133
}

.el-tooltip__popper.is-light[x-placement^=top] .popper__arrow:after {
    border-top-color: #fff
}

.el-tooltip__popper.is-light[x-placement^=bottom] .popper__arrow {
    border-bottom-color: #303133
}

.el-tooltip__popper.is-light[x-placement^=bottom] .popper__arrow:after {
    border-bottom-color: #fff
}

.el-tooltip__popper.is-light[x-placement^=left] .popper__arrow {
    border-left-color: #303133
}

.el-tooltip__popper.is-light[x-placement^=left] .popper__arrow:after {
    border-left-color: #fff
}

.el-tooltip__popper.is-light[x-placement^=right] .popper__arrow {
    border-right-color: #303133
}

.el-tooltip__popper.is-light[x-placement^=right] .popper__arrow:after {
    border-right-color: #fff
}

.el-progress-bar__inner:after {
    content: ""
}

.el-progress {
    position: relative;
    line-height: 1
}

.el-progress__text {
    font-size: 14px;
    color: #606266;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    line-height: 1
}

.el-progress__text i {
    vertical-align: middle;
    display: block
}

.el-progress--circle {
    display: inline-block
}

.el-progress--circle .el-progress__text {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.el-progress--circle .el-progress__text i {
    vertical-align: middle;
    display: inline-block
}

.el-progress--without-text .el-progress__text {
    display: none
}

.el-progress--without-text .el-progress-bar {
    padding-right: 0;
    margin-right: 0;
    display: block
}

.el-progress-bar,
.el-progress-bar__inner:after,
.el-progress-bar__innerText,
.el-spinner {
    display: inline-block;
    vertical-align: middle
}

.el-progress--text-inside .el-progress-bar {
    padding-right: 0;
    margin-right: 0
}

.el-progress.is-success .el-progress-bar__inner {
    background-color: #67c23a
}

.el-progress.is-success .el-progress__text {
    color: #67c23a
}

.el-progress.is-exception .el-progress-bar__inner {
    background-color: #f56c6c
}

.el-progress.is-exception .el-progress__text {
    color: #f56c6c
}

.el-progress-bar {
    padding-right: 50px;
    width: 100%;
    margin-right: -55px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-progress-bar__outer {
    height: 6px;
    border-radius: 100px;
    background-color: #ebeef5;
    overflow: hidden;
    position: relative;
    vertical-align: middle
}

.el-progress-bar__inner {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #409eff;
    text-align: right;
    border-radius: 100px;
    line-height: 1;
    white-space: nowrap;
    -webkit-transition: width .6s ease;
    transition: width .6s ease
}

.el-progress-bar__inner:after {
    height: 100%
}

.el-progress-bar__innerText {
    color: #fff;
    font-size: 12px;
    margin: 0 5px
}

.el-input__suffix,
.el-tree.is-dragging .el-tree-node__content * {
    pointer-events: none
}

.el-input__inner {
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-appearance: none;
    background-color: var(--color-input-bg);
    background-image: none;
    border-radius: 4px;
    border: 1px solid var(--color-border);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: var(--color-8);
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%
}

.el-input-number {
    position: relative;
    display: inline-block;
    width: 180px;
    line-height: 38px
}

.el-input-number .el-input {
    display: block
}

.el-input-number .el-input__inner {
    -webkit-appearance: none;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center
}

.el-input-number__decrease,
.el-input-number__increase {
    position: absolute;
    z-index: 1;
    top: 1px;
    width: 40px;
    height: auto;
    text-align: center;
    background: #f5f7fa;
    color: #606266;
    cursor: pointer;
    font-size: 13px
}

.el-input-number__decrease:hover,
.el-input-number__increase:hover {
    color: #409eff
}

.el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled),
.el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
    border-color: #409eff
}

.el-input-number__decrease.is-disabled {
    color: #c0c4cc;
    cursor: not-allowed
}

.el-input-number__increase {
    right: 1px;
    border-radius: 0 4px 4px 0;
    border-left: 1px solid #dcdfe6
}

.el-input-number__increase.is-disabled {
    color: #c0c4cc;
    cursor: not-allowed
}

.el-input-number__decrease {
    left: 1px;
    border-radius: 4px 0 0 4px;
    border-right: 1px solid #dcdfe6
}

.el-input-number.is-disabled .el-input-number__decrease,
.el-input-number.is-disabled .el-input-number__increase {
    border-color: #e4e7ed;
    color: #e4e7ed
}

.el-input-number.is-disabled .el-input-number__decrease:hover,
.el-input-number.is-disabled .el-input-number__increase:hover {
    color: #e4e7ed;
    cursor: not-allowed
}

.el-input-number--medium {
    width: 200px;
    line-height: 34px
}

.el-input-number--medium .el-input-number__decrease,
.el-input-number--medium .el-input-number__increase {
    width: 36px;
    font-size: 14px
}

.el-input-number--medium .el-input__inner {
    padding-left: 43px;
    padding-right: 43px
}

.el-input-number--small {
    width: 130px;
    line-height: 30px
}

.el-input-number--small .el-input-number__decrease,
.el-input-number--small .el-input-number__increase {
    width: 32px;
    font-size: 13px
}

.el-input-number--small .el-input-number__decrease [class*=el-icon],
.el-input-number--small .el-input-number__increase [class*=el-icon] {
    -webkit-transform: scale(.9);
    transform: scale(.9)
}

.el-input-number--small .el-input__inner {
    padding-left: 39px;
    padding-right: 39px
}

.el-input-number--mini {
    width: 130px;
    line-height: 26px
}

.el-input-number--mini .el-input-number__decrease,
.el-input-number--mini .el-input-number__increase {
    width: 28px;
    font-size: 12px
}

.el-input-number--mini .el-input-number__decrease [class*=el-icon],
.el-input-number--mini .el-input-number__increase [class*=el-icon] {
    -webkit-transform: scale(.8);
    transform: scale(.8)
}

.el-input-number--mini .el-input__inner {
    padding-left: 35px;
    padding-right: 35px
}

.el-input-number.is-without-controls .el-input__inner {
    padding-left: 15px;
    padding-right: 15px
}

.el-input-number.is-controls-right .el-input__inner {
    padding-left: 15px;
    padding-right: 50px
}

.el-input-number.is-controls-right .el-input-number__decrease,
.el-input-number.is-controls-right .el-input-number__increase {
    height: auto;
    line-height: 19px
}

.el-input-number.is-controls-right .el-input-number__decrease [class*=el-icon] {
    -webkit-transform: scale(.8);
    transform: scale(.8)
}

.el-input-number.is-controls-right .el-input-number__increase {
    border-radius: 0 4px 0 0;
    border-bottom: 1px solid #dcdfe6
}

.el-input-number.is-controls-right .el-input-number__increase [class*=el-icon] {
    -webkit-transform: scale(.8);
    transform: scale(.8)
}

.el-input-number.is-controls-right .el-input-number__decrease {
    right: 1px;
    bottom: 1px;
    top: auto;
    left: auto;
    border-right: none;
    border-left: 1px solid #dcdfe6;
    border-radius: 0 0 4px
}

.el-input-number.is-controls-right[class*=medium] [class*=decrease],
.el-input-number.is-controls-right[class*=medium] [class*=increase] {
    line-height: 17px
}

.el-input-number.is-controls-right[class*=small] [class*=decrease],
.el-input-number.is-controls-right[class*=small] [class*=increase] {
    line-height: 15px
}

.el-input-number.is-controls-right[class*=mini] [class*=decrease],
.el-input-number.is-controls-right[class*=mini] [class*=increase] {
    line-height: 13px
}

.el-textarea {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: bottom;
    font-size: 14px
}

.el-textarea__inner {
    display: block;
    font-size: 12px;
    resize: vertical;
    padding: 5px;
    line-height: 1.5;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    font-size: inherit;
    color: var(--color-8);
    background-color: var(--color-bg);
    background-image: none;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1)
}

.el-textarea__inner:-ms-input-placeholder,
.el-textarea__inner::-moz-placeholder,
.el-textarea__inner::-ms-input-placeholder,
.el-textarea__inner::-webkit-input-placeholder,
.el-textarea__inner::placeholder {
    color: #c0c4cc
}

.el-textarea__inner:hover {
    border-color: #c0c4cc
}

.el-textarea__inner:focus {
    outline: 0;
    border-color: #409eff
}

.el-textarea .el-input__count {
    color: #909399;
    background: #fff;
    position: absolute;
    font-size: 12px;
    bottom: 5px;
    right: 10px
}

.el-textarea.is-disabled .el-textarea__inner {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed
}

.el-textarea.is-disabled .el-textarea__inner:-ms-input-placeholder,
.el-textarea.is-disabled .el-textarea__inner::-moz-placeholder,
.el-textarea.is-disabled .el-textarea__inner::-ms-input-placeholder,
.el-textarea.is-disabled .el-textarea__inner::-webkit-input-placeholder,
.el-textarea.is-disabled .el-textarea__inner::placeholder {
    color: #c0c4cc
}

.el-textarea.is-exceed .el-textarea__inner {
    border-color: #f56c6c
}

.el-textarea.is-exceed .el-input__count {
    color: #f56c6c
}

.el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 100%
}

.el-input::-webkit-scrollbar {
    z-index: 11;
    width: 6px
}

.el-button-group>.el-button.is-active,
.el-button-group>.el-button.is-disabled,
.el-button-group>.el-button:active,
.el-button-group>.el-button:focus,
.el-button-group>.el-button:hover {
    z-index: 1
}

.el-input::-webkit-scrollbar:horizontal {
    height: 6px
}

.el-input::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 6px;
    background: #b4bccc
}

.el-input::-webkit-scrollbar-corner,
.el-input::-webkit-scrollbar-track {
    background: #fff
}

.el-input::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 6px
}

.el-input .el-input__clear {
    color: #c0c4cc;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    -webkit-transition: color .2s cubic-bezier(.645, .045, .355, 1);
    transition: color .2s cubic-bezier(.645, .045, .355, 1)
}

.el-input .el-input__clear:hover {
    color: #909399
}

.el-input .el-input__count {
    height: 100%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #909399;
    font-size: 12px
}

.el-input .el-input__count .el-input__count-inner {
    background: #fff;
    display: inline-block;
    padding: 0 5px
}

.el-input__prefix,
.el-input__suffix {
    position: absolute;
    top: 0;
    -webkit-transition: all .3s;
    height: 100%;
    color: #c0c4cc;
    text-align: center
}

.el-input__inner:-ms-input-placeholder,
.el-input__inner::-moz-placeholder,
.el-input__inner::-ms-input-placeholder,
.el-input__inner::-webkit-input-placeholder,
.el-input__inner::placeholder {
    color: #c0c4cc
}

.el-input__inner:hover {
    border-color: #c0c4cc
}

.el-input.is-active .el-input__inner,
.el-input__inner:focus {
    border-color: #409eff;
    outline: 0
}

.el-input__suffix {
    right: 5px;
    transition: all .3s
}

.el-input__suffix-inner {
    pointer-events: all
}

.el-input__prefix {
    left: 5px;
    transition: all .3s
}

.el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    -webkit-transition: all .3s;
    transition: all .3s;
    line-height: 40px
}

.el-input__icon:after {
    content: "";
    height: 100%;
    width: 0;
    display: inline-block;
    vertical-align: middle
}

.el-input__validateIcon {
    pointer-events: none
}

.el-input.is-disabled .el-input__inner {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed
}

.el-input.is-disabled .el-input__inner:-ms-input-placeholder,
.el-input.is-disabled .el-input__inner::-moz-placeholder,
.el-input.is-disabled .el-input__inner::-ms-input-placeholder,
.el-input.is-disabled .el-input__inner::-webkit-input-placeholder,
.el-input.is-disabled .el-input__inner::placeholder {
    color: #c0c4cc
}

.el-input.is-disabled .el-input__icon {
    cursor: not-allowed
}

.el-input.is-exceed .el-input__inner {
    border-color: #f56c6c
}

.el-input.is-exceed .el-input__suffix .el-input__count {
    color: #f56c6c
}

.el-input--suffix .el-input__inner {
    padding-right: 30px
}

.el-input--prefix .el-input__inner {
    padding-left: 30px
}

.el-input-group {
    line-height: normal;
    display: inline-table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0
}

.el-input-group>.el-input__inner {
    vertical-align: middle;
    display: table-cell
}

.el-input-group__append,
.el-input-group__prepend {
    background-color: var(--color-box-bg);
    color: #909399;
    vertical-align: middle;
    display: table-cell;
    position: relative;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    padding: 0 20px;
    width: 1px;
    white-space: nowrap
}

.el-input-group--prepend .el-input__inner,
.el-input-group__append {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.el-input-group--append .el-input__inner,
.el-input-group__prepend {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.el-input-group__append:focus,
.el-input-group__prepend:focus {
    outline: 0
}

.el-input-group__append .el-button,
.el-input-group__append .el-select,
.el-input-group__prepend .el-button,
.el-input-group__prepend .el-select {
    display: inline-block;
    margin: -10px -20px
}

.el-input-group__append button.el-button,
.el-input-group__append div.el-select .el-input__inner,
.el-input-group__append div.el-select:hover .el-input__inner,
.el-input-group__prepend button.el-button,
.el-input-group__prepend div.el-select .el-input__inner,
.el-input-group__prepend div.el-select:hover .el-input__inner {
    border-color: transparent;
    background-color: transparent;
    color: inherit;
    border-top: 0;
    border-bottom: 0
}

.el-input-group__append .el-button,
.el-input-group__append .el-input {
    font-size: inherit
}

.el-input-group__prepend {
    border-right: 0
}

.el-input-group__prepend .el-button,
.el-input-group__prepend .el-input {
    font-size: inherit
}

.el-input-group__append {
    border-left: 0
}

.el-input-group--append .el-select .el-input.is-focus .el-input__inner,
.el-input-group--prepend .el-select .el-input.is-focus .el-input__inner {
    border-color: transparent
}

.el-input__inner::-ms-clear {
    display: none;
    width: 0;
    height: 0
}

.el-input--medium {
    font-size: 12px
}

.el-input--medium .el-input__inner {
    height: 28px;
    line-height: 28px
}

.el-input--medium .el-input__icon {
    line-height: 28px
}

.el-input--small {
    font-size: 12px
}

.el-input--small .el-input__inner {
    height: 28px;
    line-height: 28px
}

.el-input--small .el-input__icon {
    line-height: 28px
}

.el-input--small .el-input__suffix {
    right: 0
}

.el-input--small .el-input__icon {
    line-height: 28px !important
}

.el-input--small .el-select__input {
    height: 28px !important;
    line-height: 28px
}

.el-input--small .el-button {
    height: 28px !important;
    padding: 3px 5px !important
}

.el-input--small .el-select-dropdown__item {
    height: 20px !important;
    line-height: 20px !important
}

.el-input--small .el-input-group__append {
    padding: 0 6px 0 20px
}

.el-input--small .el-tag {
    height: 19px;
    line-height: 18px;
    font-size: 11px;
    margin-right: 1px;
    margin-bottom: 1px
}

.el-input--small .el-select__caret {
    line-height: 12px;
    font-size: 12px !important;
    margin-right: -6px
}

.el-input--small .keyword-input {
    line-height: 20px;
    height: 20px
}

.el-input--mini {
    font-size: 10px
}

.el-input--mini .el-input__inner {
    height: 20px;
    line-height: 20px
}

.el-input--mini .el-input__icon {
    line-height: 20px
}

.el-input-no-radius .el-input__inner {
    border-radius: 0
}

.el-input-no-border .el-input__inner {
    border: none;
    background: 0 0
}

.el-radio {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none
}

.el-radio__inner:after,
.el-switch__core:after {
    content: ""
}

.el-radio,
.el-radio__inner,
.el-radio__input {
    position: relative;
    display: inline-block
}

.el-radio,
.el-radio--medium.is-bordered .el-radio__label {
    font-size: 14px
}

.el-radio {
    color: var(--color-8);
    font-weight: 500;
    line-height: 1;
    cursor: pointer;
    white-space: nowrap;
    outline: 0;
    margin-right: 30px
}

.el-radio.is-bordered {
    padding: 12px 20px 0 10px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 40px
}

.el-radio.is-bordered.is-checked {
    border-color: #409eff
}

.el-radio.is-bordered.is-disabled {
    cursor: not-allowed;
    border-color: #ebeef5
}

.el-radio__input.is-disabled .el-radio__inner,
.el-radio__input.is-disabled.is-checked .el-radio__inner {
    background-color: #f5f7fa;
    border-color: #e4e7ed
}

.el-radio.is-bordered+.el-radio.is-bordered {
    margin-left: 10px
}

.el-radio--medium.is-bordered {
    padding: 10px 20px 0 10px;
    border-radius: 4px;
    height: 36px
}

.el-radio--mini.is-bordered .el-radio__label,
.el-radio--small.is-bordered .el-radio__label {
    font-size: 12px
}

.el-radio--medium.is-bordered .el-radio__inner {
    height: 14px;
    width: 14px
}

.el-radio--small.is-bordered {
    padding: 8px 15px 0 10px;
    border-radius: 3px;
    height: 32px
}

.el-radio--small.is-bordered .el-radio__inner {
    height: 12px;
    width: 12px
}

.el-radio--mini.is-bordered {
    padding: 6px 15px 0 10px;
    border-radius: 3px;
    height: 28px
}

.el-radio--mini.is-bordered .el-radio__inner {
    height: 12px;
    width: 12px
}

.el-radio:last-child {
    margin-right: 0
}

.el-radio__input {
    white-space: nowrap;
    cursor: pointer;
    outline: 0;
    line-height: 1;
    vertical-align: middle
}

.el-radio__input.is-disabled .el-radio__inner {
    cursor: not-allowed
}

.el-radio__input.is-disabled .el-radio__inner:after {
    cursor: not-allowed;
    background-color: #f5f7fa
}

.el-radio__input.is-disabled .el-radio__inner+.el-radio__label {
    cursor: not-allowed
}

.el-radio__input.is-disabled.is-checked .el-radio__inner:after {
    background-color: #c0c4cc
}

.el-radio__input.is-disabled+span.el-radio__label {
    color: #c0c4cc;
    cursor: not-allowed
}

.el-radio__input.is-checked .el-radio__inner {
    border-color: #409eff;
    background: #409eff
}

.el-radio__input.is-checked .el-radio__inner:after {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
}

.el-radio__input.is-checked+.el-radio__label {
    color: #409eff
}

.el-radio__input.is-focus .el-radio__inner {
    border-color: #409eff
}

.el-radio__inner {
    border: 1px solid #dcdfe6;
    border-radius: 100%;
    width: 14px;
    height: 14px;
    background-color: var(--color-bg);
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-radio__inner:hover {
    border-color: #409eff
}

.el-radio__inner:after {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    -webkit-transition: -webkit-transform .15s ease-in;
    transition: -webkit-transform .15s ease-in;
    transition: transform .15s ease-in;
    transition: transform .15s ease-in, -webkit-transform .15s ease-in
}

.el-radio__original {
    opacity: 0;
    outline: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0
}

.el-radio-button,
.el-radio-button__inner {
    display: inline-block;
    position: relative;
    outline: 0
}

.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
    -webkit-box-shadow: 0 0 2px 2px #409eff;
    box-shadow: 0 0 2px 2px #409eff
}

.el-radio__label {
    font-size: 14px;
    padding-left: 2px
}

.el-radio-group {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    font-size: 0
}

.el-radio-button__inner {
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    background: #fff;
    border: 1px solid #dcdfe6;
    font-weight: 500;
    border-left: 0;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    cursor: pointer;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 0
}

.el-radio-button__inner.is-round {
    padding: 12px 20px
}

.el-radio-button__inner:hover {
    color: #409eff
}

.el-radio-button__inner [class*=el-icon-] {
    line-height: .9
}

.el-radio-button__inner [class*=el-icon-]+span {
    margin-left: 5px
}

.el-radio-button:first-child .el-radio-button__inner {
    border-left: 1px solid #dcdfe6;
    border-radius: 4px 0 0 4px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.el-radio-button__orig-radio {
    opacity: 0;
    outline: 0;
    position: absolute;
    z-index: -1
}

.el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    -webkit-box-shadow: -1px 0 0 0 #409eff;
    box-shadow: -1px 0 0 0 #409eff
}

.el-radio-button__orig-radio:disabled+.el-radio-button__inner {
    color: #c0c4cc;
    cursor: not-allowed;
    background-image: none;
    background-color: #fff;
    border-color: #ebeef5;
    -webkit-box-shadow: none;
    box-shadow: none
}

.el-radio-button__orig-radio:disabled:checked+.el-radio-button__inner {
    background-color: #f2f6fc
}

.el-radio-button:last-child .el-radio-button__inner {
    border-radius: 0 4px 4px 0
}

.el-radio-button:first-child:last-child .el-radio-button__inner {
    border-radius: 4px
}

.el-radio-button--medium .el-radio-button__inner {
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 0
}

.el-radio-button--medium .el-radio-button__inner.is-round {
    padding: 10px 20px
}

.el-radio-button--small .el-radio-button__inner {
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 0
}

.el-radio-button--small .el-radio-button__inner.is-round {
    padding: 9px 15px
}

.el-radio-button--mini .el-radio-button__inner {
    padding: 7px 15px;
    font-size: 12px;
    border-radius: 0
}

.el-radio-button--mini .el-radio-button__inner.is-round {
    padding: 7px 15px
}

.el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    -webkit-box-shadow: 0 0 2px 2px #409eff;
    box-shadow: 0 0 2px 2px #409eff
}

.el-checkbox {
    position: relative;
    display: inline-block;
    font-weight: 500;
    color: var(--color-9);
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    white-space: nowrap;
    cursor: pointer;
    user-select: none
}

.el-checkbox.is-bordered {
    padding: 9px 20px 9px 10px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: normal;
    height: 40px
}

.el-checkbox.is-bordered.is-checked {
    border-color: #195daa
}

.el-checkbox.is-bordered.is-disabled {
    border-color: #ebeef5;
    cursor: not-allowed
}

.el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 10px
}

.el-checkbox.is-bordered.el-checkbox--medium {
    padding: 7px 20px 7px 10px;
    border-radius: 4px;
    height: 36px
}

.el-checkbox.is-bordered.el-checkbox--medium .el-checkbox__label {
    line-height: 17px;
    font-size: 14px
}

.el-checkbox.is-bordered.el-checkbox--medium .el-checkbox__inner {
    height: 14px;
    width: 14px
}

.el-checkbox.is-bordered.el-checkbox--small {
    padding: 5px 15px 5px 10px;
    border-radius: 3px;
    height: 32px
}

.el-checkbox.is-bordered.el-checkbox--small .el-checkbox__label {
    line-height: 15px;
    font-size: 12px
}

.el-checkbox.is-bordered.el-checkbox--small .el-checkbox__inner {
    height: 12px;
    width: 12px
}

.el-checkbox.is-bordered.el-checkbox--small .el-checkbox__inner:after {
    height: 6px;
    width: 2px
}

.el-checkbox.is-bordered.el-checkbox--mini {
    padding: 3px 15px 3px 10px;
    border-radius: 3px;
    height: 28px
}

.el-checkbox.is-bordered.el-checkbox--mini .el-checkbox__label {
    line-height: 12px;
    font-size: 12px
}

.el-checkbox.is-bordered.el-checkbox--mini .el-checkbox__inner {
    height: 12px;
    width: 12px
}

.el-checkbox.is-bordered.el-checkbox--mini .el-checkbox__inner:after {
    height: 6px;
    width: 2px
}

.el-checkbox__input {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    outline: 0;
    line-height: 1;
    vertical-align: middle
}

.el-checkbox__input.is-disabled .el-checkbox__inner {
    background-color: #edf2fc;
    border-color: #dcdfe6;
    cursor: not-allowed
}

.el-checkbox__input.is-disabled .el-checkbox__inner:after {
    cursor: not-allowed;
    border-color: #c0c4cc
}

.el-checkbox__input.is-disabled .el-checkbox__inner+.el-checkbox__label {
    cursor: not-allowed
}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #f2f6fc;
    border-color: #dcdfe6
}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner:after {
    border-color: #c0c4cc
}

.el-checkbox__input.is-disabled.is-indeterminate .el-checkbox__inner {
    background-color: #f2f6fc;
    border-color: #dcdfe6
}

.el-checkbox__input.is-disabled.is-indeterminate .el-checkbox__inner:before {
    background-color: #c0c4cc;
    border-color: #c0c4cc
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #195daa;
    border-color: #195daa
}

.el-checkbox__input.is-disabled+span.el-checkbox__label {
    color: #c0c4cc;
    cursor: not-allowed
}

.el-checkbox__input.is-checked .el-checkbox__inner:after {
    -webkit-transform: rotate(45deg) scaleY(1);
    transform: rotate(45deg) scaleY(1)
}

.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #195daa
}

.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #195daa
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner:before {
    content: "";
    position: absolute;
    display: block;
    background-color: #fff;
    height: 2px;
    -webkit-transform: scale(.5);
    transform: scale(.5);
    left: 0;
    right: 0;
    top: 5px
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner:after {
    display: none
}

.el-checkbox__inner {
    display: inline-block;
    position: relative;
    border: 1px solid var(--color-input-border);
    border-radius: 2px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    background-color: var(--color-bg);
    z-index: 0;
    -webkit-transition: border-color .25s cubic-bezier(.71, -.46, .29, 1.46), background-color .25s cubic-bezier(.71, -.46, .29, 1.46);
    transition: border-color .25s cubic-bezier(.71, -.46, .29, 1.46), background-color .25s cubic-bezier(.71, -.46, .29, 1.46)
}

.el-checkbox__inner:hover {
    border-color: #195daa
}

.el-checkbox__inner:after {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 4px;
    position: absolute;
    top: 1px;
    -webkit-transform: rotate(45deg) scaleY(0);
    transform: rotate(45deg) scaleY(0);
    width: 3px;
    -webkit-transition: -webkit-transform .15s ease-in .05s;
    transition: -webkit-transform .15s ease-in .05s;
    transition: transform .15s ease-in .05s;
    transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
    -webkit-transform-origin: center;
    transform-origin: center
}

.el-checkbox-button {
    position: relative;
    display: inline-block
}

.el-checkbox-button.is-checked .el-checkbox-button__inner {
    color: #fff;
    background-color: #195daa;
    border-color: #195daa;
    -webkit-box-shadow: -1px 0 0 0 #8cc5ff;
    box-shadow: -1px 0 0 0 #8cc5ff
}

.el-checkbox-button.is-checked:first-child .el-checkbox-button__inner {
    border-left-color: #195daa
}

.el-checkbox-button.is-disabled .el-checkbox-button__inner {
    color: #c0c4cc;
    cursor: not-allowed;
    background-image: none;
    background-color: #fff;
    border-color: #ebeef5;
    -webkit-box-shadow: none;
    box-shadow: none
}

.el-checkbox-button.is-disabled:first-child .el-checkbox-button__inner {
    border-left-color: #ebeef5
}

.el-checkbox-button:first-child .el-checkbox-button__inner {
    border-left: 1px solid #dcdfe6;
    border-radius: 4px 0 0 4px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.el-checkbox-button.is-focus .el-checkbox-button__inner {
    border-color: #195daa
}

.el-checkbox-button:last-child .el-checkbox-button__inner {
    border-radius: 0 4px 4px 0
}

.el-checkbox-button__inner {
    font-weight: 500;
    color: #606266;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    white-space: nowrap;
    position: relative;
    display: inline-block;
    line-height: 1;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    border-left: 0;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 0
}

.el-checkbox-button__inner.is-round {
    padding: 12px 20px
}

.el-checkbox-button__inner:hover {
    color: #195daa
}

.el-checkbox-button__inner [class*=el-icon-] {
    line-height: .9
}

.el-checkbox-button__inner [class*=el-icon-]+span {
    margin-left: 5px
}

.el-checkbox__original {
    opacity: 0;
    outline: 0;
    position: absolute;
    margin: 0;
    width: 0;
    height: 0;
    z-index: -1
}

.el-checkbox__label {
    display: inline-block;
    padding-left: 10px;
    line-height: 19px
}

.el-checkbox-button__original {
    opacity: 0;
    outline: 0;
    position: absolute;
    margin: 0;
    z-index: -1
}

.el-checkbox-button--medium .el-checkbox-button__inner {
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 0
}

.el-checkbox-button--medium .el-checkbox-button__inner.is-round {
    padding: 10px 20px
}

.el-checkbox-button--small .el-checkbox-button__inner {
    margin-left: 1px;
    margin-right: 1px;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 0
}

.el-checkbox-button--small .el-checkbox-button__inner.is-round {
    padding: 9px 15px
}

.el-checkbox-button--mini .el-checkbox-button__inner {
    margin-left: 1px;
    margin-right: 1px;
    vertical-align: sub;
    padding: 3px;
    font-size: 11px;
    border-radius: 0
}

.el-checkbox-button--mini .el-checkbox-button__inner.is-round {
    padding: 7px 15px
}

.el-checkbox-button--mini .el-checkbox__input {
    vertical-align: top
}

.el-checkbox-button--mini .el-checkbox__label {
    vertical-align: top;
    padding-left: 4px
}

.el-checkbox-group {
    font-size: 0
}

@keyframes rotating {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@-webkit-keyframes rotating {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes v-modal-in {
    0% {
        opacity: 0
    }
}

@-webkit-keyframes v-modal-in {
    0% {
        opacity: 0
    }
}

@keyframes v-modal-out {
    to {
        opacity: 0
    }
}

@-webkit-keyframes v-modal-out {
    to {
        opacity: 0
    }
}

@keyframes dialog-fade-in {
    0% {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
        opacity: 0
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@-webkit-keyframes dialog-fade-in {
    0% {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
        opacity: 0
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes dialog-fade-out {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
        opacity: 0
    }
}

@-webkit-keyframes dialog-fade-out {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
        opacity: 0
    }
}

@keyframes msgbox-fade-in {
    0% {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
        opacity: 0
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@-webkit-keyframes msgbox-fade-in {
    0% {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
        opacity: 0
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes msgbox-fade-out {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
        opacity: 0
    }
}

@-webkit-keyframes msgbox-fade-out {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
        opacity: 0
    }
}

@-webkit-keyframes slideInRight-enter {
    0% {
        opacity: 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }

    to {
        opacity: 1;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes slideInRight-enter {
    0% {
        opacity: 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }

    to {
        opacity: 1;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes slideInRight-leave {
    0% {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }

    to {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0
    }
}

@keyframes slideInRight-leave {
    0% {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }

    to {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0
    }
}

@-webkit-keyframes slideInLeft-enter {
    0% {
        opacity: 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }

    to {
        opacity: 1;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes slideInLeft-enter {
    0% {
        opacity: 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }

    to {
        opacity: 1;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes slideInLeft-leave {
    0% {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }

    to {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0
    }
}

@keyframes slideInLeft-leave {
    0% {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }

    to {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0
    }
}

@-webkit-keyframes loading-rotate {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes loading-rotate {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@-webkit-keyframes loading-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -40px
    }

    to {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -120px
    }
}

@keyframes loading-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -40px
    }

    to {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -120px
    }
}

@-webkit-keyframes progress {
    0% {
        background-position: 0 0
    }

    to {
        background-position: 32px 0
    }
}

@keyframes progress {
    0% {
        background-position: 0 0
    }

    to {
        background-position: 32px 0
    }
}

.el-rate {
    display: inline-block;
    height: 15px;
    line-height: 15px
}

.el-rate:active,
.el-rate:focus {
    outline-width: 0
}

.el-rate__icon {
    position: relative;
    display: inline-block;
    color: #c0c4cc;
    -webkit-transition: .3s;
    transition: .3s;
    font-size: 13px;
    line-height: 15px;
    margin-right: 3px
}

.el-rate__icon .path2 {
    position: absolute;
    top: 0;
    left: 0
}

.el-rate__icon.hover {
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
}

.el-rate__item {
    position: relative;
    display: inline-block;
    font-size: 0;
    vertical-align: middle
}

.el-rate__decimal {
    font-size: 13px;
    line-height: 15px;
    margin-right: 3px;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    overflow: hidden
}

.el-rate__text {
    vertical-align: middle;
    font-size: 12px;
    line-height: 15px
}

.el-collapse {
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border)
}

.el-collapse-no-arrow .el-collapse-item__arrow {
    display: none
}

.el-collapse-align-center .el-collapse-item__header {
    text-align: center
}

.el-collapse-align-center .el-collapse-item__arrow {
    display: none
}

.el-collapse-item.is-disabled {
    color: #bbb;
    cursor: not-allowed
}

.el-collapse-item__header {
    height: 30px;
    line-height: 30px;
    cursor: not-allowed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--color-bg);
    color: var(--color-9);
    cursor: pointer;
    border-bottom: 1px solid var(--color-border);
    font-size: 13px;
    font-weight: 500;
    -webkit-transition: border-bottom-color .3s;
    transition: border-bottom-color .3s;
    outline: 0
}

.el-collapse-item__header.focusing:focus:not(:hover) {
    color: #409eff
}

.el-collapse-item__header.is-active {
    border-bottom-color: transparent
}

.el-collapse-item__arrow {
    line-height: 30px;
    margin: 0 8px 0 auto;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    font-weight: 300
}

.el-collapse-item__arrow.is-active {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.el-collapse-item__wrap {
    will-change: height;
    background-color: var(--color-bg);
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid var(--color-border)
}

.el-collapse-item__content {
    font-size: 13px;
    color: #303133;
    line-height: 1.769230769230769
}

.el-collapse-item:last-child {
    margin-bottom: -1px
}

.horizontal-collapse-transition .el-submenu__title .el-submenu__icon-arrow {
    -webkit-transition: .2s;
    transition: .2s;
    opacity: 0
}

.el-row:after,
.el-row:before {
    content: ""
}

.el-col-pull-0,
.el-col-pull-1,
.el-col-pull-2,
.el-col-pull-3,
.el-col-pull-4,
.el-col-pull-5,
.el-col-pull-6,
.el-col-pull-7,
.el-col-pull-8,
.el-col-pull-9,
.el-col-pull-10,
.el-col-pull-11,
.el-col-pull-13,
.el-col-pull-14,
.el-col-pull-15,
.el-col-pull-16,
.el-col-pull-17,
.el-col-pull-18,
.el-col-pull-19,
.el-col-pull-20,
.el-col-pull-21,
.el-col-pull-22,
.el-col-pull-23,
.el-col-pull-24,
.el-col-push-0,
.el-col-push-1,
.el-col-push-2,
.el-col-push-3,
.el-col-push-4,
.el-col-push-5,
.el-col-push-6,
.el-col-push-7,
.el-col-push-8,
.el-col-push-9,
.el-col-push-10,
.el-col-push-11,
.el-col-push-12,
.el-col-push-13,
.el-col-push-14,
.el-col-push-15,
.el-col-push-16,
.el-col-push-17,
.el-col-push-18,
.el-col-push-19,
.el-col-push-20,
.el-col-push-21,
.el-col-push-22,
.el-col-push-23,
.el-col-push-24,
.el-row {
    position: relative
}

.el-row {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-row:after,
.el-row:before {
    display: table
}

.el-row:after {
    clear: both
}

.el-row--flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.el-col-0,
.el-row--flex:after,
.el-row--flex:before {
    display: none
}

.el-row--flex.is-justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.el-row--flex.is-justify-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.el-row--flex.is-justify-space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.el-row--flex.is-justify-space-around {
    -ms-flex-pack: distribute;
    justify-content: space-around
}

.el-row--flex.is-align-middle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.el-row--flex.is-align-bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.el-col,
[class*=el-col-] {
    float: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-col-offset-0 {
    margin-left: 0
}

.el-col-pull-0 {
    right: 0
}

.el-col-push-0 {
    left: 0
}

.el-col-1 {
    width: 4.16667%
}

.el-col-offset-1 {
    margin-left: 4.16667%
}

.el-col-pull-1 {
    right: 4.16667%
}

.el-col-push-1 {
    left: 4.16667%
}

.el-col-2 {
    width: 8.33333%
}

.el-col-offset-2 {
    margin-left: 8.33333%
}

.el-col-pull-2 {
    right: 8.33333%
}

.el-col-push-2 {
    left: 8.33333%
}

.el-col-3 {
    width: 12.5%
}

.el-col-offset-3 {
    margin-left: 12.5%
}

.el-col-pull-3 {
    right: 12.5%
}

.el-col-push-3 {
    left: 12.5%
}

.el-col-4 {
    width: 16.66667%
}

.el-col-offset-4 {
    margin-left: 16.66667%
}

.el-col-pull-4 {
    right: 16.66667%
}

.el-col-push-4 {
    left: 16.66667%
}

.el-col-5 {
    width: 20.83333%
}

.el-col-offset-5 {
    margin-left: 20.83333%
}

.el-col-pull-5 {
    right: 20.83333%
}

.el-col-push-5 {
    left: 20.83333%
}

.el-col-6 {
    width: 25%
}

.el-col-offset-6 {
    margin-left: 25%
}

.el-col-pull-6 {
    right: 25%
}

.el-col-push-6 {
    left: 25%
}

.el-col-7 {
    width: 29.16667%
}

.el-col-offset-7 {
    margin-left: 29.16667%
}

.el-col-pull-7 {
    right: 29.16667%
}

.el-col-push-7 {
    left: 29.16667%
}

.el-col-8 {
    width: 33.33333%
}

.el-col-offset-8 {
    margin-left: 33.33333%
}

.el-col-pull-8 {
    right: 33.33333%
}

.el-col-push-8 {
    left: 33.33333%
}

.el-col-9 {
    width: 37.5%
}

.el-col-offset-9 {
    margin-left: 37.5%
}

.el-col-pull-9 {
    right: 37.5%
}

.el-col-push-9 {
    left: 37.5%
}

.el-col-10 {
    width: 41.66667%
}

.el-col-offset-10 {
    margin-left: 41.66667%
}

.el-col-pull-10 {
    right: 41.66667%
}

.el-col-push-10 {
    left: 41.66667%
}

.el-col-11 {
    width: 45.83333%
}

.el-col-offset-11 {
    margin-left: 45.83333%
}

.el-col-pull-11 {
    right: 45.83333%
}

.el-col-push-11 {
    left: 45.83333%
}

.el-col-12 {
    width: 50%
}

.el-col-offset-12 {
    margin-left: 50%
}

.el-col-pull-12 {
    position: relative;
    right: 50%
}

.el-col-push-12 {
    left: 50%
}

.el-col-13 {
    width: 54.16667%
}

.el-col-offset-13 {
    margin-left: 54.16667%
}

.el-col-pull-13 {
    right: 54.16667%
}

.el-col-push-13 {
    left: 54.16667%
}

.el-col-14 {
    width: 58.33333%
}

.el-col-offset-14 {
    margin-left: 58.33333%
}

.el-col-pull-14 {
    right: 58.33333%
}

.el-col-push-14 {
    left: 58.33333%
}

.el-col-15 {
    width: 62.5%
}

.el-col-offset-15 {
    margin-left: 62.5%
}

.el-col-pull-15 {
    right: 62.5%
}

.el-col-push-15 {
    left: 62.5%
}

.el-col-16 {
    width: 66.66667%
}

.el-col-offset-16 {
    margin-left: 66.66667%
}

.el-col-pull-16 {
    right: 66.66667%
}

.el-col-push-16 {
    left: 66.66667%
}

.el-col-17 {
    width: 70.83333%
}

.el-col-offset-17 {
    margin-left: 70.83333%
}

.el-col-pull-17 {
    right: 70.83333%
}

.el-col-push-17 {
    left: 70.83333%
}

.el-col-18 {
    width: 75%
}

.el-col-offset-18 {
    margin-left: 75%
}

.el-col-pull-18 {
    right: 75%
}

.el-col-push-18 {
    left: 75%
}

.el-col-19 {
    width: 79.16667%
}

.el-col-offset-19 {
    margin-left: 79.16667%
}

.el-col-pull-19 {
    right: 79.16667%
}

.el-col-push-19 {
    left: 79.16667%
}

.el-col-20 {
    width: 83.33333%
}

.el-col-offset-20 {
    margin-left: 83.33333%
}

.el-col-pull-20 {
    right: 83.33333%
}

.el-col-push-20 {
    left: 83.33333%
}

.el-col-21 {
    width: 87.5%
}

.el-col-offset-21 {
    margin-left: 87.5%
}

.el-col-pull-21 {
    right: 87.5%
}

.el-col-push-21 {
    left: 87.5%
}

.el-col-22 {
    width: 91.66667%
}

.el-col-offset-22 {
    margin-left: 91.66667%
}

.el-col-pull-22 {
    right: 91.66667%
}

.el-col-push-22 {
    left: 91.66667%
}

.el-col-23 {
    width: 95.83333%
}

.el-col-offset-23 {
    margin-left: 95.83333%
}

.el-col-pull-23 {
    right: 95.83333%
}

.el-col-push-23 {
    left: 95.83333%
}

.el-col-24 {
    width: 100%
}

.el-col-offset-24 {
    margin-left: 100%
}

.el-col-pull-24 {
    right: 100%
}

.el-col-push-24 {
    left: 100%
}

@media only screen and (max-width: 767px) {
    .el-col-xs-0 {
        display: none;
        width: 0
    }

    .el-col-xs-offset-0 {
        margin-left: 0
    }

    .el-col-xs-pull-0 {
        position: relative;
        right: 0
    }

    .el-col-xs-push-0 {
        position: relative;
        left: 0
    }

    .el-col-xs-1 {
        width: 4.16667%
    }

    .el-col-xs-offset-1 {
        margin-left: 4.16667%
    }

    .el-col-xs-pull-1 {
        position: relative;
        right: 4.16667%
    }

    .el-col-xs-push-1 {
        position: relative;
        left: 4.16667%
    }

    .el-col-xs-2 {
        width: 8.33333%
    }

    .el-col-xs-offset-2 {
        margin-left: 8.33333%
    }

    .el-col-xs-pull-2 {
        position: relative;
        right: 8.33333%
    }

    .el-col-xs-push-2 {
        position: relative;
        left: 8.33333%
    }

    .el-col-xs-3 {
        width: 12.5%
    }

    .el-col-xs-offset-3 {
        margin-left: 12.5%
    }

    .el-col-xs-pull-3 {
        position: relative;
        right: 12.5%
    }

    .el-col-xs-push-3 {
        position: relative;
        left: 12.5%
    }

    .el-col-xs-4 {
        width: 16.66667%
    }

    .el-col-xs-offset-4 {
        margin-left: 16.66667%
    }

    .el-col-xs-pull-4 {
        position: relative;
        right: 16.66667%
    }

    .el-col-xs-push-4 {
        position: relative;
        left: 16.66667%
    }

    .el-col-xs-5 {
        width: 20.83333%
    }

    .el-col-xs-offset-5 {
        margin-left: 20.83333%
    }

    .el-col-xs-pull-5 {
        position: relative;
        right: 20.83333%
    }

    .el-col-xs-push-5 {
        position: relative;
        left: 20.83333%
    }

    .el-col-xs-6 {
        width: 25%
    }

    .el-col-xs-offset-6 {
        margin-left: 25%
    }

    .el-col-xs-pull-6 {
        position: relative;
        right: 25%
    }

    .el-col-xs-push-6 {
        position: relative;
        left: 25%
    }

    .el-col-xs-7 {
        width: 29.16667%
    }

    .el-col-xs-offset-7 {
        margin-left: 29.16667%
    }

    .el-col-xs-pull-7 {
        position: relative;
        right: 29.16667%
    }

    .el-col-xs-push-7 {
        position: relative;
        left: 29.16667%
    }

    .el-col-xs-8 {
        width: 33.33333%
    }

    .el-col-xs-offset-8 {
        margin-left: 33.33333%
    }

    .el-col-xs-pull-8 {
        position: relative;
        right: 33.33333%
    }

    .el-col-xs-push-8 {
        position: relative;
        left: 33.33333%
    }

    .el-col-xs-9 {
        width: 37.5%
    }

    .el-col-xs-offset-9 {
        margin-left: 37.5%
    }

    .el-col-xs-pull-9 {
        position: relative;
        right: 37.5%
    }

    .el-col-xs-push-9 {
        position: relative;
        left: 37.5%
    }

    .el-col-xs-10 {
        width: 41.66667%
    }

    .el-col-xs-offset-10 {
        margin-left: 41.66667%
    }

    .el-col-xs-pull-10 {
        position: relative;
        right: 41.66667%
    }

    .el-col-xs-push-10 {
        position: relative;
        left: 41.66667%
    }

    .el-col-xs-11 {
        width: 45.83333%
    }

    .el-col-xs-offset-11 {
        margin-left: 45.83333%
    }

    .el-col-xs-pull-11 {
        position: relative;
        right: 45.83333%
    }

    .el-col-xs-push-11 {
        position: relative;
        left: 45.83333%
    }

    .el-col-xs-12 {
        width: 50%
    }

    .el-col-xs-offset-12 {
        margin-left: 50%
    }

    .el-col-xs-pull-12 {
        position: relative;
        right: 50%
    }

    .el-col-xs-push-12 {
        position: relative;
        left: 50%
    }

    .el-col-xs-13 {
        width: 54.16667%
    }

    .el-col-xs-offset-13 {
        margin-left: 54.16667%
    }

    .el-col-xs-pull-13 {
        position: relative;
        right: 54.16667%
    }

    .el-col-xs-push-13 {
        position: relative;
        left: 54.16667%
    }

    .el-col-xs-14 {
        width: 58.33333%
    }

    .el-col-xs-offset-14 {
        margin-left: 58.33333%
    }

    .el-col-xs-pull-14 {
        position: relative;
        right: 58.33333%
    }

    .el-col-xs-push-14 {
        position: relative;
        left: 58.33333%
    }

    .el-col-xs-15 {
        width: 62.5%
    }

    .el-col-xs-offset-15 {
        margin-left: 62.5%
    }

    .el-col-xs-pull-15 {
        position: relative;
        right: 62.5%
    }

    .el-col-xs-push-15 {
        position: relative;
        left: 62.5%
    }

    .el-col-xs-16 {
        width: 66.66667%
    }

    .el-col-xs-offset-16 {
        margin-left: 66.66667%
    }

    .el-col-xs-pull-16 {
        position: relative;
        right: 66.66667%
    }

    .el-col-xs-push-16 {
        position: relative;
        left: 66.66667%
    }

    .el-col-xs-17 {
        width: 70.83333%
    }

    .el-col-xs-offset-17 {
        margin-left: 70.83333%
    }

    .el-col-xs-pull-17 {
        position: relative;
        right: 70.83333%
    }

    .el-col-xs-push-17 {
        position: relative;
        left: 70.83333%
    }

    .el-col-xs-18 {
        width: 75%
    }

    .el-col-xs-offset-18 {
        margin-left: 75%
    }

    .el-col-xs-pull-18 {
        position: relative;
        right: 75%
    }

    .el-col-xs-push-18 {
        position: relative;
        left: 75%
    }

    .el-col-xs-19 {
        width: 79.16667%
    }

    .el-col-xs-offset-19 {
        margin-left: 79.16667%
    }

    .el-col-xs-pull-19 {
        position: relative;
        right: 79.16667%
    }

    .el-col-xs-push-19 {
        position: relative;
        left: 79.16667%
    }

    .el-col-xs-20 {
        width: 83.33333%
    }

    .el-col-xs-offset-20 {
        margin-left: 83.33333%
    }

    .el-col-xs-pull-20 {
        position: relative;
        right: 83.33333%
    }

    .el-col-xs-push-20 {
        position: relative;
        left: 83.33333%
    }

    .el-col-xs-21 {
        width: 87.5%
    }

    .el-col-xs-offset-21 {
        margin-left: 87.5%
    }

    .el-col-xs-pull-21 {
        position: relative;
        right: 87.5%
    }

    .el-col-xs-push-21 {
        position: relative;
        left: 87.5%
    }

    .el-col-xs-22 {
        width: 91.66667%
    }

    .el-col-xs-offset-22 {
        margin-left: 91.66667%
    }

    .el-col-xs-pull-22 {
        position: relative;
        right: 91.66667%
    }

    .el-col-xs-push-22 {
        position: relative;
        left: 91.66667%
    }

    .el-col-xs-23 {
        width: 95.83333%
    }

    .el-col-xs-offset-23 {
        margin-left: 95.83333%
    }

    .el-col-xs-pull-23 {
        position: relative;
        right: 95.83333%
    }

    .el-col-xs-push-23 {
        position: relative;
        left: 95.83333%
    }

    .el-col-xs-24 {
        width: 100%
    }

    .el-col-xs-offset-24 {
        margin-left: 100%
    }

    .el-col-xs-pull-24 {
        position: relative;
        right: 100%
    }

    .el-col-xs-push-24 {
        position: relative;
        left: 100%
    }
}

@media only screen and (min-width: 768px) {
    .el-col-sm-0 {
        display: none;
        width: 0
    }

    .el-col-sm-offset-0 {
        margin-left: 0
    }

    .el-col-sm-pull-0 {
        position: relative;
        right: 0
    }

    .el-col-sm-push-0 {
        position: relative;
        left: 0
    }

    .el-col-sm-1 {
        width: 4.16667%
    }

    .el-col-sm-offset-1 {
        margin-left: 4.16667%
    }

    .el-col-sm-pull-1 {
        position: relative;
        right: 4.16667%
    }

    .el-col-sm-push-1 {
        position: relative;
        left: 4.16667%
    }

    .el-col-sm-2 {
        width: 8.33333%
    }

    .el-col-sm-offset-2 {
        margin-left: 8.33333%
    }

    .el-col-sm-pull-2 {
        position: relative;
        right: 8.33333%
    }

    .el-col-sm-push-2 {
        position: relative;
        left: 8.33333%
    }

    .el-col-sm-3 {
        width: 12.5%
    }

    .el-col-sm-offset-3 {
        margin-left: 12.5%
    }

    .el-col-sm-pull-3 {
        position: relative;
        right: 12.5%
    }

    .el-col-sm-push-3 {
        position: relative;
        left: 12.5%
    }

    .el-col-sm-4 {
        width: 16.66667%
    }

    .el-col-sm-offset-4 {
        margin-left: 16.66667%
    }

    .el-col-sm-pull-4 {
        position: relative;
        right: 16.66667%
    }

    .el-col-sm-push-4 {
        position: relative;
        left: 16.66667%
    }

    .el-col-sm-5 {
        width: 20.83333%
    }

    .el-col-sm-offset-5 {
        margin-left: 20.83333%
    }

    .el-col-sm-pull-5 {
        position: relative;
        right: 20.83333%
    }

    .el-col-sm-push-5 {
        position: relative;
        left: 20.83333%
    }

    .el-col-sm-6 {
        width: 25%
    }

    .el-col-sm-offset-6 {
        margin-left: 25%
    }

    .el-col-sm-pull-6 {
        position: relative;
        right: 25%
    }

    .el-col-sm-push-6 {
        position: relative;
        left: 25%
    }

    .el-col-sm-7 {
        width: 29.16667%
    }

    .el-col-sm-offset-7 {
        margin-left: 29.16667%
    }

    .el-col-sm-pull-7 {
        position: relative;
        right: 29.16667%
    }

    .el-col-sm-push-7 {
        position: relative;
        left: 29.16667%
    }

    .el-col-sm-8 {
        width: 33.33333%
    }

    .el-col-sm-offset-8 {
        margin-left: 33.33333%
    }

    .el-col-sm-pull-8 {
        position: relative;
        right: 33.33333%
    }

    .el-col-sm-push-8 {
        position: relative;
        left: 33.33333%
    }

    .el-col-sm-9 {
        width: 37.5%
    }

    .el-col-sm-offset-9 {
        margin-left: 37.5%
    }

    .el-col-sm-pull-9 {
        position: relative;
        right: 37.5%
    }

    .el-col-sm-push-9 {
        position: relative;
        left: 37.5%
    }

    .el-col-sm-10 {
        width: 41.66667%
    }

    .el-col-sm-offset-10 {
        margin-left: 41.66667%
    }

    .el-col-sm-pull-10 {
        position: relative;
        right: 41.66667%
    }

    .el-col-sm-push-10 {
        position: relative;
        left: 41.66667%
    }

    .el-col-sm-11 {
        width: 45.83333%
    }

    .el-col-sm-offset-11 {
        margin-left: 45.83333%
    }

    .el-col-sm-pull-11 {
        position: relative;
        right: 45.83333%
    }

    .el-col-sm-push-11 {
        position: relative;
        left: 45.83333%
    }

    .el-col-sm-12 {
        width: 50%
    }

    .el-col-sm-offset-12 {
        margin-left: 50%
    }

    .el-col-sm-pull-12 {
        position: relative;
        right: 50%
    }

    .el-col-sm-push-12 {
        position: relative;
        left: 50%
    }

    .el-col-sm-13 {
        width: 54.16667%
    }

    .el-col-sm-offset-13 {
        margin-left: 54.16667%
    }

    .el-col-sm-pull-13 {
        position: relative;
        right: 54.16667%
    }

    .el-col-sm-push-13 {
        position: relative;
        left: 54.16667%
    }

    .el-col-sm-14 {
        width: 58.33333%
    }

    .el-col-sm-offset-14 {
        margin-left: 58.33333%
    }

    .el-col-sm-pull-14 {
        position: relative;
        right: 58.33333%
    }

    .el-col-sm-push-14 {
        position: relative;
        left: 58.33333%
    }

    .el-col-sm-15 {
        width: 62.5%
    }

    .el-col-sm-offset-15 {
        margin-left: 62.5%
    }

    .el-col-sm-pull-15 {
        position: relative;
        right: 62.5%
    }

    .el-col-sm-push-15 {
        position: relative;
        left: 62.5%
    }

    .el-col-sm-16 {
        width: 66.66667%
    }

    .el-col-sm-offset-16 {
        margin-left: 66.66667%
    }

    .el-col-sm-pull-16 {
        position: relative;
        right: 66.66667%
    }

    .el-col-sm-push-16 {
        position: relative;
        left: 66.66667%
    }

    .el-col-sm-17 {
        width: 70.83333%
    }

    .el-col-sm-offset-17 {
        margin-left: 70.83333%
    }

    .el-col-sm-pull-17 {
        position: relative;
        right: 70.83333%
    }

    .el-col-sm-push-17 {
        position: relative;
        left: 70.83333%
    }

    .el-col-sm-18 {
        width: 75%
    }

    .el-col-sm-offset-18 {
        margin-left: 75%
    }

    .el-col-sm-pull-18 {
        position: relative;
        right: 75%
    }

    .el-col-sm-push-18 {
        position: relative;
        left: 75%
    }

    .el-col-sm-19 {
        width: 79.16667%
    }

    .el-col-sm-offset-19 {
        margin-left: 79.16667%
    }

    .el-col-sm-pull-19 {
        position: relative;
        right: 79.16667%
    }

    .el-col-sm-push-19 {
        position: relative;
        left: 79.16667%
    }

    .el-col-sm-20 {
        width: 83.33333%
    }

    .el-col-sm-offset-20 {
        margin-left: 83.33333%
    }

    .el-col-sm-pull-20 {
        position: relative;
        right: 83.33333%
    }

    .el-col-sm-push-20 {
        position: relative;
        left: 83.33333%
    }

    .el-col-sm-21 {
        width: 87.5%
    }

    .el-col-sm-offset-21 {
        margin-left: 87.5%
    }

    .el-col-sm-pull-21 {
        position: relative;
        right: 87.5%
    }

    .el-col-sm-push-21 {
        position: relative;
        left: 87.5%
    }

    .el-col-sm-22 {
        width: 91.66667%
    }

    .el-col-sm-offset-22 {
        margin-left: 91.66667%
    }

    .el-col-sm-pull-22 {
        position: relative;
        right: 91.66667%
    }

    .el-col-sm-push-22 {
        position: relative;
        left: 91.66667%
    }

    .el-col-sm-23 {
        width: 95.83333%
    }

    .el-col-sm-offset-23 {
        margin-left: 95.83333%
    }

    .el-col-sm-pull-23 {
        position: relative;
        right: 95.83333%
    }

    .el-col-sm-push-23 {
        position: relative;
        left: 95.83333%
    }

    .el-col-sm-24 {
        width: 100%
    }

    .el-col-sm-offset-24 {
        margin-left: 100%
    }

    .el-col-sm-pull-24 {
        position: relative;
        right: 100%
    }

    .el-col-sm-push-24 {
        position: relative;
        left: 100%
    }
}

@media only screen and (min-width: 992px) {
    .el-col-md-0 {
        display: none;
        width: 0
    }

    .el-col-md-offset-0 {
        margin-left: 0
    }

    .el-col-md-pull-0 {
        position: relative;
        right: 0
    }

    .el-col-md-push-0 {
        position: relative;
        left: 0
    }

    .el-col-md-1 {
        width: 4.16667%
    }

    .el-col-md-offset-1 {
        margin-left: 4.16667%
    }

    .el-col-md-pull-1 {
        position: relative;
        right: 4.16667%
    }

    .el-col-md-push-1 {
        position: relative;
        left: 4.16667%
    }

    .el-col-md-2 {
        width: 8.33333%
    }

    .el-col-md-offset-2 {
        margin-left: 8.33333%
    }

    .el-col-md-pull-2 {
        position: relative;
        right: 8.33333%
    }

    .el-col-md-push-2 {
        position: relative;
        left: 8.33333%
    }

    .el-col-md-3 {
        width: 12.5%
    }

    .el-col-md-offset-3 {
        margin-left: 12.5%
    }

    .el-col-md-pull-3 {
        position: relative;
        right: 12.5%
    }

    .el-col-md-push-3 {
        position: relative;
        left: 12.5%
    }

    .el-col-md-4 {
        width: 16.66667%
    }

    .el-col-md-offset-4 {
        margin-left: 16.66667%
    }

    .el-col-md-pull-4 {
        position: relative;
        right: 16.66667%
    }

    .el-col-md-push-4 {
        position: relative;
        left: 16.66667%
    }

    .el-col-md-5 {
        width: 20.83333%
    }

    .el-col-md-offset-5 {
        margin-left: 20.83333%
    }

    .el-col-md-pull-5 {
        position: relative;
        right: 20.83333%
    }

    .el-col-md-push-5 {
        position: relative;
        left: 20.83333%
    }

    .el-col-md-6 {
        width: 25%
    }

    .el-col-md-offset-6 {
        margin-left: 25%
    }

    .el-col-md-pull-6 {
        position: relative;
        right: 25%
    }

    .el-col-md-push-6 {
        position: relative;
        left: 25%
    }

    .el-col-md-7 {
        width: 29.16667%
    }

    .el-col-md-offset-7 {
        margin-left: 29.16667%
    }

    .el-col-md-pull-7 {
        position: relative;
        right: 29.16667%
    }

    .el-col-md-push-7 {
        position: relative;
        left: 29.16667%
    }

    .el-col-md-8 {
        width: 33.33333%
    }

    .el-col-md-offset-8 {
        margin-left: 33.33333%
    }

    .el-col-md-pull-8 {
        position: relative;
        right: 33.33333%
    }

    .el-col-md-push-8 {
        position: relative;
        left: 33.33333%
    }

    .el-col-md-9 {
        width: 37.5%
    }

    .el-col-md-offset-9 {
        margin-left: 37.5%
    }

    .el-col-md-pull-9 {
        position: relative;
        right: 37.5%
    }

    .el-col-md-push-9 {
        position: relative;
        left: 37.5%
    }

    .el-col-md-10 {
        width: 41.66667%
    }

    .el-col-md-offset-10 {
        margin-left: 41.66667%
    }

    .el-col-md-pull-10 {
        position: relative;
        right: 41.66667%
    }

    .el-col-md-push-10 {
        position: relative;
        left: 41.66667%
    }

    .el-col-md-11 {
        width: 45.83333%
    }

    .el-col-md-offset-11 {
        margin-left: 45.83333%
    }

    .el-col-md-pull-11 {
        position: relative;
        right: 45.83333%
    }

    .el-col-md-push-11 {
        position: relative;
        left: 45.83333%
    }

    .el-col-md-12 {
        width: 50%
    }

    .el-col-md-offset-12 {
        margin-left: 50%
    }

    .el-col-md-pull-12 {
        position: relative;
        right: 50%
    }

    .el-col-md-push-12 {
        position: relative;
        left: 50%
    }

    .el-col-md-13 {
        width: 54.16667%
    }

    .el-col-md-offset-13 {
        margin-left: 54.16667%
    }

    .el-col-md-pull-13 {
        position: relative;
        right: 54.16667%
    }

    .el-col-md-push-13 {
        position: relative;
        left: 54.16667%
    }

    .el-col-md-14 {
        width: 58.33333%
    }

    .el-col-md-offset-14 {
        margin-left: 58.33333%
    }

    .el-col-md-pull-14 {
        position: relative;
        right: 58.33333%
    }

    .el-col-md-push-14 {
        position: relative;
        left: 58.33333%
    }

    .el-col-md-15 {
        width: 62.5%
    }

    .el-col-md-offset-15 {
        margin-left: 62.5%
    }

    .el-col-md-pull-15 {
        position: relative;
        right: 62.5%
    }

    .el-col-md-push-15 {
        position: relative;
        left: 62.5%
    }

    .el-col-md-16 {
        width: 66.66667%
    }

    .el-col-md-offset-16 {
        margin-left: 66.66667%
    }

    .el-col-md-pull-16 {
        position: relative;
        right: 66.66667%
    }

    .el-col-md-push-16 {
        position: relative;
        left: 66.66667%
    }

    .el-col-md-17 {
        width: 70.83333%
    }

    .el-col-md-offset-17 {
        margin-left: 70.83333%
    }

    .el-col-md-pull-17 {
        position: relative;
        right: 70.83333%
    }

    .el-col-md-push-17 {
        position: relative;
        left: 70.83333%
    }

    .el-col-md-18 {
        width: 75%
    }

    .el-col-md-offset-18 {
        margin-left: 75%
    }

    .el-col-md-pull-18 {
        position: relative;
        right: 75%
    }

    .el-col-md-push-18 {
        position: relative;
        left: 75%
    }

    .el-col-md-19 {
        width: 79.16667%
    }

    .el-col-md-offset-19 {
        margin-left: 79.16667%
    }

    .el-col-md-pull-19 {
        position: relative;
        right: 79.16667%
    }

    .el-col-md-push-19 {
        position: relative;
        left: 79.16667%
    }

    .el-col-md-20 {
        width: 83.33333%
    }

    .el-col-md-offset-20 {
        margin-left: 83.33333%
    }

    .el-col-md-pull-20 {
        position: relative;
        right: 83.33333%
    }

    .el-col-md-push-20 {
        position: relative;
        left: 83.33333%
    }

    .el-col-md-21 {
        width: 87.5%
    }

    .el-col-md-offset-21 {
        margin-left: 87.5%
    }

    .el-col-md-pull-21 {
        position: relative;
        right: 87.5%
    }

    .el-col-md-push-21 {
        position: relative;
        left: 87.5%
    }

    .el-col-md-22 {
        width: 91.66667%
    }

    .el-col-md-offset-22 {
        margin-left: 91.66667%
    }

    .el-col-md-pull-22 {
        position: relative;
        right: 91.66667%
    }

    .el-col-md-push-22 {
        position: relative;
        left: 91.66667%
    }

    .el-col-md-23 {
        width: 95.83333%
    }

    .el-col-md-offset-23 {
        margin-left: 95.83333%
    }

    .el-col-md-pull-23 {
        position: relative;
        right: 95.83333%
    }

    .el-col-md-push-23 {
        position: relative;
        left: 95.83333%
    }

    .el-col-md-24 {
        width: 100%
    }

    .el-col-md-offset-24 {
        margin-left: 100%
    }

    .el-col-md-pull-24 {
        position: relative;
        right: 100%
    }

    .el-col-md-push-24 {
        position: relative;
        left: 100%
    }
}

@media only screen and (min-width: 1200px) {
    .el-col-lg-0 {
        display: none;
        width: 0
    }

    .el-col-lg-offset-0 {
        margin-left: 0
    }

    .el-col-lg-pull-0 {
        position: relative;
        right: 0
    }

    .el-col-lg-push-0 {
        position: relative;
        left: 0
    }

    .el-col-lg-1 {
        width: 4.16667%
    }

    .el-col-lg-offset-1 {
        margin-left: 4.16667%
    }

    .el-col-lg-pull-1 {
        position: relative;
        right: 4.16667%
    }

    .el-col-lg-push-1 {
        position: relative;
        left: 4.16667%
    }

    .el-col-lg-2 {
        width: 8.33333%
    }

    .el-col-lg-offset-2 {
        margin-left: 8.33333%
    }

    .el-col-lg-pull-2 {
        position: relative;
        right: 8.33333%
    }

    .el-col-lg-push-2 {
        position: relative;
        left: 8.33333%
    }

    .el-col-lg-3 {
        width: 12.5%
    }

    .el-col-lg-offset-3 {
        margin-left: 12.5%
    }

    .el-col-lg-pull-3 {
        position: relative;
        right: 12.5%
    }

    .el-col-lg-push-3 {
        position: relative;
        left: 12.5%
    }

    .el-col-lg-4 {
        width: 16.66667%
    }

    .el-col-lg-offset-4 {
        margin-left: 16.66667%
    }

    .el-col-lg-pull-4 {
        position: relative;
        right: 16.66667%
    }

    .el-col-lg-push-4 {
        position: relative;
        left: 16.66667%
    }

    .el-col-lg-5 {
        width: 20.83333%
    }

    .el-col-lg-offset-5 {
        margin-left: 20.83333%
    }

    .el-col-lg-pull-5 {
        position: relative;
        right: 20.83333%
    }

    .el-col-lg-push-5 {
        position: relative;
        left: 20.83333%
    }

    .el-col-lg-6 {
        width: 25%
    }

    .el-col-lg-offset-6 {
        margin-left: 25%
    }

    .el-col-lg-pull-6 {
        position: relative;
        right: 25%
    }

    .el-col-lg-push-6 {
        position: relative;
        left: 25%
    }

    .el-col-lg-7 {
        width: 29.16667%
    }

    .el-col-lg-offset-7 {
        margin-left: 29.16667%
    }

    .el-col-lg-pull-7 {
        position: relative;
        right: 29.16667%
    }

    .el-col-lg-push-7 {
        position: relative;
        left: 29.16667%
    }

    .el-col-lg-8 {
        width: 33.33333%
    }

    .el-col-lg-offset-8 {
        margin-left: 33.33333%
    }

    .el-col-lg-pull-8 {
        position: relative;
        right: 33.33333%
    }

    .el-col-lg-push-8 {
        position: relative;
        left: 33.33333%
    }

    .el-col-lg-9 {
        width: 37.5%
    }

    .el-col-lg-offset-9 {
        margin-left: 37.5%
    }

    .el-col-lg-pull-9 {
        position: relative;
        right: 37.5%
    }

    .el-col-lg-push-9 {
        position: relative;
        left: 37.5%
    }

    .el-col-lg-10 {
        width: 41.66667%
    }

    .el-col-lg-offset-10 {
        margin-left: 41.66667%
    }

    .el-col-lg-pull-10 {
        position: relative;
        right: 41.66667%
    }

    .el-col-lg-push-10 {
        position: relative;
        left: 41.66667%
    }

    .el-col-lg-11 {
        width: 45.83333%
    }

    .el-col-lg-offset-11 {
        margin-left: 45.83333%
    }

    .el-col-lg-pull-11 {
        position: relative;
        right: 45.83333%
    }

    .el-col-lg-push-11 {
        position: relative;
        left: 45.83333%
    }

    .el-col-lg-12 {
        width: 50%
    }

    .el-col-lg-offset-12 {
        margin-left: 50%
    }

    .el-col-lg-pull-12 {
        position: relative;
        right: 50%
    }

    .el-col-lg-push-12 {
        position: relative;
        left: 50%
    }

    .el-col-lg-13 {
        width: 54.16667%
    }

    .el-col-lg-offset-13 {
        margin-left: 54.16667%
    }

    .el-col-lg-pull-13 {
        position: relative;
        right: 54.16667%
    }

    .el-col-lg-push-13 {
        position: relative;
        left: 54.16667%
    }

    .el-col-lg-14 {
        width: 58.33333%
    }

    .el-col-lg-offset-14 {
        margin-left: 58.33333%
    }

    .el-col-lg-pull-14 {
        position: relative;
        right: 58.33333%
    }

    .el-col-lg-push-14 {
        position: relative;
        left: 58.33333%
    }

    .el-col-lg-15 {
        width: 62.5%
    }

    .el-col-lg-offset-15 {
        margin-left: 62.5%
    }

    .el-col-lg-pull-15 {
        position: relative;
        right: 62.5%
    }

    .el-col-lg-push-15 {
        position: relative;
        left: 62.5%
    }

    .el-col-lg-16 {
        width: 66.66667%
    }

    .el-col-lg-offset-16 {
        margin-left: 66.66667%
    }

    .el-col-lg-pull-16 {
        position: relative;
        right: 66.66667%
    }

    .el-col-lg-push-16 {
        position: relative;
        left: 66.66667%
    }

    .el-col-lg-17 {
        width: 70.83333%
    }

    .el-col-lg-offset-17 {
        margin-left: 70.83333%
    }

    .el-col-lg-pull-17 {
        position: relative;
        right: 70.83333%
    }

    .el-col-lg-push-17 {
        position: relative;
        left: 70.83333%
    }

    .el-col-lg-18 {
        width: 75%
    }

    .el-col-lg-offset-18 {
        margin-left: 75%
    }

    .el-col-lg-pull-18 {
        position: relative;
        right: 75%
    }

    .el-col-lg-push-18 {
        position: relative;
        left: 75%
    }

    .el-col-lg-19 {
        width: 79.16667%
    }

    .el-col-lg-offset-19 {
        margin-left: 79.16667%
    }

    .el-col-lg-pull-19 {
        position: relative;
        right: 79.16667%
    }

    .el-col-lg-push-19 {
        position: relative;
        left: 79.16667%
    }

    .el-col-lg-20 {
        width: 83.33333%
    }

    .el-col-lg-offset-20 {
        margin-left: 83.33333%
    }

    .el-col-lg-pull-20 {
        position: relative;
        right: 83.33333%
    }

    .el-col-lg-push-20 {
        position: relative;
        left: 83.33333%
    }

    .el-col-lg-21 {
        width: 87.5%
    }

    .el-col-lg-offset-21 {
        margin-left: 87.5%
    }

    .el-col-lg-pull-21 {
        position: relative;
        right: 87.5%
    }

    .el-col-lg-push-21 {
        position: relative;
        left: 87.5%
    }

    .el-col-lg-22 {
        width: 91.66667%
    }

    .el-col-lg-offset-22 {
        margin-left: 91.66667%
    }

    .el-col-lg-pull-22 {
        position: relative;
        right: 91.66667%
    }

    .el-col-lg-push-22 {
        position: relative;
        left: 91.66667%
    }

    .el-col-lg-23 {
        width: 95.83333%
    }

    .el-col-lg-offset-23 {
        margin-left: 95.83333%
    }

    .el-col-lg-pull-23 {
        position: relative;
        right: 95.83333%
    }

    .el-col-lg-push-23 {
        position: relative;
        left: 95.83333%
    }

    .el-col-lg-24 {
        width: 100%
    }

    .el-col-lg-offset-24 {
        margin-left: 100%
    }

    .el-col-lg-pull-24 {
        position: relative;
        right: 100%
    }

    .el-col-lg-push-24 {
        position: relative;
        left: 100%
    }
}

@media only screen and (min-width: 1920px) {
    .el-col-xl-0 {
        display: none;
        width: 0
    }

    .el-col-xl-offset-0 {
        margin-left: 0
    }

    .el-col-xl-pull-0 {
        position: relative;
        right: 0
    }

    .el-col-xl-push-0 {
        position: relative;
        left: 0
    }

    .el-col-xl-1 {
        width: 4.16667%
    }

    .el-col-xl-offset-1 {
        margin-left: 4.16667%
    }

    .el-col-xl-pull-1 {
        position: relative;
        right: 4.16667%
    }

    .el-col-xl-push-1 {
        position: relative;
        left: 4.16667%
    }

    .el-col-xl-2 {
        width: 8.33333%
    }

    .el-col-xl-offset-2 {
        margin-left: 8.33333%
    }

    .el-col-xl-pull-2 {
        position: relative;
        right: 8.33333%
    }

    .el-col-xl-push-2 {
        position: relative;
        left: 8.33333%
    }

    .el-col-xl-3 {
        width: 12.5%
    }

    .el-col-xl-offset-3 {
        margin-left: 12.5%
    }

    .el-col-xl-pull-3 {
        position: relative;
        right: 12.5%
    }

    .el-col-xl-push-3 {
        position: relative;
        left: 12.5%
    }

    .el-col-xl-4 {
        width: 16.66667%
    }

    .el-col-xl-offset-4 {
        margin-left: 16.66667%
    }

    .el-col-xl-pull-4 {
        position: relative;
        right: 16.66667%
    }

    .el-col-xl-push-4 {
        position: relative;
        left: 16.66667%
    }

    .el-col-xl-5 {
        width: 20.83333%
    }

    .el-col-xl-offset-5 {
        margin-left: 20.83333%
    }

    .el-col-xl-pull-5 {
        position: relative;
        right: 20.83333%
    }

    .el-col-xl-push-5 {
        position: relative;
        left: 20.83333%
    }

    .el-col-xl-6 {
        width: 25%
    }

    .el-col-xl-offset-6 {
        margin-left: 25%
    }

    .el-col-xl-pull-6 {
        position: relative;
        right: 25%
    }

    .el-col-xl-push-6 {
        position: relative;
        left: 25%
    }

    .el-col-xl-7 {
        width: 29.16667%
    }

    .el-col-xl-offset-7 {
        margin-left: 29.16667%
    }

    .el-col-xl-pull-7 {
        position: relative;
        right: 29.16667%
    }

    .el-col-xl-push-7 {
        position: relative;
        left: 29.16667%
    }

    .el-col-xl-8 {
        width: 33.33333%
    }

    .el-col-xl-offset-8 {
        margin-left: 33.33333%
    }

    .el-col-xl-pull-8 {
        position: relative;
        right: 33.33333%
    }

    .el-col-xl-push-8 {
        position: relative;
        left: 33.33333%
    }

    .el-col-xl-9 {
        width: 37.5%
    }

    .el-col-xl-offset-9 {
        margin-left: 37.5%
    }

    .el-col-xl-pull-9 {
        position: relative;
        right: 37.5%
    }

    .el-col-xl-push-9 {
        position: relative;
        left: 37.5%
    }

    .el-col-xl-10 {
        width: 41.66667%
    }

    .el-col-xl-offset-10 {
        margin-left: 41.66667%
    }

    .el-col-xl-pull-10 {
        position: relative;
        right: 41.66667%
    }

    .el-col-xl-push-10 {
        position: relative;
        left: 41.66667%
    }

    .el-col-xl-11 {
        width: 45.83333%
    }

    .el-col-xl-offset-11 {
        margin-left: 45.83333%
    }

    .el-col-xl-pull-11 {
        position: relative;
        right: 45.83333%
    }

    .el-col-xl-push-11 {
        position: relative;
        left: 45.83333%
    }

    .el-col-xl-12 {
        width: 50%
    }

    .el-col-xl-offset-12 {
        margin-left: 50%
    }

    .el-col-xl-pull-12 {
        position: relative;
        right: 50%
    }

    .el-col-xl-push-12 {
        position: relative;
        left: 50%
    }

    .el-col-xl-13 {
        width: 54.16667%
    }

    .el-col-xl-offset-13 {
        margin-left: 54.16667%
    }

    .el-col-xl-pull-13 {
        position: relative;
        right: 54.16667%
    }

    .el-col-xl-push-13 {
        position: relative;
        left: 54.16667%
    }

    .el-col-xl-14 {
        width: 58.33333%
    }

    .el-col-xl-offset-14 {
        margin-left: 58.33333%
    }

    .el-col-xl-pull-14 {
        position: relative;
        right: 58.33333%
    }

    .el-col-xl-push-14 {
        position: relative;
        left: 58.33333%
    }

    .el-col-xl-15 {
        width: 62.5%
    }

    .el-col-xl-offset-15 {
        margin-left: 62.5%
    }

    .el-col-xl-pull-15 {
        position: relative;
        right: 62.5%
    }

    .el-col-xl-push-15 {
        position: relative;
        left: 62.5%
    }

    .el-col-xl-16 {
        width: 66.66667%
    }

    .el-col-xl-offset-16 {
        margin-left: 66.66667%
    }

    .el-col-xl-pull-16 {
        position: relative;
        right: 66.66667%
    }

    .el-col-xl-push-16 {
        position: relative;
        left: 66.66667%
    }

    .el-col-xl-17 {
        width: 70.83333%
    }

    .el-col-xl-offset-17 {
        margin-left: 70.83333%
    }

    .el-col-xl-pull-17 {
        position: relative;
        right: 70.83333%
    }

    .el-col-xl-push-17 {
        position: relative;
        left: 70.83333%
    }

    .el-col-xl-18 {
        width: 75%
    }

    .el-col-xl-offset-18 {
        margin-left: 75%
    }

    .el-col-xl-pull-18 {
        position: relative;
        right: 75%
    }

    .el-col-xl-push-18 {
        position: relative;
        left: 75%
    }

    .el-col-xl-19 {
        width: 79.16667%
    }

    .el-col-xl-offset-19 {
        margin-left: 79.16667%
    }

    .el-col-xl-pull-19 {
        position: relative;
        right: 79.16667%
    }

    .el-col-xl-push-19 {
        position: relative;
        left: 79.16667%
    }

    .el-col-xl-20 {
        width: 83.33333%
    }

    .el-col-xl-offset-20 {
        margin-left: 83.33333%
    }

    .el-col-xl-pull-20 {
        position: relative;
        right: 83.33333%
    }

    .el-col-xl-push-20 {
        position: relative;
        left: 83.33333%
    }

    .el-col-xl-21 {
        width: 87.5%
    }

    .el-col-xl-offset-21 {
        margin-left: 87.5%
    }

    .el-col-xl-pull-21 {
        position: relative;
        right: 87.5%
    }

    .el-col-xl-push-21 {
        position: relative;
        left: 87.5%
    }

    .el-col-xl-22 {
        width: 91.66667%
    }

    .el-col-xl-offset-22 {
        margin-left: 91.66667%
    }

    .el-col-xl-pull-22 {
        position: relative;
        right: 91.66667%
    }

    .el-col-xl-push-22 {
        position: relative;
        left: 91.66667%
    }

    .el-col-xl-23 {
        width: 95.83333%
    }

    .el-col-xl-offset-23 {
        margin-left: 95.83333%
    }

    .el-col-xl-pull-23 {
        position: relative;
        right: 95.83333%
    }

    .el-col-xl-push-23 {
        position: relative;
        left: 95.83333%
    }

    .el-col-xl-24 {
        width: 100%
    }

    .el-col-xl-offset-24 {
        margin-left: 100%
    }

    .el-col-xl-pull-24 {
        position: relative;
        right: 100%
    }

    .el-col-xl-push-24 {
        position: relative;
        left: 100%
    }
}

@media only screen and (max-width: 767px) {
    .hidden-xs-only {
        display: none !important
    }
}

@media only screen and (min-width: 768px) {
    .hidden-sm-and-up {
        display: none !important
    }
}

@media only screen and (min-width: 768px) and (max-width:991px) {
    .hidden-sm-only {
        display: none !important
    }
}

@media only screen and (max-width: 991px) {
    .hidden-sm-and-down {
        display: none !important
    }
}

@media only screen and (min-width: 992px) {
    .hidden-md-and-up {
        display: none !important
    }
}

@media only screen and (min-width: 992px) and (max-width:1199px) {
    .hidden-md-only {
        display: none !important
    }
}

@media only screen and (max-width: 1199px) {
    .hidden-md-and-down {
        display: none !important
    }
}

@media only screen and (min-width: 1200px) {
    .hidden-lg-and-up {
        display: none !important
    }
}

@media only screen and (min-width: 1200px) and (max-width:1919px) {
    .hidden-lg-only {
        display: none !important
    }
}

@media only screen and (max-width: 1919px) {
    .hidden-lg-and-down {
        display: none !important
    }
}

@media only screen and (min-width: 1920px) {
    .hidden-xl-only {
        display: none !important
    }
}

.page-footer[data-v-4b97e292] {
    clear: both
}

.page-footer-row[data-v-4b97e292] {
    padding: 10px 20px;
    border-left: 1px solid var(--color-1);
    border-right: 1px solid var(--color-1)
}

.page-footer-img[data-v-4b97e292] {
    width: 80%
}

.page-footer-brand[data-v-4b97e292] {
    padding: 16px 0;
    background-color: var(--color-theme-bg);
    color: var(--color-dark-neutral-100) !important;
    clear: both
}

.page-footer-information[data-v-4b97e292] {
    max-height: 160px;
    overflow-y: auto;
    margin-top: 8px
}

.page-footer-information[data-v-4b97e292]::-webkit-scrollbar,
.page-footer-information[data-v-4b97e292]::-webkit-scrollbar-track {
    display: none !important
}

.page-footer-information[data-v-4b97e292]::-webkit-scrollbar-thumb {
    display: none !important
}

.download-svg[data-v-4b97e292] {
    width: 90px
}

@media(min-width: 500px) {
    .download-app[data-v-4b97e292] {
        position: absolute;
        top: 0;
        right: 0
    }
}

.basic-navbar-title[data-v-1859f492],
.plus-navbar-title[data-v-1cc5f5b3],
.premium-navbar-title[data-v-1a9ffe88] {
    color: #ffb401
}

.share-button[data-v-b587b6e4] {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #1da1f2;
    color: #fff;
    border-color: transparent !important
}

.share-button[data-v-b587b6e4]:hover {
    background: #28abff;
    color: #fff
}

.square-button[data-v-b587b6e4] {
    width: 20px;
    height: 20px;
    border-radius: 6px
}

.share-button[data-v-22f4de86] {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #414141;
    color: #fff;
    border-color: transparent !important
}

.share-button[data-v-22f4de86]:hover {
    background: #4b4b4b;
    color: #fff
}

.el-input__inner[data-v-f4ec4354] {
    background: 0 0
}

.share-twitter-button {
    margin-top: 10px;
    margin-left: 10px;
    width: calc(100% - 30px)
}

.share-twitter-button:hover {
    background-color: #55acee
}

.share-twitter-button:hover .twitter-text {
    color: #fff
}

.share-email-button {
    margin-top: 10px;
    margin-left: 10px;
    width: calc(100% - 30px)
}

.share-email-button:hover {
    background-color: var(--color-8)
}

.share-email-button:hover .email-text {
    color: #fff
}

.my-box {
    max-width: 680px;
    margin: auto
}

.count-item[data-v-e9b35588] {
    text-align: center;
    display: block
}

.count-item-line[data-v-e9b35588] {
    margin-top: 4px;
    margin-bottom: 4px;
    white-space: nowrap
}

.count-number[data-v-e9b35588] {
    font-weight: 700;
    padding-left: 4px
}

.inner-box[data-v-03df77ac] {
    max-width: 520px;
    margin: auto
}

.dialog-content[data-v-7a2e3e52] {
    padding: 16px
}

.el-switch {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    vertical-align: middle
}

.el-switch__core,
.el-switch__label {
    display: inline-block;
    cursor: pointer
}

.el-switch.is-disabled .el-switch__core,
.el-switch.is-disabled .el-switch__label {
    cursor: not-allowed
}

.el-switch__label {
    -webkit-transition: .2s;
    transition: .2s;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    vertical-align: middle;
    color: #303133
}

.el-switch__label.is-active {
    color: #409eff
}

.el-switch__label--left {
    margin-right: 10px
}

.el-switch__label--right {
    margin-left: 10px
}

.el-switch__label * {
    line-height: 1;
    font-size: 14px;
    display: inline-block
}

.el-switch__input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0
}

.el-switch__core {
    margin: 0;
    position: relative;
    width: 40px;
    height: 20px;
    border: 1px solid #dcdfe6;
    outline: 0;
    border-radius: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #dcdfe6;
    -webkit-transition: border-color .3s, background-color .3s;
    transition: border-color .3s, background-color .3s;
    vertical-align: middle
}

.el-switch__core:after {
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 100%;
    -webkit-transition: all .3s;
    transition: all .3s;
    width: 16px;
    height: 16px;
    background-color: #fff
}

.el-switch.is-checked .el-switch__core {
    border-color: #409eff;
    background-color: #409eff
}

.el-switch.is-checked .el-switch__core:after {
    left: 100%;
    margin-left: -17px
}

.el-switch.is-disabled {
    opacity: .6
}

.el-switch--wide .el-switch__label.el-switch__label--left span {
    left: 10px
}

.el-switch--wide .el-switch__label.el-switch__label--right span {
    right: 10px
}

.el-switch .label-fade-enter,
.el-switch .label-fade-leave-active {
    opacity: 0
}

.membership-link[data-v-53d89c24] {
    color: #ffb401;
    line-height: 16px
}

hr.thin[data-v-53d89c24] {
    border-top: 1px solid #bbb
}

.switch-container[data-v-53d89c24] {
    height: 24px;
    border-left: 1px solid hsla(0, 0%, 100%, .2);
    border-right: 1px solid hsla(0, 0%, 100%, .2)
}

.switch-text[data-v-53d89c24] {
    display: inline-block;
    line-height: 24px;
    position: relative;
    padding: 0 8px 0 24px;
    font-size: 12px
}

.user-name-tag[data-v-53d89c24] {
    display: inline-block;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis
}

#phplive_btn_1570480208 {
    display: none
}

.phone-session[data-v-c1848554] {
    padding: 4px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: block;
    color: var(--color-10);
    cursor: auto
}

.basic-navbar-title[data-v-52da91bf] {
    color: #ffb401
}

.membership-link[data-v-8c41f506] {
    color: #ffb401;
    line-height: 16px
}

hr.thin[data-v-8c41f506] {
    border-top: 1px solid #bbb
}

.switch-container[data-v-8c41f506] {
    height: 24px
}

.switch-text[data-v-8c41f506] {
    display: inline-block;
    line-height: 24px;
    position: relative;
    padding: 0 8px 0 24px;
    font-size: 12px
}

.navbar-item a {
    display: block;
    color: #fff;
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    cursor: pointer;
}

.navbar-desktop[data-v-b50e56de] .navbar-dropdown {
    min-width: 150px;
    background: var(--color-tooltip-bg);
    box-shadow: 0 10px 100px rgba(10, 16, 24, .08), 0 40px 56px -60px rgba(0, 0, 0, .08);
    border-radius: 8px;
    border: 1px solid var(--color-border);
    color: #606266;
    z-index: 9999;
    margin: 0 0 6px -85px;
    padding: 10px 0;
    position: absolute;
    max-height: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: max-height .3s ease-in-out, visibility .3s;
    -moz-transition: max-height .3s ease-in-out, visibility .3s;
    -ms-transition: max-height .3s ease-in-out, visibility .3s;
    -o-transition: max-height .3s ease-in-out, visibility .3s;
    transition: max-height .3s ease-in-out, visibility .3s
}

.navbar-desktop[data-v-b50e56de] .navbar-link {
    padding: 4px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: block;
    color: var(--color-10)
}

.navbar-desktop[data-v-b50e56de] .navbar-link:hover {
    color: #0c61f7;
    text-decoration: underline
}

.navbar-desktop[data-v-b50e56de] .navbar-dropdown:after {
    content: "";
    position: absolute;
    top: -10px;
    left: 100px;
    width: 0;
    height: 0;
    border-bottom: 10px solid var(--color-tooltip-bg);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent
}

.navbar-desktop[data-v-b50e56de] .navbar-item-hoverable:hover>.navbar-dropdown,
.navbar-desktop[data-v-b50e56de] .navbar-item-hoverable:hover>div>.navbar-dropdown,
.navbar-desktop[data-v-b50e56de] .navbar-item.hover-item>.navbar-dropdown {
    max-height: 100vh;
    visibility: visible;
    overflow: visible
}

.navbar-desktop[data-v-b50e56de] .navbar-item:hover:not(.switch-outer) {
    background: #005790
}

.navbar-display[data-v-b50e56de] {
    display: block !important
}

.navbar-hidden[data-v-b50e56de] {
    display: none !important
}

.navbar[data-v-b50e56de] {
    background-color: var(--color-theme-bg);
    width: calc(100% - 80px);
    height: auto;
    display: block;
    overflow: hidden;
    min-height: 39px;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out
}

.navbar>div[data-v-b50e56de] {
    float: left
}

.navbar-brand[data-v-b50e56de] {
    display: block;
    color: #fff;
    padding: 16px 8px;
    line-height: 24px;
    font-size: 14px;
    cursor: pointer;
    padding-bottom: 0px;
}

.navbar-brand img[data-v-b50e56de] {
    height: 24px;
    vertical-align: middle
}

.navbar-item[data-v-b50e56de] {
    display: block;
    color: #fff;
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    cursor: pointer
}

.navbar-item>a[data-v-b50e56de] {
    color: #fff
}

.navbar-right-item[data-v-b50e56de] {
    float: right !important
}

.navbar-right-item>.navbar-dropdown[data-v-b50e56de] {
    margin-left: -100px
}

.navbar-collapse-button[data-v-b50e56de] {
    float: right;
    padding: 6px 10px !important;
    display: none !important;
    margin: 3px !important
}

.navbar-sub[data-v-b50e56de] {
    display: inline-block;
    vertical-align: top;
    min-width: 200px;
    overflow: auto;
    max-height: calc(100vh - 120px)
}

.navbar-sub-title[data-v-b50e56de] {
    margin: 4px 16px;
    font-size: 13px;
    line-height: 24px;
    font-weight: 700;
    color: var(--color-10) !important;
    border-bottom: 1px solid #dcdfe6
}

.nuxt-link-active[data-v-b50e56de] {
    font-weight: 700;
    background-color: var(--color-neutral-30)
}

.switch-container[data-v-b50e56de] {
    height: 24px;
    border-left: 1px solid hsla(0, 0%, 100%, .2);
    border-right: 1px solid hsla(0, 0%, 100%, .2)
}

.switch-text[data-v-b50e56de] {
    display: inline-block;
    line-height: 24px;
    position: relative;
    padding: 0 8px 0 24px;
    font-size: 12px
}

.bg-theme[data-v-b50e56de] {
    background-color: var(--color-theme-bg)
}

.login-button[data-v-b50e56de] {
    background: #fff;
    color: #195daa;
    height: 32px
}

.tablet-navbar-link {
    padding: 8px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: block;
    color: #fff
}

.tablet-navbar-link:hover {
    text-decoration: underline
}

.navbar-display[data-v-286c7b72] {
    display: block !important
}

.navbar-hidden[data-v-286c7b72] {
    display: none !important
}

.navbar[data-v-286c7b72] {
    width: calc(100% - 80px);
    height: auto;
    display: block;
    overflow: auto;
    min-height: 39px;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out
}

.navbar>div[data-v-286c7b72] {
    float: left
}

.navbar-brand[data-v-286c7b72] {
    display: block;
    color: #fff;
    padding: 8px;
    line-height: 24px;
    font-size: 14px;
    cursor: pointer
}

.navbar-brand img[data-v-286c7b72] {
    height: 24px;
    vertical-align: middle
}

.navbar-tablet[data-v-286c7b72] .navbar-item {
    display: block;
    color: #fff;
    padding: 8px 16px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer
}

.navbar-tablet[data-v-286c7b72] .navbar-item a {
    color: #fff !important
}

.navbar-tablet[data-v-286c7b72] .navbar-item:hover>.navbar-dropdown {
    max-height: 100vh;
    visibility: visible;
    overflow: visible
}

.navbar-tablet[data-v-286c7b72] .navbar-item-hover:hover,
.navbar-tablet[data-v-286c7b72] .navbar-item:hover {
    background: var(--color-theme-active-bg)
}

.navbar-tablet[data-v-286c7b72] .navbar-right-item {
    position: absolute;
    right: 40px
}

.navbar-tablet[data-v-286c7b72] .navbar-right-item-2 {
    position: absolute;
    right: 140px
}

.navbar-tablet[data-v-286c7b72] .navbar-collapse-button {
    float: right;
    padding: 6px 10px !important;
    display: none !important;
    margin: 3px !important
}

.navbar-tablet[data-v-286c7b72] .navbar-dropdown {
    min-width: 150px;
    background: #eaeff3;
    box-shadow: 0 10px 100px rgba(10, 16, 24, .08), 0 40px 56px -60px rgba(0, 0, 0, .08);
    border-radius: 8px;
    color: #606266;
    z-index: 9999;
    margin: 28px 0 6px -100px;
    padding: 10px 0;
    border: none;
    position: absolute;
    max-height: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: max-height .3s ease-in-out, visibility .3s;
    -moz-transition: max-height .3s ease-in-out, visibility .3s;
    -ms-transition: max-height .3s ease-in-out, visibility .3s;
    -o-transition: max-height .3s ease-in-out, visibility .3s;
    transition: max-height .3s ease-in-out, visibility .3s
}

.navbar-tablet[data-v-286c7b72] .navbar-dropdown:after {
    content: "";
    position: absolute;
    top: -10px;
    left: 100px;
    width: 0;
    height: 0;
    border-bottom: 10px solid #eaeff3;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent
}

.navbar-tablet[data-v-286c7b72] .navbar-sub {
    display: inline-block;
    vertical-align: top;
    min-width: 200px;
    overflow: auto;
    width: 100%
}

.navbar-tablet[data-v-286c7b72] .navbar-sub-title {
    padding: 12px 0 8px;
    font-size: 13px;
    line-height: 24px;
    color: #fff;
    opacity: .6;
    border-bottom: 1px solid #dcdfe6
}

.navbar-tablet[data-v-286c7b72] .nuxt-link-active {
    font-weight: 700;
    text-decoration: underline
}

.navbar-tablet[data-v-286c7b72] .switch-container {
    height: 24px
}

.navbar-tablet[data-v-286c7b72] .switch-text {
    display: inline-block;
    line-height: 24px;
    position: relative;
    padding: 0 8px 0 0;
    font-size: 12px
}

.navbar-tablet[data-v-286c7b72] .expand-icon {
    font-size: 10px;
    margin-right: -3px;
    padding: 7px;
    border-radius: 12px
}

.navbar-tablet[data-v-286c7b72] .expand-icon:active,
.navbar-tablet[data-v-286c7b72] .expand-icon:hover {
    background: var(--color-primary-pressed)
}

@media screen and (max-width: 600px) {
    .navbar-tablet[data-v-286c7b72] .navbar-right-item {
        position: absolute;
        right: 16px
    }

    .navbar-tablet[data-v-286c7b72] .navbar-right-item-2 {
        position: absolute;
        right: 100px
    }

    .navbar-tablet[data-v-286c7b72] .navbar-brand {
        padding: 8px 0
    }
}

.usernav-tablet[data-v-286c7b72] {
    border-color: rgba(var(--color-border-rgb), .2) !important
}

.usernav-tablet[data-v-286c7b72] .navbar-link {
    margin: 0 !important;
    color: #fff !important;
    font-size: 14px !important;
    padding: 8px 0 !important;
    display: flex;
    line-height: 20px !important
}

.usernav-tablet[data-v-286c7b72] .navbar-link i {
    font-size: 20px
}

.usernav-tablet[data-v-286c7b72] .gf-border-b {
    border-color: rgba(var(--color-border-rgb), .2) !important;
    padding-bottom: var(--size-md) !important;
    margin-bottom: var(--size-md) !important
}

.dialog[data-v-29b75c56] {
    margin-bottom: 0
}

.ready-print-button[data-v-29b75c56] {
    background: #67c23a;
    color: #fff
}

.print-dialog-button[data-v-29b75c56] {
    float: right
}

.content-div[data-v-29b75c56] {
    margin: 5px auto 8px
}

label[data-v-29b75c56] {
    margin-right: 20px
}

.notation[data-v-29b75c56] {
    margin: 45px auto 4px
}

.print-button[data-v-29b75c56] {
    font-size: 14px;
    background: var(--color-input-bg);
    border: 1px solid var(--color-border);
    box-sizing: border-box;
    border-radius: 6px;
    line-height: 24px;
    display: inline-block;
    padding: 0 12px;
    color: var(--color-10);
    cursor: pointer
}

.gfp-user-group-fill[data-v-b9c9c984] {
    line-height: 1px
}

.portfolio-icon {
    width: 15px
}

.portfolio-text {
    font-size: 12px;
    line-height: 30px;
    margin: 0 10px;
    text-decoration: none;
    color: #005790
}

.portfolio-popover {
    padding: 0 !important
}

.input-section[data-v-73745932] {
    margin-top: 4px;
    padding: 2px
}

.el-icon-close[data-v-73745932] {
    padding: 5px 0
}

.hover-pointer[data-v-73745932]:hover {
    cursor: pointer
}

.tags-view-popover-button[data-v-a583da08] {
    padding: 7px 10px !important
}

.close-button[data-v-a583da08] {
    cursor: pointer;
    color: red !important;
    text-align: center;
    border: 1px solid red;
    margin: 4px;
    border-radius: 2px
}

.tags-view-popover-item[data-v-a583da08] {
    display: block;
    font-size: 12px;
    line-height: 20px;
    padding: 4px 8px;
    color: #195daa;
    cursor: pointer
}

.tags-view-popover-item[data-v-a583da08]:hover {
    background: #dcdfe6
}

.tags-view-popover-item .el-icon-close[data-v-a583da08] {
    padding: 4px 0
}

.task-download-popover[data-v-268811c3] {
    border-radius: 0;
    padding: 0 !important;
    margin-top: 17px !important;
    margin-left: -470px !important
}

.task-download-item[data-v-268811c3] {
    padding: 10px
}

.task-download-title[data-v-268811c3] {
    font-size: 12px;
    line-height: 20px
}

.el-popper[x-placement^=bottom] .popper__arrow[data-v-268811c3] {
    left: 95% !important
}

.close-icon[data-v-268811c3] {
    position: absolute;
    top: 4px;
    right: 4px
}

.tags-view-tag[data-v-8b2ae188] {
    z-index: 100;
    position: relative;
    vertical-align: top;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: var(--color-10);
    border: 1px solid var(--color-border);
    border-radius: 6px;
    background: var(--color-tooltip-bg);
    padding: 0 8px;
    text-transform: capitalize
}

.tags-view-tag[data-v-8b2ae188]:nth-child(n+1) {
    margin-left: 4px
}

.tags-view-tag.active[data-v-8b2ae188] {
    color: var(--color-light-neutral-10);
    background: var(--color-primary-color);
    border-color: var(--color-primary-color)
}

.tags-view-tag .el-icon-close[data-v-8b2ae188] {
    height: 12px;
    width: 12px;
    font-size: 8px;
    border-radius: 50%;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transform-origin: 100% 50%;
    text-align: center;
    line-height: 12px
}

.tags-view-tag .el-icon-close[data-v-8b2ae188]:hover {
    background-color: #b4bccc;
    color: #fff
}

.tags-view-tag .close-popover[data-v-8b2ae188] {
    position: absolute;
    right: 0;
    left: 0;
    top: 30px;
    color: #fff;
    background: #f56c6c;
    border: 1px solid #f56c6c;
    z-index: 9999;
    text-align: center;
    cursor: pointer
}

.tags-view-stock-tag {
    z-index: 100;
    vertical-align: top;
    position: relative;
    display: inline-block;
    font-size: 12px;
    color: var(--color-10);
    border: 1px solid var(--color-border);
    border-radius: 6px;
    background: var(--color-tooltip-bg);
    padding: 0 8px;
    height: 30px
}

.tags-view-stock-tag:nth-child(n+1) {
    margin-left: 4px
}

.tags-view-stock-tag.active {
    background: var(--color-primary-surface);
    border-color: var(--color-primary-border)
}

.tags-view-stock-tag.active .negative,
.tags-view-stock-tag.active .positive {
    color: #fff
}

.tags-view-stock-tag .positive {
    color: green
}

.tags-view-stock-tag .negative {
    color: red
}

.tags-view-stock-tag .title {
    font-size: 11px;
    line-height: 14px;
    margin-right: 14px;
    text-align: center;
    margin-top: 3px
}

.tags-view-stock-tag .sub {
    font-size: 10px;
    line-height: 10px;
    margin-right: 14px;
    text-align: center
}

.tags-view-stock-tag .el-icon-close {
    position: absolute;
    top: 8px;
    right: 6px;
    height: 12px;
    line-height: 12px;
    width: 12px;
    font-size: 8px;
    border-radius: 50%;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transform-origin: 100% 50%;
    text-align: center
}

.tags-view-stock-tag .el-icon-close:hover {
    background-color: #b4bccc;
    color: #fff
}

.tags-view-stock-tag .close-popover {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    color: #fff;
    background: #f56c6c;
    border: 1px solid #f56c6c;
    z-index: 9999;
    padding: 6px 0;
    text-align: center;
    cursor: pointer
}

.tags-view-button {
    font-size: 12px;
    background: var(--color-input-bg);
    border: 1px solid var(--color-border);
    box-sizing: border-box;
    border-radius: 6px;
    line-height: 30px;
    display: inline-block;
    padding: 0 12px;
    color: var(--color-10);
    cursor: pointer
}

@media only screen and (min-width: 900px) {
    .tags-view-button:after {
        content: attr(data-name)
    }

    .tags-view-button i {
        margin-right: 2px
    }
}

.tags-view[data-v-38c8ef2a] {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);
    height: 32px;
    padding: 8px 0
}

.right-8[data-v-38c8ef2a] {
    margin-right: 8px
}

.tags-view-section[data-v-38c8ef2a] {
    height: 100%
}

.tags-view-section>div[data-v-38c8ef2a] {
    display: inline-block
}

.tags-view-section .el-button[data-v-38c8ef2a] {
    margin: 0 !important
}

.notification-bar[data-v-1cfff4c8] {
    font-family: Open Sans, sans-serif;
    position: relative
}

.alert-item[data-v-1cfff4c8] {
    border-radius: 0;
    padding: 7px 0 !important;
    background-color: var(--color-warning-color) !important;
    width: 100%;
    text-align: center
}

.title-section[data-v-1cfff4c8] {
    font-style: normal;
    text-align: center
}

.title-section-sm[data-v-1cfff4c8] {
    font-size: 12px;
    line-height: 16px
}

.title-section-mid[data-v-1cfff4c8] {
    font-size: 12px;
    letter-spacing: 2px
}

.title-section-xl[data-v-1cfff4c8] {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 2px;
    font-weight: 700
}

body {
    overflow: hidden
}

.fix-width {
    max-width: 1400px
}

.page-container {
    padding: 8px;
    margin: auto
}

#components-root {
    scroll-behavior: smooth
}

#components-side {
    min-width: 50px;
    background: #fff
}

@media only screen and (max-width: 800px) {
    #components-side {
        display: none
    }
}

.main-container {
    overflow: hidden;
    background-color: var(--color-1)
}

#myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: .3s
}

.modal {
    display: none;
    position: fixed;
    z-index: 9999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #000;
    background-color: rgba(0, 0, 0, .9)
}

.modal-content {
    max-width: 1000px;
    height: 80%
}

#caption,
.modal-content {
    margin: auto;
    display: block
}

#caption {
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px
}

#caption,
.modal-content {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: .6s;
    animation-name: zoom;
    animation-duration: .6s
}

@-webkit-keyframes zoom {
    0% {
        -webkit-transform: scale(0)
    }

    to {
        -webkit-transform: scale(1)
    }
}

@keyframes zoom {
    0% {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

.external-link-message {
    width: 50% !important
}

.share-button[data-v-4342551b] {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #3b5998;
    color: #fff;
    border-color: transparent !important
}

.share-button[data-v-4342551b]:hover {
    background: #4665a4;
    color: #fff
}

.square-button[data-v-4342551b] {
    width: 20px;
    height: 20px;
    border-radius: 6px
}

.share-button[data-v-46f1eedc] {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #0077b5;
    color: #fff;
    border-color: transparent !important
}

.share-button[data-v-46f1eedc]:hover {
    background: #0a81bd;
    color: #fff
}

.share-button[data-v-3c1ea7ac] {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #414141;
    color: #fff;
    border-color: transparent !important
}

.share-button[data-v-3c1ea7ac]:hover {
    background: #4b4b4b;
    color: #fff
}

.premium-icon[data-v-9b75a44e] {
    padding: 0 .2rem
}

.plus-icon[data-v-505fe70d],
.premium-icon[data-v-9b75a44e] {
    font-size: .4rem;
    border-radius: .2rem !important;
    background: #ffb401;
    color: #fff;
    vertical-align: middle;
    display: inline-block;
    height: .6rem;
    line-height: .7rem
}

.plus-icon[data-v-505fe70d] {
    padding: 0 .3rem 0 .2rem
}

.plus-icon[data-v-505fe70d]:after {
    background: #005790;
    content: "+";
    padding: 0 .1rem;
    margin-right: -.3rem;
    border-radius: 0 .2rem .2rem 0 !important;
    display: inline-block;
    height: .6rem;
    line-height: .7rem
}

.text-control[data-v-729c248e] {
    line-height: 26px
}

hr[data-v-729c248e] {
    margin: 16px 0
}

.table-row[data-v-729c248e] {
    display: table-row
}

.table-cell[data-v-729c248e] {
    display: table-cell !important;
    vertical-align: middle;
    border-bottom: var(--size-sm) solid transparent
}

.table-row:last-child .table-cell[data-v-729c248e] {
    border-bottom: none
}

.f-label.table-cell[data-v-729c248e] {
    padding-right: var(--size-xl)
}

.form-container[data-v-2231f90b] .f-label {
    flex-basis: 125px;
    line-height: 26px
}

.form-container[data-v-2231f90b] .f-content {
    flex-grow: 1
}

.form-container[data-v-2231f90b] .el-checkbox {
    line-height: 26px
}

.form-container[data-v-2231f90b] .el-input--mini .el-input__inner {
    height: 26px;
    padding-left: 8px;
    font-size: 11px
}

.el-card[data-v-4f884322] {
    border-radius: 4px;
    border: 1px solid var(--color-border);
    background-color: var(--color-bg);
    color: var(--color-9);
    -webkit-transition: .3s;
    transition: .3s;
    padding: 8px
}

.is-always-shadow[data-v-4f884322],
.is-hover-shadow:focus .is-hover-shadow[data-v-4f884322]:hover {
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1)
}

.default-content[data-v-34f0d138] {
    width: calc(100vw - 120px);
    height: 37px;
    display: inline-block;
    vertical-align: top
}

.fieldset[data-v-34f0d138] {
    padding: 0;
    border: none;
    border-top: 1px solid var(--color-border)
}

.hscroll[data-v-34f0d138] {
    width: calc(100% - 114px);
    display: inline-block;
    vertical-align: top
}

.hscroll[data-v-34f0d138] .scroll-wrapper {
    display: flex
}

.el-select {
    -webkit-box-sizing: border-box;
    display: inline-block;
    position: relative
}

.el-select .el-select__tags>span {
    display: contents
}

.el-select:hover .el-input__inner {
    border-color: #c0c4cc
}

.el-select .el-input__inner {
    cursor: pointer;
    padding-right: 35px
}

.el-select .el-input__inner:focus {
    border-color: #409eff
}

.el-select .el-input .el-select__caret {
    color: #c0c4cc;
    font-size: 14px;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    cursor: pointer
}

.el-select .el-input .el-select__caret.is-reverse {
    -webkit-transform: rotate(0);
    transform: rotate(0)
}

.el-select .el-input .el-select__caret.is-show-close {
    font-size: 14px;
    text-align: center;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    border-radius: 100%;
    color: #c0c4cc;
    -webkit-transition: color .2s cubic-bezier(.645, .045, .355, 1);
    transition: color .2s cubic-bezier(.645, .045, .355, 1)
}

.el-select .el-input .el-select__caret.is-show-close:hover {
    color: #909399
}

.el-select .el-input.is-disabled .el-input__inner {
    cursor: not-allowed
}

.el-select .el-input.is-disabled .el-input__inner:hover {
    border-color: #e4e7ed
}

.el-select .el-input.is-focus .el-input__inner {
    border-color: #409eff
}

.el-select>.el-input {
    display: block
}

.el-select .el-select__caret {
    transform: none !important
}

.el-select .el-tag__close {
    margin-top: -2px
}

.el-select .el-tag {
    box-sizing: border-box;
    border-color: transparent;
    margin: 2px 0 2px 6px;
    background-color: #f0f2f5
}

.el-select .el-tag__close.el-icon-close {
    background-color: #c0c4cc;
    right: -7px;
    top: 0;
    color: #fff
}

.el-select .el-tag__close.el-icon-close:hover {
    background-color: #909399
}

.el-select .el-tag__close.el-icon-close:before {
    display: block;
    -webkit-transform: translateY(.5px);
    transform: translateY(.5px)
}

.el-select-dropdown {
    position: absolute;
    z-index: 1001;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    background-color: var(--color-tooltip-bg);
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 5px 0
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    color: #409eff;
    background-color: #fff
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover {
    background-color: #f5f7fa
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected:after {
    position: absolute;
    right: 20px;
    font-family: element-icons;
    content: "\e6da";
    font-size: 12px;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.el-select-dropdown .el-scrollbar.is-empty .el-select-dropdown__list {
    padding: 0
}

.el-select-dropdown__empty {
    padding: 10px 0;
    margin: 0;
    text-align: center;
    color: #999;
    font-size: 14px
}

.el-select-dropdown__wrap {
    max-height: 274px
}

.el-select-dropdown__list {
    list-style: none;
    padding: 6px 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-select-dropdown__item {
    font-size: 14px;
    padding: 0 20px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-8);
    height: 34px;
    line-height: 34px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer
}

.el-select-dropdown__item.is-disabled {
    color: #c0c4cc;
    cursor: not-allowed
}

.el-select-dropdown__item.is-disabled:hover {
    background-color: #fff
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
    background-color: var(--color-hover-bg)
}

.el-select-dropdown__item.selected {
    color: #409eff;
    font-weight: 700
}

.el-select-group {
    margin: 0;
    padding: 0
}

.el-select-group__wrap {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0
}

.el-select-group__wrap:not(:last-of-type) {
    padding-bottom: 24px
}

.el-select-group__wrap:not(:last-of-type):after {
    content: "";
    position: absolute;
    display: block;
    left: 20px;
    right: 20px;
    bottom: 12px;
    height: 1px;
    background: #e4e7ed
}

.el-select-group__title {
    padding-left: 20px;
    font-size: 12px;
    color: #909399;
    line-height: 30px
}

.el-select-group .el-select-dropdown__item {
    padding-left: 20px
}

.el-select__input {
    border: none;
    outline: 0;
    padding: 0;
    margin-left: 5px;
    color: #666;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 28px;
    background-color: transparent
}

.el-select__input.is-mini {
    height: 14px
}

.el-select__close {
    cursor: pointer;
    position: absolute;
    top: 8px;
    z-index: 1000;
    right: 25px;
    color: #c0c4cc;
    line-height: 18px;
    font-size: 14px
}

.el-select__close:hover {
    color: #909399
}

.el-select__tags {
    position: absolute;
    line-height: normal;
    white-space: normal;
    z-index: 1;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 100%
}

.aio-screen-editor[data-v-e23d48fa] {
    max-height: 80vh
}

.save-new[data-v-e23d48fa] {
    border-top: 1px solid #e4e7ed;
    font-size: 12px;
    line-height: 30px;
    cursor: pointer;
    color: #005790;
    text-align: center
}

.aio-screen-item .name {
    display: inline-block;
    vertical-align: middle;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.input-section[data-v-26c67272] {
    height: 20px;
    line-height: 16px;
    width: 100%;
    font-size: 12px !important;
    box-sizing: border-box;
    padding-right: 4px
}

.email-alert[data-v-ada10f04] {
    white-space: nowrap;
    cursor: pointer;
    line-height: 20px;
    font-size: 12px;
    padding: 0 8px;
    min-width: 24px
}

.right-icon[data-v-ccb51ac6] {
    float: right;
    padding: 4px
}

.top-right[data-v-ccb51ac6] {
    position: absolute;
    right: 8px;
    top: 8px
}

.loding-icon[data-v-ccb51ac6] {
    display: block;
    width: 20px;
    height: 20px;
    font-size: 20px;
    margin-left: calc(50% - 10px);
    margin-top: 8px
}

.fields-selector-tabs {
    border: 1px solid #e4e7ed;
    border-bottom: 0;
    height: 40px
}

.fields-selector-field-section {
    max-height: 300px;
    overflow-y: auto;
    padding: 5px;
    line-height: 30px;
    border: 1px solid #e4e7ed
}

.fields-tag {
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px
}

.expression-editor-root {
    position: relative
}

#expression-section {
    padding: 5px 5px 25px;
    line-height: 30px;
    min-height: 30px;
    border: 1px solid
}

#expression-section:focus {
    outline: 1px solid #409eff
}

#expression-section:empty:before {
    color: #c0c4cc;
    font-size: 12px;
    content: attr(placeholder)
}

.expression-refresh-icon {
    position: absolute;
    cursor: pointer;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #909399;
    text-align: right;
    bottom: 0;
    right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60%
}

.expression-candidates-card {
    position: absolute;
    top: 105%;
    left: 0;
    right: 0;
    z-index: 999;
    max-height: 300px;
    overflow-y: auto
}

.expression-candidates-tag {
    cursor: pointer;
    margin-bottom: 3px;
    margin-right: 3px
}

.expression-candidates-tag-highlight {
    box-shadow: 0 0 10px #409eff
}

.expression-candidates-item {
    padding: 5px
}

.expression-candidates-item:hover {
    background: #f2f6fc
}

.expression-candidates-item-selected {
    background: #eee
}

.col-border {
    border: 1px solid #eff1f7
}

.customize-limit-button[data-v-53d2e71c] {
    color: #f56c6c;
    border: 1px solid #f56c6c;
    margin: 8px 0;
    padding: 0 8px;
    border-radius: 4px;
    font-size: 13px;
    line-height: 26px;
    display: inline-block
}

.screener-view-editor-field {
    padding: 4px;
    border-bottom: 1px solid #e4e7ed
}

.screener-view-editor-field-card {
    max-height: 40%;
    overflow-y: auto
}

.screener-edit-dialog-container-header[data-v-ccf79d5a] {
    box-sizing: border-box;
    font-size: 12px;
    padding: 0 8px;
    color: var(--color-9);
    border: 1px solid var(--color-border)
}

.screener-edit-dialog-container {
    height: 70vh
}

.screener-edit-dialog-header-button {
    width: 80px;
    text-align: center;
    display: inline-block;
    position: relative
}

.screener-edit-dialog-header-button.selected {
    color: #42b983
}

.screener-edit-dialog-header-button.selected .cursor {
    background: #42b983
}

.screener-edit-dialog-header-button .cursor {
    width: 100%;
    height: 2px;
    position: absolute;
    top: 16px;
    transition: background-color .3s
}

.screener-edit-dialog-side-item {
    padding: 4px 8px;
    border-bottom: 1px solid var(--color-border)
}

.screener-edit-dialog-side-item .subtitle,
.screener-edit-dialog-side-item .title {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.screener-edit-dialog-side-item .subtitle {
    color: #909399
}

.screener-edit-dialog-side-item:hover {
    background: var(--color-hover-bg)
}

.screener-edit-dialog-side {
    border-right: 1px solid #f2f6fc;
    height: calc(70vh - 28px)
}

.screener-edit-dialog-main {
    height: calc(70vh - 28px)
}

.manage-button[data-v-5526531a]:hover {
    color: #409eff
}

.video-item[data-v-28016c54] {
    margin: 0;
    padding: 0;
    vertical-align: top;
    line-height: 20px
}

.video-icon[data-v-28016c54] {
    color: var(--color-youtube)
}

.boolean-title {
    width: 50%;
    margin-left: -4px !important
}

.boolean-content {
    width: 48%;
    margin-left: -4px !important
}

@media only screen and (max-width: 520px) {
    .boolean-title {
        width: 25%
    }

    .boolean-content {
        width: 73%
    }
}

.boolean-select-title {
    width: 46%;
    margin-left: -4px !important
}

.boolean-select-content {
    width: 44%;
    margin-left: -4px !important
}

@media only screen and (max-width: 520px) {
    .boolean-select-title {
        width: 25%;
        min-width: var(--aio-filter-xs-title-min-width)
    }

    .boolean-select-content {
        width: 73%;
        max-width: var(--aio-filter-xs-signal-content-max-width)
    }
}

.input-section[data-v-cbfa58ac] {
    min-height: 24px;
    line-height: 16px;
    width: 100%;
    font-size: 11px !important;
    box-sizing: border-box;
    padding: 12px
}

.search-dropdown[data-v-cbfa58ac] {
    position: absolute;
    background: #fff !important;
    left: 0;
    right: 0;
    z-index: 9999;
    border: 1px solid #dcdfe6;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    cursor: pointer
}

.search-dropdown>[data-v-cbfa58ac] {
    font-size: 12px;
    line-height: 20px;
    padding: 0 4px
}

.selected-item[data-v-cbfa58ac] {
    background: #f0f0f0
}

.check-box[data-v-3f5d7247] .el-checkbox__label {
    color: var(--color-10) !important;
    padding-left: 8px
}

.country-item[data-v-3f5d7247] {
    padding-left: 46px !important
}

.item[data-v-3f5d7247] {
    display: flex;
    align-items: center;
    padding: 0 var(--size-md)
}

.item>i[data-v-3f5d7247] {
    color: var(--color-8);
    font-size: 10px;
    margin-right: 8px
}

.tree-content[data-v-3f5d7247] {
    max-height: calc(50vh - 90px);
    overflow-y: auto;
    min-width: 280px
}

.tree-content[data-v-3f5d7247]::-webkit-scrollbar {
    width: 6px !important
}

@media only screen and (max-width: 520px) {
    .aio-item-title[data-v-3f5d7247] {
        min-width: 100px
    }

    .aio-item-content[data-v-3f5d7247] {
        max-width: calc(100% - 180px)
    }

    .aio-item-content-exclude[data-v-3f5d7247] {
        max-width: 80px !important
    }
}

.aio-customized-operator-select {
    position: relative
}

.aio-customized-operator {
    white-space: nowrap;
    cursor: pointer;
    outline: none !important;
    border: none !important
}

.filter-row .el-col[data-v-78da8454] {
    position: relative
}

.filter-row .col-title[data-v-78da8454] {
    font-size: 11px;
    top: -6px;
    left: 6px;
    position: absolute;
    background: #fff;
    z-index: 1;
    padding: 0 4px;
    color: #606266
}

.filter-row .col-placeholder[data-v-78da8454] {
    line-height: 60px;
    font-size: 12px;
    padding: 0 8px;
    color: #c0c4cc
}

.filter-row .col-content[data-v-78da8454] {
    min-height: 63px;
    line-height: 63px;
    font-size: 12px;
    padding: 0 8px
}

.col-border[data-v-78da8454] {
    border: 1px solid #eff1f7
}

.exp-example[data-v-78da8454] {
    display: relative;
    margin: 0 4px
}

.exp-example .hint[data-v-78da8454] {
    border-top: 1px solid #fff;
    font-size: 11px
}

.customize-limit-button[data-v-78da8454] {
    color: #f56c6c;
    border: 1px solid #f56c6c;
    margin: 8px 0;
    padding: 0 8px;
    border-radius: 4px;
    font-size: 13px;
    line-height: 30px;
    display: inline-block
}

.box,
.delete-dialog-box[data-v-5c4a0a4f] {
    padding: 32px 32px 20px
}

.aio-customized {
    height: 350px;
    overflow-y: auto;
    margin: 0 5px
}

.aio-customized-table th {
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    padding: 11px 16px !important
}

.aio-customized-table td {
    white-space: normal !important;
    padding: 6px 4px !important
}

.aio-customized-table .inactive * {
    color: var(--color-8) !important;
    background: 0 0 !important
}

.aio-customized-table tbody {
    overflow-y: auto
}

.aio-customized-display-name {
    max-height: 45px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.name-td {
    max-width: 200px
}

.date-range-filter-title {
    width: 46%;
    margin-left: -4px !important
}

.date-range-filter-content {
    width: 18%;
    margin-left: -4px !important
}

.date-range-filter-to {
    width: 8%;
    margin-left: -4px !important;
    text-align: center
}

@media only screen and (max-width: 520px) {
    .date-range-filter-title {
        width: 25%;
        min-width: var(--aio-filter-xs-title-min-width)
    }

    .date-range-filter-to {
        width: 13%;
        max-width: var(--aio-filter-xs-range-to-max-width)
    }

    .date-range-filter-content {
        width: 30%;
        max-width: var(--aio-filter-xs-range-content-max-width)
    }
}

.date-select-title {
    width: 46%;
    margin-left: -4px !important
}

.date-select-content {
    width: 44%;
    margin-left: -4px !important
}

@media only screen and (max-width: 520px) {
    .date-select-title {
        width: 25%;
        min-width: var(--aio-filter-xs-title-min-width)
    }

    .date-select-content {
        width: 73%;
        max-width: var(--aio-filter-xs-signal-content-max-width)
    }
}

.el-row .el-col [style*="width: 50%"][data-v-57d6f0c4] {
    height: 40%
}

.aio-guru-filter[data-v-57d6f0c4] {
    padding: 4px;
    margin: 0 4px;
    background: #fff
}

.aio-guru-filter-input[data-v-57d6f0c4] {
    width: 156px !important
}

.aio-guru-filter-title .el-button[data-v-57d6f0c4] {
    padding: 2px;
    font-size: 11px;
    border-radius: 0;
    margin-left: 4px
}

.aio-guru-filter-title .selected[data-v-57d6f0c4] {
    background-color: rgba(103, 194, 58, .1);
    border: 1px solid rgba(103, 194, 58, .2);
    color: #67c23a !important
}

.aio-guru-filter-sub-title[data-v-57d6f0c4] {
    background: #f6f6f6;
    font-size: 12px;
    line-height: 20px;
    padding: 0 8px;
    color: #606266
}

.aio-guru-filter-sub-title .el-button[data-v-57d6f0c4] {
    padding: 2px;
    font-size: 11px;
    border-radius: 0;
    margin-left: 4px
}

.aio-guru-item-gurus[data-v-57d6f0c4] {
    height: 240px;
    overflow-y: auto;
    padding: 0 4px
}

.aio-guru-item-guru[data-v-57d6f0c4] {
    font-size: 12px;
    color: #606266;
    line-height: 20px;
    height: 20px;
    margin: 4px 0;
    vertical-align: middle;
    cursor: pointer
}

.aio-guru-item-guru[data-v-57d6f0c4]:hover {
    background: #eee
}

.aio-guru-avatar[data-v-57d6f0c4] {
    height: 20px;
    width: 20px;
    border: 1px solid #e4e7ed;
    margin: 1px;
    border-radius: 10px;
    display: inline-block
}

.aio-guru-name[data-v-57d6f0c4] {
    display: inline-block;
    vertical-align: top;
    padding-left: 4px
}

.aio-guru-filter-loading[data-v-57d6f0c4] {
    font-size: 12px;
    color: #409eff;
    line-height: 20px;
    margin: 4px 0;
    cursor: pointer;
    text-align: center;
    font-weight: 700
}

.check-box[data-v-3818820c] .el-checkbox__label {
    color: var(--color-10) !important;
    padding-left: 8px
}

.item[data-v-3818820c] {
    display: flex;
    align-items: center;
    padding: 0 var(--size-md)
}

.item>i[data-v-3818820c] {
    color: var(--color-8);
    font-size: 10px;
    margin-right: 8px
}

.group-item[data-v-3818820c] {
    padding-left: 30px !important
}

.industry-item[data-v-3818820c] {
    padding-left: 66px !important
}

.tree-content[data-v-3818820c] {
    max-height: calc(50vh - 90px);
    overflow-y: auto
}

.tree-content[data-v-3818820c]::-webkit-scrollbar {
    width: 6px !important
}

@media only screen and (max-width: 520px) {
    .aio-item-title[data-v-3818820c] {
        min-width: 100px
    }

    .aio-item-content[data-v-3818820c] {
        max-width: calc(100% - 180px)
    }

    .aio-item-content-exclude[data-v-3818820c] {
        max-width: 80px !important
    }
}

.number-range-filter-title {
    width: 46%;
    margin-left: -4px !important
}

.number-range-filter-content {
    width: 18%;
    margin-left: -4px !important
}

.ant-dropdown-menu {
    max-height: 250px;
    overflow: auto;
}

.number-range-filter-to {
    width: 8%;
    margin-left: -9px !important;
    text-align: center
}

@media only screen and (max-width: 520px) {
    .number-range-filter-title {
        width: 25%;
        min-width: var(--aio-filter-xs-title-min-width)
    }

    .number-range-filter-to {
        width: 13%;
        max-width: var(--aio-filter-xs-range-to-max-width)
    }

    .number-range-filter-content {
        width: 30%;
        max-width: var(--aio-filter-xs-range-content-max-width)
    }
}

.highlight {
    background-color: #ff0
}

.number-select-title {
    width: 50%;
    margin-left: -4px !important
}

.number-select-content {
    width: 44%;
    margin-left: -4px !important
}

@media only screen and (max-width: 520px) {
    .number-select-title {
        width: 25%
    }

    .number-select-content {
        width: 73%
    }
}

@media only screen and (max-width: 520px) {
    .aio-item-title[data-v-22d46c0a] {
        min-width: 100px
    }

    .aio-item-content[data-v-22d46c0a] {
        max-width: calc(100% - 180px)
    }

    .aio-item-content-exclude[data-v-22d46c0a] {
        max-width: 80px !important
    }
}

.aio-stock-filter-sub-item {
    font-size: 11px;
    color: #909399;
    margin-left: 5px
}

.aio-stock-filter-sub-item .el-icon-caret-top {
    color: #67c23a
}

.aio-stock-filter-sub-item .el-icon-caret-down {
    color: #f56c6c
}

.aio-stock-filter-input .el-input-group__prepend {
    padding: 0;
    border: none
}

.aio-stock-filter-input .el-tag {
    margin-right: 1px;
    cursor: pointer
}

@media only screen and (max-width: 520px) {
    .aio-item-title[data-v-3151375d] {
        min-width: 100px
    }

    .aio-item-content[data-v-3151375d] {
        max-width: calc(100% - 180px)
    }

    .aio-item-content-exclude[data-v-3151375d] {
        max-width: 80px !important
    }
}

.string-select-title {
    width: 46%;
    margin-left: -4px !important
}

.string-select-content {
    width: 44%;
    margin-left: -4px !important
}

@media only screen and (max-width: 520px) {
    .string-select-title {
        width: 25%;
        min-width: var(--aio-filter-xs-title-min-width)
    }

    .string-select-content {
        width: 73%;
        max-width: var(--aio-filter-xs-signal-content-max-width)
    }
}

.add-icon[data-v-d357779a] {
    border: 2px solid var(--color-primary-color);
    width: 12px;
    height: 12px;
    border-radius: 8px;
    position: relative
}

.add-icon[data-v-d357779a]:before {
    position: absolute;
    top: 2px;
    left: 2px;
    font-size: 8px
}

.portfolio-create-dialog .el-dialog__body>[data-v-d357779a] {
    font-size: 12px;
    margin-top: 4px
}

.header-section[data-v-d357779a] {
    font-size: 14px;
    line-height: 30px
}

.body-section[data-v-d357779a] {
    height: 80px;
    padding: 4px;
    overflow-y: scroll;
    border: 1px solid #e4e7ed
}

.body-section .el-radio[data-v-d357779a] {
    display: block
}

.button-section>[data-v-d357779a] {
    margin-left: 8px
}

.filter-items[data-v-d357779a] {
    margin-top: 8px
}

.el-dialog__header[data-v-d357779a] {
    background-color: #eee;
    font-weight: 700
}

.subscribe-link[data-v-d357779a] {
    display: inline-block;
    font-size: 16px;
    line-height: 36px;
    background: #fc3;
    color: #f53;
    border: 1px solid #f53;
    border-radius: 4px;
    width: 100%
}

.historical-filter {
    cursor: pointer
}

.active {
    font-weight: 700;
    color: #67c23a;
    font-size: 13px
}

.date-select-title[data-v-5d892d69] {
    width: 46%;
    margin-left: -4px !important
}

.date-select-content[data-v-5d892d69] {
    width: 44%;
    margin-left: -4px !important
}

@media only screen and (max-width: 520px) {
    .date-select-title[data-v-5d892d69] {
        width: 25%
    }

    .date-select-content[data-v-5d892d69] {
        width: 73%
    }
}

.multi-select-item[data-v-5d892d69] {
    color: #195daa;
    border-bottom: 1px solid #ebeef5
}

.right-top[data-v-5d892d69] {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1
}

.el-checkbox[data-v-5d892d69] {
    display: flex
}

.select-input[data-v-5d892d69] {
    width: 44%;
    margin-left: -4px
}

.index-title[data-v-5d892d69] {
    width: 23%
}

.index-input[data-v-5d892d69] {
    width: 50%
}

.aio-group-title {
    padding: 0 4px;
    font-size: 12px;
    line-height: 16px;
    height: 16px;
    cursor: pointer;
    text-align: center
}

.aio-item-link:hover * {
    color: #195daa;
    text-decoration: underline
}

.aio-item-title {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 11px;
    color: var(--color-10);
    text-align: right;
    padding: 0 4px;
    cursor: pointer
}

.aio-item-content,
.aio-item-title {
    display: inline-block;
    height: 20px;
    line-height: 20px;
    vertical-align: top;
    white-space: normal
}

.aio-item-content {
    font-size: 12px;
    color: var(--color-9);
    position: relative
}

.aio-item-border-box {
    padding: 0 4px;
    border: 1px solid var(--color-border);
    line-height: 18px;
    font-size: 11px;
    font-weight: 700;
    background: var(--color-bg);
    white-space: nowrap;
    cursor: pointer
}

.aio-item-border-box:focus {
    outline: none !important;
    border-color: #409eff
}

.aio-item-border-box.selected {
    color: var(--color-primary-color) !important
}

.aio-item-el-input .el-input__inner {
    height: 20px !important;
    line-height: 20px !important;
    padding: 0 var(--size-xs)
}

.aio-item-drop-down {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    background: #fff;
    z-index: 9999;
    border: 1px solid #dcdfe6;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-height: 400px;
    overflow-y: auto
}

.aio-drop-down-item,
.aio-item-drop-down .item {
    white-space: nowrap;
    cursor: pointer;
    line-height: 20px;
    font-size: 12px;
    padding: 0 8px;
    min-width: 24px
}

.aio-item-checkbox {
    margin: 1px !important
}

.aio-item-checkbox .el-checkbox__label {
    font-size: 11px;
    padding-left: 4px
}

.aio-selected {
    background: #eee !important
}

.add-to-view {
    cursor: pointer
}

.aio-group-fieldset {
    padding: 0;
    border: none;
    border-top: 1px solid var(--color-border)
}

.filter-row-section {
    position: relative;
    width: 100%
}

.filter-row-item {
    display: block;
    position: absolute
}

.el-autocomplete {
    position: relative;
    display: inline-block
}

.el-autocomplete-suggestion {
    margin: 5px 0;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    border-radius: 4px;
    border: 1px solid var(--color-border);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: var(--color-bg)
}

.el-autocomplete-suggestion__wrap {
    max-height: 280px;
    padding: 10px 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-autocomplete-suggestion__list {
    margin: 0;
    padding: 0
}

.el-autocomplete-suggestion li {
    padding: 0 20px;
    margin: 0;
    line-height: 34px;
    cursor: pointer;
    color: #606266;
    font-size: 14px;
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.el-autocomplete-suggestion li.highlighted,
.el-autocomplete-suggestion li:hover {
    background-color: var(--color-hover-bg)
}

.el-autocomplete-suggestion li.divider {
    margin-top: 6px;
    border-top: 1px solid #000
}

.el-autocomplete-suggestion li.divider:last-child {
    margin-bottom: -6px
}

.el-autocomplete-suggestion.is-loading li {
    text-align: center;
    height: 100px;
    line-height: 100px;
    font-size: 20px;
    color: #999
}

.el-autocomplete-suggestion.is-loading li:after {
    display: inline-block;
    content: "";
    height: 100%;
    vertical-align: middle
}

.el-autocomplete-suggestion.is-loading li:hover {
    background-color: #fff
}

.el-autocomplete-suggestion.is-loading .el-icon-loading {
    vertical-align: middle
}

.filter-search-highlight[data-v-83ebd9d4] {
    -webkit-box-shadow: 0 0 4px 4px rgba(103, 194, 58, .5);
    -moz-box-shadow: 0 0 4px 4px rgba(103, 194, 58, .5);
    box-shadow: 0 0 4px 4px rgba(103, 194, 58, .5)
}

.field-search[data-v-d7dffec6] {
    margin: 8px
}

.rank-popvoer[data-v-d7dffec6] {
    margin-top: 4px !important
}

.rank-selector[data-v-d7dffec6] {
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid #dcdfe6;
    font-size: 11px;
    color: #606266;
    background: #fff;
    line-height: 17px;
    cursor: pointer;
    margin-right: 8px;
    position: relative
}

.field-selector[data-v-d7dffec6] {
    display: inline-block;
    min-width: 60px;
    padding: 0 4px;
    outline: none
}

.sort-selector[data-v-d7dffec6] {
    display: inline-block;
    border-left: 1px solid #dcdfe6;
    padding: 0 4px;
    margin-left: -4px;
    outline: none
}

.field-selector.selected[data-v-d7dffec6],
.sort-selector.selected[data-v-d7dffec6] {
    background-color: rgba(103, 194, 58, .1) !important;
    color: #67c23a !important;
    font-weight: 700
}

.remove-icon[data-v-d7dffec6] {
    display: inline-block;
    border-left: 1px solid #dcdfe6;
    color: #f56c6c;
    padding: 0 4px;
    margin-left: -4px
}

.rank-filter[data-v-50e9212a] {
    min-height: 20px;
    padding: 8px 0;
    margin: 0 24px;
    border-top: 1px solid #fff
}

.filter-section[data-v-13991ee7] {
    padding: 8px 0;
    background: var(--color-box-bg)
}

.base-checked[data-v-3066cbed] {
    border-color: var(--color-primary-color) !important;
    background-color: var(--color-primary-surface) !important
}

.exchange-box[data-v-3066cbed] {
    min-width: 80px;
    transition: width .1s ease !important;
    position: relative;
    margin: 0;
    display: flex !important
}

.exchange-box.disabled .exchange-region[data-v-3066cbed] {
    color: #f63
}

.exchange-box .exchange-region[data-v-3066cbed] {
    vertical-align: super
}

.exchange-box .el-checkbox-inner[data-v-3066cbed] {
    width: 12px;
    height: 12px
}

.exchange-box .el-checkbox-inner[data-v-3066cbed]:after {
    left: 3px;
    top: 0;
    height: 8px
}

.exchange-box[data-v-3066cbed] .el-checkbox__label {
    padding: 3px 0 3px 10px
}

.exchange-box[data-v-3066cbed] .el-checkbox__input {
    height: 16px
}

.exchange-box .el-checkbox__input.is-checked+.el-checkbox__label[data-v-3066cbed] {
    color: #67c23a
}

.exchange-box .exchange-popover[data-v-3066cbed] {
    position: absolute;
    top: -12px;
    left: 40px;
    background: #f6f6f6;
    border: 1px solid #000;
    color: #303133;
    z-index: 9999;
    padding: 0 4px
}

.exchange-box .exchange-popover-orange[data-v-3066cbed] {
    position: absolute;
    top: -12px;
    left: 40px;
    background: #f53;
    border: 1px solid #000;
    color: #fff;
    z-index: 9999;
    padding: 0 4px
}

.exchange-box .exchange-top[data-v-3066cbed] {
    color: var(--color-10)
}

.exchange-col[data-v-6da772cf] {
    position: relative
}

.exchange-col[data-v-6da772cf]::-webkit-scrollbar {
    width: 4px !important
}

.exchange-col[data-v-6da772cf] .el-checkbox__label {
    color: var(--color-10) !important;
    font-size: 11px
}

.exchange-col-lg[data-v-6da772cf] {
    display: inline-block;
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    vertical-align: top
}

.exchange-col-md[data-v-6da772cf] {
    display: block;
    border-bottom: 1px solid var(--color-border);
    width: 100% !important;
    min-height: 36px;
    position: relative;
    margin-left: 114px
}

.exchange-col-item[data-v-6da772cf] {
    margin: 4px 8px 4px 0
}

.exchange-col-item .el-checkbox__label[data-v-6da772cf] {
    font-size: 11px !important;
    padding-left: 4px
}

.exchange-col-item-lg[data-v-6da772cf] {
    display: block !important
}

.exchange-col-item-md[data-v-6da772cf] {
    display: inline-block !important
}

.exchange-col-subscribe[data-v-6da772cf] {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    background: rgba(0, 0, 0, .5);
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    padding: 8px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    transition: visibility 0s, opacity .5s ease-out
}

.exchange-col-subscribe a[data-v-6da772cf] {
    padding: 4px 8px;
    margin: 8px;
    border-radius: 20px !important;
    color: #fff;
    background: #f56c6c
}

.region-hint-lg[data-v-6da772cf] {
    display: none
}

.region-hint-md[data-v-6da772cf] {
    position: absolute;
    left: -114px;
    top: 0;
    font-size: 13px;
    line-height: 30px;
    padding: 0 18px
}

.region-exchange-default {
    position: absolute;
    right: 8px;
    bottom: 8px;
    font-size: 12px;
    cursor: pointer;
    color: #409eff
}

.default-content[data-v-e4263f44] {
    width: calc(100vw - 120px);
    height: 37px;
    display: inline-block;
    vertical-align: top
}

.screener-field-list-editor .el-input[data-v-63cd8764] {
    box-sizing: border-box
}

.fields-section[data-v-63cd8764] {
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden
}

.title[data-v-63cd8764] {
    padding: 8px var(--size-md) 4px
}

.item[data-v-63cd8764] {
    font-size: 12px;
    position: relative;
    cursor: pointer
}

.item .el-checkbox[data-v-63cd8764] {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 11px;
    line-height: 14px;
    padding: var(--size-xs) 0
}

.item .icon[data-v-63cd8764] {
    position: absolute;
    top: 6px;
    right: 4px
}

.item[data-v-63cd8764]:hover {
    background: var(--color-hover-bg)
}

.gf.el-checkbox[data-v-63cd8764] .el-checkbox__input+.el-checkbox__label {
    color: var(--color-9) !important;
    font-size: 11px;
    font-weight: 500;
    line-height: 14px !important;
    white-space: normal;
    word-break: break-word
}

.aio-view-editor[data-v-7116be8f] {
    max-height: 80vh
}

.aio-view-editor .el-form-item[data-v-7116be8f] {
    margin-bottom: 0 !important
}

.is-public[data-v-7116be8f] {
    padding: 0 !important
}

.is-public[data-v-7116be8f] .el-checkbox__label {
    font-weight: 400
}

.save-new[data-v-7116be8f] {
    border-top: 1px solid #e4e7ed;
    font-size: 12px;
    line-height: 30px;
    cursor: pointer;
    color: #005790;
    text-align: center;
    margin-top: 8px
}

.padding-item[data-v-7116be8f] {
    padding: 0 4px
}

.form-item[data-v-7116be8f] {
    margin-top: 14px;
    position: relative
}

.form-item[data-v-7116be8f]:before {
    content: attr(data-name);
    position: absolute;
    top: -12px;
    font-size: 11px
}

.fields-item[data-v-7116be8f] {
    background: #f0f0f0;
    padding: 4px;
    margin-left: 4px;
    margin-right: 4px
}

.buttons-item[data-v-7116be8f] {
    margin-top: 4px
}

.aio-view-item .name {
    display: inline-block;
    vertical-align: top;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.view-list-item[data-v-b4a743b4] {
    text-transform: capitalize
}

.right-icon[data-v-6c9679b1],
.right-icon[data-v-63a53a5e] {
    float: right;
    padding: 4px
}

.compare-section[data-v-11ea524e] {
    position: relative
}

.compare-stock[data-v-11ea524e] {
    background: var(--color-primary-surface);
    border: 1px solid var(--color-primary-border);
    color: var(--color-primary-color);
    font-size: 10px;
    height: 18px;
    margin-left: 1px;
    padding: 0 2px;
    border-radius: var(--size-radius-sm)
}

.compare-stock .el-icon-close[data-v-11ea524e]:hover {
    color: var(--color-10);
    background: var(--color-3)
}

.compare-input[data-v-11ea524e] {
    width: 200px
}

.compare-dropdown[data-v-11ea524e] {
    position: fixed;
    min-width: 200px;
    background: var(--color-bg);
    border: 1px solid var(--color-border);
    z-index: 200
}

.compare-dropdown>div[data-v-11ea524e] {
    white-space: nowrap;
    padding: 0 4px
}

.compare-dropdown .compare-stock-symbol[data-v-11ea524e] {
    display: inline-block;
    width: 64px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle
}

.compare-dropdown .compare-stock-price[data-v-11ea524e] {
    font-size: 11px;
    width: 60px;
    display: inline-block;
    font-weight: 700;
    vertical-align: middle
}

.compare-dropdown .compare-stock-currency[data-v-11ea524e] {
    color: #67c23a;
    font-size: 8px;
    vertical-align: text-bottom
}

.compare-dropdown .compare-stock-company[data-v-11ea524e] {
    font-size: 10px;
    vertical-align: middle
}

.subscribe-link[data-v-07336dc1] {
    display: inline-block;
    font-size: 16px;
    line-height: 36px;
    background: #fc3;
    color: #f53;
    border: 1px solid #f53;
    border-radius: 4px;
    width: 100%
}

.aio-tabs-button[data-v-1c197dc5] {
    position: relative
}

.aio-tabs-button img[data-v-1c197dc5] {
    height: 8px;
    position: absolute;
    top: -2px;
    right: -6px
}

.view-field-editor[data-v-0c47b914] {
    max-width: 80vw;
    border: 1px solid var(--color-border);
    background: var(--color-bg);
    box-shadow: 0 0 10px rgba(var(--color-shadow-rgb), .12)
}

.add-to-portfolio[data-v-7e9f3ef1] {
    font-size: 12px;
    line-height: 28px;
    display: inline-block;
    cursor: pointer;
    margin: 0 8px
}

.comparision-add-to-portfolio[data-v-7e9f3ef1] {
    font-size: 12px;
    color: #fff;
    cursor: pointer
}

.screener-view[data-v-3e179206] {
    position: relative
}

.view-field-editor[data-v-3e179206] {
    position: absolute;
    z-index: 400;
    margin: 8px
}

#logo[data-v-7eeb50bc] {
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    margin-top: 10px;
    margin-left: 20px;
    padding: 8px;
    border-radius: 8px;
    position: relative;
    cursor: pointer
}

#logo .add-icon[data-v-7eeb50bc] {
    background: var(--color-8);
    height: 20px;
    position: absolute;
    top: 40px;
    left: 49px;
    width: 2px
}

#logo .add-icon[data-v-7eeb50bc]:after {
    background: var(--color-8);
    content: "";
    height: 2px;
    top: 9px;
    left: -9px;
    position: absolute;
    width: 20px
}

#logo img[data-v-7eeb50bc] {
    height: 100%;
    width: 100%;
    border-radius: 8px
}

#logo .icon-edit[data-v-7eeb50bc] {
    top: 0;
    color: #6495ed
}

#logo .icon-edit[data-v-7eeb50bc],
#logo .icon-minus[data-v-7eeb50bc] {
    position: absolute;
    right: -7px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 0 12px 1px #b8b8b8
}

#logo .icon-minus[data-v-7eeb50bc] {
    bottom: 0;
    color: var(--color-danger-color)
}

#logo .icon-minus[data-v-7eeb50bc]:active {
    transform: scale(.95);
    background-image: linear-gradient(#f4f5f5, #dfdddd)
}

#logo[data-v-7eeb50bc] .el-loading-mask {
    border-radius: 8px
}

.has-img-border[data-v-7eeb50bc] {
    border: 1px solid var(--color-6)
}

.has-img-border[data-v-7eeb50bc]:hover {
    border: 1px solid var(--color-primary-border)
}

.no-img-border[data-v-7eeb50bc] {
    border: 2px dashed var(--color-6)
}

.no-img-border[data-v-7eeb50bc]:hover {
    border: 2px dashed var(--color-primary-border)
}

.left-right .preview-section[data-v-0068385f] {
    min-height: 100px;
    min-width: 200px
}

.left-right .preview-section img[data-v-0068385f] {
    width: 100%
}

.left-right .dialog-container[data-v-0068385f] {
    border-top: 1px solid var(--color-border);
    padding-top: 16px
}

.left-right .share-item[data-v-0068385f] {
    padding-bottom: 16px
}

.left-right .share-item .label[data-v-0068385f] {
    padding-bottom: 2px;
    display: block
}

.left-right .link-input[data-v-0068385f] {
    width: 100%
}

.top-bottom .preview-section[data-v-0068385f] {
    min-height: 100px;
    min-width: 200px
}

.top-bottom .preview-section img[data-v-0068385f] {
    width: 100%
}

.top-bottom .dialog-container[data-v-0068385f] {
    border-top: 1px solid var(--color-border);
    padding-top: 16px
}

.top-bottom .share-item[data-v-0068385f] {
    display: flex;
    padding-bottom: 12px;
    line-height: 28px
}

.top-bottom .share-item .label[data-v-0068385f] {
    flex-basis: 100px;
    display: inline-block
}

.top-bottom .share-item .el-input-group__append .el-button[data-v-0068385f] {
    color: var(--color-primary-color)
}

.top-bottom .share-item .el-input-group__append .el-button.is-loading[data-v-0068385f] {
    color: var(--color-6)
}

.top-bottom .link-input[data-v-0068385f] {
    width: calc(100% - 105px)
}

.top-bottom .social-share-section[data-v-0068385f] {
    display: flex
}

.top-bottom .social-share-section[data-v-0068385f]>div {
    margin: 2px
}

.top-bottom .social-share-section[data-v-0068385f] .el-checkbox {
    margin-right: 4px;
    color: var(--color-9);
    font-weight: 400
}

.setting-item[data-v-0068385f] {
    flex-direction: column;
    padding-bottom: var(--size-xs)
}

.setting-item span.t-label[data-v-0068385f] {
    font-weight: 500
}

.inline-setting[data-v-0068385f] {
    flex-direction: row !important
}

.inline-setting span.t-label[data-v-0068385f] {
    flex-basis: 50px;
    flex-shrink: 0
}

.scale-input[data-v-0068385f] .el-input__prefix,
.scale-input[data-v-0068385f] .el-input__suffix {
    color: var(--color-8);
    font-size: 16px;
    line-height: 28px;
    cursor: pointer
}

.scale-input[data-v-0068385f] .el-input__suffix {
    padding-right: 6px
}

.scale-input[data-v-0068385f] .el-input__inner {
    text-align: center
}

.icon-position[data-v-0068385f] {
    position: relative;
    top: 4px
}

.logo-note-node[data-v-0068385f] {
    position: absolute;
    left: 188px;
    border: 1px dashed var(--color-6);
    padding: 2px;
    color: var(--color-6);
    font-size: 12px
}

.top-right[data-v-0692c30f] {
    z-index: 100;
    position: absolute;
    top: -4px;
    right: 4px
}

.bottom-right[data-v-0692c30f] {
    float: right
}

.top-place-holder[data-v-0692c30f] {
    height: 18px
}

.download-dropdown[data-v-0692c30f] {
    overflow: hidden;
    position: relative;
    top: 8px
}

.chart-title[data-v-0692c30f]:first-letter {
    text-transform: capitalize
}

.legend-popover-node[data-v-0692c30f],
.legend-tooltip-node[data-v-0692c30f] {
    position: absolute;
    top: 4px;
    left: 4px
}

.legend-btn[data-v-0692c30f] {
    border: none;
    padding: 5px 7px !important
}

.legend-node[data-v-0692c30f] {
    flex-direction: column
}

.legend-tooltip-node[data-v-0692c30f] {
    gap: 8px
}

.legend-tooltip-icon[data-v-0692c30f] {
    cursor: pointer
}

.premium-icon[data-v-0692c30f] {
    position: relative;
    top: 2px
}

.backtesting-performance[data-v-664c96f0] {
    padding: 10px
}

.backtesting-table[data-v-664c96f0] {
    width: auto !important;
    min-width: 50%;
    margin-bottom: 20px
}

.backtesting-table td[data-v-664c96f0]:nth-child(n+1) {
    text-align: right
}

.aio-testing-go[data-v-46c8040e] {
    font-size: 12px;
    background: #67c23a;
    color: #fff !important;
    padding: 0 12px;
    border-radius: 2px
}

.aio-testing-blank-page[data-v-46c8040e] {
    min-height: 300px;
    width: 100%;
    text-align: center;
    border: 1px solid var(--color-border);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center
}

.support-item[data-v-46c8040e] {
    margin: 0
}

.field-name[data-v-46c8040e] {
    font-style: italic;
    font-weight: 600
}

.emphasize[data-v-46c8040e] {
    font-weight: 600
}

.warning-list[data-v-46c8040e] {
    margin-top: 12px;
    text-align: left;
    padding-left: 0;
    list-style-type: circle;
    margin-left: 16px
}

.warning-title[data-v-46c8040e] {
    margin-bottom: 0 !important;
    margin-top: 8px;
    font-size: 13px
}

.with-margin[data-v-46c8040e] {
    margin-right: 8px;
    margin-left: 8px
}

.backtesting-result-tabs .aio-tabs-item {
    margin: 12px 8px 4px
}

.backtesting-result-tabs .aio-tabs-title {
    margin: 12px 4px 4px
}

.download-button {
    font-size: 12px;
    line-height: 28px;
    display: inline-block;
    cursor: pointer;
    margin: 0 8px !important
}

.download-button img {
    height: 10px;
    vertical-align: baseline
}

.introjs-overlay {
    position: absolute;
    box-sizing: content-box;
    z-index: 999999;
    background-color: #000;
    opacity: 0;
    background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, .4) 0, rgba(0, 0, 0, .9) 100%);
    background: -webkit-gradient(radial, center center, 0, center center, 100%, color-stop(0, rgba(0, 0, 0, .4)), color-stop(100%, rgba(0, 0, 0, .9)));
    background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, .4) 0, rgba(0, 0, 0, .9) 100%);
    background: -o-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, .4) 0, rgba(0, 0, 0, .9) 100%);
    background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, .4) 0, rgba(0, 0, 0, .9) 100%);
    background: radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, .4) 0, rgba(0, 0, 0, .9) 100%);
    filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000',endColorstr='#e6000000',GradientType=1)";
    -ms-filter: "alpha(opacity=50)";
    filter: alpha(opacity=50);
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out
}

.introjs-fixParent {
    z-index: auto !important;
    opacity: 1 !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
    transform: none !important
}

.introjs-showElement,
tr.introjs-showElement>td,
tr.introjs-showElement>th {
    z-index: 9999999 !important
}

.introjs-disableInteraction {
    z-index: 99999999 !important;
    position: absolute;
    background-color: #fff;
    opacity: 0;
    filter: alpha(opacity=0)
}

.introjs-relativePosition,
tr.introjs-showElement>td,
tr.introjs-showElement>th {
    position: relative
}

.introjs-helperLayer {
    z-index: 9999998;
    background-color: #fff;
    background-color: hsla(0, 0%, 100%, .9);
    border: 1px solid #777;
    border: 1px solid rgba(0, 0, 0, .5);
    border-radius: 4px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, .4)
}

.introjs-helperLayer,
.introjs-tooltipReferenceLayer {
    box-sizing: content-box;
    position: absolute;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out
}

.introjs-tooltipReferenceLayer {
    visibility: hidden;
    z-index: 100000000;
    background-color: transparent
}

.introjs-helperLayer *,
.introjs-helperLayer :after,
.introjs-helperLayer :before {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -ms-box-sizing: content-box;
    -o-box-sizing: content-box;
    box-sizing: content-box
}

.introjs-helperNumberLayer {
    box-sizing: content-box;
    position: absolute;
    visibility: visible;
    top: -16px;
    left: -16px;
    z-index: 9999999999 !important;
    padding: 2px;
    font-family: Arial, verdana, tahoma;
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
    background: #ff3019;
    background: -webkit-linear-gradient(top, #ff3019, #cf0404);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ff3019), color-stop(100%, #cf0404));
    background: -moz-linear-gradient(top, #ff3019 0, #cf0404 100%);
    background: -ms-linear-gradient(top, #ff3019 0, #cf0404 100%);
    background: -o-linear-gradient(top, #ff3019 0, #cf0404 100%);
    background: linear-gradient(180deg, #ff3019 0, #cf0404);
    width: 20px;
    height: 20px;
    line-height: 20px;
    border: 3px solid #fff;
    border-radius: 50%;
    filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3019', endColorstr='#cf0404', GradientType=0)";
    filter: "progid:DXImageTransform.Microsoft.Shadow(direction=135, strength=2, color=ff0000)";
    box-shadow: 0 2px 5px rgba(0, 0, 0, .4)
}

.introjs-arrow {
    border: 5px solid transparent;
    content: "";
    position: absolute
}

.introjs-arrow.top,
.introjs-arrow.top-right {
    top: -10px;
    border-bottom-color: #fff
}

.introjs-arrow.top-right {
    right: 10px
}

.introjs-arrow.top-middle {
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: #fff
}

.introjs-arrow.right {
    right: -10px;
    top: 10px;
    border-left-color: #fff
}

.introjs-arrow.right-bottom {
    bottom: 10px;
    right: -10px;
    border-left-color: #fff
}

.introjs-arrow.bottom,
.introjs-arrow.bottom-right {
    bottom: -10px;
    border-top-color: #fff
}

.introjs-arrow.bottom-right {
    right: 10px
}

.introjs-arrow.bottom-middle {
    bottom: -10px;
    left: 50%;
    margin-left: -5px;
    border-top-color: #fff
}

.introjs-arrow.left {
    left: -10px;
    top: 10px;
    border-right-color: #fff
}

.introjs-arrow.left-bottom {
    left: -10px;
    bottom: 10px;
    border-right-color: #fff
}

.introjs-tooltip {
    box-sizing: content-box;
    position: absolute;
    visibility: visible;
    padding: 10px;
    background-color: #fff;
    min-width: 200px;
    max-width: 300px;
    border-radius: 3px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .4);
    -webkit-transition: opacity .1s ease-out;
    -moz-transition: opacity .1s ease-out;
    -ms-transition: opacity .1s ease-out;
    -o-transition: opacity .1s ease-out;
    transition: opacity .1s ease-out
}

.introjs-tooltipbuttons {
    text-align: right;
    white-space: nowrap
}

.introjs-button {
    box-sizing: content-box;
    position: relative;
    overflow: visible;
    display: inline-block;
    padding: .3em .8em;
    border: 1px solid #d4d4d4;
    text-decoration: none;
    text-shadow: 1px 1px 0 #fff;
    font: 11px/normal sans-serif;
    color: #333;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    background-color: #ececec;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f4f4f4), to(#ececec));
    background-image: -moz-linear-gradient(#f4f4f4, #ececec);
    background-image: -o-linear-gradient(#f4f4f4, #ececec);
    background-image: linear-gradient(#f4f4f4, #ececec);
    -webkit-background-clip: padding;
    -moz-background-clip: padding;
    -o-background-clip: padding-box;
    -webkit-border-radius: .2em;
    -moz-border-radius: .2em;
    border-radius: .2em;
    zoom: 1;
    display: inline;
    margin: 10px 0 0
}

.introjs-button:hover {
    border-color: #bcbcbc;
    text-decoration: none;
    box-shadow: 0 1px 1px #e3e3e3
}

.introjs-button:active,
.introjs-button:focus {
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ececec), to(#f4f4f4));
    background-image: -moz-linear-gradient(#ececec, #f4f4f4);
    background-image: -o-linear-gradient(#ececec, #f4f4f4);
    background-image: linear-gradient(#ececec, #f4f4f4)
}

.introjs-button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.introjs-skipbutton {
    box-sizing: content-box;
    margin-right: 5px;
    color: #7a7a7a
}

.introjs-prevbutton {
    -webkit-border-radius: .2em 0 0 .2em;
    -moz-border-radius: .2em 0 0 .2em;
    border-radius: .2em 0 0 .2em;
    border-right: none
}

.introjs-prevbutton.introjs-fullbutton {
    border: 1px solid #d4d4d4;
    -webkit-border-radius: .2em;
    -moz-border-radius: .2em;
    border-radius: .2em
}

.introjs-nextbutton {
    -webkit-border-radius: 0 .2em .2em 0;
    -moz-border-radius: 0 .2em .2em 0;
    border-radius: 0 .2em .2em 0
}

.introjs-nextbutton.introjs-fullbutton {
    -webkit-border-radius: .2em;
    -moz-border-radius: .2em;
    border-radius: .2em
}

.introjs-disabled,
.introjs-disabled:focus,
.introjs-disabled:hover {
    color: #9a9a9a;
    border-color: #d4d4d4;
    box-shadow: none;
    cursor: default;
    background-color: #f4f4f4;
    background-image: none;
    text-decoration: none
}

.introjs-hidden {
    display: none
}

.introjs-bullets {
    text-align: center
}

.introjs-bullets ul {
    box-sizing: content-box;
    clear: both;
    margin: 15px auto 0;
    padding: 0;
    display: inline-block
}

.introjs-bullets ul li {
    box-sizing: content-box;
    list-style: none;
    float: left;
    margin: 0 2px
}

.introjs-bullets ul li a {
    box-sizing: content-box;
    display: block;
    width: 6px;
    height: 6px;
    background: #ccc;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    text-decoration: none;
    cursor: pointer
}

.introjs-bullets ul li a.active,
.introjs-bullets ul li a:hover {
    background: #999
}

.introjs-progress {
    box-sizing: content-box;
    overflow: hidden;
    height: 10px;
    margin: 10px 0 5px;
    border-radius: 4px;
    background-color: #ecf0f1
}

.introjs-progressbar {
    box-sizing: content-box;
    float: left;
    width: 0;
    height: 100%;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    background-color: #08c
}

.introjsFloatingElement {
    position: absolute;
    height: 0;
    width: 0;
    left: 50%;
    top: 50%
}

.introjs-fixedTooltip {
    position: fixed
}

.introjs-hint {
    box-sizing: content-box;
    position: absolute;
    background: 0 0;
    width: 20px;
    height: 15px;
    cursor: pointer
}

.introjs-hint:focus {
    border: 0;
    outline: 0
}

.introjs-hidehint {
    display: none
}

.introjs-fixedhint {
    position: fixed
}

.introjs-hint:hover>.introjs-hint-pulse {
    border: 5px solid rgba(60, 60, 60, .57)
}

.introjs-hint-pulse {
    box-sizing: content-box;
    width: 10px;
    height: 10px;
    border: 5px solid rgba(60, 60, 60, .27);
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: hsla(0, 0%, 53.3%, .24);
    z-index: 10;
    position: absolute;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out
}

.introjs-hint-no-anim .introjs-hint-dot {
    -webkit-animation: none;
    -moz-animation: none;
    animation: none
}

.introjs-hint-dot {
    box-sizing: content-box;
    border: 10px solid hsla(0, 0%, 57.3%, .36);
    background: 0 0;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    border-radius: 60px;
    height: 50px;
    width: 50px;
    -webkit-animation: introjspulse 3s ease-out;
    -moz-animation: introjspulse 3s ease-out;
    animation: introjspulse 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    position: absolute;
    top: -25px;
    left: -25px;
    z-index: 1;
    opacity: 0
}

@-webkit-keyframes introjspulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0
    }

    25% {
        -webkit-transform: scale(0);
        opacity: .1
    }

    50% {
        -webkit-transform: scale(.1);
        opacity: .3
    }

    75% {
        -webkit-transform: scale(.5);
        opacity: .5
    }

    to {
        -webkit-transform: scale(1);
        opacity: 0
    }
}

@-moz-keyframes introjspulse {
    0% {
        -moz-transform: scale(0);
        opacity: 0
    }

    25% {
        -moz-transform: scale(0);
        opacity: .1
    }

    50% {
        -moz-transform: scale(.1);
        opacity: .3
    }

    75% {
        -moz-transform: scale(.5);
        opacity: .5
    }

    to {
        -moz-transform: scale(1);
        opacity: 0
    }
}

@keyframes introjspulse {
    0% {
        transform: scale(0);
        opacity: 0
    }

    25% {
        transform: scale(0);
        opacity: .1
    }

    50% {
        transform: scale(.1);
        opacity: .3
    }

    75% {
        transform: scale(.5);
        opacity: .5
    }

    to {
        transform: scale(1);
        opacity: 0
    }
}

.el-steps--vertical {
    -webkit-box-direction: normal
}

.el-step__icon-inner {
    -moz-user-select: none;
    -webkit-user-select: none
}

.el-step.is-vertical,
.el-steps {
    display: -webkit-box;
    display: -ms-flexbox
}

.el-steps {
    display: flex
}

.el-steps--simple {
    padding: 13px 8%;
    border-radius: 4px;
    background: #f5f7fa
}

.el-steps--horizontal {
    white-space: nowrap
}

.el-steps--vertical {
    height: 100%;
    -webkit-box-orient: vertical;
    -ms-flex-flow: column;
    flex-flow: column
}

.el-step {
    position: relative;
    -ms-flex-negative: 1;
    flex-shrink: 1
}

.el-step:last-of-type .el-step__line {
    display: none
}

.el-step:last-of-type.is-flex {
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0
}

.el-step:last-of-type .el-step__description,
.el-step:last-of-type .el-step__main {
    padding-right: 0
}

.el-step__head {
    position: relative;
    width: 100%
}

.el-step__head.is-process {
    color: var(--color-9);
    border-color: var(--color-9)
}

.el-step__head.is-wait {
    color: #c0c4cc;
    border-color: #c0c4cc
}

.el-step__head.is-success {
    color: #67c23a;
    border-color: #67c23a
}

.el-step__head.is-error {
    color: #f56c6c;
    border-color: #f56c6c
}

.el-step__head.is-finish {
    color: #409eff;
    border-color: #409eff
}

.el-step__icon {
    position: relative;
    z-index: 1;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 24px;
    height: 24px;
    font-size: 14px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: var(--color-bg);
    -webkit-transition: .15s ease-out;
    transition: .15s ease-out
}

.el-step__icon.is-text {
    border-radius: 50%;
    border: 2px solid;
    border-color: inherit
}

.el-step__icon.is-icon {
    width: 40px
}

.el-step__icon-inner {
    display: inline-block;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    font-weight: 700;
    line-height: 1;
    color: inherit
}

.el-step__icon-inner[class*=el-icon]:not(.is-status) {
    font-size: 25px;
    font-weight: 400
}

.el-step__icon-inner.is-status {
    -webkit-transform: translateY(1px);
    transform: translateY(1px)
}

.el-step__line {
    position: absolute;
    border-color: inherit;
    background-color: #c0c4cc
}

.el-step__line-inner {
    display: block;
    border: 1px solid;
    border-color: inherit;
    -webkit-transition: .15s ease-out;
    transition: .15s ease-out;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 0;
    height: 0
}

.el-step__main {
    white-space: normal;
    text-align: left
}

.el-step__title {
    font-size: 16px;
    line-height: 38px
}

.el-step__title.is-process {
    font-weight: 700;
    color: var(--color-9)
}

.el-step__title.is-wait {
    color: #c0c4cc
}

.el-step__title.is-success {
    color: #67c23a
}

.el-step__title.is-error {
    color: #f56c6c
}

.el-step__title.is-finish {
    color: #409eff
}

.el-step__description {
    padding-right: 10%;
    margin-top: -5px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400
}

.el-step__description.is-process {
    color: var(--color-9)
}

.el-step__description.is-wait {
    color: #c0c4cc
}

.el-step__description.is-success {
    color: #67c23a
}

.el-step__description.is-error {
    color: #f56c6c
}

.el-step__description.is-finish {
    color: #409eff
}

.el-step.is-horizontal {
    display: inline-block
}

.el-step.is-horizontal .el-step__line {
    height: 2px;
    top: 11px;
    left: 0;
    right: 0
}

.el-step.is-vertical {
    display: flex
}

.el-step.is-vertical .el-step__head {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    width: 24px
}

.el-step.is-vertical .el-step__main {
    padding-left: 10px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.el-step.is-vertical .el-step__title {
    line-height: 24px;
    padding-bottom: 8px
}

.el-step.is-vertical .el-step__line {
    width: 2px;
    top: 0;
    bottom: 0;
    left: 11px
}

.el-step.is-vertical .el-step__icon.is-icon {
    width: 24px
}

.el-step.is-center .el-step__head,
.el-step.is-center .el-step__main {
    text-align: center
}

.el-step.is-center .el-step__description {
    padding-left: 20%;
    padding-right: 20%
}

.el-step.is-center .el-step__line {
    left: 50%;
    right: -50%
}

.el-step.is-simple {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.el-step.is-simple .el-step__head {
    width: auto;
    font-size: 0;
    padding-right: 10px
}

.el-step.is-simple .el-step__icon {
    background: 0 0;
    width: 16px;
    height: 16px;
    font-size: 12px
}

.el-step.is-simple .el-step__icon-inner[class*=el-icon]:not(.is-status) {
    font-size: 18px
}

.el-step.is-simple .el-step__icon-inner.is-status {
    -webkit-transform: scale(.8) translateY(1px);
    transform: scale(.8) translateY(1px)
}

.el-step.is-simple .el-step__main {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.el-step.is-simple .el-step__title {
    font-size: 16px;
    line-height: 20px
}

.el-step.is-simple:not(:last-of-type) .el-step__title {
    max-width: 50%;
    word-break: break-all
}

.el-step.is-simple .el-step__arrow {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.el-step.is-simple .el-step__arrow:after,
.el-step.is-simple .el-step__arrow:before {
    content: "";
    display: inline-block;
    position: absolute;
    height: 15px;
    width: 1px;
    background: #c0c4cc
}

.el-step.is-simple .el-step__arrow:before {
    -webkit-transform: rotate(-45deg) translateY(-4px);
    transform: rotate(-45deg) translateY(-4px);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0
}

.el-step.is-simple .el-step__arrow:after {
    -webkit-transform: rotate(45deg) translateY(4px);
    transform: rotate(45deg) translateY(4px);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%
}

.el-step.is-simple:last-of-type .el-step__arrow {
    display: none
}

.check-list-steps {
    width: 80%;
    max-width: 800px;
    margin: 60px auto;
    padding: 24px;
    border: 1px solid var(--color-border);
    background: var(--color-bg);
    border-radius: 4px
}

.pagination[data-v-5ee715d9] {
    margin-top: 2px
}

.pagination-item[data-v-5ee715d9] {
    display: inline-block;
    font-size: 12px;
    line-height: 26px;
    vertical-align: bottom;
    margin-right: 8px
}

.download-button[data-v-c35df976] {
    font-size: 12px;
    line-height: 28px;
    display: inline-block;
    cursor: pointer;
    margin: 0 8px !important
}

.download-button img[data-v-c35df976] {
    height: 10px;
    vertical-align: baseline
}

.el-date-editor {
    position: relative;
    display: inline-block;
    text-align: left
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 220px
}

.el-date-editor--monthrange.el-input,
.el-date-editor--monthrange.el-input__inner {
    width: 300px
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
    width: 350px
}

.el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
    width: 400px
}

.el-date-editor--dates .el-input__inner {
    text-overflow: ellipsis;
    white-space: nowrap
}

.el-date-editor .el-icon-circle-close {
    cursor: pointer
}

.el-date-editor .el-range__icon {
    font-size: 14px;
    margin-left: -5px;
    color: #c0c4cc;
    float: left;
    line-height: 32px
}

.el-date-editor .el-range-input {
    appearance: none;
    border: none;
    outline: none;
    display: inline-block;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 39%;
    text-align: center;
    font-size: 14px;
    color: var(--color-8);
    background-color: var(--color-tooltip-bg)
}

.el-date-editor .el-range-input::placeholder {
    color: #c0c4cc
}

.el-date-editor .el-range-separator {
    display: inline-block;
    height: 100%;
    padding: 0 5px;
    margin: 0;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    width: 5%;
    color: var(--color-9)
}

.el-date-editor .el-range__close-icon {
    font-size: 14px;
    color: #c0c4cc;
    width: 25px;
    display: inline-block;
    float: right;
    line-height: 32px
}

.el-date-table {
    font-size: 12px;
    user-select: none
}

.el-date-table.is-week-mode .el-date-table__row:hover div {
    background-color: #f2f6fc
}

.el-date-table.is-week-mode .el-date-table__row:hover td.available:hover {
    color: var(--color-8)
}

.el-date-table.is-week-mode .el-date-table__row:hover td:first-child div {
    margin-left: 5px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px
}

.el-date-table.is-week-mode .el-date-table__row:hover td:last-child div {
    margin-right: 5px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px
}

.el-date-table.is-week-mode .el-date-table__row.current div {
    background-color: #f2f6fc
}

.el-date-table td {
    width: 32px;
    height: 30px;
    padding: 4px 0;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    position: relative
}

.el-date-table td div {
    height: 30px;
    padding: 3px 0;
    box-sizing: border-box
}

.el-date-table td span {
    width: 24px;
    height: 24px;
    display: block;
    margin: 0 auto;
    line-height: 24px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%
}

.el-date-table td.next-month,
.el-date-table td.prev-month {
    color: var(--color-disabled)
}

.el-date-table td.today {
    position: relative
}

.el-date-table td.today span {
    color: #409eff;
    font-weight: 700
}

.el-date-table td.today.end-date span,
.el-date-table td.today.start-date span {
    color: #fff
}

.el-date-table td.available:hover {
    color: #409eff
}

.el-date-table td.in-range div,
.el-date-table td.in-range div:hover {
    background-color: rgba(12, 97, 247, .05)
}

.el-date-table td.current:not(.disabled) span {
    color: #fff;
    background-color: #409eff
}

.el-date-table td.end-date div,
.el-date-table td.start-date div {
    color: #fff
}

.el-date-table td.end-date span,
.el-date-table td.start-date span {
    background-color: var(--color-primary-color)
}

.el-date-table td.start-date div {
    margin-left: 5px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px
}

.el-date-table td.end-date div {
    margin-right: 5px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px
}

.el-date-table td.disabled div {
    background-color: var(--color-disabled-bg);
    opacity: 1;
    cursor: not-allowed;
    color: var(--color-disabled)
}

.el-date-table td.selected div {
    margin-left: 5px;
    margin-right: 5px;
    background-color: rgba(12, 97, 247, .05);
    border-radius: 15px
}

.el-date-table td.selected div:hover {
    background-color: #f2f6fc
}

.el-date-table td.selected span {
    background-color: var(--color-primary-color);
    color: #fff;
    border-radius: 15px
}

.el-date-table td.week {
    font-size: 80%;
    color: var(--color-8)
}

.el-date-table th {
    padding: 5px;
    color: var(--color-8);
    font-weight: 400;
    border-bottom: 1px solid var(--color-border)
}

.el-range-editor.el-input__inner {
    display: inline-flex;
    align-items: center;
    padding: 3px 10px;
    background: 0 0
}

.el-range-editor .el-range-input {
    line-height: 1
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover {
    border-color: #409eff
}

.el-range-editor--medium.el-input__inner {
    height: 36px
}

.el-range-editor--medium .el-range-separator {
    line-height: 28px;
    font-size: 14px
}

.el-range-editor--medium .el-range-input {
    font-size: 14px
}

.el-range-editor--medium .el-range__close-icon,
.el-range-editor--medium .el-range__icon {
    line-height: 28px
}

.el-range-editor--small.el-input__inner {
    height: 32px
}

.el-range-editor--small .el-range-separator {
    line-height: 24px;
    font-size: 13px
}

.el-range-editor--small .el-range-input {
    font-size: 13px
}

.el-range-editor--small .el-range__close-icon,
.el-range-editor--small .el-range__icon {
    line-height: 24px
}

.el-range-editor--mini.el-input__inner {
    height: 28px
}

.el-range-editor--mini .el-range-separator {
    line-height: 20px;
    font-size: 12px
}

.el-range-editor--mini .el-range-input {
    font-size: 12px
}

.el-range-editor--mini .el-range__close-icon,
.el-range-editor--mini .el-range__icon {
    line-height: 20px
}

.el-range-editor.is-disabled {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed
}

.el-range-editor.is-disabled:focus,
.el-range-editor.is-disabled:hover {
    border-color: #e4e7ed
}

.el-range-editor.is-disabled input {
    background-color: #f5f7fa;
    color: #c0c4cc;
    cursor: not-allowed
}

.el-range-editor.is-disabled input::placeholder {
    color: #c0c4cc
}

.el-range-editor.is-disabled .el-range-separator {
    color: #c0c4cc
}

[data-v-438eb844] .el-button+.el-button {
    margin-left: 0
}

[data-v-438eb844] .el-button--mini {
    padding: 5px 8px !important
}

.date-picker-panel {
    margin-top: 0 !important
}

.date-picker-panel .popper__arrow {
    display: none
}

.reference[data-v-0262e0e7] {
    color: #606266;
    border: 1px solid var(--color-border);
    display: inline-block;
    padding: 3px;
    font-size: 12px;
    cursor: pointer;
    line-height: 19px
}

.reference .tag[data-v-0262e0e7] {
    color: var(--color-10);
    display: inline-block;
    border: 1px solid var(--color-border);
    background: var(--color-box-bg);
    line-height: 18px;
    font-size: 11px;
    margin-right: 2px;
    padding: 0 4px
}

#chart[data-v-10e995e1] {
    width: 100%;
    height: 250px
}

.portfolio-chart-select-item[data-v-10e995e1] {
    font-size: 11px
}

.date-btn[data-v-10e995e1] {
    font-size: 11px;
    margin-left: 0 !important
}

.text-red[data-v-943fb7a8] {
    color: var(--color-danger-color)
}

.text-green[data-v-943fb7a8] {
    color: var(--color-success-color)
}

.text-warning[data-v-943fb7a8] {
    color: var(--color-warning-color)
}

.play-icon[data-v-1755fece] {
    font-size: 10px;
    padding: 6px !important
}

.year-selector[data-v-1755fece] input.el-input__inner {
    border-color: var(--color-border);
    border-radius: var(--size-radius-md);
    height: 24px;
    line-height: 14px;
    font-size: 11px;
    padding: 0 var(--size-sm)
}

.year-selector[data-v-1755fece] .el-input__suffix {
    display: none
}

.play-icon[data-v-7659f6d6] {
    font-size: 10px;
    padding: 6px !important
}

.year-selector[data-v-7659f6d6] input.el-input__inner {
    border-color: var(--color-border);
    border-radius: var(--size-radius-md);
    height: 24px;
    line-height: 14px;
    font-size: 11px;
    padding: 0 var(--size-sm)
}

.year-selector[data-v-7659f6d6] .el-input__suffix {
    display: none
}

.chart-root[data-v-59ba56e4] {
    height: calc(100vh - 225px);
    min-height: 400px;
    width: 100%
}

.chart-section[data-v-59ba56e4] {
    position: relative
}

.portfolio-chart[data-v-59ba56e4] {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    min-height: 250px;
    box-sizing: border-box;
    border: 1px solid #e4e7ed;
    padding: 4px
}

.foot-section[data-v-59ba56e4] {
    border-top: 1px solid #dcdfe6
}

.table-check-list-section[data-v-59ba56e4] {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: hsla(0, 0%, 100%, .6666666666666666);
    z-index: 2001
}

.el-tree {
    position: relative;
    cursor: default;
    background: var(--color-bg);
    color: var(--color-8)
}

.el-tree.is-dragging .el-tree-node__content * {
    pointer-events: none
}

.el-tree__empty-block {
    position: relative;
    min-height: 60px;
    text-align: center;
    width: 100%;
    height: 100%
}

.el-tree__empty-text {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #909399
}

.el-tree__drop-indicator {
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #409eff
}

.el-tree-node {
    white-space: nowrap;
    outline: 0
}

.el-tree-node.is-drop-inner>.el-tree-node__content .el-tree-node__label {
    background-color: #409eff;
    color: #fff
}

.el-tree-node__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 26px;
    cursor: pointer
}

.el-tree-node__content>.el-tree-node__expand-icon {
    padding: 6px
}

.el-tree-node__content>.el-checkbox {
    margin-right: 8px
}

.el-tree-node__content:hover {
    background-color: var(--color-hover-bg);
    color: var(--color-primary-text)
}

.el-tree.is-dragging .el-tree-node__content {
    cursor: move
}

.el-tree.is-dragging.is-drop-not-allow .el-tree-node__content {
    cursor: not-allowed
}

.el-tree-node__expand-icon {
    cursor: pointer;
    color: #c0c4cc;
    font-size: 12px;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out
}

.el-tree-node__expand-icon.expanded {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.el-tree-node__expand-icon.is-leaf {
    color: transparent;
    cursor: default
}

.el-tree-node__label {
    font-size: 14px
}

.el-tree-node__loading-icon {
    margin-right: 8px;
    font-size: 14px;
    color: #c0c4cc
}

.el-tree-node>.el-tree-node__children {
    overflow: hidden;
    background-color: transparent
}

.el-tree-node.is-expanded>.el-tree-node__children {
    display: block
}

.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
    color: var(--color-primary-text);
    font-weight: 700;
    background-color: var(--color-active-bg)
}

::v-deep .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
    color: #195daa;
    font-weight: 700
}

.dark-border {
    border-color: #606266 !important
}

.dark {
    background: #404553 !important
}

.white-text {
    color: #fff !important
}

.first[data-v-7024daa6] {
    fill: #f63538
}

.second[data-v-7024daa6] {
    fill: #df3a3d
}

.three[data-v-7024daa6] {
    fill: #bf4044
}

.four[data-v-7024daa6] {
    fill: #824450
}

.five[data-v-7024daa6] {
    fill: #3a614f
}

.six[data-v-7024daa6] {
    fill: #31904e
}

.seven[data-v-7024daa6] {
    fill: #2fa450
}

.eight[data-v-7024daa6] {
    fill: #2fb854
}

.nine[data-v-7024daa6] {
    fill: #30cc5a
}

.legend-text[data-v-7024daa6] {
    fill: #fff;
    font-size: 12px;
    font-weight: 700
}

.my-button {
    positive: relative;
    top: 20%;
    text-align: left;
    padding: 5px;
    border-width: 0
}

h5[data-v-bba553c0] {
    margin: 8px 0
}

.item[data-v-a16adb1c]:hover {
    background: var(--color-primary-surface)
}

.my-font-size[data-v-6b32d5ee] {
    font-weight: 12px
}

.index-item[data-v-6b32d5ee] {
    padding-left: 5px;
    border-bottom: 1px solid #363a46;
    display: block;
    font-size: 12px;
    line-height: 29px;
    cursor: pointer
}

.bold-text[data-v-6b32d5ee] {
    font-weight: 700
}

.lite-blue-text[data-v-6b32d5ee] {
    color: #5faaf4 !important
}

.header-switch-white[data-v-6b32d5ee] {
    text-align: center;
    display: inline;
    width: 50px;
    position: relative;
    left: 49%;
    color: #fff;
    z-index: 99;
    border: 1px solid;
    font-size: 12px
}

[data-v-6b32d5ee] .el-tree-node__label {
    font-size: 12px
}

[data-v-6b32d5ee] .el-dropdown {
    display: inline-block;
    position: relative;
    color: #606266;
    font-size: 12px
}

.left[data-v-6b32d5ee] {
    position: absolute;
    left: 20px;
    overflow: hidden;
    width: 270px
}

.right[data-v-6b32d5ee] {
    position: absolute;
    left: 300px;
    width: calc(100vw - 370px);
    margin-left: 10px
}

::deep .el-popover[data-v-6b32d5ee] {
    background: #404553 !important
}

.dark-border[data-v-6b32d5ee] {
    border-color: #606266
}

.dark[data-v-6b32d5ee] {
    background: #404553
}

.white-text[data-v-6b32d5ee] {
    color: #fff
}

.my-button[data-v-6b32d5ee] {
    height: 100%;
    text-align: left;
    padding: 5px;
    border-width: 0
}

.box-border[data-v-6b32d5ee] {
    border-radius: 5px;
    border: 1px solid #5f6266
}

.box-height[data-v-6b32d5ee] {
    height: 40px
}

.map-root[data-v-6b32d5ee] {
    min-height: 600px;
    width: 100%
}

.map-section[data-v-6b32d5ee] {
    position: relative
}

.foot-section[data-v-6b32d5ee] {
    border-top: 1px solid #dcdfe6
}

.table-check-list-section[data-v-6b32d5ee] {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: hsla(0, 0%, 100%, .6666666666666666);
    z-index: 4
}

.switch[data-v-6b32d5ee] {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 25px
}

.switch input[data-v-6b32d5ee] {
    opacity: 0;
    width: 0;
    height: 0
}

.slider[data-v-6b32d5ee] {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    height: 20px;
    width: 35px
}

.slider[data-v-6b32d5ee]:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s
}

input:checked+.slider[data-v-6b32d5ee] {
    background-color: #2196f3
}

input:focus+.slider[data-v-6b32d5ee] {
    box-shadow: 0 0 1px #2196f3
}

input:checked+.slider[data-v-6b32d5ee]:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px)
}

.slider.round[data-v-6b32d5ee] {
    border-radius: 34px
}

.slider.round[data-v-6b32d5ee]:before {
    border-radius: 50%
}

.fields-description[data-v-d203bf58] {
    position: relative
}

.title[data-v-d203bf58] {
    padding: 4px 8px;
    cursor: pointer
}

.inactive[data-v-d203bf58] {
    color: #909399 !important
}

.content[data-v-d203bf58] {
    padding: 4px 8px
}

.mini-content[data-v-d203bf58] {
    position: fixed;
    width: 400px;
    right: 30px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    background: #f6f6f6;
    z-index: 9999;
    margin-top: -33px
}

.recent-screener[data-v-3a30cd0e] {
    position: relative
}

.content[data-v-3a30cd0e],
.title[data-v-3a30cd0e] {
    padding: 4px 8px
}

.mini-content[data-v-3a30cd0e] {
    position: fixed;
    width: 400px;
    right: 30px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    background: #f6f6f6;
    z-index: 9999;
    margin-top: -33px
}

.screener-field-list-editor .el-input[data-v-7c513049] {
    box-sizing: border-box;
    padding: 4px
}

.fields-section[data-v-7c513049] {
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden
}

.title[data-v-7c513049] {
    font-size: 11px;
    color: #909399;
    line-height: 24px;
    padding: 0 4px;
    border-bottom: 1px solid #dcdfe6
}

.title .icon[data-v-7c513049] {
    margin: 6px 0
}

.item[data-v-7c513049] {
    font-size: 12px;
    padding: 0 4px;
    position: relative;
    cursor: pointer
}

.item .el-checkbox[data-v-7c513049] {
    display: block;
    margin-right: 20px
}

.item .icon[data-v-7c513049] {
    position: absolute;
    top: 2px;
    right: 4px
}

.item[data-v-7c513049]:hover {
    background: #f0f0f0
}

.view-field-editor[data-v-6348473e] {
    width: 400px;
    max-width: 80vw;
    border: 1px solid #dcdfe6;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .12)
}

.title[data-v-6348473e] {
    background: #ebeef5;
    padding: 4px 8px;
    font-size: 12px;
    color: #606266
}

.side-rank-selector[data-v-9503ad48] {
    margin-top: 8px
}

.side-rank-hint[data-v-9503ad48] {
    font-style: italic;
    font-size: 11px;
    color: #606266;
    margin-top: 8px
}

.seperator[data-v-9503ad48] {
    border-top: 1px solid #e4e7ed
}

.side-title[data-v-985f88ac] {
    font-size: 12px;
    line-height: 20px;
    padding: 4px 9px;
    font-size: #606266;
    border-bottom: 1px solid var(--color-border);
    cursor: pointer
}

.side-content[data-v-985f88ac] {
    padding: 4px 9px;
    border-bottom: 1px solid var(--color-border);
    background: var(--color-box-bg);
    overflow: scroll
}

.side-content .side-title[data-v-985f88ac] {
    padding: 4px 0 !important
}

.side-collapse-content[data-v-985f88ac] {
    position: fixed;
    z-index: 100;
    border: 1px solid var(--color-border);
    box-sizing: border-box;
    right: 30px;
    width: 300px;
    height: 480px
}

.filter-box[data-v-6bb43de0] {
    display: flex !important
}

.filter-box[data-v-6bb43de0] .aio-item-content {
    width: unset !important;
    margin: 0 !important;
    padding: 0 var(--size-xs)
}

.filter-box[data-v-6bb43de0] .aio-item-title {
    display: none !important
}

.th-section[data-v-32daf176] {
    position: relative
}

.th-section:hover .popover-section[data-v-32daf176] {
    display: block
}

.title-section[data-v-32daf176] {
    margin-right: 8px;
    height: 28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.popover-section[data-v-32daf176] {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #ddd;
    padding: 2px;
    min-width: 100%;
    font-size: 12px;
    line-height: 18px;
    background: #f6f6f6;
    font-weight: 400;
    z-index: 9999;
    min-width: 200px
}

.popover-section .title[data-v-32daf176] {
    white-space: nowrap
}

.sort-button[data-v-32daf176] {
    position: absolute;
    bottom: 1px;
    right: 1px;
    font-size: 10px
}

.sort-button.el-icon-bottom[data-v-32daf176],
.sort-button.el-icon-top[data-v-32daf176] {
    font-weight: 700;
    color: green
}

.resize-button[data-v-32daf176] {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 8px;
    z-index: 1;
    cursor: col-resize;
    box-sizing: border-box
}

.resize-button-display[data-v-32daf176] {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000
}

.resize-button-display .divider[data-v-32daf176] {
    width: 1px;
    height: 100%;
    margin: 0 auto;
    background: #000
}

.popover-tooltip {
    margin-top: 0 !important;
    padding: 4px !important
}

.th-filter-section[data-v-7fcee23b] {
    height: 24px
}

[data-v-7fcee23b] .el-popover {
    border: 0;
    padding: 0
}

.th-section[data-v-7fcee23b] {
    position: relative
}

.title-section[data-v-7fcee23b] {
    margin-right: 8px
}

.popover-section[data-v-7fcee23b] {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400
}

.popover-section .title[data-v-7fcee23b] {
    white-space: nowrap
}

.sort-button[data-v-7fcee23b] {
    position: absolute;
    top: 12px;
    right: 4px;
    font-size: 10px
}

.sort-button.el-icon-bottom[data-v-7fcee23b],
.sort-button.el-icon-top[data-v-7fcee23b] {
    font-weight: 700;
    color: green
}

.resize-button[data-v-7fcee23b] {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 8px;
    z-index: 1;
    cursor: col-resize;
    box-sizing: border-box
}

.resize-button-display[data-v-7fcee23b] {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000
}

.resize-button-display .divider[data-v-7fcee23b] {
    width: 1px;
    height: 100%;
    margin: 0 auto;
    background: #000
}

.td-date {
    text-align: right;
    word-break: keep-all;
    white-space: nowrap
}

.avatar[data-v-43099cce] {
    height: 40px !important;
    width: 40px !important;
    border: 2px solid #e4e7ed;
    border-radius: 20px;
    color: #e4e7ed;
    font-size: 20px;
    line-height: 35px;
    font-weight: 700;
    text-align: center
}

.guru-title[data-v-43099cce] {
    color: #005790
}

.guru-title[data-v-43099cce],
.subtitle[data-v-43099cce] {
    font-size: 12px;
    line-height: 20px;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden
}

.subtitle[data-v-43099cce] {
    color: #606266
}

.guru-table[data-v-43099cce] {
    border-collapse: collapse;
    font-size: 12px;
    margin: 10px 0
}

.guru-table>tbody>tr>td[data-v-43099cce] {
    padding: 0 5px;
    word-break: keep-all;
    white-space: nowrap;
    border: 1px solid #ddd;
    color: #606266
}

.guru-table>thead>tr[data-v-43099cce] {
    border-top: 1px solid #ddd
}

.guru-table>thead>tr>th[data-v-43099cce] {
    padding: 5px 0;
    text-align: center
}

.collapse-content[data-v-43099cce] {
    font-size: 12px;
    color: #606266;
    text-align: left;
    max-width: 400px;
    max-height: 400px;
    overflow-y: auto;
    word-break: normal
}

.guru-router-link[data-v-6e617f0a] {
    text-decoration: none;
    cursor: pointer;
    margin: 0 !important
}

.td-guru {
    word-break: keep-all;
    white-space: auto
}

.td-range[data-v-596559bb] {
    width: 160px
}

.range-bar[data-v-596559bb] {
    height: 2px;
    width: 100%;
    background: #e4e7ed;
    position: relative
}

.range-cursor[data-v-596559bb] {
    position: absolute;
    top: -9px;
    color: #409eff
}

.range-label[data-v-596559bb] {
    font-size: 10px;
    height: 14px;
    color: #606266
}

.range-min[data-v-596559bb] {
    display: inline-block;
    width: 40px;
    overflow: hidden;
    text-align: left
}

.range-cur[data-v-596559bb] {
    display: inline-block;
    width: 80px;
    overflow: hidden;
    text-align: center
}

.range-max[data-v-596559bb] {
    display: inline-block;
    width: 40px;
    overflow: hidden;
    text-align: right
}

.data-table-boolean-field {
    text-align: right;
    font-size: 12px
}

.indicator-progress-bar {
    width: 100% !important;
    height: 7px !important;
    line-height: 14px;
    text-align: center;
    background-color: var(--color-3)
}

#chart[data-v-7a55eda0] {
    width: 100%;
    height: 170px
}

.play-icon[data-v-7a55eda0] {
    font-size: 10px;
    padding: 6px !important
}

.year-selector[data-v-7a55eda0] input.el-input__inner {
    border-color: var(--color-border);
    border-radius: var(--size-radius-md);
    height: 24px;
    line-height: 14px;
    font-size: 11px;
    padding: 0 var(--size-sm)
}

.year-selector[data-v-7a55eda0] .el-input__suffix {
    display: none
}

.desc-table tr>th[data-v-28f6d67e] {
    background: var(--color-bg-box);
    color: var(--color-9)
}

.desc-table tr>td[data-v-28f6d67e] {
    color: var(--color-10);
    border-color: var(--color-border) !important
}

.desc-table tbody>tr[data-v-28f6d67e]:hover {
    background: var(--color-primary-surface)
}

.sign-subscribe[data-v-ca1ac2fa] {
    display: block;
    font-size: 12px;
    line-height: 16px;
    background: red;
    color: var(--color-1);
    padding: 4px;
    border-radius: 4px;
    font-weight: 700
}

.sign-subscribe i[data-v-ca1ac2fa] {
    font-size: 20px;
    line-height: 32px;
    float: left;
    margin-right: 4px
}

.desc-section[data-v-17b09b2c] {
    border-top: 1px solid var(--color-border);
    word-break: normal
}

.link-section[data-v-17b09b2c] {
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border)
}

.link-section a[data-v-17b09b2c] {
    color: var(--color-primary-color)
}

.sign-section[data-v-17b09b2c] {
    border-bottom: 1px solid var(--color-border)
}

.left-col[data-v-17b09b2c] {
    width: 235px;
    padding-right: var(--size-md)
}

.right-col[data-v-17b09b2c] {
    width: 297px;
    padding-left: var(--size-md);
    border-left: 1px solid var(--color-border)
}

.el-icon-close[data-v-7875428c] {
    position: absolute;
    top: 4px;
    right: 4px
}

.stock-popover[data-v-7875428c] {
    width: 532px;
    max-width: 100%;
    position: relative
}

.color-primary[data-v-7875428c]:hover {
    color: var(--color-primary-color) !important
}

.call-tag[data-v-04c4fde8] {
    font-size: 9px;
    color: #606266
}

.subscribe-a[data-v-04c4fde8] {
    font-size: 11px;
    padding: 2px 4px;
    color: #ff8f00;
    font-weight: 700;
    border: 1px solid #ff8f00
}

.el-icon-minus[data-v-04c4fde8] {
    font-family: gf-icons !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    display: inline-block;
    -webkit-font-smoothing: antialiased
}

.el-icon-minus[data-v-04c4fde8]:hover {
    background-color: #b4bccc;
    color: #fff
}

.hide-button[data-v-04c4fde8] {
    display: inline;
    visibility: hidden;
    font-size: 8px
}

.td-stock[data-v-04c4fde8] {
    word-break: keep-all;
    white-space: nowrap;
    min-width: 100px;
    position: relative
}

.td-stock:hover .hide-button[data-v-04c4fde8] {
    visibility: visible;
    position: relative;
    top: calc(100% - 4px)
}

.portfolio-button[data-v-04c4fde8] {
    font-size: 10px;
    color: grey;
    position: absolute;
    top: calc(50% - 5px);
    right: 12px
}

.string-field-box {
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    max-width: 300px;
    text-overflow: ellipsis
}

.td-insider-buy {
    float: right;
    color: #195daa;
    text-decoration: none
}

.trend-td[data-v-384fddff] {
    min-width: 90px
}

.bar-chart-container[data-v-384fddff] {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px
}

.td-trading[data-v-69a10f09] {
    padding: 0 4px !important
}

.trading-table[data-v-69a10f09] {
    position: fixed;
    z-index: 100;
    border: 1px solid #dcdfe6;
    background: #fafafa;
    box-shadow: 0 0 10px rgba(0, 0, 0, .12);
    width: 600px;
    max-width: 80vw;
    max-height: 400px;
    overflow-y: auto;
    padding: 8px
}

.trading-tooltip[data-v-69a10f09] {
    padding: 0 4px
}

.stock-search[data-v-15750c70] {
    position: relative
}

.larger-container[data-v-15750c70] {
    height: auto;
    display: inline-block;
    margin: 5px 20px
}

.input-left[data-v-15750c70] {
    display: flex;
    align-items: center;
    margin: auto;
    height: 100%;
    color: var(--color-9);
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    min-height: 40px;
    line-height: 20px;
    position: absolute;
    right: 0;
    top: 0
}

.close-icon[data-v-15750c70] {
    margin-right: 5px;
    cursor: pointer;
    color: #4d4d4d
}

/* Estilos de la pantalla vistas */
.pantallaSelecFiltros{
    display: flex;
    gap: 50px;
}
.pantallaSelecFiltros .pantallaIzda{
    flex-basis: 70%;
}
.pantallaSelecFiltros .pantallaDrch{
    flex-basis: 30%;
}
/* .descripcion{
    display: flex;
    flex-direction: column;
} */

.search-dropdown[data-v-15750c70] {
    position: absolute;
    background: var(--color-bg);
    left: 0;
    right: 0;
    z-index: 9999;
    min-width: 150px;
    max-width: 250px;
    border: 1px solid var(--color-border);
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1)
}

.search-dropdown>[data-v-15750c70] {
    font-size: 12px;
    line-height: 20px
}

.stock-table-field-input[data-v-15750c70] {
    min-height: 25px
}

.selected-stock[data-v-15750c70] {
    background: var(--color-active-bg)
}

.drop-down-item[data-v-15750c70] {
    max-width: 250px;
    overflow: hidden;
    white-space: pre-wrap
}

.stock-search-cell[data-v-7a3c1ffa] {
    position: static
}

.stock-search[data-v-7a3c1ffa] {
    width: 100%;
    margin: 3px 5px 3px 0;
    min-height: 25px;
    position: static !important
}

.main-section {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative
}

.oculta {
    display: none;
}

.scroll-section {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: calc(100% - 30px);
    overflow-x: scroll
}

.sticky-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: var(--color-bg);
    overflow: hidden;
    z-index: 2
}

.sticky-header .screener-table {
    margin-right: 8px
}

.sticky-left-corner {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4
}

.sticky-right-corner {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
    padding-right: 4px;
    background: #fff
}

.sticky-right-column {
    position: absolute;
    right: 4px;
    top: 0;
    bottom: 0;
    background: var(--color-bg);
    z-index: 2;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, .12)
}

.sticky-right-column .screener-table {
    margin-bottom: 8px
}

.sticky-left-column {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: 0 0;
    z-index: 2;
    overflow: hidden;
    box-shadow: 0 10px 100px rgba(10, 16, 24, .08), 0 40px 56px -60px rgba(0, 0, 0, .08)
}

.sticky-left-column .screener-table {
    margin-bottom: 14px;
    background: var(--color-bg)
}

.sticky-left-column td {
    background: unset !important
}

.screener-table {
    border-collapse: collapse
}

.screener-table thead>tr>th:first-child {
    border-left: 0
}

.screener-table thead>tr>th:last-child {
    border-right: 0
}

.screener-table thead>tr>th {
    padding: 0 4px;
    text-align: left;
    background: var(--color-box-bg);
    color: var(--color-9);
    cursor: pointer;
    font-weight: 400;
    border-left: 2px solid;
    border-left-color: var(--color-border);
    border-bottom: 1px solid;
    border-bottom-color: var(--color-border);
    border-right: 2px solid;
    border-right-color: var(--color-border);
    border-top-color: var(--color-border);
    height: 36px
}

.screener-table thead>tr>th .hcell {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    height: 100%
}

.screener-table thead>tr>th:first-child .hcell {
    padding-left: var(--size-md)
}

.screener-table thead>tr>th .hcell-sm {
    height: unset !important
}

.screener-table tbody>tr>td {
    padding: 0 12px 0 4px;
    word-break: keep-all;
    white-space: nowrap;
    border-bottom: 1px solid var(--color-2);
    color: var(--color-9);
    font-size: 12px;
    line-height: 27px
}

.screener-table tbody>tr>td:first-child {
    background: var(--color-bg);
    padding-left: var(--size-md);
    font-weight: 500;
    font-size: 11px
}

.screener-table .hover-row,
.screener-table tbody>tr:hover {
    background: var(--color-hover-bg)
}

.table-root[data-v-4cbe18f4] {
    height: calc(100vh - 264.5px);
    min-height: 400px;
    width: 100%
}

.sticky-foot[data-v-4cbe18f4] {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid var(--color-border);
    background: var(--color-bg);
    z-index: 10;
    height: 30px;
    box-sizing: border-box
}

.table-section[data-v-4cbe18f4] {
    position: relative
}

.side-section[data-v-4cbe18f4] {
    border-left: 1px solid var(--color-border)
}

.foot-section[data-v-4cbe18f4] {
    border-top: 1px solid var(--color-border)
}

#data-table-stocky-footer[data-v-4cbe18f4] {
    display: flex;
    align-items: center
}

.subscribe-link[data-v-5f73546c] {
    display: inline-block;
    font-size: 16px;
    line-height: 36px;
    background: #fc3;
    color: #f53;
    border: 1px solid #f53;
    border-radius: 4px;
    width: 100%
}


.blob_red {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 1s 1;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}