.contenido-modal div{
    margin-top: 1%;
    margin-bottom: 1%;
}

.contenido-modal h3{
    font-size: 16px;
}

.input-via-y-numero, .div-direccion-interna, .input-municipio {
    display: flex;
    align-items: center;
    gap: 15px;
}

.input-municipio{
    padding-top: 1%;
}

.input-numero{
    width: 60px;
}

.input-direccion{
    padding-bottom: 2%;
}

.input-direccion-interna{
    width: 60px;
}

.div-resultado-busqueda{
    display: block;
    flex-direction: column;
    width: 100%;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    border: 1px solid lightgrey;
    border-radius: 10px;
    margin-bottom: 1%;
    color: grey;
    background-color: rgb(235, 235, 235);
}

.div-info-busqueda, .div-info-busqueda2{
    display: flex;
    gap: 10px;
}

.contenido-modal .div-info-busqueda{
    text-transform: uppercase;
    margin-bottom: 0.5%;
}

.div-info-busqueda p:first-child{
    font-weight: 600;
    color: black;
}

.div-info-busqueda2 p{
    padding-right: 10px;
    border-right: 1px solid grey;
}

.div-info-busqueda2 p:first-child{
    padding-left: none;
    border-left: none;
}

.div-info-busqueda2 p:last-child{
    padding-right: none;
    border-right: none;
}